import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  Breadcrumbs,
  Link,
  Backdrop,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import useStyles from "./styles.js";
import { BASE_URL } from "../../healpers/api.js";
import axios from "axios";
import api from "../../healpers/apiRoutes.js";
import { tokenConfig } from "../../context/UserContext.js";
import { useVendorState } from "../../context/VendorContext.js";
import BarChart from "./components/BarChart.js";
import Widget from "../../components/Widget/Widget.js";
import PrintIcon from "@material-ui/icons/Print";
import { exportReport, generateReportPdf, getCurrency } from "../../healpers/utilityFunctions.js";
import CreateActionItemModal from "../actionItems/components/createActionItemModal.js";
import { ACTION_ITEMS, FILTER_OPTIONS, chartLegend, forecastChartLegend, months } from "../../constant/wbr.js";
import Table from "./components/table.js"
import ChartLegend from "../../components/ChartLegend/ChartLegend.js";

export default function KeyOutputMetrics(props) {
  const vendorState = useVendorState();
  const classes = useStyles();
  const exportFunctionRef = useRef();
  const [metricsData, setMetricsData] = useState([]);
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [actionItemModal, setActionItemModal] = useState(false)
  const [asin, setAsin] = useState("")
  const [category, setCategory] = useState("")
  const [selectedFilter, setSelectedFilter] = useState("")
  const [currency, setCurrency] = useState("USD")


  const fetchKeyOutputMetrics = async () => {
    try {
      setFullPageLoader(true);
      const params = {
        vendorCode: vendorState?.selected,
      };
      if (asin !== "" && selectedFilter === FILTER_OPTIONS.ASIN) {
        params.asins = asin.split(",")
      }
      if (category !== "" && selectedFilter === FILTER_OPTIONS.CATEGORY) {
        params.category = category
      }
      const response = await axios(
        BASE_URL + api.keyOutputMetrics,
        tokenConfig(params)
      );
      setMetricsData(response.data.data);
      setFullPageLoader(false);
    } catch (error) {
      setFullPageLoader(false);
      alert(error.message || "Failed to Fetch Key Output metrics data")
      console.log("fetchKeyOutputMetrics - error", error);
    }
  };

  useEffect(() => {
    if (vendorState?.selected) {
      fetchKeyOutputMetrics();
      const currency = getCurrency(vendorState?.selected.split('_')[1])
      setCurrency(currency)
    }
  }, [vendorState?.selected]);


  const exportShippedRevenue = () => {
    const weeklyShippedRevenue = document.getElementById("weeklyShippedRevenue")
    const yearlyShippedRevenue = document.getElementById("yearlyShippedRevenue")
    generateReportPdf([weeklyShippedRevenue, yearlyShippedRevenue], 'Shipped Revenue.pdf')
  }

  const handleOnChange = (event) => {
    if (selectedFilter === FILTER_OPTIONS.CATEGORY) {
      setCategory(event.target.value)
    } else {
      setAsin(event.target.value)
    }
  }

  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.header}
      >
        <Grid item xs={6}>
          <Typography
            variant="h4"
            weight="medium"
            style={{ paddingBottom: "20px" }}
          >
            Key Output Metrics
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className={classes.link}>
              WBR
            </Link>
            <Typography className={classes.link} color="primary">
              Key Output Metrics
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={6}>
          <Grid container style={{ gap: 20 }} justifyContent="flex-end" alignItems="center">
            <Grid item>
              <FormControl style={{ minWidth: 100 }} fullWidth variant="outlined">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(event) => setSelectedFilter(event.target.value)}
                  value={selectedFilter}
                >
                  <MenuItem value={FILTER_OPTIONS.CATEGORY}>Category</MenuItem>
                  <MenuItem value={FILTER_OPTIONS.ASIN}>Asin</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {selectedFilter !== "" && <Grid item>
              <FormControl fullWidth variant="outlined">
                <TextField
                  type="text"
                  value={selectedFilter === FILTER_OPTIONS.CATEGORY ? category : asin}
                  onChange={(event) => {
                    handleOnChange(event)
                  }}
                  label={selectedFilter.toLowerCase()}
                  variant="outlined"
                />
              </FormControl>
            </Grid>}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  fetchKeyOutputMetrics();
                }}
                disabled={selectedFilter === "" && (asin === "" || category === "")}
              >
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  setActionItemModal(true)
                }}
              >
                Add Action Item
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!fullPageLoader && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <Grid item xs={12}>
                  <div className={classes.mainChartHeader}>
                    <Typography
                      variant="h2"
                      gutterBottom
                      style={{
                        fontWeight: 600,
                        fontSize: 20,
                        color: "black",
                      }}
                    >
                      Shipped Revenue (IN {currency})
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<PrintIcon />}
                      onClick={() => exportShippedRevenue()}
                    >
                      Export
                    </Button>
                  </div>
                </Grid>
              }
            >
              <Grid container id="shippedRevenue">
                <Grid item xs={6}>
                  <BarChart
                    data={metricsData?.weeklyChartData?.data}
                    previousWeekData={metricsData?.previousYearWeeklyChartData?.data}
                    forecastWeekData={metricsData?.forecastWeekData?.data}
                    labels={metricsData?.weeklyChartData?.labels}
                    label="Weekly Shipped Revenue"
                    id="weeklyShippedRevenue"
                  />
                </Grid>
                <Grid item xs={6}>
                  <BarChart
                    data={metricsData?.yearlyChartData?.data}
                    previousYearData={metricsData?.previousYearChartData?.data}
                    forecastYearData={metricsData?.forecastData?.data}
                    labels={months}
                    label="Yearly Shipped Revenue"
                    id="yearlyShippedRevenue"
                  />
                </Grid>
                <ChartLegend legendData={forecastChartLegend} />
              </Grid>
            </Widget>
          </Grid>
        </Grid>
      )}
      {!fullPageLoader && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    style={{
                      fontWeight: 600,
                      fontSize: 20,
                      color: "black",
                    }}
                  >
                    Shipped Orders
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportReport("Orders")}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Grid container>
                <Grid item xs={6}>
                  <BarChart
                    data={metricsData?.weeklyOrdersChartData?.data}
                    labels={metricsData?.weeklyOrdersChartData?.labels}
                    previousWeekData={metricsData?.previousYearWeeklyOrdersChartData?.data}
                    id="weeklyOrders"
                    yAxisLabel="Orders"
                  />
                </Grid>
                <Grid item xs={6}>
                  <BarChart
                    data={metricsData?.yearlyOrdersChartData?.data}
                    labels={months}
                    previousYearData={metricsData?.previousYearlyOrdersChartData?.data}
                    id="yearlyOrders"
                    yAxisLabel="Orders"
                  />
                </Grid>
                <ChartLegend legendData={chartLegend} heading={'Orders'} />
              </Grid>
            </Widget>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    color: "black",
                  }}
                >
                  Top Category
                </Typography>
              </div>
            }
          >
            <Grid container>
              <Grid item xs={12} >
                <Table
                  ref={exportFunctionRef}
                  tableData={metricsData?.categoryTableData}
                />
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CreateActionItemModal
        open={actionItemModal}
        close={() => {
          setActionItemModal(false);
        }}
        slideName={ACTION_ITEMS.KEY_OUTPUT_METRICS}
      />
    </div>
  );
}
