import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import moment from "moment";

//helpers
import { BASE_URL } from "../../../../healpers/api";
import api from "../../../../healpers/apiRoutes";

//context
import { useUserState } from "../../../../context/UserContext";
import { useVendorState } from "../../../../context/VendorContext";

export default function DraggableDialog({
  setnotification,
  seterr,
  setfullPageLoader,
  open,
  closeDialog,
  shipmentToBeShipped,
  fetchData,
}) {
  console.log({ shipmentToBeShipped });
  const vendorState = useVendorState();

  async function markAsShipped() {
    try {
      setfullPageLoader(true);
      const data = {
        vendorCode: vendorState?.selected,
        ...shipmentToBeShipped,
      };
      await axios.post(BASE_URL + api.markAsShipped, data);
      setnotification("Shipment marked as Shipped");
      setTimeout(() => setnotification(null), 5000);
      fetchData();
    } catch (err) {
      console.log(err);
      seterr(
        err?.response?.data?.message ||
          err?.response?.data?.msg ||
          "Error occured"
      );
      setTimeout(() => seterr(null), 5000);
    } finally {
      setfullPageLoader(false);
      closeDialog();
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={closeDialog} fullWidth={true}>
        <Paper>
          <DialogTitle>Cancel Shipment</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to mark this shipment as Shipped?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={closeDialog} color="primary">
              Cancel
            </Button>
            <Button
              autoFocus
              variant="contained"
              color="primary"
              onClick={markAsShipped}
            >
              Confirm
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
}
