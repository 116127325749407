import React, { useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import { updateDetailPageBaselineDetails } from "../../../../http/api";

export default function Table(props) {
  const [infoMessage, setInfoMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isExpandImages, setIsExpandImages] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [isExpandVideos, setIsExpandVideos] = useState(false);
  const [videoUrls, setVideoUrls] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");

  const BulletPointsRenderer = (props) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          padding: "4px",
          height: "48px",
          lineHeight: "normal",
          overflow: "scroll",
          overflowX: "hidden",
        }}
      >
        {props.value?.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                lineHeight: "normal",
                width: 400,
                whiteSpace: "break-spaces",
              }}
            >
              &bull;&nbsp;{item}
            </div>
          );
        })}
      </div>
    );
  };

  const ImagesRenderer = (param) => {
    return (
      <div
        style={{
          display: "flex",
          gap: "8px",
        }}
        onClick={() => {
          setImageUrls(param.value);
          setIsExpandImages(true);
        }}
      >
        {param.value?.map((url, index) => {
          return (
            <img
              key={index}
              src={url}
              alt="product images"
              style={{
                height: "48px",
                width: "38px",
              }}
              loading="lazy"
            />
          );
        })}
      </div>
    );
  };

  const VideosRenderer = (param) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          padding: "4px",
          height: "48px",
          lineHeight: "normal",
          overflow: "scroll",
          overflowX: "hidden",
        }}
        onClick={() => {
          setVideoUrls(param.value);
          setIsExpandVideos(true);
        }}
      >
        {param.value?.sort()?.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                lineHeight: "normal",
                width: 400,
                whiteSpace: "break-spaces",
              }}
            >
              &bull;&nbsp;{item.split("/")?.slice(-1)?.[0]}
            </div>
          );
        })}
      </div>
    );
  };

  const UpdateBaselineRenderer = (param) => {
    const baselineDetails = {
      title: param.data.baselineTitle || "",
      descriptions: param.data.baselineDescription || "",
      bulletPoints: (param.data.baselineBulletPoints || []).sort(),
      imageUrls: (param.data.baselineImageUrls || []).sort(),
      videoUrls: (param.data.baselineVideoUrls || []).sort(),
      status: param.data.baselineStatus || "inactive",
    };

    const latestDetails = {
      title: param.data.latestTitle || "",
      descriptions: param.data.latestDescription || "",
      bulletPoints: (param.data.latestBulletPoints || []).sort(),
      imageUrls: (param.data.latestImageUrls || []).sort(),
      videoUrls: (param.data.latestVideoUrls || []).sort(),
      status: param.data.latestStatus || "inactive",
    };

    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ textTransform: "capitalize" }}
        disabled={
          JSON.stringify(baselineDetails) === JSON.stringify(latestDetails)
        }
        onClick={async () => {
          try {
            const response = await updateDetailPageBaselineDetails(
              param.data.asin
            );
            if (response.status === "Success") {
              setInfoMessage(response.message);
              props.refresh();
            } else {
              setErrorMessage(response.message);
            }
          } catch (err) {
            console.log(err);
            setErrorMessage(err.message);
          } finally {
            setTimeout(() => {
              setInfoMessage("");
              setErrorMessage("");
            }, 3000);
          }
        }}
      >
        Update Baseline
      </Button>
    );
  };

  return (
    <>
      {infoMessage && <Alert severity="success">{infoMessage}</Alert>}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <div className="ag-theme-material" style={{ height: 620, width: "100%" }}>
        <Dialog
          open={isExpandImages}
          onClose={() => {
            setImageUrls([]);
            setIsExpandImages(false);
          }}
          fullWidth={true}
          maxWidth={"lg"}
        >
          <DialogContent
            style={{
              display: "flex",
              gap: "12px",
            }}
          >
            {imageUrls.map((url, index) => {
              return (
                <img
                  key={index}
                  src={url}
                  alt="product images"
                  style={{
                    height: "500px",
                    width: "500px",
                  }}
                  loading="lazy"
                />
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setImageUrls([]);
                setIsExpandImages(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isExpandVideos}
          onClose={() => {
            setVideoUrls([]);
            setIsExpandVideos(false);
          }}
          fullWidth={true}
          maxWidth={"lg"}
        >
          <DialogContent
            style={{
              display: "flex",
              gap: "12px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "16px",
              }}
            >
              <div
                style={{
                  width: "50%",
                }}
              >
                {videoUrls.map((url, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => setVideoUrl(url)}
                      style={{
                        cursor: "pointer",
                        textDecoration:
                          url === videoUrl ? "underline" : undefined,
                      }}
                    >
                      {url}
                    </div>
                  );
                })}
              </div>
              {videoUrl ? (
                <video
                  controls={true}
                  src={videoUrl}
                  style={{
                    width: "50%",
                  }}
                  preload="metadata"
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    width: "50%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Click on a video url to play the video
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setVideoUrls([]);
                setVideoUrl("");
                setIsExpandVideos(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <AgGridReact
          defaultColDef={{
            resizable: true,
            sortable: true,
            filter: "agTextColumnFilter",
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
          }}
          rowData={props.rows || []}
          headerHeight={50}
          pagination={true}
          paginationPageSize={10}
          frameworkComponents={{
            bulletPointsRenderer: BulletPointsRenderer,
            imagesRenderer: ImagesRenderer,
            updateBaselineRenderer: UpdateBaselineRenderer,
            videosRenderer: VideosRenderer,
          }}
        >
          <AgGridColumn headerName="ASIN">
            <AgGridColumn headerName="ASIN" field="asin" width={140} />
          </AgGridColumn>
          <AgGridColumn headerName="Title">
            <AgGridColumn
              headerName="Baseline"
              field="baselineTitle"
              width={250}
              cellStyle={{
                lineHeight: "normal",
                whiteSpace: "break-spaces",
              }}
            />
            <AgGridColumn
              headerName="Latest"
              field="latestTitle"
              width={250}
              cellStyle={{
                lineHeight: "normal",
                whiteSpace: "break-spaces",
              }}
            />
          </AgGridColumn>
          <AgGridColumn headerName="Description">
            <AgGridColumn
              headerName="Baseline"
              field="baselineDescription"
              width={300}
              cellStyle={{
                lineHeight: "normal",
                whiteSpace: "break-spaces",
              }}
            />
            <AgGridColumn
              headerName="Latest"
              field="latestDescription"
              width={300}
              cellStyle={{
                lineHeight: "normal",
                whiteSpace: "break-spaces",
              }}
            />
          </AgGridColumn>
          <AgGridColumn headerName="Bullet Points">
            <AgGridColumn
              headerName="Baseline"
              field="baselineBulletPoints"
              cellRenderer="bulletPointsRenderer"
              width={450}
            />
            <AgGridColumn
              headerName="Latest"
              field="latestBulletPoints"
              cellRenderer="bulletPointsRenderer"
              width={450}
            />
          </AgGridColumn>
          <AgGridColumn headerName="Image Urls">
            <AgGridColumn
              headerName="Baseline"
              field="baselineImageUrls"
              cellRenderer="imagesRenderer"
              width={450}
            />
            <AgGridColumn
              headerName="Latest"
              field="latestImageUrls"
              cellRenderer="imagesRenderer"
              width={450}
            />
          </AgGridColumn>
          <AgGridColumn headerName="Video Urls">
            <AgGridColumn
              headerName="Baseline"
              field="baselineVideoUrls"
              cellRenderer="videosRenderer"
              width={450}
            />
            <AgGridColumn
              headerName="Latest"
              field="latestVideoUrls"
              cellRenderer="videosRenderer"
              width={450}
            />
          </AgGridColumn>
          <AgGridColumn headerName="Status">
            <AgGridColumn
              headerName="Baseline"
              field="baselineStatus"
              width={130}
            />
            <AgGridColumn
              headerName="Latest"
              field="latestStatus"
              width={130}
            />
          </AgGridColumn>
          <AgGridColumn headerName="Last Updated">
            <AgGridColumn
              headerName="Baseline"
              field="baselineUpdatedAt"
              valueFormatter={(param) =>
                moment(param.value).format("MM-DD-YYYY")
              }
              filter={false}
              width={130}
            />
            <AgGridColumn
              headerName="Latest"
              field="createdAt"
              valueFormatter={(param) =>
                moment(param.value).format("MM-DD-YYYY")
              }
              filter={false}
              width={130}
            />
          </AgGridColumn>
          <AgGridColumn
            filter={false}
            sort={false}
            cellRenderer="updateBaselineRenderer"
            width={150}
          />
        </AgGridReact>
      </div>
    </>
  );
}
