import React, { useEffect, useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import {
  Breadcrumbs,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { Switch } from "@mui/material";
import moment from "moment";
import { CloseOutlined } from "@material-ui/icons";
import PageTitle from "../../components/PageTitle/PageTitle";
import { fetchContentChangelogs } from "../../http/api";
import { useVendorState } from "../../context/VendorContext";
import Widget from "../../components/Widget/Widget";
import { CHANGELOG_ACTION_MESSAGE } from "../../constant/content";

export default function ChangelogContent() {
  const vendorState = useVendorState();
  const [changelogs, setChangelogs] = useState([]);

  const [showLoader, setShowLoader] = useState(false);
  const [showAllChangelogs, setShowAllChangelogs] = useState(false);

  const [showChanges, setShowChanges] = useState(false);
  const [changelog, setChangelog] = useState({});

  const loadChangelog = async (vendorCode) => {
    try {
      setShowLoader(true);
      const { status, data } = await fetchContentChangelogs(vendorCode);
      setShowLoader(false);
      if (status === "Success") {
        setChangelogs(data);
      } else {
        setChangelogs([]);
      }
    } catch (err) {
      console.log(err);
      setShowLoader(false);
      setChangelogs([]);
    }
  };

  const ChangelogItemContainer = (label, value) => {
    return (
      <div style={{ display: "flex", gap: "4px", width: "100%" }}>
        <Typography style={{ width: "20%", textAlign: "right" }}>
          {label + ":"}
        </Typography>
        <Typography style={{ width: "80%" }}>{value}</Typography>
      </div>
    );
  };

  const ActionButtonsRenderer = (param) => {
    return (
      <>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            setShowChanges(true);
            setChangelog(param.data);
          }}
        >
          View
        </Button>
      </>
    );
  };

  useEffect(() => {
    if (!showAllChangelogs && vendorState.selected) {
      loadChangelog(vendorState.selected);
    }
  }, [vendorState.selected, showAllChangelogs]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Changelog"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit">Content Revisions</Link>
              <Typography color="primary">Changelog</Typography>
            </Breadcrumbs>
          }
        />
        <Grid container>
          <Grid item xs={12}>
            <Widget
              header={
                <>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showAllChangelogs}
                          onChange={(e) => {
                            setShowAllChangelogs(e.target.checked);
                            e.target.checked && loadChangelog("ALL");
                          }}
                        />
                      }
                      label="Show All"
                      labelPlacement="start"
                    />
                  </div>
                </>
              }
              upperTitle
              noBodyPadding
              disableWidgetMenu
            >
              <div
                className="ag-theme-material"
                style={{ height: 620, width: "100%" }}
              >
                {showLoader ? (
                  <Typography>Loading please wait...</Typography>
                ) : (
                  <AgGridReact
                    defaultColDef={{
                      resizable: true,
                      sortable: true,
                      filter: "agTextColumnFilter",
                      headerComponentParams: {
                        template:
                          '<div class="ag-cell-label-container" role="presentation">' +
                          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                          '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                          "  </div>" +
                          "</div>",
                      },
                    }}
                    rowData={changelogs}
                    headerHeight={50}
                    pagination={true}
                    paginationPageSize={10}
                    frameworkComponents={{
                      actionButtonsRenderer: ActionButtonsRenderer,
                    }}
                  >
                    {showAllChangelogs && (
                      <AgGridColumn
                        headerName="Vendor Code"
                        field="vendorCode"
                        width={150}
                      />
                    )}
                    <AgGridColumn headerName="ASIN" field="asin" width={150} />
                    <AgGridColumn
                      headerName="Action"
                      field="action"
                      valueFormatter={(param) =>
                        CHANGELOG_ACTION_MESSAGE[param.value] || param.value
                      }
                      width={250}
                    />
                    <AgGridColumn
                      headerName="Attributes"
                      field="attributes"
                      valueFormatter={(param) => param.value?.join(",")}
                      filter={false}
                      width={150}
                    />
                    <AgGridColumn
                      headerName="Changed At"
                      field="updatedAt"
                      valueFormatter={(param) =>
                        moment(param.value).format("MM-DD-YYYY")
                      }
                      filter={false}
                      width={150}
                    />
                    <AgGridColumn
                      headerName="Changed By"
                      field="user"
                      width={200}
                    />
                    <AgGridColumn
                      filter={false}
                      sort={false}
                      cellRenderer="actionButtonsRenderer"
                    />
                  </AgGridReact>
                )}
              </div>
            </Widget>
          </Grid>
        </Grid>
      </Container>
      {showChanges && (
        <Dialog
          open={true}
          onClose={() => {
            setShowChanges(false);
            setChangelog({});
          }}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {"Content Changes"}
              <CloseOutlined
                onClick={() => {
                  setShowChanges(false);
                  setChangelog({});
                }}
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              {ChangelogItemContainer("ASIN", changelog?.asin)}
              {ChangelogItemContainer("Vendor Code", changelog?.vendorCode)}
              {ChangelogItemContainer(
                "Action",
                CHANGELOG_ACTION_MESSAGE[changelog?.action] || changelog?.action
              )}
              {ChangelogItemContainer("Changed By", changelog?.user)}
              {ChangelogItemContainer(
                "Updated At",
                moment(changelog?.updatedAt).format("MM-DD-YYYY")
              )}
              {changelog?.attributes?.length > 0 &&
                ChangelogItemContainer(
                  "Attributes",
                  changelog.attributes.join(",")
                )}
              <Divider />
              <div
                style={{
                  display: "flex",
                }}
              >
                <div style={{ width: "50%" }}>
                  <Typography>{"Before Change"}</Typography>
                  <Typography style={{ whiteSpace: "break-spaces" }}>
                    {Object.keys(changelog?.beforeChange)
                      .map((key) => `${key}: ${changelog?.beforeChange[key]}`)
                      .join("\n\n")}
                  </Typography>
                </div>
                <div style={{ width: "50%" }}>
                  <Typography>{"After Change"}</Typography>
                  <Typography style={{ whiteSpace: "break-spaces" }}>
                    {Object.keys(changelog?.afterChange)
                      .map((key) => `${key}: ${changelog?.afterChange[key]}`)
                      .join("\n\n")}
                  </Typography>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
