/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  CircularProgress,
  Backdrop
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import "date-fns";
import * as XLSX from 'xlsx';

//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";
import GetAppIcon from '@material-ui/icons/GetApp';
import PrintIcon from "@material-ui/icons/Print";
import PublishIcon from '@material-ui/icons/Publish';

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";

//context
import { useVendorState } from "../../context/VendorContext";

//helpers
import { fetchMapViolatersRange, fetchMapViolaters, uploadAsinMapPrice } from "../../http/api";
import SampleFile from "../../healpers/assets/ASINMapPriceTemplate.xlsx";
import { changeDateFormat } from "../../healpers/utilityFunctions";

import useStyles from "./styles";

export default function Charts(props) {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  var vendorState = useVendorState();

  // local
  const [dates, setDates] = useState([]);
  const [mainTableData, setmainTableData] = useState([]);
  const [selectedDate, setselectedDate] = useState('');
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");

  const upload = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (evt) => {
      const bstr = evt.target.result;
      const workbook = XLSX.read(bstr, { type: "binary" });
      const ws = workbook.Sheets[workbook.SheetNames[0]];
      const data = XLSX.utils.sheet_to_json(ws);
      if (data.length === 0) {
        setError("File contains no data, please check and try again.");
        setTimeout(() => {
          setError("");
        }, 2000);
      } else {
        const uploadData = [];
        data.every((item, index) => {
          if (
            !item["Vendor Code"] ||
            !item["ASIN"] ||
            !item["SKU"] ||
            !item["Map Price"]
          ) {
            setError(`Error on row ${index + 1}, please check and try again.`);
            setTimeout(() => {
              setError("");
            }, 2000);
            return false;
          } else {
            uploadData.push({
              vendorCode: item["Vendor Code"]?.trim(),
              asin: item["ASIN"]?.trim(),
              sku: item["SKU"]?.trim(),
              mapPrice: item["Map Price"],
            });
            return true;
          }
        });
        if (data.length === uploadData.length) {
          try {
            await uploadAsinMapPrice(uploadData);
            setInfo("Updated successfully");
          } catch (e) {
            setError(e);
          } finally {
            setTimeout(() => {
              setInfo("");
              setError("");
            }, 2000);
          }
        }
      }
    };
    reader.readAsBinaryString(file);
  };

  useEffect(() => {
    fetchMapViolatersRange(vendorState?.selected)
      .then(({ dates }) => {
        const datesArray = dates.map(ele => changeDateFormat(ele.reportDate));
        setDates(datesArray);
        setselectedDate(datesArray[datesArray.length-1])
      }).catch((err) => {
        console.log('Error', err)
        console.error('Failed to fetch the ranges')
      })
  }, [vendorState?.selected])

  useEffect(() => {
    async function fetchData() {
      try {

        setfullPageLoader(true);
        const filteredData = await fetchMapViolaters(vendorState.selected, selectedDate);
        setmainTableData(filteredData);

      } catch (error) {
        console.error(error);
      } finally {
        setfullPageLoader(false);
      }
    }

    if (vendorState?.selected && selectedDate) {
      fetchData();
    }

    return () => {
      setmainTableData([]);
    };

  }, [selectedDate, vendorState?.selected]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Map Violators"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Operations
              </Link>
              <Typography className={classes.link}>
                <EqualizerIcon color="primary" className={classes.icon} />
                Map Violators
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <FormControl
                variant="outlined"
                className={classes.campaignControl}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Date
                </InputLabel>
                <Select
                  value={selectedDate || ""}
                  onChange={(e) => {
                    setselectedDate(e.target.value);
                  }}
                  label="Select Range"
                  disabled={dates && dates.length === 0 ? true : false}
                >
                  {dates && dates.length ? (
                    dates.map((range, index) => {
                      return (
                        <MenuItem key={index} value={range}>
                          {range}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={0}>
                      <i>No Dates</i>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                    }}
                  >
                    <a
                      href={SampleFile}
                      download="ASINMapPriceTemplate.xlsx"
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        color="primary"
                        variant="outlined"
                        component="span"
                        size="small"
                        startIcon={<GetAppIcon />}
                      >
                        Download Sample File
                      </Button>
                    </a>
                    <label htmlFor="upload">
                      <input
                        style={{ display: "none" }}
                        id="upload"
                        name="upload"
                        type="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={(e) => upload(e)}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        component="span"
                        startIcon={<PublishIcon />}
                      >
                        Upload here
                      </Button>
                    </label>
                  </div>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={fullPageLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
      {info && (
        <Alert
          severity="success"
          style={{ position: "absolute", bottom: 30, left: "48%" }}
        >
          {info}
        </Alert>
      )}
      {error && (
        <Alert
          severity="error"
          style={{ position: "absolute", bottom: 30, left: "48%" }}
        >
          {error}
        </Alert>
      )}
    </>
  );
}
