import React from "react";
import axios from "axios";

//helpers
import { BASE_URL } from "../healpers/api";
import { tokenConfig } from '../context/UserContext';

var VendorStateContext = React.createContext();
var VendorDispatchContext = React.createContext();

function vendorReducer(state, action) {
  switch (action.type) {
    case "GET_ALL_VENDORS":
      return { ...state, list: action.list };
    case "SELECTED_VENDOR":
      return { ...state, country: action.country, selected: action.selected, accounttype: action.accounttype, annualsalesgoal: action.annualsalesgoal,  weeklysalesgoal: action.weeklysalesgoal, quarterlysalesgoal: action.quarterlysalesgoal, monthlysalesgoal: action.monthlysalesgoal};
    case "GET_ALL_CARRIERS":
      return { ...state, carrierScacList: action.carrierScacList };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function VendorProvider({ children }) {
  var [state, dispatch] = React.useReducer(vendorReducer, {
    list: null,
    selected: 0,
    annualsalesgoal:null
  });

  return (
    <VendorStateContext.Provider value={state} displayName="Vendor Context">
      <VendorDispatchContext.Provider value={dispatch}>
        {children}
      </VendorDispatchContext.Provider>
    </VendorStateContext.Provider>
  );
}

function useVendorState() {
  var context = React.useContext(VendorStateContext);
  if (context === undefined) {
    throw new Error("useVendorState must be used within a VendorProvider");
  }
  return context;
}

function useVendorDispatch() {
  var context = React.useContext(VendorDispatchContext);
  if (context === undefined) {
    throw new Error("useVendorDispatch must be used within a VendorProvider");
  }
  return context;
}

export { VendorProvider, useVendorState, useVendorDispatch, getAllVendors, setSelectedVendor };

// ###########################################################

async function getAllVendors(dispatch, email, history) {
    axios
      .get(BASE_URL + '/api/user/vendorConfigs/' + email + "_vendorList", tokenConfig())
      .then((result) => {
        if (!result.data.error) {
          var list = [];
          result.data.data.forEach(element => {
            list.push({
              accounttype: findAccountType(element.vendorcode),
              country: findCountry(element.vendorcode),
              vendorcode: element.vendorcode, 
              vendorname: element.vendorname + ' - ' + element.vendorcode,
              annualsalesgoal: element.annualsalesgoal,
              weeklysalesgoal: element.weeklysalesgoal,
              quarterlysalesgoal: element.quarterlysalesgoal,
              monthlysalesgoal: element.monthlysalesgoal
            })
          });
          dispatch({type:"GET_ALL_VENDORS",list})
          if(list.length>0) {
            const vendorCode =
              JSON.parse(localStorage.getItem("selectedVendor"))?.vendorcode ??
              list[0].vendorcode;
            window.location.pathname === "/app/sales" && history.push(findAccountType(vendorCode) === "seller" ? "/app/sales/sellerCentral" : "/app/sales/shippedCOGS");
            dispatch({
              type:"SELECTED_VENDOR",
              selected: vendorCode, 
              country: findCountry(vendorCode),
              accounttype: findAccountType(vendorCode),
              annualsalesgoal: list[0].annualsalesgoal, 
              weeklysalesgoal: list[0].weeklysalesgoal, 
              quarterlysalesgoal: list[0].quarterlysalesgoal, 
              monthlysalesgoal: list[0].monthlysalesgoal
            })
          }
        } 
      })
      .catch((err) => {
        console.log(err);
      });
}

const findAccountType = (vendorCode) => {
  const account = vendorCode.split("_").at(-1).trim();
  if(account === "VC") {
    return "vendor";
  } else if(account === "SC") {
    return "seller";
  }
}

const findCountry = (vendorCode = "") => {
  return vendorCode.split("_")?.[1] || "US";
}

function setSelectedVendor(dispatch, vendorState, selected) {
  const selectedVendor = vendorState.list.find( ({ vendorcode }) => vendorcode === selected );
  localStorage.setItem("selectedVendor", JSON.stringify(selectedVendor));
  dispatch({ type: "SELECTED_VENDOR", country: findCountry(selected), selected, accounttype: findAccountType(selected), annualsalesgoal: selectedVendor.annualsalesgoal, weeklysalesgoal: selectedVendor.weeklysalesgoal, monthlysalesgoal: selectedVendor.monthlysalesgoal, quarterlysalesgoal: selectedVendor.quarterlysalesgoal});
}