import React, { useState, useEffect } from "react";
import {
  Grid,
  Breadcrumbs,
  Container,
  Button,
  CircularProgress,
  Backdrop,
  Link,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField
} from "@material-ui/core";
import axios from "axios";
import _ from "lodash";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../../components/Wrappers/Wrappers";

import PageTitle from "../../components/PageTitle/PageTitle";

//context
import { useVendorState } from "../../context/VendorContext";

//helpers
import { CONFIG, REPORT_HEALTH_TYPES, REPORTS_HEALTH } from "../../constant/reportsHealth";
import ContributionChart from "./contributionChart";
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";


export default function ReportsHealth(props) {
  var classes = useStyles();


  //global
  var vendorState = useVendorState();

  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [reportType, setReportType] = useState(REPORTS_HEALTH[0].value)
  const [reportData, setReportData] = useState([])
  const [configuration, setConfiguration] = useState(CONFIG[REPORTS_HEALTH[0].value])
  const [id, setId] = useState("")
  const [asin, setAsin] = useState("");

  useEffect(() => {
    setAsin("");
    if (vendorState.selected) {
      handleGetReportHealth()
    }
    return () => {
    };
  }, [vendorState.selected]);


  const handleGetReportHealth = async () => {
    try {
      setfullPageLoader(true)
      let postData = {
        vendorCode: vendorState.selected,
        configuration,
      }
      if(!_.isEmpty(configuration.id)) {
        configuration[configuration["id"].value] = id
      }
      if(!_.isEmpty(asin)) {
        configuration["asin"] = asin;
      }
      const response = await axios.post(BASE_URL + api.fetchReportHealth, postData)
      setReportData(response.data.data)
      setfullPageLoader(false)
    } catch (error) {
      setfullPageLoader(false)
      console.log("error - getReportHealth", error)
    }
  }

  const setConfig = (key) => {
    const config = CONFIG[key];
    setConfiguration(config)
  }
  
  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title={'Reports Health'}
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Onboarding
              </Link>
              <Typography className={classes.link} color="primary">
                Reports Health
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <FormControl variant="outlined" className={classes.formControl} >
                <InputLabel id="demo-simple-select-outlined-label">
                  Report
                </InputLabel>
                <Select
                  value={reportType}
                  onChange={(e) => { setReportType(e.target.value); setConfig(e.target.value) }}
                  label="Report Type"
                >
                  {REPORTS_HEALTH.map((report) => (
                    <MenuItem value={report.value}>{report.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {!_.isEmpty(configuration?.id) && <FormControl className={classes.textFieldContainer}>
                <TextField
                  id="id"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                  margin="normal"
                  placeholder={configuration.id?.label}
                  type="text"
                  fullWidth
                />
              </FormControl>}
              {[
                REPORT_HEALTH_TYPES.JS_SEGMENT_BRAND_REPORTS,
                REPORT_HEALTH_TYPES.SALES_TRAFFIC_REPORTS,
                REPORT_HEALTH_TYPES.VENDOR_SALES_REPORTS
              ].includes(reportType) && <FormControl className={classes.textFieldContainer}>
                <TextField
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  placeholder="ASIN"
                  value={asin}
                  onChange={(e) => setAsin(e.target.value)}
                  margin="normal"
                  type="text"
                  fullWidth
                />
              </FormControl>}
              <FormControl
                variant="outlined"
                className={classes.formControl}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: 53, width: 100 }}
                  onClick={handleGetReportHealth}
                >
                  Apply
                </Button>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {reportData.length > 0 ? <ContributionChart type={configuration?.type} reports={reportData} /> : 'No Reports Found'}
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
