import React, { useState, useRef, useEffect } from "react";
import {
  Breadcrumbs,
  Select,
  FormControl,
  MenuItem,
  Container,
  CircularProgress,
  Backdrop,
  InputLabel,
  Link,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  Button,
} from "@material-ui/core";
import moment from "moment";

//icons
import PrintIcon from "@material-ui/icons/Print";

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import Table from "./components/Table";
import Widget from "../../components/Widget/Widget";


//context
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";

//helpers
import { cloneAndSortDescending } from "../../healpers/dateRange.helpers";
import { getMonthDatesBetween, getQuarterDatesBetween, getWeekDatesBetween, getYearDatesBetween } from "../../healpers/utilityFunctions";
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import axios from "axios";
import _ from "lodash";
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";


export default function TargetPerformance(props) {
  var classes = useStyles();
  const topPerformingExportRef = useRef();
  const lowPerformingExportRef = useRef();
  const exportFunctionRef = useRef();

  //global
  var vendorState = useVendorState();
  // local
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [radioValue, setRadioValue] = useState("monthly");
  const [dateRange, setDateRange] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(1);
  const [weekRange, setWeekRange] = useState(null);
  const [monthRange, setMonthRange] = useState(null);
  const [quarterRange, setQuarterRange] = useState(null);
  const [annualRange, setAnnualRange] = useState(null);
  const [range, setRange] = useState({
    startDate: new Date(moment("01-01-2023", "DD-MM-YYYY")),
    endDate: new Date(),
  });
  const [topPerformingData, setTopPerformingData] = useState(null);
  const [highACOSData, setHighACOSData] = useState(null);
  const [highSpendNoSalesData, setHighSpendNoSalesData] = useState(null);
  const [toDate, setToDate] = useState(moment().endOf('month'))
  const [fromDate, setFromDate] = useState(moment().startOf('month'))

  useEffect(() => {
    if (range) {
      const weekDateRange = cloneAndSortDescending([...getWeekDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const monthDateRange = cloneAndSortDescending([...getMonthDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const quarterDateRange = cloneAndSortDescending([...getQuarterDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const annualDateRange = cloneAndSortDescending([...getYearDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      setDateRange(monthDateRange);
      if (monthDateRange.length !== 0) setSelectedDateRange(1);

      setWeekRange(weekDateRange);
      setMonthRange(monthDateRange);
      setQuarterRange(quarterDateRange);
      setAnnualRange(annualDateRange);
      if (monthDateRange.length) {
        setFromDate(moment(monthDateRange[1].startdate, 'MM/DD/YYYY').format())
        setToDate(moment(monthDateRange[1].enddate, 'MM/DD/YYYY').format())
      }
    }
    return () => {
      setDateRange(null);
      setWeekRange([]);
      setMonthRange([]);
      setQuarterRange([]);
      setAnnualRange([]);
    };
  }, [range]);

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    if (event.target.value === "weekly") {
      setDateRange(weekRange);
      setSelectedDateRange(1);
    } else if (event.target.value === "monthly") {
      setDateRange(monthRange);
      setSelectedDateRange(1);
    } else if (event.target.value === "quarterly") {
      setDateRange(quarterRange);
      setSelectedDateRange(1);
    } else {
      setDateRange(annualRange);
      setSelectedDateRange(0);
    }
  };

  useEffect(() => {
    if(vendorState.selected) {
      loadData()
    }
  }, [vendorState.selected, selectedDateRange, dateRange])

  async function loadData() {
    try {
      setFullPageLoader(true);
      let params = {
        vendorCode: vendorState?.selected,
        startDate: moment(dateRange[selectedDateRange].startdate).format("MM-DD-YYYY"),
        endDate: moment(dateRange[selectedDateRange].enddate).format("MM-DD-YYYY"),
      };
      if(radioValue === 'custom') {
        params.startDate = moment(fromDate).format("MM-DD-YYYY")
        params.endDate = moment(toDate).format("MM-DD-YYYY")
      }
      const result = await axios.get(BASE_URL + api.productTargetPerformance, tokenConfig(params))
      if (result.data) {
        setPerformingData(result.data)
        setFullPageLoader(false);
      }
    } catch (error) {
      console.log(error);
      setFullPageLoader(false);
    }
  }

  const setPerformingData = (data) => {
    data = data.filter((item) => item.cost > 0);
    setTopPerformingData(_.orderBy(data, ['sales'], ['desc']).slice(0, 5))

    const orderData = data.filter((item) => item.sales > 0 && item.acos >= 0.3);
    setHighACOSData(_.orderBy(orderData, ['acos'], ['desc']));
    
    const zeroOrderData = data.filter((item) => item.sales <= 0);
    setHighSpendNoSalesData(_.orderBy(zeroOrderData, ['cost'], ['desc']))
  }

  return (
    <Container maxWidth={false}>
      <PageTitle
        title="Products Target Performance"
        breadCrump={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className={classes.link}>
              AMS Reports
            </Link>
            <Typography className={classes.link} color="primary">
              Products Target Performance
            </Typography>
          </Breadcrumbs>
        }
        rightAlignComponent={
          <Grid container style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexWrap: "nowrap", width: '100%', alignItems: 'center' }}>
            <FormControl>
              <RadioGroup value={radioValue} onChange={handleRadioChange}>
                <FormControlLabel
                  value="weekly"
                  control={<Radio color="primary" />}
                  label="Weekly"
                />
                <FormControlLabel
                  value="monthly"
                  control={<Radio color="primary" />}
                  label="Monthly"
                />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <RadioGroup value={radioValue} onChange={handleRadioChange}>
                <FormControlLabel
                  value="quarterly"
                  control={<Radio color="primary" />}
                  label="Quarterly"
                />
                <FormControlLabel
                  value="custom"
                  control={<Radio color="primary" />}
                  label="Custom"
                />
              </RadioGroup>
            </FormControl>
            {radioValue !== 'custom' && <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Select Range</InputLabel>
              <Select
                value={selectedDateRange}
                onChange={(e) => {
                  setSelectedDateRange(e.target.value);
                }}
                label="Select Range"
                disabled={dateRange && dateRange.length === 0 ? true : false}
              >
                {dateRange ? (
                  dateRange.map((range, index) => {
                    return <MenuItem value={index}>{range.startdate + " - " + range.enddate}</MenuItem>;
                  })
                ) : (
                  <MenuItem value={0}>
                    <i>No Dates</i>
                  </MenuItem>
                )}
              </Select>
            </FormControl>}
            {radioValue === 'custom' &&
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <FormControl
                  variant="outlined"
                  className={classes.dateFormControl}
                  style={{paddingRight:'5px'}}
                >
                  <KeyboardDatePicker
                    disableToolbar
                    inputVariant="outlined"
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="startDate"
                    label="From"
                    value={fromDate}
                    onChange={(date) => setFromDate(date)}
                    style={{ width: '170px' }}
                  />
                </FormControl>
                <FormControl
                  variant="outlined"
                  className={classes.dateFormControl}
                  style={{paddingRight:'5px'}}
                >
                  <KeyboardDatePicker
                    disableToolbar
                    inputVariant="outlined"
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="startDate"
                    label="To"
                    value={toDate}
                    onChange={(date) => setToDate(date)}
                    minDate={fromDate}
                    style={{ width: '170px' }}
                  />
                </FormControl>
              </MuiPickersUtilsProvider>}
          </Grid>
        }
      />
      <Grid container style={{ marginBottom: 30 }} className="target_performance_grid">
        <Grid item xs={12}>
          <Widget
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    color: "black",
                  }}
                >
                  Top Performing Targets
                </Typography>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => topPerformingExportRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              </div>
            }
          >
            <Table ref={topPerformingExportRef} tableData={topPerformingData} height={350} />
          </Widget>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: 30 }} className="target_performance_grid">
        <Grid item xs={12}>
          <Widget
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    color: "black",
                  }}
                >
                  High Spend & No Sales
                </Typography>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => lowPerformingExportRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              </div>
            }
          >
            <Table ref={lowPerformingExportRef} tableData={highSpendNoSalesData} height={600} />
          </Widget>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: 30 }} className="target_performance_grid">
        <Grid item xs={12}>
          <Widget
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    color: "black",
                  }}
                >
                  High ACOS
                </Typography>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => lowPerformingExportRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              </div>
            }
          >
            <Table ref={lowPerformingExportRef} tableData={highACOSData} height={600} />
          </Widget>
        </Grid>
      </Grid>
      
      <Backdrop
        className={classes.backdrop}
        open={fullPageLoader}
        onClick={() => setFullPageLoader(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
