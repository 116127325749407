import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Grid,
  Breadcrumbs,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  Divider,
  MenuItem,
  InputLabel,
  Select,
  OutlinedInput,
  ListItemText,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import PrintIcon from "@material-ui/icons/Print";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PageTitle from "../../components/PageTitle/PageTitle";
import _ from "lodash";
import XLSX from "xlsx";
import {
  fetchAllSpNotificationsSubscriptions,
  fetchCohesityAsins,
  fetchCohesityOptions,
  fetchCustomerConfig,
  fetchSpNotificationsSubscriptions,
  fetchUserGroupNames,
  fetchWBROptions,
  saveCohesityAsins,
  updateAMRulesConfig,
  updateBIAccess,
  updateCohesityOptions,
  updateSpNotificationsSubscriptions,
  updateWBROptions,
  uploadWbrShortageClaimsAndChargebacks,
} from "../../http/api";
import { useVendorState } from "../../context/VendorContext";
import { toProperCaseRemoveUnderscore } from "../../healpers/utilityFunctions";
import ShortageClaimsAndChargeBacksTemplate from "../../healpers/assets/ChargebacksAndShortageClaimsTemplate.xlsx";
import moment from "moment";
import { AMS_STRATEGY } from "../../constant/amRules";

export default function CustomerSettings() {
  const vendorState = useVendorState();
  const [infoMessage, setInfoMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [
    availableNotificationsSubscriptionOptions,
    setAvailableNotificationsSubscriptionOptions,
  ] = useState({});
  const [
    subscribedNotificationsSubscriptions,
    setSubscribedNotificationsSubscriptions,
  ] = useState([]);
  const [
    updateNotificationsSubscriptions,
    setUpdateNotificationsSubscriptions,
  ] = useState({
    add: [],
    remove: [],
  });
  const [
    isLoadingSubscribedNotificationsSubscriptions,
    setIsLoadingSubscribedNotificationsSubscriptions,
  ] = useState(true);
  const [
    isSavingSubscribedNotificationsSubscriptions,
    setIsSavingSubscribedNotificationsSubscriptions,
  ] = useState(false);

  const [cohesityOptions, setCohesityOptions] = useState([]);
  const [configuredCohesityOptions, setConfiguredCohesityOptions] = useState([]);
  const [wbrOptions, setWBROptions] = useState([]);
  const [configuredWBROptions, setConfiguredWBROptions] = useState([]);
  const [
    isLoadingConfiguredCohesityOptions,
    setIsLoadingConfiguredCohesityOptions,
  ] = useState(true);
  const [
    isLoadingWBROptions,
    setIsLoadingWBROptions,
  ] = useState(true);
  const [
    isSavingUpdatedCohesityOptions,
    setIsSavingUpdatedCohesityOptions,
  ] = useState(false);
  const [
    isSavingUpdatedWBROptions,
    setIsSavingUpdatedWBROptions,
  ] = useState(false);
  const [
    isSavingUpdatedBIAccess,
    setIsSavingUpdatedBIAccess,
  ] = useState(false);
  const [notificationGroups, setNotificationGroups] = useState([])
  const [wbrEnabled, setWbrEnabled] = useState(true)
  const [userGroups, setUserGroups] = useState([])
  const [uploading, setUploading] = useState(false)
  const [amsStrategy, setAmsStrategy] = useState(null)
  const [seoOptimization, setSeoOptimization] = useState(null)
  const [isLoadingAMRulesConfig, setIsLoadingAMRulesConfig] = useState(false);
  const [isSavingAMRulesConfig, setIsSavingAMRulesConfig] = useState(false);
  const [biAccess,setBiAccess] = useState(false)
  const [
    isLoadingBiAccess,
    setIsLoadingBiAccess,
  ] = useState(true);

  const loadNotificationsSubscriptionOptions = async () => {
    try {
      const { status, message } = await fetchAllSpNotificationsSubscriptions();
      if (status === "Success") {
        setAvailableNotificationsSubscriptionOptions(message);
      } else {
        setAvailableNotificationsSubscriptionOptions({});
      }
    } catch (err) {
      console.log(err);
      setAvailableNotificationsSubscriptionOptions({});
    }
  };

  const loadSubscribedNotificationsSubscriptionOptions = async (vendorCode) => {
    setUpdateNotificationsSubscriptions({
      add: [],
      remove: [],
    });
    try {
      setIsLoadingSubscribedNotificationsSubscriptions(true);
      const { status, message } = await fetchSpNotificationsSubscriptions(
        vendorCode
      );
      if (status === "Success") {
        setSubscribedNotificationsSubscriptions(message);
        setUpdateNotificationsSubscriptions({
          add: message,
          remove: [],
        });
      } else {
        setSubscribedNotificationsSubscriptions([]);
      }
    } catch (err) {
      console.log(err);
      setSubscribedNotificationsSubscriptions([]);
    } finally {
      setIsLoadingSubscribedNotificationsSubscriptions(false);
    }
  };

  const handleAddNotificationsSubscriptionOption = (option) => {
    setUpdateNotificationsSubscriptions({
      add: [...updateNotificationsSubscriptions.add, option],
      remove: [
        ...updateNotificationsSubscriptions.remove.filter(
          (item) => item !== option
        ),
      ],
    });
  };

  const handleRemoveNotificationsSubscriptionOption = (option) => {
    setUpdateNotificationsSubscriptions({
      add: [
        ...updateNotificationsSubscriptions.add.filter(
          (item) => item !== option
        ),
      ],
      remove: [...updateNotificationsSubscriptions.remove, option],
    });
  };

  const handleUpdateNotificationsSubscriptionSave = async () => {
    try {
      setIsSavingSubscribedNotificationsSubscriptions(true);
      const updateNotificationsSubscription = await updateSpNotificationsSubscriptions(
        vendorState.selected,
        {
          add: updateNotificationsSubscriptions.add.filter(
            (item) => !subscribedNotificationsSubscriptions.includes(item)
          ),
          remove: updateNotificationsSubscriptions.remove.filter((item) =>
            subscribedNotificationsSubscriptions.includes(item)
          ),
        }
      );
      if (updateNotificationsSubscription.status === "Success") {
        setInfoMessage(updateNotificationsSubscription.message);
      } else {
        setErrorMessage(updateNotificationsSubscription.message);
      }
    } catch (err) {
      console.log(err);
      setErrorMessage(err?.message);
    } finally {
      setIsSavingSubscribedNotificationsSubscriptions(false);
      loadSubscribedNotificationsSubscriptionOptions(vendorState.selected);
      setTimeout(() => {
        setInfoMessage("");
        setErrorMessage("");
      }, 3000);
    }
  };

  const loadCohesityOptions = async () => {
    const { data } = await fetchCohesityOptions();
    setCohesityOptions(data);
  };

  const loadWBROptions = async () => {
    const { data } = await fetchWBROptions();
    setWBROptions(data);
  };

  const loadCustomerCohesityOptions = async (vendorCode) => {
    setIsLoadingConfiguredCohesityOptions(true);
    const data = await fetchCustomerConfig(vendorCode);
    setConfiguredCohesityOptions(data.cohesityCheck || []);
    setIsLoadingConfiguredCohesityOptions(false);
  };

  const loadCustomerWBROptions = async (vendorCode) => {
    setIsLoadingWBROptions(true);
    const data = await fetchCustomerConfig(vendorCode);
    setConfiguredWBROptions(data.wbrOptions || []);
    setBiAccess(data.biAccess || false);
    setIsLoadingWBROptions(false);
    setUserGroups(data.userGroups || [])
    setWbrEnabled(data.wbrEnabled)
  };

  const handleUpdatedCohesityOptionsSave = async () => {
    try {
      setIsSavingUpdatedCohesityOptions(true);
      const data = await updateCohesityOptions({
        cohesity: configuredCohesityOptions,
        vendor: vendorState.selected,
      });
      if (data.status === "Success") {
        setInfoMessage(data.message);
      } else {
        setErrorMessage(data.message);
      }
    } catch (err) {
      console.log(err);
      setErrorMessage(err?.message);
    } finally {
      setIsSavingUpdatedCohesityOptions(false);
      loadCustomerCohesityOptions(vendorState.selected);
      setTimeout(() => {
        setInfoMessage("");
        setErrorMessage("");
      }, 3000);
    }
  };

  const handleUpdatedWBROptionsSave = async () => {
    try {
      setIsSavingUpdatedWBROptions(true);
      const data = await updateWBROptions({
        wbrOptions: configuredWBROptions,
        vendorCode: vendorState.selected,
        wbrEnabled: wbrEnabled,
        userGroups: userGroups
      });
      setInfoMessage(data.message);
    } catch (err) {
      console.log(err);
      setErrorMessage(err?.message);
    } finally {
      setIsSavingUpdatedWBROptions(false);
      loadCustomerCohesityOptions(vendorState.selected);
      setTimeout(() => {
        setInfoMessage("");
        setErrorMessage("");
      }, 3000);
    }
  };

  const fetchNotificationData = async () => {
    try {
      const { data } = await fetchUserGroupNames()
      setNotificationGroups(data)
    } catch (error) {
      console.log('error -  Failed to load notification data', error)
    }
  }

  const parseChargebacksData = (data) => {
    const parsedData = data.map((item) => {
      return {
        chargebackType: item["Chargeback Type"],
        subType: item["Sub type"] || "",
        quantity: parseFloat(
          (item["No of Chargebacks"] || "").replace(
            /[^0-9. ]/g,
            ""
          )
        ) || 0.0,
        disputedSum: parseFloat(
          (item["Sum of Disputed"] || "").replace(
            /[^0-9. ]/g,
            ""
          )
        ) || 0.0,
        financialSum: parseFloat(
          (item["Sum of Financial charge"] || "").replace(
            /[^0-9. ]/g,
            ""
          )
        ) || 0.0,
        notDisputedSum: parseFloat(
          (item["Sum of Not Disputed"] || "").replace(
            /[^0-9. ]/g,
            ""
          )
        ) || 0.0,
        vendorCode: item["Vendor Code"],
        reportDate: (moment(item["Report Date"], 'MM/DD/YYYY').format()),
        comment: item["Comment"] || "",
      }
    })
    return parsedData
  }
  
  const parseShortageClaimsData = (data) => {
    const parsedData = data.map((item) => {
      return {
        invoiceAmount: parseFloat(
          (item["Invoice Amount"] || "").replace(
            /[^0-9. ]/g,
            ""
          )
        ) || 0.0,
        varianceAmount: parseFloat(
          (item["Qty Variance Amount (shortage claim)"] || "").replace(
            /[^0-9. ]/g,
            ""
          )
        ) || 0.0,
        invoiceNumber: item["Invoice Number"],
        disputeId: item["Dispute ID"],
        po: item["PO"],
        vendorCode: item["Vendor Code"],
        reportDate: (moment(item["Report Date"], 'MM/DD/YYYY').format()),
        comment: item["Comment"] || "",
      }
    })
    return parsedData
  }

  const uploadShortageClaimsAndChargebacks = async (e, type) => {
    try {
      setUploading(true)
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = async (event) => {
        const fileContent = event.target.result;
        const wb = XLSX.read(fileContent, { type: "array" });
        const wsName = wb.SheetNames[0];
        const ws = wb.Sheets[wsName];
        const data = XLSX.utils.sheet_to_json(ws, { raw: false });
        let parsedData = []
        if (type === 'chargebacks') {
          parsedData = parseChargebacksData(data)
        } else {
          parsedData = parseShortageClaimsData(data)
        }
        try {
          const { message } = await uploadWbrShortageClaimsAndChargebacks(
            parsedData,
            type
          );
          window.alert(message);
          setUploading(false)
        } catch (e) {
          setUploading(false)
          window.alert(e);
        }
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      setUploading(false)
      console.log('uploadShortageClaimsAndChargebacks - error', error);
    }
  }

  const handleUpdateAMRulesConfig = async () => {
    try {
      setIsSavingAMRulesConfig(true);
      const data = await updateAMRulesConfig({
        amsStrategy,
        seoOptimization,
        vendorCode: vendorState.selected,
      });
      setInfoMessage(data.message);
    } catch (err) {
      console.log(err);
      setErrorMessage(err?.message);
    } finally {
      setIsSavingAMRulesConfig(false);
      setTimeout(() => {
        setInfoMessage("");
        setErrorMessage("");
      }, 3000);
    }
  };

  const loadAMRulesConfig = async () => {
    try {
      setIsLoadingAMRulesConfig(true);
      const data = await fetchCustomerConfig(vendorState?.selected);
      setAmsStrategy(data?.amsStrategy);
      setSeoOptimization(data?.seoOptimization);
      setIsLoadingAMRulesConfig(false);
    } catch (error) {
      setIsLoadingAMRulesConfig(false)
    }
  };

  const handleUpdatedBIAccessSave = async () => {
    try {
      setIsSavingUpdatedBIAccess(true);
      const data = await updateBIAccess({ biAccess, vendorCode: vendorState?.selected });
      setInfoMessage(data.message);
    } catch (err) {
      console.log(err);
      setErrorMessage(err?.message);
    } finally {
      setIsSavingUpdatedBIAccess(false);
      setTimeout(() => {
        setInfoMessage("");
        setErrorMessage("");
      }, 3000);
    }
  };

  useEffect(() => {
    loadNotificationsSubscriptionOptions();
    loadCohesityOptions();
    loadWBROptions();
    fetchNotificationData()
    loadAMRulesConfig()
  }, []);

  useEffect(() => {
    loadSubscribedNotificationsSubscriptionOptions(vendorState.selected);
    loadCustomerCohesityOptions(vendorState.selected);
    loadCustomerWBROptions(vendorState.selected);
    loadAMRulesConfig()
  }, [vendorState.selected]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Customer Settings"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="primary">Customer Settings</Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: "white", margin: "16px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "1rem",
              }}
            >
              {infoMessage && <Alert severity="success">{infoMessage}</Alert>}
              {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
              <Typography color="textSecondary">
                Bi Dashboard Access
                {isSavingUpdatedBIAccess && (
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    style={{ paddingLeft: "4px" }}
                  >
                    (Saving changes. Please wait...)
                  </Typography>
                )}
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={biAccess}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setBiAccess(true)
                      } else {
                        setBiAccess(false)
                      }
                    }}
                    color="primary"
                  />
                }
                label={'BI Access'}
                style={{
                  width: "fit-content",
                }}
              />
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={
                  isSavingUpdatedBIAccess
                }
                style={{
                  alignSelf: "end",
                }}
                onClick={handleUpdatedBIAccessSave}
              >
                Save
              </Button>
              <Divider />
              <Typography color="textSecondary">
                Notifications Subscription
                {isLoadingSubscribedNotificationsSubscriptions && (
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    style={{ paddingLeft: "4px" }}
                  >
                    (Loading notifications subscribed. Please wait...)
                  </Typography>
                )}
                {isSavingSubscribedNotificationsSubscriptions && (
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    style={{ paddingLeft: "4px" }}
                  >
                    (Saving changes. Please wait...)
                  </Typography>
                )}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {availableNotificationsSubscriptionOptions[
                  vendorState.accounttype
                ]?.map((option) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={updateNotificationsSubscriptions.add.includes(
                          option
                        )}
                        disabled={
                          isLoadingSubscribedNotificationsSubscriptions ||
                          isSavingSubscribedNotificationsSubscriptions
                        }
                        onChange={(event) => {
                          if (event.target.checked) {
                            handleAddNotificationsSubscriptionOption(option);
                          } else {
                            handleRemoveNotificationsSubscriptionOption(option);
                          }
                        }}
                        color="primary"
                      />
                    }
                    label={option}
                    style={{
                      width: "fit-content",
                    }}
                  />
                ))}
              </div>
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={
                  isLoadingSubscribedNotificationsSubscriptions ||
                  isSavingSubscribedNotificationsSubscriptions
                }
                style={{
                  alignSelf: "end",
                }}
                onClick={handleUpdateNotificationsSubscriptionSave}
              >
                Save
              </Button>
              <Divider />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography color="textSecondary">
                  Cohesity Options
                  {isLoadingConfiguredCohesityOptions && (
                    <Typography
                      color="textSecondary"
                      variant="caption"
                      style={{ paddingLeft: "4px" }}
                    >
                      (Loading configured cohesity options. Please wait...)
                    </Typography>
                  )}
                  {isSavingUpdatedCohesityOptions && (
                    <Typography
                      color="textSecondary"
                      variant="caption"
                      style={{ paddingLeft: "4px" }}
                    >
                      (Saving changes. Please wait...)
                    </Typography>
                  )}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{
                      textTransform: "none",
                    }}
                    startIcon={<PrintIcon />}
                    onClick={async () => {
                      const { data: asins } = await fetchCohesityAsins(
                        vendorState?.selected
                      );
                      const worksheet = XLSX.utils.json_to_sheet(
                        (asins || []).length > 0
                          ? [
                              ..._.uniq(asins)
                                .sort()
                                .map((item) => ({ ASIN: item })),
                            ]
                          : [{ ASIN: "" }]
                      );
                      const workbook = XLSX.utils.book_new();
                      XLSX.utils.book_append_sheet(
                        workbook,
                        worksheet,
                        "Sheet1"
                      );
                      const exportFile = `Cohesity_ASINs_${vendorState?.selected}.xlsx`;
                      XLSX.writeFile(workbook, exportFile);
                    }}
                  >
                    Export ASINs
                  </Button>
                  <FormControl variant="outlined">
                    <label htmlFor="upload-xlsx">
                      <input
                        style={{ display: "none" }}
                        id="upload-xlsx"
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onChange={async (e) => {
                          const reader = new FileReader();
                          reader.onload = async (event) => {
                            try {
                              const workbook = XLSX.read(event.target.result, {
                                type: "array",
                              });
                              const worksheetName = workbook.SheetNames[0];
                              const worksheet = workbook.Sheets[worksheetName];
                              const data = XLSX.utils.sheet_to_json(worksheet);
                              setIsSavingUpdatedCohesityOptions(true);
                              await saveCohesityAsins(
                                vendorState?.selected,
                                data
                                  .map((item) => item.ASIN)
                                  .filter((item) => item.length > 0)
                              );
                              setIsSavingUpdatedCohesityOptions(false);
                            } catch (err) {
                              console.error(err);
                              setIsSavingUpdatedCohesityOptions(false);
                            }
                          };
                          reader.readAsArrayBuffer(e.target.files[0]);
                        }}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{
                          textTransform: "none",
                        }}
                        startIcon={<CloudUploadIcon />}
                        component={"span"}
                      >
                        Upload ASINs
                      </Button>
                    </label>
                  </FormControl>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                {cohesityOptions.map((option) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={configuredCohesityOptions.includes(option)}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setConfiguredCohesityOptions([
                              ...configuredCohesityOptions,
                              option,
                            ]);
                          } else {
                            setConfiguredCohesityOptions([
                              ...configuredCohesityOptions.filter(
                                (item) => item !== option
                              ),
                            ]);
                          }
                        }}
                        color="primary"
                      />
                    }
                    label={option}
                    style={{
                      width: "fit-content",
                    }}
                  />
                ))}
              </div>
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={
                  isLoadingConfiguredCohesityOptions ||
                  isSavingUpdatedCohesityOptions
                }
                style={{
                  alignSelf: "end",
                }}
                onClick={handleUpdatedCohesityOptionsSave}
              >
                Save
              </Button>
              <Divider />
              <Typography color="textSecondary">
                WBR Options
                {isLoadingWBROptions && (
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    style={{ paddingLeft: "4px" }}
                  >
                    (Loading configured WBR options. Please wait...)
                  </Typography>
                )}
                {isSavingUpdatedWBROptions && (
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    style={{ paddingLeft: "4px" }}
                  >
                    (Saving changes. Please wait...)
                  </Typography>
                )}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                {wbrOptions.map((option) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={configuredWBROptions.includes(option)}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setConfiguredWBROptions([
                              ...configuredWBROptions,
                              option,
                            ]);
                          } else {
                            setConfiguredWBROptions([
                              ...configuredWBROptions.filter(
                                (item) => item !== option
                              ),
                            ]);
                          }
                        }}
                        color="primary"
                      />
                    }
                    label={toProperCaseRemoveUnderscore(option)}
                    style={{
                      width: "fit-content",
                    }}
                  />
                ))}
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wbrEnabled}
                      onChange={(event) => {
                          setWbrEnabled(event.target.checked)
                      }}
                      color="primary"
                    />
                  }
                  label={'WBR Enabled'}
                  style={{
                    width: "fit-content",
                  }}
                />
              </div>
              <div style={{ width: '500px' }}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-multiple-checkbox-label">User Groups</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={userGroups}
                    onChange={(e) => setUserGroups(e.target.value)}
                    input={<OutlinedInput label="User Groups" />}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {notificationGroups.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={userGroups?.indexOf(name) > -1} color="primary" />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={
                  isLoadingWBROptions ||
                  isSavingUpdatedWBROptions
                }
                style={{
                  alignSelf: "end",
                }}
                onClick={handleUpdatedWBROptionsSave}
              >
                Save
              </Button>
              <Divider />
              <Typography color="textSecondary">
                Shortage Claims And Chargebacks
                {uploading && (
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    style={{ paddingLeft: "4px" }}
                  >
                    (Uploading data. Please wait...)
                  </Typography>
                )}
              </Typography>
              <div
                style={{
                  display: "flex",
                  gap: "16px",
                }}
              >
              </div>
              <div style={{ display: "flex", justifyContent: 'flex-start', alignItems: 'center', gap: '10px' }}>
                <>
                  <input
                    id="file-upload-input"
                    type="file"
                    accept=".xlsx"
                    onClick={e => (e.target.value = null)}
                    onChange={async (e) => {
                      uploadShortageClaimsAndChargebacks(e, 'shortageClaims')
                    }}
                    style={{ display: "none" }}
                    disabled={uploading}
                  />
                  <label htmlFor="file-upload-input">
                    <Button
                      component="span"
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                      disabled={uploading}
                    >
                      Upload Shortage Claims [.xlsx]
                    </Button>
                  </label>
                </>
                <>
                  <input
                    id="file-upload-input-cb"
                    type="file"
                    accept=".xlsx"
                    onClick={e => (e.target.value = null)}
                    onChange={async (e) => {
                      uploadShortageClaimsAndChargebacks(e, 'chargebacks')
                    }}
                    style={{ display: "none" }}
                    disabled={uploading}
                  />
                  <label htmlFor="file-upload-input-cb">
                    <Button
                      component="span"
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                      disabled={uploading}
                    >
                      Upload Chargebacks [.xlsx]
                    </Button>
                  </label>
                </>
                <a
                  href={ShortageClaimsAndChargeBacksTemplate}
                  download="ShortageClaimsAndChargeBacksTemplate.xlsx"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    component="span"
                    size="small"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Download Template
                  </Button>
                </a>
              </div>
              <Divider />
              <Typography color="textSecondary">
                AM Rules Config
                {isLoadingAMRulesConfig && (
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    style={{ paddingLeft: "4px" }}
                  >
                    (Loading configured AM Rules Config. Please wait...)
                  </Typography>
                )}
                {isSavingAMRulesConfig && (
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    style={{ paddingLeft: "4px" }}
                  >
                    (Saving changes. Please wait...)
                  </Typography>
                )}
              </Typography>
              <Grid container fullWidth direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={12} style={{ paddingLeft: '6px' }}>
                  <Typography style={{ fontSize: 16 }}>
                    Are the content and creatives optimized?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={seoOptimization}
                      onChange={(e) => setSeoOptimization(parseInt(e.target.value))}
                    >
                      <FormControlLabel value={0} control={<Radio color="primary" />} label={'Optimized'} />
                      <FormControlLabel value={10} control={<Radio color="primary" />} label="Not Optimized" />
                      <FormControlLabel value={5} control={<Radio color="primary" />} label="Minimum" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                fullWidth
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                style={{ paddingTop: '15px' }}>
                <Grid item xs={12} style={{ paddingLeft: '6px' }}>
                  <Typography style={{ fontSize: 16 }}>
                    What is the AMS strategy?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={amsStrategy}
                      onChange={(e) => setAmsStrategy(e.target.value)}
                    >
                      <FormControlLabel value={AMS_STRATEGY.NO_AMS} control={<Radio color="primary" />} label={'No AMS'} />
                      <FormControlLabel value={AMS_STRATEGY.TOP_OF_SEARCH_IMPRESSIONS} control={<Radio color="primary" />} label="Top of search impressions" />
                      <FormControlLabel value={AMS_STRATEGY.MIX_OF_ALL} control={<Radio color="primary" />} label="Mix of all" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={
                  isSavingAMRulesConfig
                }
                style={{
                  alignSelf: "end",
                }}
                onClick={handleUpdateAMRulesConfig}
              >
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
