import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Breadcrumbs,
  Select,
  FormControl,
  MenuItem,
  Container,
  Button,
  CircularProgress,
  Backdrop,
  InputLabel,
  Link,
  TextField,
  Box,
  FormControlLabel,
  RadioGroup,
  Divider,
  Radio,
  Chip,
  FormLabel,
  TextareaAutosize,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import axios from "axios";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import moment from "moment";
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

//icons
import PrintIcon from "@material-ui/icons/Print";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import Table from "./components/Table/Table";
import PageTitle from "../../components/PageTitle/PageTitle";

//context
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import { arrayFromString, toProperCase } from "../../healpers/utilityFunctions";
import Confirmation from "./components/Table/Confirmation";
import { cloneAndSortDescending } from "../../healpers/dateRange.helpers";
import { getMonthDatesBetween, getQuarterDatesBetween, getWeekDatesBetween, getYearDatesBetween } from "../../healpers/utilityFunctions";
import ScheduleDayPartingCampaign from "./components/ScheduleDayParting";
import ConfirmationPop from "../../components/Confirmation/Confirmation";

export default function AmsCampaigns(props) {
  var classes = useStyles();
  const exportFunctionRef = useRef();

  const INITIAL_FORM_STATE = {
    keywords: [],
    defaultKeywords: [],
    negativeKeywordsExact: [],
    negativeKeywordsPhrase: [],
    defaultNegativeKeywords: [],
    productTargets: [],
    defaultProductTargets: [],
    negativeProducts: [],
    defaultNegativeProducts: [],
    negativeKeywordType: "Negative exact",
    manualTargetingType: null,
    adGroupId: null,
  };

  //global
  var vendorState = useVendorState();
  // local
  const [mainTableData, setmainTableData] = useState(null);
  const [disablePast, setDisablePast] = useState(null);
  const [fullPageLoader, setfullPageLoader] = useState(false);

  const [campaignTypes] = useState(["SP", "SB", "SD"]);
  const [campaignStates] = useState(["ENABLED", "PAUSED", "ARCHIVED"]);

  const [selectedCampaignType, setSelectedCampaignType] = useState("SP");
  const [selectedCampaignState, setSelectedCampaignState] = useState("ENABLED");
  const [open, setOpen] = useState(false);
  const [editCampaignData, setEditCampaignData] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [campaignData, setCampaignData] = useState(INITIAL_FORM_STATE);
  const [reportDays, setReportDays] = useState(65);
  const [advertisingGoal, setAdvertisingGoal] = useState("Increase Sales");
  const [openOptimizationModel, setOpenOptimizationModel] = useState(false);
  const [optimizeNowModal, setOptimizeNowModal] = useState(false);
  const [optimizationCampaignID, setOptimizationCampaignID] = useState(null);
  const [campaignName, setCampaignName] = useState("");
  const [advertisingTarget, setAdvertisingTarget] = useState("");
  const [accountType, setAccountType] = useState("");
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [scheduleCampaignDays, setScheduleCampaignDays] = useState(14);
  const [optimizationInterval, setOptimizationInterval] = useState(7);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [activity, setActivity] = useState(false);
  const [radioValue, setradioValue] = useState("weekly");
  const [dateRange, setdateRange] = useState(null);
  const [weekRange, setweekRange] = useState(null);
  const [monthRange, setmonthRange] = useState(null);
  const [quarterRange, setquarterRange] = useState(null);
  const [annualRange, setannualRange] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [selectedDateRange, setselectedDateRange] = useState(0);
  const [maxAdvertisingCost, setMaxAdvertisingCost] = useState(10);
  const [maxAdvertisingClicks, setMaxAdvertisingClicks] = useState(10);
  const [excludedNegativeKeywords, setExcludedNegativeKeywords] = useState([]);
  const [excludedNegativeProductTargets, setExcludedNegativeProductTargets] = useState([]);
  const selectedRowCount = selectedRowData.length;
  const [range, setRange] = useState({
    startDate: new Date(moment("01-01-2023", "DD-MM-YYYY")),
    endDate: new Date(),
  });
  const [ScheduleDayPartingCampaignModal , setScheduleDayPartingCampaignModal] = useState(false);
  const [toDate, setToDate] = useState(moment().endOf('month'))
  const [fromDate, setFromDate] = useState(moment().startOf('month'))
  const [exact, setExact] = useState(false)
  const [broad, setBroad] = useState(false)
  const [phrase, setPhrase] = useState(false)
  const [ordersCount, setOrdersCount] = useState(0)
  const [topOfSearch, setTopOfSearch] = useState(0);
  const [productPage, setProductPage] = useState(0);
  const [restOfSearch, setRestOfSearch] = useState(0);

  async function loadData() {
    try {
      setfullPageLoader(true);
      let params = {
        vendorCode: vendorState?.selected,
        campaignType: selectedCampaignType.toLowerCase(),
        campaignState: selectedCampaignState,
        startDate: moment(dateRange[selectedDateRange].startdate).format("MM-DD-YYYY"),
        endDate: moment(dateRange[selectedDateRange].enddate).format("MM-DD-YYYY"),
      };
      if(radioValue === 'custom') {
        params.startDate = moment(fromDate).format("MM-DD-YYYY")
        params.endDate = moment(toDate).format("MM-DD-YYYY")
      }
      axios
        .get(BASE_URL + api.getAMSCampaigns, tokenConfig(params))
        .then((result) => {
          if (result.data.data) {
            setmainTableData(result.data.data);
            setfullPageLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setfullPageLoader(false);
        });
    } catch (error) {
      console.log(error);
      setfullPageLoader(false);
    }
  }

  useEffect(() => {
    if (vendorState.selected !== 0) {
      loadData();
    }
    return () => {
      setmainTableData(null);
    };
  }, [selectedCampaignType, selectedCampaignState, vendorState.selected, selectedDateRange, dateRange, fromDate,toDate]);

  useEffect(() => {
    if (vendorState.selected) {
      fetchDateRanges();
    }
  }, [vendorState.selected]);

  useEffect(() => {
    if (range) {
      const weekDateRange = cloneAndSortDescending([...getWeekDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const monthDateRange = cloneAndSortDescending([...getMonthDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const quarterDateRange = cloneAndSortDescending([...getQuarterDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const annualDateRange = cloneAndSortDescending([...getYearDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      setdateRange(weekDateRange);
      if (weekDateRange.length !== 0) setselectedDateRange(0);

      setweekRange(weekDateRange);
      setmonthRange(monthDateRange);
      setquarterRange(quarterDateRange);
      setannualRange(annualDateRange);
    }
    return () => {
      setdateRange(null);
      setweekRange([]);
      setmonthRange([]);
      setquarterRange([]);
      setannualRange([]);
    };
  }, [range]);

  const fetchDateRanges = async () => {
    try {
      const result = await axios.get(BASE_URL + api.getAMSCampaignReportsDateRange + vendorState.selected, tokenConfig());
      if (result.data.data) {
        setRange(result.data.data);
        setfullPageLoader(false);
      }
    } catch (error) {
      console.log(error);
      setfullPageLoader(false);
    }
  };

  const editCampaign = () => {
    setDisableSubmit(true);

    let body = {
      campaignId: editCampaignData.campaignId,
      dailyBudget: parseFloat(editCampaignData?.budget?.budget),
      campaignState: editCampaignData.state,
      endDate: editCampaignData.endDate ? moment(editCampaignData.endDate).format("YYYYMMDD") : null,
      campaignType: selectedCampaignType.toLowerCase(),
      vendorCode: vendorState?.selected,
      campaignName: editCampaignData.name,
      manualTargetingType: campaignData.manualTargetingType,
      adGroupId: campaignData.adGroupId,
      keywords: {
        shouldDelete: campaignData.defaultKeywords
          .filter((keyword) => !campaignData.keywords.includes(keyword.keywordText))
          .map((keyword) => keyword.keywordId),
        shouldInsert: campaignData.keywords.filter((keyword) => {
          if (campaignData.defaultKeywords.find((dk) => dk.keywordText === keyword)) return false;
          else return true;
        }),
      },

      negativeKeywords: {
        shouldDelete: campaignData.defaultNegativeKeywords
          .filter((keyword) => {
            if (keyword.matchType === "NEGATIVE_EXACT" && !campaignData.negativeKeywordsExact.includes(keyword.keywordText)) return true;

            if (keyword.matchType === "NEGATIVE_PHRASE" && !campaignData.negativeKeywordsPhrase.includes(keyword.keywordText)) return true;
            return false;
          })
          .map((keyword) => keyword.keywordId),
        shouldInsert: {
          exact: campaignData.negativeKeywordsExact.filter((keyword) => {
            if (campaignData.defaultNegativeKeywords.find((dnk) => dnk.matchType === "NEGATIVE_EXACT" && dnk.keywordText === keyword)) return false;
            else return true;
          }),
          phrase: campaignData.negativeKeywordsPhrase.filter((keyword) => {
            if (campaignData.defaultNegativeKeywords.find((dnk) => dnk.matchType === "NEGATIVE_PHRASE" && dnk.keywordText === keyword)) return false;
            else return true;
          }),
        },
      },

      negativeProductTargets: {
        shouldDelete: campaignData.defaultNegativeProducts
          .filter((product) => !campaignData.negativeProducts.includes(product.expression[0].value))
          .map((product) => product.targetId),
        shouldInsert: campaignData.negativeProducts.filter((asin) => {
          if (campaignData.defaultNegativeProducts.find((dk) => dk.expression[0].value === asin)) return false;
          else return true;
        }),
      },

      productTargets: {
        shouldDelete: campaignData.defaultProductTargets
          .filter((product) => !campaignData.productTargets.includes(product.expression[0].value))
          .map((product) => product.targetId),
        shouldInsert: campaignData.productTargets.filter((asin) => {
          if (campaignData.defaultProductTargets.find((dk) => dk.expression[0].value === asin)) return false;
          else return true;
        }),
      },
    };
    console.log({ body, campaignData });
    axios
      .put(BASE_URL + api.editAMSCampaigns, body, tokenConfig())
      .then(() => {
        loadData();
        setCampaignData(INITIAL_FORM_STATE);
        onCloseModal();
        setfullPageLoader(false);
        setDisableSubmit(false);
      })
      .catch((err) => {
        console.log(err);
        loadData();
        setfullPageLoader(false);
        onCloseModal();
        setDisableSubmit(false);
      });
  };

  const getKeywords = async (campaign) => {
    try {
      setfullPageLoader(true);
      const result = await axios.get(
        BASE_URL + api.getAMSCampaign,
        tokenConfig({
          campaignId: campaign.campaignId,
          campaignType: selectedCampaignType.toLowerCase(),
          targetingType: editCampaignData.targetingType,
          vendorCode: campaign.vendorCode,
        })
      );
      if (result.data.data) {
        setCampaignData((prevState) => ({
          ...prevState,
          keywords: result.data.data.keywords
            .filter((keywords) => keywords.state === "ENABLED")
            .map((keyword) => keyword.keywordText)
            .reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), []),

          productTargets: result.data.data.productTargets
            .filter((asin) => asin.state === "ENABLED")
            .map((asin) => asin.expression[0].value)
            .reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), []),

          negativeKeywordsExact: result.data.data.negativeKeywords
            .filter((keyword) => keyword.matchType === "NEGATIVE_EXACT" && keyword.state === "ENABLED")
            .map((keyword) => keyword.keywordText)
            .reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), []),
          negativeKeywordsPhrase: result.data.data.negativeKeywords
            .filter((keyword) => keyword.matchType === "NEGATIVE_PHRASE" && keyword.state === "ENABLED")

            .map((keyword) => keyword.keywordText)
            .reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), []),
          negativeProducts: result.data.data.negativeProductTargets
            .filter((asin) => asin.state === "ENABLED")
            .map((asin) => asin.expression[0].value)
            .reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), []),
          defaultKeywords: result.data.data.keywords,
          defaultProductTargets: result.data.data.productTargets,
          defaultNegativeKeywords: result.data.data.negativeKeywords,
          defaultNegativeProducts: result.data.data.negativeProductTargets,
          manualTargetingType: result.data.data.manualTargetingType,
          adGroupId: result.data.data.adGroupId,
        }));
        setfullPageLoader(false);
      }
    } catch (err) {
      console.log(err);
      setfullPageLoader(false);
    }
  };

  const onOpenModal = (campaign) => {
    setOpen(true);
    getKeywords(campaign);
    setEditCampaignData(campaign);
  };
  const onCloseModal = () => setOpen(false);

  const handleDateChange = (date) => {
    setEditCampaignData({ ...editCampaignData, endDate: date });
  };

  // console.log("editCampaignData", editCampaignData, mainTableData);

  const onSetCampaignData = (campaignValue) => {
    setCampaignData((prevState) => ({
      ...prevState,
      ...campaignValue,
    }));
  };
  const onTypeChange = (e) => {
    const { name, value } = e.target;
    setCampaignData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const onValuesChange = (e) => {
    const { name, value } = e.target;

    setCampaignData((prevState) => ({
      ...prevState,
      [name]: ["keywords", "productTargets", "negativeKeywordsPhrase", "negativeKeywordsExact", "negativeProducts"].includes(name)
        ? formatStringtoArray(value)
        : value,
    }));
  };

  const formatStringtoArray = (string) => {
    let arr = string ? string.replace(/\n/g, ",").split(",") : [];
    const filtered = arr.filter((item, index) => {
      if (!!item || index === arr.length - 1) {
        return true;
      }
      return false;
    });
    return filtered;
  };

  const runOptimization = async (campaignId) => {
    setActivity(true);
    try {
      let body = {
        campaignId: optimizationCampaignID,
        advertisingGoal: advertisingGoal,
        reportDays: parseInt(reportDays),
        vendorCode: vendorState?.selected,
        advertisingTarget: parseInt(advertisingTarget),
        optimizationInterval: parseInt(optimizationInterval),
        accountType,
        excludedNegativeKeywords,
        excludedNegativeProductTargets,
        maxAdvertisingClicks: parseInt(maxAdvertisingClicks),
        maxAdvertisingCost: parseInt(maxAdvertisingCost),
        campaignType: selectedCampaignType
      };
      let matchTypes = []
      if (exact) { matchTypes.push("EXACT") }
      if (broad) { matchTypes.push('BROAD') }
      if (phrase) { matchTypes.push('PHRASE') }
      if (ordersCount) { body.numberOfOrders = parseInt(ordersCount) }
      if (topOfSearch) {body.topOfSearch = parseInt(topOfSearch) }
      if (productPage) {body.productPage = parseInt(productPage) }
      if (restOfSearch) {body.restOfSearch = parseInt(restOfSearch) }
      body.matchTypes = matchTypes
      await axios.post(BASE_URL + api.runOptimization, body);
      alert(`Optimization added to campaign - ${campaignId}`);
      setActivity(false);
      onCloseOptimizationModal();
      loadData();
    } catch (error) {
      console.log(error);
      // alert("Failed to optimize campaign");
      setActivity(false);
      onCloseOptimizationModal();
    }
  };

  const optimizeNow = async () => {
    setActivity(true);
    let campaigns = selectedRowData.map((campaign) => campaign.campaignId);
    if(campaigns.length === 0) {
      campaigns = [optimizationCampaignID];
    }
    try {
      let body = {
        campaigns,
        campaignType: selectedCampaignType
      };
      await axios.post(BASE_URL + api.runOptimizationImmediately, body);
      setActivity(false);
      onCloseOptimizationModal();
      loadData();
    } catch (error) {
      console.log(error);
      // alert("Failed to optimize campaign");
      setActivity(false);
      onCloseOptimizationModal();
    }
  };

  const runScheduleCampaignDays = async (campaignId) => {
    setActivity(true);
    try {
      let body = {
        campaignId: optimizationCampaignID,
        advertisingGoal: advertisingGoal,
        reportDays: parseInt(reportDays),
        vendorCode: vendorState?.selected,
        advertisingTarget: parseInt(advertisingTarget),
        optimizationInterval: parseInt(optimizationInterval),
        accountType,
        scheduleCampaignDays: parseInt(scheduleCampaignDays),
        excludedNegativeKeywords,
        excludedNegativeProductTargets,
        maxAdvertisingClicks: parseInt(maxAdvertisingClicks),
        maxAdvertisingCost: parseInt(maxAdvertisingCost),
        campaignType: selectedCampaignType
      };
      let matchTypes = []
      if (exact) { matchTypes.push("EXACT") }
      if (broad) { matchTypes.push('BROAD') }
      if (phrase) { matchTypes.push('PHRASE') }
      if (ordersCount) { body.numberOfOrders = parseInt(ordersCount) }
      body.matchTypes = matchTypes
      await axios.post(BASE_URL + api.createScheduledCampaign, body);
      setOpenScheduleModal(false);
      setActivity(false);
      loadData();
    } catch (error) {
      setOpenScheduleModal(false);
      setActivity(false);
      alert("Failed to schedule campaign");
      console.log(error);
    }
  };

  const optimizationModel = (open, campaignId, campaignName) => {
    setCampaignName(campaignName);
    setOptimizationCampaignID(campaignId);
    setOpenOptimizationModel(open);
  };
  const scheduleModel = (open, campaignId, campaignName) => {
    setCampaignName(campaignName);
    setOptimizationCampaignID(campaignId);
    setOpenScheduleModal(open);
  };

  const handleSelectAdvertisingGoal = (event) => {
    setAdvertisingGoal(event.target.value);
  };

  const handleSelectAccountType = (event) => {
    setAccountType(event.target.value);
  };

  const onCloseOptimizationModal = () => {
    setOpenOptimizationModel(false);
    setOptimizeNowModal(false);
    setOpenScheduleModal(false);
    setCampaignName("");
    setAdvertisingGoal("Increase Sales");
    setReportDays(65);
    setSelectedRowData([]);
  };

  const stopOptimization = async () => {
    try {
      let body = {
        campaignId: optimizationCampaignID,
      };
      await axios.post(BASE_URL + api.stopOptimization, body);
      setOpenConfirmation(false);
      loadData();
      setCampaignName("");
      setOptimizationCampaignID("");
      setSelectedRowData([]);
    } catch (error) {
      console.log(error);
      setOpenConfirmation(false);
      setCampaignName("");
      setOptimizationCampaignID("");
      setSelectedRowData([]);
      alert("Failed to stop campaign optimization");
    }
  };

  const stopOptimizationModal = (campaign) => {
    setOpenConfirmation(true);
    setCampaignName(campaign.name);
    setOptimizationCampaignID(campaign.campaignId);
  };

  const handleRadioChange = (event) => {
    setradioValue(event.target.value);
    if (event.target.value === "weekly") {
      setdateRange(weekRange);
    } else if (event.target.value === "monthly") {
      setdateRange(monthRange);
    } else if (event.target.value === "quarterly") {
      setdateRange(quarterRange);
    } else {
      setdateRange(annualRange);
    }
    setselectedDateRange(0);
  };

  const runMultipleOptimization = async () => {
    setActivity(true);
    try {
      const campaigns = [];
      selectedRowData.forEach((rowData) => {
        if (!rowData.runOptimization) {
          let campaignObj = {
            campaignId: rowData.campaignId,
            advertisingGoal: advertisingGoal,
            reportDays: parseInt(reportDays),
            vendorCode: vendorState?.selected,
            advertisingTarget: parseInt(advertisingTarget),
            optimizationInterval: parseInt(optimizationInterval),
            accountType,
            excludedNegativeKeywords,
            excludedNegativeProductTargets,
            maxAdvertisingClicks: parseInt(maxAdvertisingClicks),
            maxAdvertisingCost: parseInt(maxAdvertisingCost),
            campaignType: selectedCampaignType
          } 
          let matchTypes = []
          if (exact) { matchTypes.push("EXACT") }
          if (broad) { matchTypes.push('BROAD') }
          if (phrase) { matchTypes.push('PHRASE') }
          if (ordersCount) { campaignObj.numberOfOrders = parseInt(ordersCount) }
          if (topOfSearch) {campaignObj.topOfSearch = parseInt(topOfSearch) }
          if (productPage) {campaignObj.productPage = parseInt(productPage) }
          if (restOfSearch) {campaignObj.restOfSearch = parseInt(restOfSearch) }
          campaignObj.matchTypes = matchTypes
          campaigns.push(campaignObj);
        }
      });
      await axios.post(BASE_URL + api.startOptimizations, { campaigns });
      onCloseOptimizationModal();
      loadData();
    } catch (error) {
      console.log("Start Optmizations error: ", error);
    }
    setActivity(false);
  };

  const stopMultipleOptimization = async () => {
    setActivity(true);
    try {
      const campaigns = [];
      selectedRowData.forEach((rowData) => {
        if (rowData.runOptimization) {
          campaigns.push(rowData.campaignId);
        }
      });
      await axios.post(BASE_URL + api.stopOptimizations, { campaigns });
      setOpenConfirmation(false);
      setSelectedRowData([]);
      loadData();
    } catch (error) {
      console.log("error: ", error);
    }
    setActivity(false);
  };

  const setPlacementBidding = (campaign) => {
    const {dynamicBidding}  = campaign;
    const topOfSearch = dynamicBidding.placementBidding.find((item)=> item.placement === 'PLACEMENT_TOP')
    const productPage = dynamicBidding.placementBidding.find((item)=> item.placement === 'PLACEMENT_PRODUCT_PAGE')
    const restOfSearch = dynamicBidding.placementBidding.find((item)=> item.placement === 'PLACEMENT_REST_OF_SEARCH')
    setTopOfSearch(topOfSearch?.percentage || 0)
    setProductPage(productPage?.percentage || 0)
    setRestOfSearch(restOfSearch?.percentage || 0)
  }

  return (
    <Container maxWidth={false}>
      <PageTitle
        title="Campaigns Optimisation"
        breadCrump={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className={classes.link}>
              AMS
            </Link>
            <Typography className={classes.link} color="primary">
              Manage Campaigns
            </Typography>
          </Breadcrumbs>
        }
        rightAlignComponent={
          <Grid container style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexWrap: "nowrap", width: '100%' }}>
            <FormControl >
              <RadioGroup value={radioValue} onChange={handleRadioChange}>
                <FormControlLabel value="weekly" control={<Radio color="primary" />} label="Weekly" />
                <FormControlLabel value="monthly" control={<Radio color="primary" />} label="Monthly" />
              </RadioGroup>
            </FormControl>
            <FormControl >
              <RadioGroup value={radioValue} onChange={handleRadioChange}>
                <FormControlLabel value="quarterly" control={<Radio color="primary" />} label="Quarterly" />
                <FormControlLabel value="custom" control={<Radio color="primary" />} label="Custom" />
              </RadioGroup>
            </FormControl>
            {radioValue !== 'custom' && <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Select Range</InputLabel>
              <Select
                value={selectedDateRange}
                onChange={(e) => {
                  setselectedDateRange(e.target.value);
                }}
                label="Select Range"
                disabled={dateRange && dateRange.length === 0 ? true : false}
              >
                {dateRange ? (
                  dateRange.map((range, index) => {
                    return <MenuItem value={index}>{range.startdate + " - " + range.enddate}</MenuItem>;
                  })
                ) : (
                  <MenuItem value={0}>
                    <i>No Dates</i>
                  </MenuItem>
                )}
              </Select>
            </FormControl>}
            {radioValue === 'custom' && 
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <FormControl
                  variant="outlined"
                  className={classes.dateFormControl}
                >
                  <KeyboardDatePicker
                    disableToolbar
                    inputVariant="outlined"
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="startDate"
                    label="From"
                    value={fromDate}
                    onChange={(date) => setFromDate(date)}
                    style={{width:'170px'}}
                  />
               
                  <KeyboardDatePicker
                    disableToolbar
                    inputVariant="outlined"
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="startDate"
                    label="To"
                    value={toDate}
                    onChange={(date) => setToDate(date)}
                    minDate={fromDate}
                    style={{width:'170px'}}
                  />
                </FormControl>
              </MuiPickersUtilsProvider>}
            <FormControl variant="outlined" className={classes.formControl} style={{alignItems:'flex-start'}}>
              <InputLabel id="demo-simple-select-outlined-label">Campaign Type</InputLabel>
              <Select
                defaultValue={selectedCampaignType}
                value={selectedCampaignType}
                onChange={(e) => {
                  setSelectedCampaignType(e.target.value);
                  // loadData();
                }}
                label="Campaign Type"
                disabled={campaignTypes && campaignTypes.length === 0 ? true : false}
                className={classes.select}
              >
                {campaignTypes ? (
                  campaignTypes.map((type) => {
                    return <MenuItem value={type}>{type}</MenuItem>;
                  })
                ) : (
                  <MenuItem value={0}>
                    <i>No Dates</i>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl} style={{alignItems:'flex-start'}}>
              <InputLabel id="demo-simple-select-outlined-label">State</InputLabel>
              <Select
                value={selectedCampaignState}
                onChange={(e) => {
                  setSelectedCampaignState(e.target.value);
                }}
                label="Select Range"
                disabled={campaignStates && campaignStates.length === 0 ? true : false}
                className={classes.select}
              >
                {campaignStates ? (
                  campaignStates.map((state) => {
                    return <MenuItem value={state}>{toProperCase(state)}</MenuItem>;
                  })
                ) : (
                  <MenuItem value={0}>
                    <i>No Data</i>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        }
      />
      <Grid container spacing={4} className="ams_campaign_grid">
        <Grid item xs={12} className="ams_campaign_grid_item">
          <Widget
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    color: "black",
                  }}
                >
                  Selected: {selectedRowCount}
                </Typography>
                <div>
                  {selectedRowCount > 0 && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                          setOptimizeNowModal(true);
                        }}
                        style={{ marginRight: "10px" }}
                      >
                        Run Optimizations Now
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                          setOpenOptimizationModel(true);
                        }}
                        style={{ marginRight: "10px" }}
                      >
                        Start Optimization
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        style={{ marginRight: "20px", backgroundColor: "red", color: "white" }}
                        onClick={() => setOpenConfirmation(true)}
                      >
                        Stop Optimization
                      </Button>
                    </>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              </div>
            }
          >
            <Table
              ref={exportFunctionRef}
              tableData={mainTableData}
              openEditModel={(data) => onOpenModal(data)}
              navigate={props.history.push}
              openOptimization={(campaign) => {optimizationModel(true, campaign.campaignId, campaign.name); setPlacementBidding(campaign);}}
              optimizeNow={(campaign) => {
                setOptimizeNowModal(true);
                setCampaignName(campaign.name);
                setOptimizationCampaignID(campaign.campaignId);
              }}
              openSchedule={(campaign) => scheduleModel(true, campaign.campaignId, campaign.name)}
              stopOptimization={(campaign) => stopOptimizationModal(campaign)}
              selectedRowData={(data) => {
                setSelectedRowData(data);
              }}
              openWeeklySchedule={(data)=>{setScheduleDayPartingCampaignModal(true) ; setCampaignName(data.name)}}
            />
          </Widget>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={fullPageLoader} onClick={() => setfullPageLoader(false)}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Modal open={open} onClose={onCloseModal} styles={{ modal: { width: 800, height: 500 } }} center>
        <Backdrop className={classes.backdrop} open={fullPageLoader} onClick={() => setfullPageLoader(false)}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Typography
          variant="h2"
          className={classes.typo}
          style={{
            fontWeight: 600,
            fontSize: 24,
            color: "black",
            marginBottom: 20,
          }}
        >
          Edit Campaign
        </Typography>
        <Grid container>
          <Grid item xs={6}>
            <Typography onClick={() => editCampaign()} className={classes.link}>
              Campaign State
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Select
              value={editCampaignData.state}
              onChange={(e) => {
                setEditCampaignData({
                  ...editCampaignData,
                  state: e.target.value,
                });
              }}
              label="Select State"
              disabled={campaignStates && campaignStates.length === 0 ? true : false}
              className={classes.select}
            >
              {campaignStates ? (
                campaignStates.map((state) => {
                  return <MenuItem value={state}>{state}</MenuItem>;
                })
              ) : (
                <MenuItem value={0}>
                  <i>No Data</i>
                </MenuItem>
              )}
            </Select>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 20 }}>
          <Grid item xs={6}>
            <Typography className={classes.link}>Daily Budget</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={editCampaignData?.budget?.budget}
              onChange={(e) =>
                setEditCampaignData({
                  ...editCampaignData,
                  budget: { budget: e.target.value },
                })
              }
              variant="outlined"
              type="number"
              required
            />
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 20 }}>
          <Grid item xs={6}>
            <Typography className={classes.link}>End Date</Typography>
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                variant="inline"
                label="End Date"
                placeholder="Select Date"
                value={editCampaignData.endDate ? moment(editCampaignData.endDate).format("MM-DD-YYYY") : null}
                disablePast={disablePast}
                onOpen={() => setDisablePast(true)}
                onChange={handleDateChange}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        {editCampaignData.targetingType === "MANUAL" && (
          <>
            {campaignData?.manualTargetingType === "Keyword Targeting" && (
              <Grid
                container
                spacing={4}
                style={{
                  marginTop: 20,
                }}
              >
                <Grid item xs={12}>
                  <FormLabel id="demo-radio-buttons-group-label">Keyword Targeting</FormLabel>
                  <Divider />
                </Grid>
                <Grid
                  container
                  spacing={4}
                  style={{
                    paddingLeft: "4%",
                    paddingRight: "4%",
                    paddingBottom: "2%",
                  }}
                >
                  <Grid item xs={6}>
                    <TextField
                      value={campaignData?.keywords && campaignData.keywords.join(",")}
                      onChange={onValuesChange}
                      label="List (Seperated by comma)"
                      variant="outlined"
                      rows={4}
                      name="keywords"
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      component="div"
                      width="100%"
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      borderBottom="1px solid #f0f0f0"
                      marginBottom="5px"
                    >
                      <Typography
                        component="div"
                        style={{
                          fontFamily: "Helvetica",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        {`Total keywords: ${campaignData?.keywords?.length}`}
                      </Typography>
                      <Typography
                        component="div"
                        style={{
                          fontFamily: "Helvetica",
                          fontSize: "12px",
                          lineHeight: "20px",
                          cursor: "pointer",
                          color: "blue",
                        }}
                        onClick={() => {
                          onSetCampaignData({ keywords: [] });
                        }}
                      >
                        {`Remove all`}
                      </Typography>
                    </Box>

                    {campaignData?.keywords?.map((keyWord) => (
                      <Chip key={keyWord} style={{ margin: "0px 5px 5px 0px" }} label={keyWord} />
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {campaignData?.manualTargetingType === "Product Targeting" && (
              <Grid
                container
                spacing={4}
                style={{
                  marginTop: 20,
                }}
              >
                <Grid item xs={12}>
                  <FormLabel id="demo-radio-buttons-group-label">Product Targeting</FormLabel>
                  <Divider />
                </Grid>
                <Grid
                  container
                  spacing={4}
                  style={{
                    paddingLeft: "4%",
                    paddingRight: "4%",
                    paddingBottom: "2%",
                  }}
                >
                  <Grid item xs={6}>
                    <TextField
                      value={campaignData?.productTargets && campaignData.productTargets.join(",")}
                      onChange={onValuesChange}
                      label="List (Seperated by comma)"
                      variant="outlined"
                      rows={4}
                      name="productTargets"
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      component="div"
                      width="100%"
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      borderBottom="1px solid #f0f0f0"
                      marginBottom="5px"
                    >
                      <Typography
                        component="div"
                        style={{
                          fontFamily: "Helvetica",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        {`Total ASINs: ${campaignData?.productTargets?.length}`}
                      </Typography>
                      <Typography
                        component="div"
                        style={{
                          fontFamily: "Helvetica",
                          fontSize: "12px",
                          lineHeight: "20px",
                          cursor: "pointer",
                          color: "blue",
                        }}
                        onClick={() => {
                          onSetCampaignData({ productTargets: [] });
                        }}
                      >
                        {`Remove all`}
                      </Typography>
                    </Box>

                    {campaignData?.productTargets?.map((asin) => (
                      <Chip key={asin} style={{ margin: "0px 5px 5px 0px" }} label={asin} />
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}

        {(editCampaignData.targetingType !== "MANUAL" || campaignData?.manualTargetingType === "Keyword Targeting") && (
          <Grid
            container
            spacing={4}
            style={{
              marginTop: 20,
            }}
          >
            <Grid item xs={12}>
              <FormLabel id="demo-radio-buttons-group-label">
                Negative Keyword Targeting <Typography variant="caption">Optional</Typography>
              </FormLabel>
              <Divider />
            </Grid>
            <Grid
              container
              spacing={4}
              style={{
                paddingLeft: "4%",
                paddingRight: "4%",
                paddingBottom: "2%",
              }}
            >
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <FormLabel id="negative-keyword-group-label">Match type</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="negative-keyword-group-label"
                    defaultValue="Negative exact"
                    value={campaignData.negativeKeywordType}
                    onChange={onTypeChange}
                    name="negativeKeywordType"
                  >
                    <FormControlLabel value="Negative exact" control={<Radio />} label="Negative exact" />
                    <FormControlLabel value="Negative phrase" control={<Radio />} label="Negative phrase" />
                  </RadioGroup>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={
                    campaignData.negativeKeywordType === "Negative phrase"
                      ? campaignData.negativeKeywordsPhrase && campaignData?.negativeKeywordsPhrase.join(",")
                      : campaignData.negativeKeywordsExact && campaignData?.negativeKeywordsExact.join(",")
                  }
                  onChange={onValuesChange}
                  label="List (Seperated by comma)"
                  variant="outlined"
                  rows={4}
                  name={campaignData.negativeKeywordType === "Negative phrase" ? "negativeKeywordsPhrase" : "negativeKeywordsExact"}
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item xs={6}>
                <Box
                  component="div"
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  borderBottom="1px solid #f0f0f0"
                  marginBottom="5px"
                >
                  <Typography
                    component="div"
                    style={{
                      fontFamily: "Helvetica",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    {`Total Negative keywords: ${campaignData?.negativeKeywordsExact?.length + campaignData?.negativeKeywordsPhrase?.length}`}
                  </Typography>
                  <Typography
                    component="div"
                    style={{
                      fontFamily: "Helvetica",
                      fontSize: "12px",
                      lineHeight: "20px",
                      cursor: "pointer",
                      color: "blue",
                    }}
                    onClick={() => {
                      onSetCampaignData({ negativeKeywordsExact: [] });
                      onSetCampaignData({ negativeKeywordsPhrase: [] });
                    }}
                  >
                    {`Remove all`}
                  </Typography>
                </Box>
                {campaignData?.negativeKeywordsExact?.length > 0 && <Typography component="div">{`Exact`}</Typography>}
                {campaignData?.negativeKeywordsExact?.map((keyWord) => (
                  <Chip key={keyWord} style={{ margin: "0px 5px 5px 0px" }} label={keyWord} />
                ))}

                {campaignData?.negativeKeywordsPhrase?.length > 0 && (
                  <Typography component="div" style={{ marginTop: "10px" }}>
                    Phrase
                  </Typography>
                )}
                {campaignData?.negativeKeywordsPhrase?.map((keyWord) => (
                  <Chip key={keyWord} style={{ margin: "0px 5px 5px 0px" }} label={keyWord} />
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}

        {(editCampaignData.targetingType !== "MANUAL" || campaignData?.manualTargetingType === "Product Targeting") && (
          <Grid
            container
            spacing={4}
            style={{
              marginTop: 20,
            }}
          >
            <Grid item xs={12}>
              <FormLabel id="demo-radio-buttons-group-label">
                Negative Product Target <Typography variant="caption">Optional</Typography>
              </FormLabel>
              <Divider />
            </Grid>

            <Grid
              container
              spacing={4}
              style={{
                paddingLeft: "4%",
                paddingRight: "4%",
                paddingBottom: "2%",
              }}
            >
              <Grid item xs={6}>
                <TextField
                  value={campaignData.negativeProducts && campaignData.negativeProducts.join(",")}
                  onChange={onValuesChange}
                  label="List (Seperated by comma)"
                  variant="outlined"
                  rows={4}
                  name="negativeProducts"
                  fullWidth
                  multiline
                />
              </Grid>

              <Grid item xs={6}>
                <Box
                  component="div"
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  borderBottom="1px solid #f0f0f0"
                  marginBottom="5px"
                >
                  <Typography
                    component="div"
                    style={{
                      fontFamily: "Helvetica",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    {`Total Negative products: ${campaignData.negativeProducts.length}`}
                  </Typography>
                  <Typography
                    component="div"
                    style={{
                      fontFamily: "Helvetica",
                      fontSize: "12px",
                      lineHeight: "20px",
                      cursor: "pointer",
                      color: "blue",
                    }}
                    onClick={() => {
                      onSetCampaignData({ negativeProducts: [] });
                    }}
                  >
                    {`Remove all`}
                  </Typography>
                </Box>

                {campaignData.negativeProducts.map((product) => (
                  <Chip key={product} style={{ margin: "0px 5px 5px 0px" }} label={product} />
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid style={{ display: "flex", justifyContent: "center", marginTop: 20 }} container>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            style={{ textTransform: "capitalize" }}
            disabled={disableSubmit}
            onClick={() => editCampaign()}
          >
            Update
          </Button>
        </Grid>
      </Modal>
      <Modal open={openOptimizationModel} onClose={() => onCloseOptimizationModal()} styles={{ modal: { width: 600, borderRadius: 10 } }} center>
        <Backdrop className={classes.backdrop} open={fullPageLoader} onClick={() => setfullPageLoader(false)}></Backdrop>
        {campaignName ? (
          <Grid container style={{ paddingBottom: 30 }} alignItems="center" flexDirection="row">
            <Typography style={{ fontSize: 12, paddingRight: 10, fontWeight: 600 }}>Campaign Name :</Typography>
            <Typography style={{ fontSize: 12 }}>{campaignName}</Typography>
          </Grid>
        ) : (
          <Grid container style={{ paddingBottom: 30 }} alignItems="center" flexDirection="row">
            <Typography style={{ fontSize: 12, paddingRight: 10, fontWeight: 600 }}>Start Optimization for {selectedRowCount} Campaign:</Typography>
          </Grid>
        )}
        <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="advertising-goal-label">Advertising Goal</InputLabel>
            <Select
              labelId="advertising-goal-label"
              value={advertisingGoal}
              onChange={handleSelectAdvertisingGoal}
              name="advertisingGoal"
              label="Advertising Goal"
            >
              <MenuItem value={"Increase Sales"}>Increase Sales</MenuItem>
              <MenuItem value={"Reduce ACOS"}>Reduce ACOS</MenuItem>
              <MenuItem value={"Awareness"}>Increase Awareness</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              type="number"
              value={reportDays}
              id="outlined-adornment-amount"
              name="scheduledCampaignDays"
              onChange={(number) => {
                setReportDays(number.target.value);
              }}
              label="Report Days"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              type="number"
              value={advertisingTarget}
              id="outlined-adornment-amount"
              name="advertisingTarget"
              onChange={(number) => {
                setAdvertisingTarget(number.target.value);
              }}
              label="Target ACOS"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="account-type-label">Account Type</InputLabel>
            <Select labelId="account-type-label" value={accountType} onChange={handleSelectAccountType} name="accountType" label="Account Type">
              <MenuItem value={"seller"}>Seller</MenuItem>
              <MenuItem value={"vendor"}>Vendor</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              type="number"
              value={optimizationInterval}
              id="outlined-adornment-amount"
              name="optimizationInterval"
              onChange={(number) => {
                setOptimizationInterval(number.target.value);
              }}
              label="Optimization Interval"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        {selectedCampaignType !== 'SD' &&
          <>
            <Grid xs={12} spacing={4} style={{ paddingBottom: 30 }}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <Grid>
                  <Typography color="textPrimary" variant="paragraph1">
                    Exclude Negative Keywords
                  </Typography>
                </Grid>
                <TextareaAutosize
                  maxRows={4}
                  minRows={4}
                  value={excludedNegativeKeywords}
                  onChange={(event) => {
                    setExcludedNegativeKeywords(arrayFromString(event.target.value));
                  }}
                  className={classes.textarea}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} spacing={4} style={{ paddingBottom: 30 }}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <Grid>
                  <Typography color="textPrimary" variant="paragraph1">
                    Exclude Negative Product Targets
                  </Typography>
                </Grid>
                <TextareaAutosize
                  maxRows={4}
                  minRows={4}
                  value={excludedNegativeProductTargets}
                  onChange={(event) => {
                    setExcludedNegativeProductTargets(arrayFromString(event.target.value));
                  }}
                  className={classes.textarea}
                />
              </FormControl>
            </Grid>
          </>
        }
        <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              type="number"
              value={maxAdvertisingCost}
              id="outlined-adornment-amount"
              name="maxAdvertisingCost"
              onChange={(number) => {
                setMaxAdvertisingCost(number.target.value);
              }}
              label="Max Advertising Cost"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              type="number"
              value={maxAdvertisingClicks}
              id="outlined-adornment-amount"
              name="maxAdvertisingClicks"
              onChange={(number) => {
                setMaxAdvertisingClicks(number.target.value);
              }}
              label="Max Advertising Clicks"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        {(selectedCampaignType === 'SP' || selectedCampaignType === 'SB') &&
          <>
            <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
              <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                type="number"
                value={ordersCount}
                id="outlined-adornment-amount"
                name="order"
                onChange={(number) => {
                  setOrdersCount(number.target.value);
                }}
                label="Number of Orders"
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <FormControl component="fieldset">
            <FormLabel component="legend">Match Types</FormLabel>
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox checked={exact}
                  color="primary"
                  onChange={(e) => { setExact(e.target.checked); }} name="exact" />}
                label="Exact"
              />
              <FormControlLabel
                control={<Checkbox checked={phrase}
                  color="primary"
                  onChange={(e) => { setPhrase(e.target.checked); }} name="phrase" />}
                label="Phrase"
              />
              <FormControlLabel
                control={<Checkbox checked={broad}
                  color="primary"
                  onChange={(e) => { setBroad(e.target.checked); }} name="broad" />}
                label="Broad"
              />
            </FormGroup>
          </FormControl>
          </>}
          <Grid item xs={12} spacing={4} style={{ paddingBottom: 30, paddingTop: 10 }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              type="number"
              value={topOfSearch}
              id="outlined-adornment-amount"
              name="topOfSearch"
              onChange={(number) => {
                setTopOfSearch(number.target.value);
              }}
              label="Top of Search"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              type="number"
              value={productPage}
              id="outlined-adornment-amount"
              name="productPage"
              onChange={(number) => {
                setProductPage(number.target.value);
              }}
              label="Product Page"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              type="number"
              value={restOfSearch}
              id="outlined-adornment-amount"
              name="restOfSearch"
              onChange={(number) => {
                setRestOfSearch(number.target.value);
              }}
              label="Rest of Search"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid container fullWidth direction="row" justifyContent="center" alignItems="center">
          {activity ? (
            <CircularProgress size={24} className={classes.loginLoader} />
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="small"
              loading={false}
              onClick={() => {
                selectedRowData.length > 0 ? runMultipleOptimization() : runOptimization(optimizationCampaignID);
              }}
            >
              Submit
            </Button>
          )}
        </Grid>
      </Modal>
      <Modal open={openScheduleModal} onClose={() => onCloseOptimizationModal()} styles={{ modal: { width: 600, borderRadius: 10 } }} center>
        <Backdrop className={classes.backdrop} open={fullPageLoader} onClick={() => setfullPageLoader(false)}></Backdrop>
        <Grid container style={{ paddingBottom: 30 }} alignItems="center" flexDirection="row">
          <Typography style={{ fontSize: 12, paddingRight: 10, fontWeight: 600 }}>Campaign Name :</Typography>
          <Typography style={{ fontSize: 12 }}>{campaignName}</Typography>
        </Grid>
        <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="advertising-goal-label">Advertising Goal</InputLabel>
            <Select
              labelId="advertising-goal-label"
              value={advertisingGoal}
              onChange={handleSelectAdvertisingGoal}
              name="advertisingGoal"
              label="Advertising Goal"
            >
              <MenuItem value={"Increase Sales"}>Increase Sales</MenuItem>
              <MenuItem value={"Reduce ACOS"}>Reduce ACOS</MenuItem>
              <MenuItem value={"Awareness"}>Increase Awareness</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              type="number"
              value={reportDays}
              id="outlined-adornment-amount"
              name="scheduledCampaignDays"
              onChange={(number) => {
                setReportDays(number.target.value);
              }}
              label="Report Days"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              type="number"
              value={advertisingTarget}
              id="outlined-adornment-amount"
              name="advertisingTarget"
              onChange={(number) => {
                setAdvertisingTarget(number.target.value);
              }}
              label="Target ACOS"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="account-type-label">Account Type</InputLabel>
            <Select labelId="account-type-label" value={accountType} onChange={handleSelectAccountType} name="accountType" label="Account Type">
              <MenuItem value={"seller"}>Seller</MenuItem>
              <MenuItem value={"vendor"}>Vendor</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              type="number"
              value={scheduleCampaignDays}
              id="outlined-adornment-amount"
              name="scheduleCampaignDays"
              onChange={(number) => {
                setScheduleCampaignDays(number.target.value);
              }}
              label="Schedule Campaign Days"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              type="number"
              value={optimizationInterval}
              id="outlined-adornment-amount"
              name="optimizationInterval"
              onChange={(number) => {
                setOptimizationInterval(number.target.value);
              }}
              label="Optimization Interval"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid  xs={12} spacing={4} style={{ paddingBottom: 30 }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <Grid>
              <Typography color="textPrimary" variant="paragraph1">
                Exclude Negative Keywords
              </Typography>
            </Grid>
            <TextareaAutosize
              maxRows={4}
              minRows={4}
              value={excludedNegativeKeywords}
              onChange={(event) => {
                setExcludedNegativeKeywords(arrayFromString(event.target.value));
              }}
              className={classes.textarea}
            />
          </FormControl>
        </Grid>
        <Grid  xs={12} spacing={4} style={{ paddingBottom: 30 }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <Grid>
              <Typography color="textPrimary" variant="paragraph1">
                Exclude Negative Product Targets
              </Typography>
            </Grid>
            <TextareaAutosize
              maxRows={4}
              minRows={4}
              value={excludedNegativeProductTargets}
              onChange={(event) => {
                setExcludedNegativeProductTargets(arrayFromString(event.target.value));
              }}
              className={classes.textarea}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              type="number"
              value={maxAdvertisingCost}
              id="outlined-adornment-amount"
              name="maxAdvertisingCost"
              onChange={(number) => {
                setMaxAdvertisingCost(number.target.value);
              }}
              label="Max Advertising Cost"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              type="number"
              value={maxAdvertisingClicks}
              id="outlined-adornment-amount"
              name="maxAdvertisingClicks"
              onChange={(number) => {
                setMaxAdvertisingClicks(number.target.value);
              }}
              label="Max Advertising Clicks"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid container fullWidth direction="row" justifyContent="center" alignItems="center">
          <Button variant="contained" color="primary" size="small" onClick={() => runScheduleCampaignDays(optimizationCampaignID)}>
            Submit
          </Button>
        </Grid>
      </Modal>
      <Confirmation
        open={openConfirmation}
        onCancel={() => {
          setOpenConfirmation(false);
          setCampaignName("");
          setOptimizationCampaignID("");
        }}
        campaignCount={selectedRowData.length}
        campaignName={campaignName}
        onConfirm={selectedRowData.length > 0 ? stopMultipleOptimization : stopOptimization}
      />
      <ConfirmationPop
        open={optimizeNowModal}
        onCancel={() => {
          setOptimizeNowModal(false);
          setCampaignName("");
          setOptimizationCampaignID("");
        }}
        title="Optimize Now"
        subtitle={`${campaignName ? 
          `Are you sure want to run optimization for this campaign - ${campaignName}` : 
          `Are you sure want to run optimization for ${selectedRowData.length} campaigns`} `
        }
        onConfirm={optimizeNow}
        activity={activity}
      />
      <ScheduleDayPartingCampaign open={ScheduleDayPartingCampaignModal} close={()=>setScheduleDayPartingCampaignModal(false)} campaignName={campaignName} campaignType={selectedCampaignType} />
    </Container>
  );
}