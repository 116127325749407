import React, {
  useState,
  useRef,
  forwardRef,
  useMemo,
  useEffect,
} from "react";
import {
  Button,
  Backdrop,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import ConfirmationPop from "../../../../components/Confirmation/Confirmation";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";
import { useVendorState } from "../../../../context/VendorContext";
import axios from "axios";
import api from "../../../../healpers/apiRoutes";
import { tokenConfig } from "../../../../context/UserContext";
import { BASE_URL } from "../../../../healpers/api";

export default forwardRef(function Tables(props, ref) {
  const gridRef = useRef(null);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  var vendorState = useVendorState();
  const [searchValue, setSearchValue] = useState(null);
  const [mainTableData, setMainTableData] = useState([]);
  const [addSDProgramModal, setAddSDProgramModal] = useState(false);
  const [removeSDProgramModal, setRemoveSDProgramModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const rowStyle = { background: "white" };

  const getRowStyle = useMemo(() => {
    return (params) => {
      if (!params.data.sdProgram) {
        return {
          color: "rgba(0, 0, 0, 0.45)",
          backgroundColor: "rgba(0, 0, 0, 0.05)",
        };
      }
    };
  }, []);
  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  function isUrlValid(url) {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  }

  const ImageCellRenderer = ({ value }) => {
    return (
      <div>
        {isUrlValid(value) ? (
          <img
            src={value}
            alt="Image"
            style={{
              height: "60px",
              width: "60px",
              display: "block",
              padding: "10px",
            }}
          />
        ) : (
          <div> {value} </div>
        )}
      </div>
    );
  };

  const CustomToolTip = ({ value }) => {
    return (
      <div
        style={{
          position: "absolute",
          top: "18%",
          height: "50%",
          width: "50%",
          left: "200px",
        }}
      >
        {isUrlValid(value) ? (
          <img
            src={value}
            alt="Image"
            style={{
              height: "100%",
            }}
          />
        ) : (
          <div> {value} </div>
        )}
      </div>
    );
  };

  const handleSDProgram = async (sdProgram) => {
    try{
      if (gridApi.getSelectedRows().length === 0) {
        alert("Please select atleast 1 row for exporting");
        setfullPageLoader(false);
        return;
      }
      const vendorCode = vendorState.selected;
      if (!vendorCode) {
        alert("No vendor selected");
        setfullPageLoader(false);
        return;
      }
  
      const selectedRows = gridApi.getSelectedRows();
      const body = {
        catalogItems: [],
        vendorCode,
        sdProgram,
      };
      selectedRows.forEach((row) => {
        body.catalogItems.push({
          id: row.id,
        });
      });
  
      await axios.post(BASE_URL + api.updateSDProgram, body, tokenConfig());
      props.fetchData();

    }catch(error){
      console.log("Failed to update SD program", error)
      alert("Failed to update SD Program")
    } finally {
      setfullPageLoader(false);
      setAddSDProgramModal(false);
      setRemoveSDProgramModal(false);
      gridRef.current.api.deselectAll();
    }
  }

  useEffect(() => {
    const list = searchValue?.split(" ");
    if (searchValue?.length && list?.length) {
      const filteredList = props.tableData.filter((data) => list.includes(data.asin));
      setMainTableData(filteredList);
    } else {
      setMainTableData(props.tableData);
    }
  }, [props.tableData, searchValue]);

  const columns = [
    {
      header: "Image",
      field: "productImage",
      size: 150
    },
    {
      header: "ASIN",
      field: "asin",
    },
    {
      header: "SKU",
      field: "sku",
      size: 200
    },
    {
      header: "Marketplace ID",
      field: "marketplaceId",
      size: 200
    },
    {
      header: "Item Name",
      field: "itemName",
      size: 250
    },
    {
      header: "Product Type",
      field: "productType",
    },
    {
      header: "Price",
      field: "price",
    }
  ]

  return (
    <>
      <form
        className={props.classes.form}
        noValidate
        autoComplete="off"
        onSubmit={(e) => e.preventDefault()}
      >
        <TextField
          label="Search in the table"
          value={searchValue}
          onChange={handleChange}
          style={{ width: 250 }}
        />
        <div>
          {
            gridApi?.getSelectedRows().length > 0 && 
            <>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setAddSDProgramModal(true)}
                style={{ marginRight: "10px" }}
              >
                Add to SD Program
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setRemoveSDProgramModal(true)}
              >
                Remove from SD Program
              </Button>
            </>
          }
        </div>
      </form>
      <div
        className="ag-theme-material"
        style={{ height: 750, width: "100%" }}
        id="#grid-theme-wrapper"
      >
        <AgGridReact
          ref={gridRef}
          rowData={mainTableData}
          suppressExcelExport={true}
          rowSelection="multiple"
          tooltipShowDelay={100}
          tooltipMouseTrack={true}
          rowHeight={60}
          defaultColDef={{
            filter: "agTextColumnFilter",
            resizable: true,
            sortable: true,
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
          }}
          frameworkComponents={{
            imageCellRenderer: ImageCellRenderer,
            customToolTip: CustomToolTip,
          }}
          onGridReady={onGridReady}
          pagination={true}
          paginationPageSize={100}
          rowStyle={rowStyle}
          suppressDragLeaveHidesColumns={true}
          getRowStyle={getRowStyle}
          onSelectionChanged={() => setSelectedRows(gridApi?.getSelectedRows())}
        >
          <AgGridColumn headerName='' width={50} headerCheckboxSelection={true} pinned="left" checkboxSelection={true} />
          {props.headerNames.length > 0 && (
            <>
              {columns.map(({ header, field, size }, index) => (
                <AgGridColumn
                  key={field}
                  headerName={header}
                  field={field}
                  width={size || 150}
                  {...(header.includes("Image")
                    ? {
                      cellRenderer: "imageCellRenderer",
                      tooltipField: field,
                      tooltipComponent: "customToolTip",
                    }
                    : {})}
                />
              ))}
            </>
          )}
        </AgGridReact>
      </div>
      <Backdrop className={props.classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ConfirmationPop
        open={addSDProgramModal}
        title={"Add to SD Program"}
        subtitle={`Are you sure to add ${gridApi?.getSelectedRows().length} ASINs to SD Program?`}
        onCancel={() => setAddSDProgramModal(false)}
        onConfirm={() => handleSDProgram(true)}
      />
      <ConfirmationPop
        open={removeSDProgramModal}
        title={"Remove from SD Program"}
        subtitle={`Are you sure to remove ${gridApi?.getSelectedRows().length} ASINs from SD Program?`}
        onCancel={() => setRemoveSDProgramModal(false)}
        onConfirm={() => handleSDProgram(false)}
      />
    </>
  );
});
