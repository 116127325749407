import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  Breadcrumbs,
  Link,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import useStyles from "./styles.js";
import { BASE_URL } from "../../healpers/api.js";
import axios from "axios";
import api from "../../healpers/apiRoutes.js";
import { tokenConfig } from "../../context/UserContext.js";
import { useVendorState } from "../../context/VendorContext.js";
import BarChart from "./components/BarChart.js";
import Widget from "../../components/Widget/Widget.js";
import PrintIcon from "@material-ui/icons/Print";
import { exportReport } from "../../healpers/utilityFunctions.js";

export default function ProgressAgainstGoals(props) {
  const vendorState = useVendorState();
  const classes = useStyles();
  const exportFunctionRef = useRef();
  const [metricsData, setMetricsData] = useState([]);
  const [fullPageLoader, setFullPageLoader] = useState(false);

  const fetchGoalsMetrics = async () => {
    try {
      setFullPageLoader(true);
      const params = {
        vendorCode: vendorState?.selected,
      };
      const response = await axios(
        BASE_URL + api.progressAgainstGoalsMetrics,
        tokenConfig(params)
      );
      setMetricsData(response.data.data);
      setFullPageLoader(false);
      console.log("response", response);
    } catch (error) {
      console.log("fetchGoalsMetrics - error", error);
    }
  };

  useEffect(() => {
    if (vendorState?.selected) {
      fetchGoalsMetrics();
    }
  }, [vendorState?.selected]);

  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.header}
      >
        <Grid>
          <Typography
            variant="h4"
            weight="medium"
            style={{ paddingBottom: "20px" }}
          >
            Progress Against Goals
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className={classes.link}>
              WBR
            </Link>
            <Typography className={classes.link} color="primary">
              Progress Against Goals
            </Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      {!fullPageLoader && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    style={{
                      fontWeight: 600,
                      fontSize: 20,
                      color: "black",
                    }}
                  >
                  ACOS
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportReport("ACOS")}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Grid container>
                <Grid item xs={6}>
                  <BarChart
                    data={metricsData?.weeklyACOSChartData?.data}
                    labels={metricsData?.weeklyACOSChartData?.labels}
                    label="Weekly ACOS"
                    id="weeklyACOS"
                  />
                </Grid>
                <Grid item xs={6}>
                  <BarChart
                    data={metricsData?.yearlyACOSChartData?.data}
                    labels={metricsData?.yearlyACOSChartData?.labels}
                    label="Yearly ACOS"
                    id="yearlyACOS"
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
        </Grid>
      )}
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
