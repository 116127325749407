import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  CircularProgress,
  Backdrop,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Radio,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";

//icons
import GetAppIcon from "@material-ui/icons/GetApp";
import SearchIcon from "@material-ui/icons/Search";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { useUserState } from "../../context/UserContext";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useVendorState } from "../../context/VendorContext";

export default function Page() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  const userState = useUserState();
  const vendorState = useVendorState();

  // local
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);
  const [shipmentId, setShipmentId] = useState("");
  const [labelType, setLabelType] = useState("UNIQUE");
  const [pageSize, setPageSize] = useState(null);
  const [pageStartIndex, setPageStartIndex] = useState(null);

  async function getLabels() {
    try {
      setfullPageLoader(true);
      const data = {
        shipmentId,
        labelType,
        vendorCode: vendorState?.selected,
        pageSize,
        pageStartIndex,
      };
      const response = await axios({
        url: BASE_URL + api.getLabels,
        params: data,
        method: "GET",
        responseType: "blob",
      });
      const href = window.URL.createObjectURL(response.data);
      let type;
      if (labelType === "UNIQUE" || labelType === "BARCODE_2D") {
        type = "Box Labels";
      } else {
        type = "Pallet Labels";
      }
      const filename = shipmentId + " " + type + ".zip";
      const anchorElement = document.createElement("a");

      anchorElement.href = href;
      anchorElement.download = filename;
      document.body.appendChild(anchorElement);
      anchorElement.click();
    } catch (err) {
      console.log(err);
      seterr(err.response?.data?.message || "Error occured");
      setTimeout(() => seterr(null), 5000);
    } finally {
      setfullPageLoader(false);
    }
  }

  async function getBol() {
    try {
      setfullPageLoader(true);
      const data = {
        shipmentId,
        vendorCode: vendorState?.selected,
      };
      const response = await axios.get(BASE_URL + api.getBillOfLading, {
        params: data,
      });
      window.location.href = response.data;
      setnotification("BOL fetched");
      setTimeout(() => setnotification(null), 3000);
    } catch (err) {
      console.log(err);
      seterr(err.response?.data?.message || "Error occured");
      setTimeout(() => seterr(null), 5000);
    } finally {
      setfullPageLoader(false);
    }
  }

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Print Labels or BOL"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                FBA Shipment
              </Link>
              <Typography className={classes.link} color="primary">
                Print Labels or BOL
              </Typography>
            </Breadcrumbs>
          }
        />

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              // noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <div className={classes.mainChartHeader}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                      <TextField
                        name="shipmentId"
                        label="Shipment Id"
                        type="text"
                        variant="outlined"
                        value={shipmentId}
                        onChange={(e) => setShipmentId(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        variant="outlined"
                        // className={classes.formControl}
                        fullWidth
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Label Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={labelType}
                          onChange={(e) => setLabelType(e.target.value)}
                          label="Label Type"
                          fullWidth
                        >
                          <MenuItem value={"BARCODE_2D"}>BARCODE_2D</MenuItem>
                          <MenuItem value={"UNIQUE"}>UNIQUE</MenuItem>
                          <MenuItem value={"PALLET"}>PALLET</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {labelType === "BARCODE_2D" && (
                      <>
                        <Grid item xs={6}>
                          <TextField
                            name="pageSize"
                            label="Page Size"
                            type="text"
                            variant="outlined"
                            value={pageSize}
                            onChange={(e) => setPageSize(e.target.value)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            name="pageStartIndex"
                            label="Page Start Index"
                            type="text"
                            variant="outlined"
                            value={pageStartIndex}
                            onChange={(e) => setPageStartIndex(e.target.value)}
                            fullWidth
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </div>
              }
            ></Widget>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              // noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <div className={classes.mainChartHeader}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!shipmentId}
                        size="large"
                        style={{ width: 200 }}
                        onClick={getLabels}
                      >
                        Get Labels
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!shipmentId}
                        size="large"
                        style={{ width: 200 }}
                        onClick={getBol}
                      >
                        Get BOL
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              }
            ></Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {notification ? (
        <Alert
          severity="success"
          style={{ position: "absolute", bottom: 50, left: "48%" }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{ position: "absolute", bottom: 50, left: "48%" }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
