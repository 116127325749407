import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  CircularProgress,
  Backdrop,
  Box,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import axios from "axios";
import * as XLSX from 'xlsx';
import _ from 'lodash';

//icons
import RefreshIcon from '@material-ui/icons/Refresh';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import ConfirmDialog from "./components/ComfirmDialog/ConfirmDialog";
import Table from "./components/Table/Table";
import SampleFile from "../../healpers/assets/Sample.xlsx";
import MergeFilesConfirmDialog from "./components/MergeFilesComfirmDialog/MergeFilesConfirmDialog";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { useUserState } from "../../context/UserContext";

export default function Page() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var userState = useUserState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [file, setfile] = useState({name:"No File uploaded"});
  const [confirmDialog, setconfirmDialog] = useState(false);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [estimatedTime, setestimatedTime] = useState(null);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);
  const [filesToMerge, setFilesToMerge] = useState([])
  const [mergeFilesDialog, setMergeFilesDialog] = useState(false);

  async function uploadCSV (e){
    const file = e.target.files[0];
    const reader = new FileReader();

      reader.onload = evt => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_row_object_array(ws, { header: 1 });
        const fileType = file.name.split(".")[1];
        
        const errPromise = new Promise((resolve, reject) => {
          if (fileType === "xlsx") {
            if (data[0][0] !== "ASIN") {
              reject(`Column ASIN is missing`);
            }
            if (!(data.length > 1)) {
              reject("File is empty");
            }
            data.slice(1, data.length).forEach((entry, index) => {
              if (entry.length !== 1 || entry[0][0].length === 0) {
                reject(`Invalid data at row ${index + 2}`);
              }
            });
            resolve("No error found");
          } else {
            if (data.length === 0) {
              reject("Uploaded sheet is empty");
            }
            const columns = ["ASIN"];
            for (const column of columns) {
              if (!data[0].includes(column)) {
                reject(`Column ${column} is missing`);
              }
            }
            if (data.length <= 1) {
              reject("Uploaded sheet has no data");
            }
            resolve("No error found");
          }
        });

        errPromise
          .then(()=>{
            setfile(file);
            setconfirmDialog(true);
            convertSeconds(((data.length - 1)*5)*2); //rough time to fetch API is 5 sec.
          })
          .catch((err)=>{
            seterr(err)
            document.getElementById('upload-csv').value = '';
            setTimeout(() => {
              seterr(null)
            }, 2000);
            setfile({name:"No File uploaded"});
            setconfirmDialog(false);
            setestimatedTime(null);
          })
      };
    reader.readAsBinaryString(file);
  }

  async function uploadCSVBrand (e){
    const file = e.target.files[0];
    const reader = new FileReader();

      reader.onload = evt => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_row_object_array(ws, { header: 1 });
        const fileType = file.name.split(".")[1];
        
        const errPromise = new Promise((resolve, reject) => {
          if (fileType === "xlsx") {
            if (!(data.length > 1)) {
              reject("File is empty");
            }
            data.slice(1, data.length).forEach((entry, index) => {
              if (entry[0] && entry[0][0].length === 0) {
                reject(`Invalid data at row ${index + 2}`);
              }
            });
            resolve("No error found");
          } else {
            reject("Invalid file type. Upload only xlsx file");
          }
        });

        errPromise
          .then(()=>{
            setfile(file);
            setconfirmDialog(true);
            convertSeconds(((data.length - 1)*5)*2); //rough time to fetch API is 5 sec.
          })
          .catch((err)=>{
            seterr(err)
            document.getElementById('upload-csv').value = '';
            setTimeout(() => {
              seterr(null)
            }, 2000);
            setfile({name:"No File uploaded"});
            setconfirmDialog(false);
            setestimatedTime(null);
          })
      };
    reader.readAsBinaryString(file);
  }

  async function retryFile (id){
    try{
      await axios.post(BASE_URL + api.retryBrandDetails, {
        id: id,
      });
    }catch(error){
      console.log(error);
    }
  }

  function convertSeconds(seconds) {
    var convert = function(x) { return (x < 10) ? "0"+x : x; }
    setestimatedTime(convert(parseInt(seconds / (60*60))) + ":" +
           convert(parseInt(seconds / 60 % 60)) + ":" +
           convert(seconds % 60))
  }

  async function mergeFiles(files) {
    const sheetsToMerge = ["Apollo Contacts", "Apollo Contacts-Large Accounts", "MR Sheet", "Null Contacts", "Sheet1"];
    const finalWorkbook = XLSX.utils.book_new();

    for (const sheetName of sheetsToMerge) {
      const mergedData = [];
      for (const file of files) {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        const sheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(sheet, { defval: "" });
        mergedData.push(...sheetData);
      }
      if (sheetName === "Sheet1") {
        const mergedSheet = XLSX.utils.json_to_sheet(_.uniqBy(mergedData, "ASIN"));
        XLSX.utils.book_append_sheet(finalWorkbook, mergedSheet, sheetName);
      } else if (["Apollo Contacts", "Apollo Contacts-Large Accounts"].includes(sheetName)) {
        const mergedSheet = XLSX.utils.json_to_sheet(_.uniqBy(mergedData, "Email"));
        XLSX.utils.book_append_sheet(finalWorkbook, mergedSheet, sheetName);
      } else  {
        const mergedSheet = XLSX.utils.json_to_sheet(mergedData);
        XLSX.utils.book_append_sheet(finalWorkbook, mergedSheet, sheetName);
      }
    }
    XLSX.writeFile(finalWorkbook, "output.xlsx");
    setFilesToMerge([]);
  }

  async function uploadFilesToMerge(e) {
    const files = e.target.files;
    if (files.length <= 1) {
      seterr("Please select 2 or more files to merge");
      setTimeout(() => seterr(null), 3000);
      return;
    }
    setFilesToMerge([...files]);
    setMergeFilesDialog(true);
    e.target.value = null
  }

  const fetchData = () => {
    setfullPageLoader(true)
    axios.get( BASE_URL + api.getBrandDetails + userState.userData.email ) // need to tokenize later
      .then((res)=>{
        setmainTableData(res.data)
        setfullPageLoader(false)
      })
      .catch((err)=>{
        console.log(err)
        setfullPageLoader(false)
      })
  }

  useEffect(() => {
    if(userState.userData) {
      fetchData();
    }
  }, [userState.userData])

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Upload File"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Brand Finder
              </Link>
              <Typography className={classes.link} color="primary">
                Upload File
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
            <Box display={"flex"} minWidth={"70%"} justifyContent={"space-evenly"} alignItems={"baseline"}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
              >
                <label htmlFor="upload-files-to-merge">
                  <input
                    style={{ display: "none", }}
                    id="upload-files-to-merge"
                    name="upload-files-to-merge"
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => uploadFilesToMerge(e)}
                    multiple
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    component="span"
                    size="small"
                    startIcon={<PublishIcon />}
                  >
                    Merge Files
                  </Button>
                </label>
                <Typography
                  className={classes.link}
                  color="primary"
                  style={{ fontSize: 12, margin: "5% 0% 0% 1%" }}
                >
                  <div>
                    {filesToMerge?.map((file) => (
                      <React.Fragment key={file?.name}>
                        {file?.name}
                        <br></br>
                      </React.Fragment>
                    ))}
                  </div>
                </Typography>
              </FormControl>
              <FormControl
                variant="outlined"
                className={classes.formControl}
              >
                <label htmlFor="upload-csv">
                  <input
                    style={{ display: "none" }}
                    id="upload-csv"
                    name="upload-csv"
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => uploadCSVBrand(e)}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    component="span"
                    size="small"
                    startIcon={<PublishIcon />}
                  >
                    Upload Brands
                  </Button>
                </label>
                <Typography
                  className={classes.link}
                  color="primary"
                  style={{ fontSize: 12, margin: "5% 0% 0% 1%" }}
                >
                  {file.name}
                </Typography>
              </FormControl>
              <a
                href={SampleFile}
                download="Sample File.xlsx"
                style={{ textDecoration: "none", minWidth: 150 }}
              >
                <Button
                  color="primary"
                  variant="outlined"
                  component="span"
                  size="small"
                  startIcon={<GetAppIcon />}
                >
                  Download Sample File
                </Button>
              </a>
              <FormControl
                variant="outlined"
                className={classes.formControl}
              >
                <label htmlFor="upload-csv">
                  <input
                    style={{ display: "none" }}
                    id="upload-csv"
                    name="upload-csv"
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => uploadCSV(e)}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    component="span"
                    size="small"
                    startIcon={<PublishIcon />}
                  >
                    Upload here
                  </Button>
                </label>
                <Typography
                  className={classes.link}
                  color="primary"
                  style={{ fontSize: 12, margin: "5% 0% 0% 1%" }}
                >
                  {file.name}
                </Typography>
              </FormControl>
            </Box>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <div className={classes.mainChartHeader}>
                  <div />
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<RefreshIcon />}
                    onClick={() => fetchData()}
                  >
                    Refresh
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} retryFile={(id)=>retryFile(id)}/>
            </Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ConfirmDialog
        open={confirmDialog}
        toggleDialog={() => setconfirmDialog(!confirmDialog)}
        file={file}
        setFile={setfile}
        estimatedTime={estimatedTime}
        setnotification={setnotification}
        seterr={seterr}
        fetchData={fetchData}
      />
      <MergeFilesConfirmDialog
        open={mergeFilesDialog}
        toggleDialog={() => setMergeFilesDialog(!mergeFilesDialog)}
        setnotification={setnotification}
        seterr={seterr}
        filesToMerge={filesToMerge}
        setFilesToMerge={setFilesToMerge}
        mergeFiles={mergeFiles}
      />
      {notification ? (
        <Alert
          severity="success"
          style={{ position: "absolute", bottom: 30, left: "48%" }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{ position: "absolute", bottom: 30, left: "48%" }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
