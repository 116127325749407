import React, { useEffect, useState } from "react";
import { Container, Grid, Breadcrumbs, Typography } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
import { fetchDetailPages } from "../../http/api";
import { useVendorState } from "../../context/VendorContext";

export default function DetailPage() {
  const vendorState = useVendorState();
  const [detailPages, setDetailPages] = useState([]);

  const loadDetailPages = async (vendorCode) => {
    try {
      const { status, data } = await fetchDetailPages(vendorCode);
      if (status === "Success") {
        setDetailPages(data);
      } else {
        setDetailPages([]);
      }
    } catch (err) {
      console.log(err);
      setDetailPages([]);
    }
  };

  useEffect(() => {
    vendorState.selected && loadDetailPages(vendorState.selected);
  }, [vendorState.selected]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Detail Page"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="primary">Detail Page</Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12} style={{ backgroundColor: "white" }}>
            <Table rows={detailPages} refresh={() => loadDetailPages(vendorState.selected)}/>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
