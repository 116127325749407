import { Bar } from "react-chartjs-2";
import { colors } from "../../../constant/wbr";
import { formatChartValues } from "../../../healpers/utilityFunctions";

export default function BenchmarkChart(props) {
    const labels = props.data.map((item, index) => item.brandName);
    const backgroundColors = []
    const dataSet = []
    props.data?.map((item, index) => {
        dataSet.push(item.salesPercentageChange)
        if (item.type === 'customer') {
            backgroundColors.push('rgba(66, 135, 245)')
        } else {
            backgroundColors.push(colors[index])
        }
    })

    return (
        <div style={{ padding: '10px' }}>
            <Bar
                id={props.id}
                options={{
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                return formatChartValues(tooltipItem, data, true)
                            }
                        }
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                autoSkip: false,
                                maxRotation: 90,
                                minRotation: 90,
                            },
                        }],
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                callback: function (value) {
                                    return value + "%";
                                },
                            },
                        }],
                    },
                    legend: {
                        display: false,
                    },
                }}
                data={{
                    labels,
                    datasets: [
                        {
                            data: dataSet,
                            backgroundColor: backgroundColors,
                            barThickness: 20,
                        },
                    ],
                }}
            />
        </div>
    );
}
