import React, { useEffect, useState } from "react";
import {
  Breadcrumbs,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import {
  setSelectedVendor,
  useVendorDispatch,
  useVendorState,
} from "../../context/VendorContext";
import { deactivateAccount } from "../../http/api";

const DELETE_OPTIONS = ["AMS", "Catalog", "Config", "Sales"];

export default function DeactivateAccount() {
  const vendorDispatch = useVendorDispatch();
  const vendorState = useVendorState();
  const [deleteOptions, setDeleteOptions] = useState([...DELETE_OPTIONS]);
  const [
    showDeactivateConfirmDialog,
    setShowDeactivateConfirmDialog,
  ] = useState(false);
  const [deleteConfirmationText, setDeleteConfirmationText] = useState("");
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");

  const handleDeactivateConfirm = async () => {
    setDeleteInProgress(true);
    try {
      const deactivate = await deactivateAccount(
        vendorState?.selected,
        deleteOptions
      );
      setInfoMessage(
        deactivate.message + "An email will be sent regarding the deactivation."
      );
      setTimeout(() => {
        setInfoMessage("");
        handleDeactivateClose();
        if (deleteOptions.length === DELETE_OPTIONS.length) {
          if (vendorState?.list?.[0]?.vendorcode !== vendorState?.selected) {
            setSelectedVendor(
              vendorDispatch,
              vendorState,
              vendorState?.list?.[0]?.vendorcode
            );
          } else {
            setSelectedVendor(
              vendorDispatch,
              vendorState,
              vendorState?.list?.[1]?.vendorcode
            );
          }
        }
      }, 3000);
    } catch (e) {
      console.error(e);
    } finally {
      setDeleteInProgress(false);
    }
  };

  const handleDeactivateClose = async () => {
    setDeleteConfirmationText("");
    setShowDeactivateConfirmDialog(false);
  };

  useEffect(() => {
    setDeleteOptions([...DELETE_OPTIONS]);
    setDeleteConfirmationText("");
    setDeleteInProgress(false);
    setInfoMessage("");
  }, [vendorState?.selected]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Deactivate Account"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit">Onboarding</Link>
              <Typography color="primary">Deactivate Account</Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget noHeaderPadding noBodyPadding disableWidgetMenu>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  padding: "16px",
                }}
              >
                <Typography
                  style={{
                    whiteSpace: "break-spaces",
                  }}
                >
                  {`You are about to delete the account data from the dashboard. This includes permanent removal of data stored related to the account.\nProceed with caution.`}
                </Typography>
                <Divider />
                <Typography>Vendor Code: {vendorState?.selected}</Typography>
                <Divider />
                <Typography>Select the option(s) below to remove</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={deleteOptions.length === DELETE_OPTIONS.length}
                      onChange={(event) => {
                        setDeleteOptions(
                          event.target.checked ? [...DELETE_OPTIONS] : []
                        );
                      }}
                    />
                  }
                  label={"All"}
                  style={{
                    width: "fit-content",
                  }}
                />
                {DELETE_OPTIONS.map((item) => (
                  <FormControlLabel
                    key={item}
                    control={
                      <Checkbox
                        color="primary"
                        checked={deleteOptions.includes(item)}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setDeleteOptions([...deleteOptions, item]);
                          } else {
                            setDeleteOptions([
                              ...deleteOptions.filter(
                                (option) => option !== item
                              ),
                            ]);
                          }
                        }}
                      />
                    }
                    label={item}
                    style={{
                      width: "fit-content",
                    }}
                  />
                ))}
                <Divider />
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => setShowDeactivateConfirmDialog(true)}
                  disabled={deleteInProgress || deleteOptions.length === 0}
                  style={{
                    alignSelf: "end",
                  }}
                >
                  Deactivate
                </Button>
              </div>
            </Widget>
            <Dialog
              open={showDeactivateConfirmDialog}
              onClose={handleDeactivateClose}
              maxWidth={"sm"}
              fullWidth={true}
            >
              <DialogTitle>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {"Confirm Deactivate"}
                  <CloseOutlined
                    onClick={handleDeactivateClose}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </div>
              </DialogTitle>
              <DialogContent>
                {deleteInProgress && (
                  <LinearProgress style={{ margin: "0px 32px" }} />
                )}
                {infoMessage && <Typography>{infoMessage}</Typography>}
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography>
                    Please enter the vendor code to proceed
                  </Typography>
                  <TextField
                    value={deleteConfirmationText}
                    placeholder="Vendor Code"
                    variant="outlined"
                    size="small"
                    onChange={(event) =>
                      setDeleteConfirmationText(
                        (event.target.value || "").toUpperCase()
                      )
                    }
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleDeactivateClose}
                  variant="outlined"
                  size="small"
                >
                  Cancel
                </Button>
                <Button
                  disabled={
                    deleteInProgress ||
                    deleteConfirmationText !== vendorState.selected
                  }
                  onClick={handleDeactivateConfirm}
                  variant="contained"
                  color="secondary"
                  size="small"
                >
                  Deactivate
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
