import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import axios from "axios";

//icons
import RefreshIcon from '@material-ui/icons/Refresh';

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { useUserState } from "../../context/UserContext";

export default function Page() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var userState = useUserState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);

  const fetchData = () => {
    setfullPageLoader(true)
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    axios.post(BASE_URL + api.getCategoryFilesList, { id: parseInt(id) })
      .then((res) => {
        setmainTableData(res.data)
        setfullPageLoader(false)
      })
      .catch((err) => {
        console.log(err)
        setfullPageLoader(false)
      })
  }

  useEffect(() => {
    if (userState.userData) {
      fetchData();
    }
  }, [userState.userData])

  async function retryFile (id, country){
    try{
      if(country){
        const body = {
          id,
          country
        }
        await axios.post(BASE_URL + api.brandFinderGlobalRetry, body);
      } else {
        await axios.post(BASE_URL + api.retryBrandDetails, {
          id: id,
        });
      }
      alert("Retry request sent successfully");
      fetchData();
    }catch(error){
      console.log(`retryFile error`, error);
    }
  }

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Category Files"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Category Files
              </Link>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <div className={classes.mainChartHeader}>
                  <div />
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<RefreshIcon />}
                    onClick={() => fetchData()}
                  >
                    Refresh
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} retryFile={retryFile}/>
            </Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
