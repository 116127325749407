import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import ShowCartonIdDialog from "../ShowCartonIdDialog/ShowCartonIdDialog";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";
import { BASE_URL } from "../../../../healpers/api";
import api from "../../../../healpers/apiRoutes";
import axios from "axios";
import CancelShipmentDialog from "../CancelShipmentDialog/CancelShipmentDialog";
import MarkAsShippedDialog from "../MarkAsShippedDialog/MarkAsShippedDialog";
import ShipmentItemsTable from "../ShipmentItemsTable/ShipmentItemsTable";
import { useVendorState } from "../../../../context/VendorContext";

export default forwardRef(function Tables(
  {
    tableData,
    vendorCode,
    setfullPageLoader,
    setnotification,
    seterr,
    fetchData,
  },
  ref
) {
  const gridRef = useRef(null);
  const vendorState = useVendorState();

  const [cancelDialog, setCancelDialog] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [shipmentToCancel, setShipmentToCancel] = useState(null);
  const [viewShipmentDetails, setViewShipmentDetails] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [shipmentItems, setShipmentItems] = useState([]);
  const [cartonIdDialog, setCartonIdDialog] = useState(false);
  const [feedErrors, setFeedErrors] = useState([]);
  const [cartonIds, setCartonIds] = useState([]);
  const [excelData, setExcelData] = useState(null);
  const [isPerishable, setIsPerishable] = useState(false);
  const [shipmentToBeShipped, setShipmentToBeShipped] = useState(null);
  const [markAsShippedDialog, setMarkAsShippedDialog] = useState(false);

  useEffect(() => {
    if (selectedShipment?.ShipmentId) {
      getShipmentItemsByShipmentId(selectedShipment?.ShipmentId);
    }
  }, [selectedShipment]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else gridApi.exportDataAsCsv();
    },
  }));

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#F6F7FF" };
    }
  };

  const rowStyle = { background: "white" };

  async function getShipmentItemsByShipmentId(shipmentId) {
    try {
      setShipmentItems([]);
      setfullPageLoader(true);
      const [response, shipmentFromDb] = await Promise.all([
        axios.get(BASE_URL + api.getShipmentItemsByShipmentId, {
          params: { vendorCode: vendorState?.selected, shipmentId },
        }),
        axios.get(BASE_URL + api.getShipmentFromDb, {
          params: { shipmentId },
        }),
      ]);
      const items = response.data?.ItemData.map((item) => {
        const itemFromDb = shipmentFromDb.data.shipmentItems?.find(
          (obj) => obj["sku"] === item["SellerSKU"]
        );
        return {
          ...item,
          expirationDate: itemFromDb["expirationDate"],
        };
      });
      console.log({ items });
      setShipmentItems(items);
    } catch (err) {
      console.log(err);
    } finally {
      setfullPageLoader(false);
    }
  }

  const ViewButtonRenderer = (props) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={(e) => {
          setSelectedShipment(props.data);
          setViewShipmentDetails(true);
        }}
      >
        VIEW
      </Button>
    );
  };

  const CancelButtonRenderer = (props) => {
    return (
      <Button
        variant="contained"
        color="secondary"
        size="medium"
        onClick={() => {
          setShipmentToCancel(props.data);
          setCancelDialog(true);
        }}
      >
        CANCEL
      </Button>
    );
  };

  const MarkAsShippedButtonRenderer = (props) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={() => {
          setShipmentToBeShipped(props.data);
          setMarkAsShippedDialog(true);
        }}
      >
        Mark as Shipped
      </Button>
    );
  };

  async function setExpirationDates() {
    try {
      setfullPageLoader(true);
      const data = {
        selectedShipment,
        shipmentItems,
        vendorCode: vendorState?.selected,
        isPerishable
      };
      const response = await axios.post(
        BASE_URL + api.sendCartonContentInformation,
        data
      );
      setnotification("Carton Content Info Updated");
      setTimeout(() => setnotification(null), 5000);
      setFeedErrors(response.data?.errorMessages || []);
      // setCartonIds(response.data?.cartonIdList || []);
      setExcelData(response?.data?.excelData);
      setCartonIdDialog(true);
    } catch (err) {
      console.log(err);
      const errMsg = err?.response?.data?.msg || err?.response?.data?.message;
      seterr(errMsg || "Error occured");
      setTimeout(() => seterr(null), 5000);
    } finally {
      setfullPageLoader(false);
    }
  }

  return (
    <div
      className="ag-theme-material"
      style={{ height: 620, width: "100%" }}
      id="#grid-theme-wrapper"
    >
      <AgGridReact
        ref={gridRef}
        rowData={tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        getRowStyle={getRowStyle}
        suppressDragLeaveHidesColumns={true}
        frameworkComponents={{
          viewButtonRenderer: ViewButtonRenderer,
          cancelButtonRenderer: CancelButtonRenderer,
          markAsShippedButtonRenderer: MarkAsShippedButtonRenderer
        }}
      >
        <AgGridColumn headerName="Shipment Id" field="ShipmentId" />
        <AgGridColumn
          headerName="Shipment Name"
          field="ShipmentName"
          width={200}
        />
        <AgGridColumn headerName="Shipment Status" field="ShipmentStatus" />
        <AgGridColumn cellRenderer={"viewButtonRenderer"} width={100} />
        <AgGridColumn cellRenderer={"cancelButtonRenderer"} width={150} />
        <AgGridColumn cellRenderer={"markAsShippedButtonRenderer"} />
      </AgGridReact>
      <CancelShipmentDialog
        setnotification={setnotification}
        seterr={seterr}
        setfullPageLoader={setfullPageLoader}
        shipmentToCancel={shipmentToCancel}
        open={cancelDialog}
        closeDialog={() => {
          setCancelDialog(false);
          setShipmentToCancel(null);
        }}
        fetchData={fetchData}
      />
      <MarkAsShippedDialog 
      setnotification={setnotification}
      seterr={seterr}
      setfullPageLoader={setfullPageLoader}
      open={markAsShippedDialog}
      closeDialog={() => {
        setMarkAsShippedDialog(false);
        setShipmentToBeShipped(null);
      }}
      shipmentToBeShipped={shipmentToBeShipped}
      fetchData={fetchData}
      />
      <Dialog
        onClose={() => {
          setViewShipmentDetails(false);
          setSelectedShipment(null);
        }}
        open={viewShipmentDetails}
        maxWidth="lg"
      >
        <DialogContent>
          <form autoComplete="off">
            <Grid container spacing={4} style={{ padding: 30 }}>
              <Grid item xs={12}>
                <Typography color="textPrimary" variant="h3">
                  Shipment Details
                </Typography>
                <br />
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="ShipmentId"
                  label="Shipment Id"
                  type="text"
                  variant="outlined"
                  disabled={true}
                  value={selectedShipment?.ShipmentId}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="ShipmentName"
                  label="Shipment Name"
                  type="text"
                  variant="outlined"
                  disabled={true}
                  value={selectedShipment?.ShipmentName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="DestinationFulfillmentCenterId"
                  label="Destination Fulfillment Center Id"
                  type="text"
                  variant="outlined"
                  disabled={true}
                  value={selectedShipment?.DestinationFulfillmentCenterId}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="ShipmentStatus"
                  label="Shipment Status"
                  type="text"
                  variant="outlined"
                  disabled={true}
                  value={selectedShipment?.ShipmentStatus}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="LabelPrepType"
                  label="Label Prep Type"
                  type="text"
                  variant="outlined"
                  disabled={true}
                  value={selectedShipment?.LabelPrepType}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="BoxContentsSource"
                  label="Box Contents Source"
                  type="text"
                  variant="outlined"
                  disabled={true}
                  value={selectedShipment?.BoxContentsSource}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography color="textPrimary" variant="h3">
                  Shipment Items
                </Typography>
                <br />
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isPerishable}
                      onChange={(e) => setIsPerishable(prev => !prev)}
                      name="isPerishable"
                      color="primary"
                    />
                  }
                  label="Perishable"
                />
                <ShipmentItemsTable
                  tableData={shipmentItems}
                  setShipmentItems={setShipmentItems}
                  setfullPageLoader={setfullPageLoader}
                  setnotification={setnotification}
                  seterr={seterr}
                  isPerishable={isPerishable}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          {/* <Button
            variant="contained"
            color="primary"
            onClick={setExpirationDates}
          >
            Submit carton content info
          </Button> */}
          <Button
            variant="contained"
            color="secondary"
            style={{ marginLeft: "10px", width: 150}}
            onClick={() => {
              setViewShipmentDetails(false);
              setSelectedShipment(null);
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
      <ShowCartonIdDialog
        open={cartonIdDialog}
        closeDialog={() => setCartonIdDialog(false)}
        errorMessages={feedErrors}
        cartonIdList={cartonIds}
        excelData={excelData}
        shipmentId={selectedShipment?.ShipmentId}
      />
    </div>
  );
});
