import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Button } from "@material-ui/core";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import '../styles'
import { toProperCase } from "../../../healpers/utilityFunctions";

export default forwardRef(function Tables(props, ref) {

  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);


  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else
        gridApi.exportDataAsCsv();
    }
  }));

  const rowStyle = { background: 'white' };

  const InputFileDownloadBtn = (buttonProps) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        style={{ textTransform: "capitalize" }}
        onClick={() => downloadFile(buttonProps?.data.inputFile)}
      >
        Download
      </Button>
    );
  };

  const OutputFileDownloadBtn = (buttonProps) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        style={{ textTransform: "capitalize" }}
        onClick={() => downloadFile(buttonProps?.data.outputFile)}
      >
        Download
      </Button>
    );
  };

  const downloadFile = (s3FileUrl, fileName) => {
    const link = document.createElement('a');
    link.href = s3FileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className="ag-theme-material" style={{ height: 620, width: '100%' }} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={50}
        rowStyle={rowStyle}
        frameworkComponents={{
          inputFileDownloadBtn: InputFileDownloadBtn,
          outputFileDownloadBtn: OutputFileDownloadBtn,
        }}
        fullWidth
        suppressDragLeaveHidesColumns={true}
        suppressRowClickSelection={true}
      >
        <AgGridColumn headerName="Name" field="name" width={200} />
        <AgGridColumn headerName="Email" field="email" width={220} />
        <AgGridColumn headerName="Phone" field="phone" width={200} />
        <AgGridColumn headerName="Store/ ASIN / Product URL" field="storeName" width={250} />
        <AgGridColumn headerName="Input File" width={150} cellRenderer="inputFileDownloadBtn" cellEditorParams={{ cellEditorParams: "inputFileDownloadBtn" }} />
        <AgGridColumn headerName="Output File" width={150} cellRenderer="outputFileDownloadBtn" cellEditorParams={{ cellEditorParams: "outputFileDownloadBtn" }} />
      </AgGridReact>
    </div>
  );
})



