import React from "react";

export default function ChartLegend({ legendData, heading }) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        paddingBottom: "10px",
      }}
    >
      {legendData.map((legend) => (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <div
            style={{
              height: "10px",
              width: "10px",
              backgroundColor: legend.backgroundColor,
            }}
          ></div>
          <div>
            {legend.name} {heading && `(${heading})`}
          </div>
        </div>
      ))}
    </div>
  );
}
