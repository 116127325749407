import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import moment from "moment";

//helpers
import { BASE_URL } from "../../../../healpers/api";
import api from "../../../../healpers/apiRoutes";

//context
import { useUserState } from "../../../../context/UserContext";

export default function DraggableDialog({
  open,
  toggleDialog,
  setconfirmDialog,
  confirmTransport,
}) {
  return (
    <div>
      <Dialog open={open} onClose={toggleDialog} fullWidth={true}>
        <Paper>
          {/* <DialogTitle>Confirm Shipment</DialogTitle> */}
          <DialogContent>
            <DialogContentText>
              Are you sure you want to confirm the shipment
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              variant="outlined"
              onClick={() => {
                toggleDialog();
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                confirmTransport();
                setconfirmDialog(false);
              }}
              color="primary"
            >
              Confirm Shipment
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
}
