import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'
import _ from "lodash";
import { useVendorState } from "../../../../context/VendorContext";
import { numberWithCommas } from "../../../../healpers/utilityFunctions";
import { TIME_PERIOD } from "../../../../constant/spReports";

export default forwardRef(function Tables(props, ref) {

  const gridRef = useRef(null);
  var vendorState = useVendorState();
  const [gridApi, setGridApi] = useState(null);
  const [seller, setSeller] = useState(false);
  const [label, setLabel] = useState('Prior Period (MOM)');

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const rowStyle = { background: 'white' };

  useEffect(() => {
    if (!_.isEmpty(vendorState)) {
      let sellerState = vendorState?.accounttype === 'seller'
      setSeller(sellerState)
    }
  }, [vendorState])

  useEffect(() => {
    if (props.period === TIME_PERIOD.WEEKLY) {
      setLabel('Prior Period (WOW)')
    } else if (props.period === TIME_PERIOD.MONTHLY) {
      setLabel('Prior Period (MOM)')
    } else {
      setLabel('Prior Period (QOQ)')
    }
  }, [props.tableData])

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else
        gridApi.exportDataAsCsv();
    }
  }));

  const valueFormatter = (params, percentage = false) => {
    if (params.value && params.value !== null) {
      if (percentage) {
        return `${numberWithCommas(params.value.toFixed())}%`
      } else {
        return numberWithCommas(params.value.toFixed())
      }
    } else {
      return '-'
    }
  }

  const formatPrice = (params) => {
    if (params.value) {
      return `$${numberWithCommas(params.value.toFixed())}`
    } else {
      return '-'
    }
  }

  const valueRenderer = (props) => {
    if (props.value && props.value !== null) {
      if (props.value > 0) {
        return <div style={{ color: 'green' }}>{valueFormatter({ value: props.value }, true)}</div>
      } else {
        return <div style={{ color: 'red' }}>{valueFormatter({ value: props.value }, true)}</div>
      }
    } else {
      return '-'
    }
  }

  return (
    <div className="ag-theme-material" style={{ height: 620, width: '100%' }} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        suppressDragLeaveHidesColumns={true}
        frameworkComponents={{
          valueRenderer: valueRenderer,
        }}
      >
        <AgGridColumn key={2} headerName={"Range"} field={"key"} />
        <AgGridColumn key={2} headerName={seller ? "Page Views" : "Glance Views"} field={"glanceViews"} valueFormatter={(params) => valueFormatter(params)} />
        <AgGridColumn key={2} headerName={label} field={"priorPeriod.glanceViewsPercentage"} cellRenderer={"valueRenderer"}  />
        <AgGridColumn key={2} headerName={'Prior Period(YOY)'} field={"yoy.glanceViewsPercentage"} cellRenderer={"valueRenderer"} />
        <AgGridColumn key={2} headerName={seller ? "Ordered Product Sales" : "Revenue"} field={"revenue"} valueFormatter={(params) => formatPrice(params)} />
        <AgGridColumn key={2} headerName={label} field={"priorPeriod.revenuePercentage"} cellRenderer={"valueRenderer"} />
        <AgGridColumn key={2} headerName={'Prior Period(YOY)'} field={"yoy.revenuePercentage"} cellRenderer={"valueRenderer"} />
        <AgGridColumn key={2} headerName={"Units"} field={"units"} valueFormatter={(params) => valueFormatter(params)} />
        <AgGridColumn key={2} headerName={label} field={"priorPeriod.unitsPercentage"} cellRenderer={"valueRenderer"} />
        <AgGridColumn key={2} headerName={'Prior Period(YOY)'} field={"yoy.unitsPercentage"} cellRenderer={"valueRenderer"} />
        <AgGridColumn key={2} headerName={"Sales Conversion"} field={"salesConversion"} cellRenderer={"valueRenderer"} />
        <AgGridColumn key={2} headerName={label} field={"priorPeriod.salesConversionPercentage"} cellRenderer={"valueRenderer"} />
        <AgGridColumn key={2} headerName={'Prior Period(YOY)'} field={"yoy.salesConversionPercentage"} cellRenderer={"valueRenderer"} />
        <AgGridColumn key={2} headerName={"Inventory (Units)"} field={"inventory"}  valueFormatter={(params) => valueFormatter(params)} />
        <AgGridColumn key={2} headerName={label} field={"priorPeriod.inventoryPercentage"} cellRenderer={"valueRenderer"} />
        <AgGridColumn key={2} headerName={'Prior Period(YOY)'} field={"yoy.inventoryPercentage"} cellRenderer={"valueRenderer"} />
        {seller && <>
          <AgGridColumn key={2} headerName={"Feedbacks Received"} field={"feedbackReceived"} valueFormatter={(params) => valueFormatter(params)} />
          <AgGridColumn key={2} headerName={label} field={"priorPeriod.feedbackReceived"} valueFormatter={(params) => valueFormatter(params)} />
          <AgGridColumn key={2} headerName={'Prior Period(YOY)'} field={"yoy.feedbackReceived"} valueFormatter={(params) => valueFormatter(params)} />
          <AgGridColumn key={2} headerName={"Negative Feedbacks Received"} field={"negativeFeedbackReceived"} valueFormatter={(params) => valueFormatter(params)} />
          <AgGridColumn key={2} headerName={label} field={"priorPeriod.negativeFeedbackReceived"} valueFormatter={(params) => valueFormatter(params)} />
          <AgGridColumn key={2} headerName={'Prior Period(YOY)'} field={"yoy.negativeFeedbackReceived"} valueFormatter={(params) => valueFormatter(params)} />
          <AgGridColumn key={2} headerName={"Units Refunded"} field={"unitsRefunded"} valueFormatter={(params) => valueFormatter(params)} />
          <AgGridColumn key={2} headerName={label} field={"priorPeriod.unitsRefunded"} valueFormatter={(params) => valueFormatter(params)} />
          <AgGridColumn key={2} headerName={'Prior Period(YOY)'} field={"yoy.unitsRefunded"} valueFormatter={(params) => valueFormatter(params)} />
          <AgGridColumn key={2} headerName={"Refund Rate"} field={"refundRate"} valueFormatter={(params) => valueFormatter(params)} />
          <AgGridColumn key={2} headerName={label} field={"priorPeriod.refundRate"} valueFormatter={(params) => valueFormatter(params)} />
          <AgGridColumn key={2} headerName={'Prior Period(YOY)'} field={"yoy.refundRate"} valueFormatter={(params) => valueFormatter(params)} />
        </>
        }
        {!seller &&
          <>
            <AgGridColumn key={2} headerName={"COGS"} field={"cogs"} valueFormatter={(params) => formatPrice(params)} />
            <AgGridColumn key={2} headerName={label} field={"yoy.cogsPercentage"} cellRenderer={"valueRenderer"} />
            <AgGridColumn key={2} headerName={"Prior Period(YOY)"} field={"priorPeriod.cogsPercentage"} cellRenderer={"valueRenderer"} />
            <AgGridColumn key={2} headerName={"Net PPM"} field={"netPPM"} valueFormatter={(params) => valueFormatter(params, true)} />
            <AgGridColumn key={2} headerName={label} field={"priorPeriod.netPPMPercentage"} cellRenderer={"valueRenderer"}/>
            <AgGridColumn key={2} headerName={'Prior Period(YOY)'} field={"yoy.netPPMPercentage"} cellRenderer={"valueRenderer"}/>
          </>
        }
      </AgGridReact>
    </div>
  );
})