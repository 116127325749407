import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import '../styles'
import { numberWithCommas } from "../../../healpers/utilityFunctions";
import { colors } from "../../../constant/wbr";

export default forwardRef(function Tables(props, ref) {

    const gridRef = useRef(null);
    const [gridApi, setGridApi] = useState(null);


    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    useImperativeHandle(ref, () => ({
        onBtnExport() {
            if (gridRef.current.api.getSelectedNodes().length)
                gridApi.exportDataAsCsv({ onlySelected: true, fileName: 'Competitive Benchmarking.csv' });
            else
                gridApi.exportDataAsCsv({ fileName: 'Competitive Benchmarking.csv' });
        }
    }));

    const rowStyle = { background: 'white', width: '100%' };

    const ColorRenderer = (params) => {
        if (params && params.data) {
            if (params.data.type === 'customer') {
                return <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    {params.data.brandName}
                    <div style={{ backgroundColor: 'rgba(66, 135, 245)', height: '10px', width: '10px', marginLeft: '10px' }}></div>
                </div>
            } else {
                return (
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        {params.data.brandName}
                        <div style={{ backgroundColor: colors[params.rowIndex], height: '10px', width: '10px', marginLeft: '10px' }}></div>
                    </div>
                )
            }
        } else {
            return null
        }
    }

    return (
        <div className="ag-theme-material" style={{ height: 620, width: '100%' }} id="#grid-theme-wrapper">
            <AgGridReact
                ref={gridRef}
                rowData={props.tableData}
                suppressExcelExport={true}
                defaultColDef={{
                    filter: 'agTextColumnFilter',
                    resizable: true,
                    sortable: true,
                    headerComponentParams: {
                        template:
                            '<div class="ag-cell-label-container" role="presentation">' +
                            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                            '  </div>' +
                            '</div>',
                    },
                }}
                onGridReady={onGridReady}
                pagination={true}
                paginationPageSize={50}
                rowStyle={rowStyle}
                fullWidth
                suppressDragLeaveHidesColumns={true}
                suppressRowClickSelection={true}
                frameworkComponents={{
                    colorRenderer: ColorRenderer,
                }}
            >
                <AgGridColumn headerName="Brand" pinned="left" cellRenderer="colorRenderer" cellEditorParams={{ cellEditorParams: "colorRenderer" }} width={300} valueGetter={(params) => params.data?.brandName} />
                <AgGridColumn headerName="Current Week">
                    <AgGridColumn headerName="Current Week Sales" valueFormatter={(params) => formatValue(params)} field="currentWeekSales" />
                    <AgGridColumn headerName="Current Week Units" field="currentWeekUnits" />
                </AgGridColumn>
                <AgGridColumn headerName="Previous Week">
                    <AgGridColumn headerName="Previous Week Sales" valueFormatter={(params) => formatValue(params)} field="prevWeekSales" />
                    <AgGridColumn headerName="Previous Week Units" field="prevWeekUnits" />
                </AgGridColumn>
                <AgGridColumn headerName="Change (%) from Previous Week">
                    <AgGridColumn headerName="Sales" valueFormatter={(params) => formatValue(params, true)} field="salesPercentageChange" />
                    <AgGridColumn headerName="Units" valueFormatter={(params) => formatValue(params, true)} field="unitsPercentageChange" />
                </AgGridColumn>
            </AgGridReact>
        </div>
    );
})


const formatValue = (params, percentage) => {
    if (params.value) {
        if (percentage) {
            return params.value.toFixed(2) + "%"
        } else {
            return numberWithCommas(params.value)
        }
    } else { return '-' }
}