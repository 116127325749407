import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Breadcrumbs,
  Grid,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import CreateGroup from "./components/CreateGroup/CreateGroup";
import EditGroup from "./components/EditGroup/EditGroup";
import DeleteGroup from "./components/DeleteGroup/DeleteGroup";
import { fetchUserGroups } from "../../http/api";

export default function UserGroups() {
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [showEditGroup, setShowEditGroup] = useState(false);
  const [showDeleteGroup, setShowDeleteGroup] = useState(false);
  const [userGroups, setUserGroups] = useState([]);

  const loadUserGroups = async () => {
    try {
      const userGroupsData = await fetchUserGroups();
      setUserGroups(userGroupsData.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadUserGroups();
  }, []);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="User Groups"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="primary">User Groups</Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <div style={{ display: "flex", gap: "8px", alignSelf: "center" }}>
              <Button
                color="secondary"
                variant="outlined"
                size="small"
                style={{
                  height: "min-content",
                }}
                onClick={() => setShowDeleteGroup(true)}
              >
                Delete Group
              </Button>
              <Button
                color="primary"
                variant="outlined"
                size="small"
                style={{
                  height: "min-content",
                }}
                onClick={() => setShowEditGroup(true)}
              >
                Edit Group
              </Button>
              <Button
                color="primary"
                variant="contained"
                size="small"
                style={{
                  height: "min-content",
                }}
                onClick={() => setShowCreateGroup(true)}
              >
                Create Group
              </Button>
            </div>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {showDeleteGroup && (
              <DeleteGroup
                userGroups={_.sortBy(userGroups, "groupName")}
                reload={loadUserGroups}
                handleCancel={() => {
                  setShowDeleteGroup(false);
                }}
              />
            )}
            {showEditGroup && (
              <EditGroup
                userGroups={_.sortBy(userGroups, "groupName")}
                reload={loadUserGroups}
                handleCancel={() => {
                  setShowEditGroup(false);
                }}
              />
            )}
            {showCreateGroup && (
              <CreateGroup
                reload={loadUserGroups}
                handleCancel={() => {
                  setShowCreateGroup(false);
                }}
              />
            )}
            <Widget noBodyPadding disableWidgetMenu>
              <Table rows={_.sortBy(userGroups, "groupName")} />
            </Widget>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
