import React, {
  useRef,
  forwardRef,
} from "react";
import { Button } from "@material-ui/core";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import greenTick from "../../../../images/Green-tick.jpg";
import redCross from "../../../../images/Red-cross.png";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";

export default forwardRef(function Tables({ tableData, enableModal, selectUser}, ref) {
  const gridRef = useRef(null);

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#F6F7FF" };
    }
  };

  const rowStyle = { background: "white" };

  const ButtonRenderer = (props) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        style={{ textTransform: "capitalize", width: 100 }}
        onClick={()=>{
          selectUser(props.data)
          enableModal()
        }}
      >
        Edit
      </Button>
    );
  };

  const ImageRenderer = (props) => {
    return (
      <img
        src={props.value ? greenTick : redCross}
        alt="True"
        style={{ width: 15, height: 15 }}
      />
    );
  };

  return (
    <div
      className="ag-theme-material"
      style={{ height: 620, width: "100%" }}
      id="#grid-theme-wrapper"
    >
      <AgGridReact
        ref={gridRef}
        rowData={tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        }}
        frameworkComponents={{
          buttonRenderer: ButtonRenderer,
          imageRenderer: ImageRenderer,
        }}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        getRowStyle={getRowStyle}
        suppressDragLeaveHidesColumns={true}
      >
        <AgGridColumn headerName="Email" field="email" width={200} pinned />
        <AgGridColumn headerName="Permissions">
          <AgGridColumn
            headerName="Sales"
            cellRenderer="imageRenderer"
            field="permissions_module_level.sales.allowed"
            width={150}
          />
          <AgGridColumn
            headerName="Business Intelligence"
            cellRenderer="imageRenderer"
            field="permissions_module_level.businessIntelligence.allowed"
            width={180}
          />
          <AgGridColumn
            headerName="Creative"
            cellRenderer="imageRenderer"
            field="permissions_module_level.creative.allowed"
            width={150}
          />
          <AgGridColumn
            headerName="Advertising"
            cellRenderer="imageRenderer"
            field="permissions_module_level.advertising.allowed"
            width={180}
          />
          <AgGridColumn
            headerName="Operations"
            cellRenderer="imageRenderer"
            field="permissions_module_level.operations.allowed"
            width={180}
          />
          <AgGridColumn
            headerName="Invoices Reconciliation"
            cellRenderer="imageRenderer"
            field="permissions_module_level.invoiceReconciliations.allowed"
            width={180}
          />
          <AgGridColumn
            headerName="ShortageClaims & Chargebacks"
            cellRenderer="imageRenderer"
            field="permissions_module_level.shortageclaimsAndChargebacks.allowed"
            width={180}
          />
          <AgGridColumn
            headerName="Amazon Advertising"
            cellRenderer="imageRenderer"
            field="permissions_module_level.amazonAdvertising.allowed"
            width={180}
          />
          <AgGridColumn
            headerName="Uncategorised Reports"
            cellRenderer="imageRenderer"
            field="permissions_module_level.uncategorizedReports.allowed"
            width={180}
          />
          <AgGridColumn
            headerName="Catalog"
            cellRenderer="imageRenderer"
            field="permissions_module_level.catalog.allowed"
            width={150}
          />
          <AgGridColumn
            headerName="Apollo"
            cellRenderer="imageRenderer"
            field="permissions_module_level.prospects.allowed"
            width={150}
          />
          <AgGridColumn
            headerName="FBA Shipment"
            cellRenderer="imageRenderer"
            field="permissions_module_level.fbaShipment.allowed"
            width={150}
          />
          <AgGridColumn
            headerName="Content Revisions"
            cellRenderer="imageRenderer"
            field="permissions_module_level.content.allowed"
            width={150}
          />
          <AgGridColumn
            headerName="Users"
            cellRenderer="imageRenderer"
            field="permissions_module_level.users.allowed"
            width={150}
          />
          <AgGridColumn
            headerName="Onboarding"
            cellRenderer="imageRenderer"
            field="permissions_module_level.onboarding.allowed"
            width={180}
          />
          <AgGridColumn
            headerName="AMS"
            cellRenderer="imageRenderer"
            field="permissions_module_level.ams.allowed"
            width={150}
          />
          <AgGridColumn
            headerName="AMS Reports"
            cellRenderer="imageRenderer"
            field="permissions_module_level.amsReports.allowed"
            width={150}
          />
        </AgGridColumn>
        <AgGridColumn
          cellRenderer="buttonRenderer"
          cellEditorParams={{
            cellRenderer: "buttonRenderer",
          }}
          width={200}
        />
      </AgGridReact>
    </div>
  );
});
