import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  CircularProgress,
  Backdrop,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import axios from "axios";
import * as XLSX from 'xlsx';

//icons
import RefreshIcon from '@material-ui/icons/Refresh';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import ConfirmDialog from "./components/ComfirmDialog/ConfirmDialog";
import Table from "./components/Table/Table";
import SampleFile from "../../healpers/assets/Sample.xlsx";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { useUserState } from "../../context/UserContext";

export default function Page() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var userState = useUserState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [file, setfile] = useState({name:"No File uploaded"});
  const [confirmDialog, setconfirmDialog] = useState(false);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [estimatedTime, setestimatedTime] = useState(null);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("")

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  async function uploadCSV (e){
    const file = e.target.files[0];
    const reader = new FileReader();

      reader.onload = evt => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_row_object_array(ws, { header: 1 });
        const fileType = file.name.split(".")[1];
        
        const errPromise = new Promise((resolve, reject) => {
          if (fileType === "xlsx") {
            if (data[0][0] !== "ASIN") {
              reject(`Column ASIN is missing`);
            }
            if (!(data.length > 1)) {
              reject("File is empty");
            }
            data.slice(1, data.length).forEach((entry, index) => {
              if (entry.length !== 1 || entry[0][0].length === 0) {
                reject(`Invalid data at row ${index + 2}`);
              }
            });
            resolve("No error found");
          } else {
            const columns = ["ASIN"];
            for (const column of columns) {
              if (!data[0].includes(column)) {
                reject(`Column ${column} is missing`);
              }
            }
            if (data.length <= 1) {
              reject("Uploaded sheet has no data");
            }
            resolve("No error found");
          }
        });

        errPromise
          .then(()=>{
            setfile(file);
            setconfirmDialog(true);
            convertSeconds(((data.length - 1)*5)*2); //rough time to fetch API is 5 sec.
          })
          .catch((err)=>{
            seterr(err)
            document.getElementById('upload-csv').value = '';
            setTimeout(() => {
              seterr(null)
            }, 2000);
            setfile({name:"No File uploaded"});
            setconfirmDialog(false);
            setestimatedTime(null);
          })
      };
    reader.readAsBinaryString(file);
    e.target.value = null
  }

  async function uploadCSVBrands (e){
    const file = e.target.files[0];
    const reader = new FileReader();

      reader.onload = evt => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_row_object_array(ws, { header: 1 });
        const fileType = file.name.split(".")[1];
        
        const errPromise = new Promise((resolve, reject) => {
          if (fileType === "xlsx") {
            if (!(data.length > 1)) {
              reject("File is empty");
            }
            data.slice(1, data.length).forEach((entry, index) => {
              if (entry[0][0].length === 0) {
                reject(`Invalid data at row ${index + 2}`);
              }
            });
            resolve("No error found");
          } else {
            const columns = ["ASIN"];
            for (const column of columns) {
              if (!data[0].includes(column)) {
                reject(`Column ${column} is missing`);
              }
            }
            if (data.length <= 1) {
              reject("Uploaded sheet has no data");
            }
            resolve("No error found");
          }
        });

        errPromise
          .then(()=>{
            setfile(file);
            setconfirmDialog(true);
            convertSeconds(((data.length - 1)*5)*2); //rough time to fetch API is 5 sec.
          })
          .catch((err)=>{
            seterr(err)
            document.getElementById('upload-csv').value = '';
            setTimeout(() => {
              seterr(null)
            }, 2000);
            setfile({name:"No File uploaded"});
            setconfirmDialog(false);
            setestimatedTime(null);
          })
      };
    reader.readAsBinaryString(file);
    e.target.value = null
  }

  const retryFile = async (id, country) => {
    try {
      const body = {
        id,
        country
      }
      await axios.post(BASE_URL + api.brandFinderGlobalRetry, body);
    }catch(error) {
      console.log(error);
    }
  }

  function convertSeconds(seconds) {
    var convert = function(x) { return (x < 10) ? "0"+x : x; }
    setestimatedTime(convert(parseInt(seconds / (60*60))) + ":" +
           convert(parseInt(seconds / 60 % 60)) + ":" +
           convert(seconds % 60))
  }

  const fetchData = () => {
    if (!selectedCountry) {
      setmainTableData([]);
      return;
    }
    setfullPageLoader(true)
    axios
    .get(BASE_URL + api.brandFinderGlobalList + userState.userData.email, {
      params: { country: selectedCountry },
    })
    .then((res) => {
        setmainTableData(res.data);
        setfullPageLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setfullPageLoader(false);
      });
  }

  useEffect(() => {
    if(userState.userData) {
      fetchData();
    }
  }, [userState.userData, selectedCountry])

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Brand Finder Global"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Brand Finder
              </Link>
              <Typography className={classes.link} color="primary">
                Upload File
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <form>
              <Box display="flex" alignItems="baseline">
              <FormControl variant="outlined" className={classes.formControl} size="small" min>
                <InputLabel id="demo-simple-select-filled-label">
                  Country
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  label="Country"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"AUSTRALIA"}>AUSTRALIA</MenuItem>
                  <MenuItem value={"BELGIUM"}>BELGIUM</MenuItem>
                  <MenuItem value={"BRAZIL"}>BRAZIL</MenuItem>
                  <MenuItem value={"CANADA"}>CANADA</MenuItem>
                  <MenuItem value={"EGYPT"}>EGYPT</MenuItem>   
                  <MenuItem value={"FRANCE"}>FRANCE</MenuItem>
                  <MenuItem value={"GERMANY"}>GERMANY</MenuItem>
                  <MenuItem value={"INDIA"}>INDIA</MenuItem>  
                  <MenuItem value={"ITALY"}>ITALY</MenuItem>
                  <MenuItem value={"MEXICO"}>MEXICO</MenuItem>   
                  <MenuItem value={"NETHERLANDS"}>NETHERLANDS</MenuItem>
                  <MenuItem value={"POLAND"}>POLAND</MenuItem>
                  <MenuItem value={"SAUDI_ARABIA"}>SAUDI ARABIA</MenuItem>
                  <MenuItem value={"SINGAPORE"}>SINGAPORE</MenuItem>
                  <MenuItem value={"SOUTH_AFRICA"}>SOUTH AFRICA</MenuItem>
                  <MenuItem value={"SPAIN"}>SPAIN</MenuItem>   
                  <MenuItem value={"SWEDEN"}>SWEDEN</MenuItem>
                  <MenuItem value={"TURKEY"}>TURKEY</MenuItem>
                  <MenuItem value={"UAE"}>UAE</MenuItem>
                  <MenuItem value={"UK"}>UK</MenuItem>
                  <MenuItem value={"US"}>US</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                className={classes.formControl}
              >
                <label htmlFor="upload-csv">
                  <input
                    style={{ display: "none" }}
                    id="upload-csv"
                    name="upload-csv"
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => uploadCSVBrands(e)}
                    disabled={!selectedCountry}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    component="span"
                    size="small"
                    startIcon={<PublishIcon />}
                    disabled={!selectedCountry}
                  >
                    Upload Brands
                  </Button>
                </label>
                <Typography
                  className={classes.link}
                  color="primary"
                  style={{ fontSize: 12, margin: "5% 0% 0% 1%" }}
                >
                  {file.name}
                </Typography>
              </FormControl>
              <a
                href={SampleFile}
                download="Sample File.xlsx"
                style={{ textDecoration: "none" }}
              >
                <Button
                  color="primary"
                  variant="outlined"
                  component="span"
                  size="small"
                  style={{width: 220}}
                  startIcon={<GetAppIcon />}
                >
                  Download Sample File
                </Button>
              </a>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ marginRight: "-5%" }}
              >
                <label htmlFor="upload-csv">
                  <input
                    style={{ display: "none" }}
                    id="upload-csv"
                    name="upload-csv"
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => uploadCSV(e)}
                    disabled={!selectedCountry}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    component="span"
                    size="small"
                    startIcon={<PublishIcon />}
                    disabled={!selectedCountry}
                  >
                    Upload here
                  </Button>
                </label>
                <Typography
                  className={classes.link}
                  color="primary"
                  style={{ fontSize: 12, margin: "5% 0% 0% 1%" }}
                >
                  {file.name}
                </Typography>
              </FormControl>
              </Box>
            </form>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <div className={classes.mainChartHeader}>
                  <div />
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<RefreshIcon />}
                    onClick={() => fetchData()}
                  >
                    Refresh
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} retryFile={retryFile}/>
            </Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ConfirmDialog
        open={confirmDialog}
        toggleDialog={() => setconfirmDialog(!confirmDialog)}
        file={file}
        setFile={setfile}
        estimatedTime={estimatedTime}
        setnotification={setnotification}
        seterr={seterr}
        fetchData={fetchData}
        country={selectedCountry}
      />
      {notification ? (
        <Alert
          severity="success"
          style={{ position: "absolute", bottom: 30, left: "48%" }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{ position: "absolute", bottom: 30, left: "48%" }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
