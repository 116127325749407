import React, { useState, useEffect } from "react";
import {
  Dialog,
  Grid,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  FormControlLabel,
  TextField,
  Divider,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

//helpers
import { BASE_URL } from "../../../../healpers/api";
import api from "../../../../healpers/apiRoutes";

//context
import { tokenConfig } from "../../../../context/UserContext";
import { useVendorState } from "../../../../context/VendorContext";

//styles
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight: 600,
    fontSize: 20,
  },
}));

export default function PageModal({
  showmodal,
  closeModal,
  toggleError,
  toggleNotification,
  setfullPageLoader,
}) {

  const vendorState = useVendorState()

  const classes = useStyles();
  const [address, setAddress] = useState({});

  console.log(address);
  async function onAddressChange(event) {
    event.persist();
    setAddress((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  }

  async function createAddress() {
    setfullPageLoader(true);
    try {
      await axios.post(BASE_URL + api.createAddress, {
        vendorCode: vendorState?.selected,
        ...address,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setfullPageLoader(false);
    }
  }

  async function validateAddress() {
    const requiredFields = [
      "addressLine1",
      "city",
      "countryCode",
      "name",
      "postalCode",
      "stateOrProvinceCode",
    ];

    for (const field of requiredFields) {
      if (!address[field]) {
        toggleError("Please fill all the required fields");
        return;
      }
    }
    await createAddress();
    toggleNotification("Address created");
    setAddress({});
    closeModal()
  }

  return (
    <Dialog onClose={closeModal} open={showmodal} maxWidth="lg">
      <DialogContent>
        <form autoComplete="off">
          <Grid container spacing={2} style={{ padding: 30 }}>
            <Grid item xs={12}>
              <Typography color="textPrimary" variant="h3">
                Shipment Address
              </Typography>
              <br />
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="name"
                value={address?.name}
                onChange={onAddressChange}
                label="Name"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="addressLine1"
                value={address?.addressLine1}
                onChange={onAddressChange}
                label="Address Line 1"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="addressLine2"
                value={address?.addressLine2}
                onChange={onAddressChange}
                label="Address Line 2"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="city"
                value={address?.city}
                onChange={onAddressChange}
                label="City"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="districtOrCounty"
                value={address?.districtOrCounty}
                onChange={onAddressChange}
                label="District Or County"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="stateOrProvinceCode"
                value={address?.stateOrProvinceCode}
                onChange={onAddressChange}
                label="State or Province Code"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="countryCode"
                value={address?.countryCode}
                onChange={onAddressChange}
                label="Country Code"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="postalCode"
                value={address?.postalCode}
                onChange={onAddressChange}
                label="Postal Code"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            closeModal();
            setAddress({});
          }}
        >
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={validateAddress}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
