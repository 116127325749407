import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  FormControl,
  TextField,
  Typography,
  Backdrop,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useVendorState } from "../../../context/VendorContext";
import axios from "axios";
import api from "../../../healpers/apiRoutes";
import { tokenConfig, useUserState } from "../../../context/UserContext";
import { BASE_URL } from "../../../healpers/api";
import moment from "moment";
import { DeleteForever, Add } from "@material-ui/icons";
import { ACTION_ITEMS, ACTION_ITEMS_STATUS } from "../../../constant/wbr";

export default function ActionItemModal({ open, close, fetchActionItems, edit, actionId }) {
  const vendorState = useVendorState();
  const userState = useUserState();
  const [slide, setSlide] = useState("");
  const [week, setWeek] = useState("");
  const [description, setDescription] = useState("");
  const [owner, setOwner] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [comments, setComments] = useState([]);
  const [closedDate, setClosedDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");

  const onModalClose = () => {
    setSlide("");
    setWeek("");
    setDescription("");
    setOwner("");
    setAssignedTo("");
    setComments([]);
    setClosedDate("");
    setDueDate("");
    setStatus("");
  };

  const createActionItem = async () => {
    setLoading(true);
    try {
      let body = {
        slide,
        description,
        owner,
        assignedTo,
        comments,
        dueDate,
        vendorCode: vendorState?.selected,
      };

      await axios.post(BASE_URL + api.addActionItem, body, tokenConfig());
      close();
      onModalClose();
      fetchActionItems();
      window.location.reload()
    } catch (error) {
      console.error(error);
      alert("Failed to create action item");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (edit) {
      getActionItem();
    }
  }, [edit]);

  const getActionItem = async () => {
    try {
      const params = {
        id: actionId,
      };
      const response = await axios.get(BASE_URL + api.getActionItem, tokenConfig(params));
      const data = response.data.data;
      setSlide(data.slide);
      setStatus(data.status);
      setDescription(data.description);
      setStatus(data.status);
      setOwner(data.owner);
      setAssignedTo(data.assignedTo);
      setComments(data.comments);
      if(data.closedDate) {
        setClosedDate(moment(data.closedDate).format("YYYY-MM-DD"));
      }
      setDueDate(moment(data.dueDate).format("YYYY-MM-DD"));
    } catch (error) {
      console.error(error);
      alert("Failed to get action item");
    }
  }

  const editActionItem = async () => {
    try {
      let body = {
        id: actionId,
        slide,
        description,
        owner,
        assignedTo,
        comments,
        closedDate,
        dueDate,
        vendorCode: vendorState?.selected,
        status
      };

      await axios.post(BASE_URL + api.updateActionItem, body, tokenConfig());
      close();
      onModalClose();
      fetchActionItems();
      window.location.reload()
    } catch (error) {
      console.error(error);
      alert("Failed to edit action item");
    }
  };

  const addNewComment = () => {
    const commentsArray = comments;
    setComments([...comments, { comment: "", createdBy: userState.userData.email }])
  }

  const setCommentValue = (index, event) => {
    const {value} = event.target
    const commentsArray = [...comments];
    comments[index].comment = value
    setComments(commentsArray)
  }

  const removeComment = (index) => {
    const commentsArray = [...comments];
    commentsArray.splice(index, 1)
    setComments(commentsArray)
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          close();
          onModalClose();
        }}
        styles={{ modal: { width: 520, borderRadius: 10 } }}
        center
        closeOnOverlayClick
      >
        <Backdrop />
        <Grid container>
          <Typography style={{ fontSize: 16, fontWeight: 600 }}>
            {edit ? "Edit" : "Create"} Action Item
          </Typography>
        </Grid>

        <Grid container style={{ paddingTop: "10px" }}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="demo-simple-select-label" >Slide</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={slide}
              label="Slide"
              onChange={(event) => setSlide(event.target.value)}

            >
              <MenuItem value={ACTION_ITEMS.KEY_INPUT_METRICS}>Key Input Metrics</MenuItem>
              <MenuItem value={ACTION_ITEMS.KEY_OUTPUT_METRICS}>Key Output Metrics</MenuItem>
              <MenuItem value={ACTION_ITEMS.FINANCE_DISPUTES}>Finance Disputes</MenuItem>
              <MenuItem value={ACTION_ITEMS.OPERATION_TICKETS}>Operation Tickets</MenuItem>
              <MenuItem value={ACTION_ITEMS.AMS}>AMS</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {edit && <Grid container style={{ paddingTop: "10px" }}>
        <FormControl fullWidth variant="outlined">
            <InputLabel id="demo-simple-select-label" >Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={status}
              label="Status"
              onChange={(event) => setStatus(event.target.value)}

            >
              <MenuItem value={ACTION_ITEMS_STATUS.OPEN}>Open</MenuItem>
              <MenuItem value={ACTION_ITEMS_STATUS.CLOSED}>Closed</MenuItem>
            </Select>
          </FormControl>
        </Grid>}
        <Grid container style={{ paddingTop: "10px" }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              type="text"
              value={description}
              id="action-item"
              name="description"
              onChange={(event) => setDescription(event.target.value)}
              label="Description"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid container style={{ paddingTop: "10px" }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              type="email"
              value={owner}
              id="action-item"
              name="owner"
              onChange={(event) => setOwner(event.target.value)}
              label="Owner"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid container style={{ paddingTop: "10px" }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              type="email"
              value={assignedTo}
              id="action-item"
              name="assignedTo"
              onChange={(event) => setAssignedTo(event.target.value)}
              label="Assigned to"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid container style={{ paddingTop: "10px" }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              type="date"
              value={dueDate}
              id="action-item"
              name="dueDate"
              onChange={(event) => setDueDate(event.target.value)}
              label="Due date"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        {
          !edit ? null :
          <Grid container style={{ paddingTop: "10px" }}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                type="date"
                value={closedDate}
                id="action-item"
                name="closedDate"
                onChange={(event) => setClosedDate(event.target.value)}
                label="Closed date"
                variant="outlined"
              />
            </FormControl>
          </Grid>
        }
        {edit &&
          <Grid container style={{ paddingTop: "10px", justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid item>
              <Typography style={{ fontSize: 16, fontWeight: 600, paddingTop: "5px" }}>
                Comments
              </Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="add" onClick={() => addNewComment()}  color="primary">
                <Add color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        }
        {edit && comments.map((item, index) => {
            return <Grid container style={{ paddingTop: "5px" }}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  type="text"
                  value={item.comment}
                  id="action-item"
                  name="comments"
                  onChange={(event) => setCommentValue(index, event)}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <IconButton aria-label="delete" onClick={() => removeComment(index)} color="primary">
                        <DeleteForever color="primary" />
                      </IconButton>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          })
        }
        <Grid
          container
          fullWidth
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginTop: "20px" }}
            disabled={loading}
            onClick={edit ? editActionItem : createActionItem}
          >
            {edit ? "Edit" : "Create"} Action Item
          </Button>
        </Grid>
      </Modal>
    </div>
  );
}
