import React, { useState, useRef, useEffect } from "react";
import {
	Grid,
	Typography,
	Breadcrumbs,
	Link,
	Backdrop,
	CircularProgress,
	Button
} from "@material-ui/core";
import useStyles from "./styles.js";
import { BASE_URL } from "../../healpers/api.js";
import axios from "axios";
import api from "../../healpers/apiRoutes.js";
import { tokenConfig } from "../../context/UserContext.js";
import { useVendorState } from "../../context/VendorContext.js";
import Widget from "../../components/Widget/Widget.js";
import Table from "./components/table.js"
import useQuery from "../../hooks/useQuery.js";
import moment from "moment";
import { AM_STATUS, COMPARING_RANGE_TYPES } from "../../constant/amRules.js";
import RefreshIcon from "@material-ui/icons/Refresh";
import PrintIcon from "@material-ui/icons/Print";

export default function Details(props) {
	const vendorState = useVendorState();
	const classes = useStyles();
	const exportFunctionRef = useRef();
	const [ruleData, setRuleData] = useState([]);
	const [ruleName, setRuleName] = useState("");
	const [fullPageLoader, setFullPageLoader] = useState(false);
	const [metricData, setMetricData] = useState({});
	const [range, setRange] = useState({});
	const query = useQuery();

	useEffect(() => {
		if (vendorState?.selected) {
			fetchRuleDetails()
		}
	}, [vendorState?.selected]);

	const fetchRuleDetails = async () => {
		try {
			setFullPageLoader(true);
			let ruleId = query.get('ruleId')
			let params = { id: ruleId }
			const response = await axios(
				BASE_URL + api.getRule,
				tokenConfig(params)
			);
			const rule = response.data.data;
			const dateFormat = "MM/DD/YYYY";
			if (rule.comparingRange !== COMPARING_RANGE_TYPES.VALUE) {
				rule.comparisonDateRange = `${moment(rule.comparisonDateRange.comparisonStartDate).format(dateFormat)} - ${moment(rule.comparisonDateRange.comparisonEndDate).format(dateFormat)}`;
			}
			rule.currentDateRange = `${moment(rule.currentDateRange.startDate).format(dateFormat)} - ${moment(rule.currentDateRange.endDate).format(dateFormat)}`;
			rule.status.forEach((item) => {
				if (rule.comparingRange !== COMPARING_RANGE_TYPES.VALUE) {
					item.comparisonDateRange = `${moment(rule.comparisonDateRange.comparisonStartDate).format(dateFormat)} - ${moment(rule.comparisonDateRange.comparisonEndDate).format(dateFormat)}`;
				}
				item.currentDateRange = `${moment(rule.currentDateRange.startDate).format(dateFormat)} - ${moment(rule.currentDateRange.endDate).format(dateFormat)}`;
				item.comparingRange = rule.comparingRange;
				item.range = rule.range;
				item.metric = rule.metric;
			})
			const statusOrder = {
				[AM_STATUS.RED]: 1,
				[AM_STATUS.AMBER]: 2,
				[AM_STATUS.GREEN]: 3,
			};

			rule.status.sort((a, b) => {
				return statusOrder[a.status] - statusOrder[b.status];
			});
			setMetricData(rule)
			setRuleData(rule.status)
			setRuleName(rule.name)
			if(rule.status.length > 0) {
				setRange(rule.status[0])
			}
			setFullPageLoader(false);
		} catch (error) {
			setFullPageLoader(false);
			alert(error.message || "Failed to fetch Rule Details")
			console.log("rules details api - error", error);
		}
	}


	return (
		<div>
			<Grid
				container
				justifyContent="space-between"
				alignItems="center"
				className={classes.header}
			>
				<Grid item xs={6}>
					<Typography
						variant="h4"
						weight="medium"
						style={{ paddingBottom: "20px" }}
					>
						Rule: {ruleName}
					</Typography>
					<Breadcrumbs aria-label="breadcrumb">
						<Link color="inherit" className={classes.link}>
							WBR
						</Link>
						<Typography className={classes.link} color="primary">
							Details
						</Typography>
					</Breadcrumbs>
					<Typography
						variant="h4"
						weight="medium"
						style={{ fontSize: 14, paddingTop: '10px' }}
					>
						<span style={{ fontWeight: "bold" }}>Metric:</span> {metricData.metric}
					</Typography>
					<Typography
						variant="h4"
						weight="medium"
						style={{ fontSize: 14, paddingTop: '10px' }}
					>
						<span style={{ fontWeight: "bold" }}>Current Range:</span> {range.range}
					</Typography>
					<Typography
						variant="h4"
						weight="medium"
						style={{ fontSize: 14, paddingTop: '10px' }}
					>
						<span style={{ fontWeight: "bold" }}>Current Date Range:</span> {metricData.currentDateRange}
					</Typography>
					<Typography
						variant="h4"
						weight="medium"
						style={{ fontSize: 14, paddingTop: '10px' }}
					>
						<span style={{ fontWeight: "bold" }}>Comparison Range:</span> {range.comparingRange}
					</Typography>
					<Typography
						variant="h4"
						weight="medium"
						style={{ fontSize: 14, paddingTop: '10px' }}
					>
						<span style={{ fontWeight: "bold" }}>Comparison Date Range:</span> {metricData.comparisonDateRange}
					</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Widget
						upperTitle
						noBodyPadding
						bodyClass={classes.tableOverflow}
						header={
							<div className={classes.mainChartHeader}>
								<div>
									<Button
										variant="contained"
										color="primary"
										size="small"
										startIcon={<PrintIcon />}
										onClick={() => exportFunctionRef.current.onBtnExport()}
									>
										Export
									</Button>
								</div>
								<div>
									<Button
										variant="contained"
										color="primary"
										size="small"
										startIcon={<RefreshIcon />}
										onClick={() => fetchRuleDetails()}
									>
										Refresh
									</Button>
								</div>
							</div>
						}
					>
						<Grid container>
							<Grid item xs={12} >
								<Table
									ref={exportFunctionRef}
									tableData={ruleData}
									metricData={metricData}
								/>
							</Grid>
						</Grid>
					</Widget>
				</Grid>
			</Grid>
			<Backdrop className={classes.backdrop} open={fullPageLoader}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	);
}
