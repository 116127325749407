import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import '../styles'
import { camelToProperCase, getStatusColor, numberWithCommas } from "../../../healpers/utilityFunctions";
import { IconButton } from "@material-ui/core";
import TableRowsIcon from '@mui/icons-material/TableRows';
import DetailModal from "./detailModal";
import xlsx from "xlsx";

export default forwardRef(function Tables(props, ref) {

  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);
  const [detailModal, setDetailModal] = useState(false);
  const [ASIN, setASIN] = useState("")

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      let data = props.tableData.map(item => ({ ...item }));
      data.forEach((item) => {
        item.currentValue = item.currentValue.toFixed(2)
        item.comparisonValue = item.comparisonValue.toFixed(2)
        item.actualSales = item.actualSales.toFixed(2)
        item.previousSales = item.previousSales.toFixed(2)
        item.forecast = item.forecast.toFixed(2)
        item.target = item.target.toFixed(2)
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            if (key !== 'metrics') {
              const properCaseKey = camelToProperCase(key);
              item[properCaseKey] = item[key];
              delete item[key];
            }
          }
        }
        if(item.metrics.length > 0) {
          item.metrics.forEach((metric) => {
            item[`${metric.key} Current Value`] = metric.currentValue.toFixed(2)
            item[`${metric.key} Comparison Value`] = metric.comparisonValue.toFixed(2)
          })
        }
        delete item.metrics
      })
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet);
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const excelBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      var excelFileDownloadEle = document.createElement("a");
      let ruleName = props.metricData.name
      excelFileDownloadEle.download = `${ruleName}` + ".xlsx";
      excelFileDownloadEle.href = window.URL.createObjectURL(excelBlob);
      excelFileDownloadEle.click();
    }
  }));

  const rowStyle = { background: 'white', width: '100%' };

  const StatusRenderer = (buttonProps) => {
    return (
      <div style={{ color: getStatusColor(buttonProps.data.status) }}>{buttonProps.data.status}</div>
    );
  };

  const ViewButtonRenderer = (buttonProps) => {
    return (
      <IconButton
        onClick={() => {setASIN(buttonProps.data.asin); setDetailModal(true)}}
        style={{padding: "0px !important" }}
        variant="contained"
        size="medium"
      >
        <TableRowsIcon color="primary" />
      </IconButton>
    );
  };

  return (
    <div className="ag-theme-material" style={{ height: 620, width: '100%' }} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={50}
        rowStyle={rowStyle}
        frameworkComponents={{
          statusRenderer: StatusRenderer,
          viewButtonRenderer:ViewButtonRenderer
        }}
        fullWidth
        suppressDragLeaveHidesColumns={true}
        suppressRowClickSelection={true}

      >
        <AgGridColumn headerName="ASIN" field="asin" />
        <AgGridColumn headerName="SKU" field="sku" />
        <AgGridColumn headerName="Current" field="currentValue" valueFormatter={(params) => roundValue(params)} />
        <AgGridColumn headerName="Target" field="target" valueFormatter={(params) => roundValue(params)} width={150} />
        <AgGridColumn headerName="Previous Sales" field="previousSales" valueFormatter={(params) => formatSales(params)}/>
        <AgGridColumn headerName="Sales (Units)" field="actualSales" valueFormatter={(params) => formatSales(params)} />
        <AgGridColumn headerName="Forecast" field="forecast" width={150} valueFormatter={(params) => formatSales(params)} />
        <AgGridColumn headerName="Status" cellRenderer="statusRenderer" width={150} />
        <AgGridColumn
          width={80}
          cellRenderer="viewButtonRenderer"
        />
      </AgGridReact>
      <DetailModal open={detailModal} close={() => setDetailModal(false)} ruleData={props?.metricData} asin={ASIN} />
    </div>
  );
})

const roundValue = (params) => {
  if (params.value) {
    return numberWithCommas(params.value, 2)
  }
}

const formatSales = (params) => {
  if (params.value) {
    return numberWithCommas(params.value)
  }
}