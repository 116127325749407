import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Breadcrumbs,
  Typography,
  Switch,
  FormControl,
  FormLabel,
  Divider,
  Button,
  Box,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import axios from "axios";
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import { tokenConfig } from "../../context/UserContext";
import { useVendorState } from "../../context/VendorContext";
import { INITIAL_CRONS } from "./utils";
import useStyles from "./styles";
import { TextArea } from "./components/TextArea";
import { SegmentFields } from "./components/SegmentFields";
import { useUserState } from "../../context/UserContext";
import XLSX from "xlsx";
import ShareOfVoiceTemplate from "../../healpers/assets/ShareOfVoiceTemplate.xlsx";
import { saveShareOfVoiceApiConfig } from "../../http/api";

export default function Page() {
  const classes = useStyles();
  const vendorState = useVendorState();
  const { userData } = useUserState();
  const [crons, setCrons] = useState(INITIAL_CRONS);
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const cronsManagementPermi =
    userData?.permissions_module_level?.cronsManagement;

  useEffect(() => {
    getApiConfigData();
  }, [vendorState.selected]);
  const getApiConfigData = () => {
    const params = {
      vendorCode: vendorState?.selected,
    };
    axios
      .get(BASE_URL + api.getApiConfig, tokenConfig(params))
      .then((res) => {
        let newCrons = INITIAL_CRONS.map((cron) => {
          let filteredCron = res.data.data.find(
            (apiConfig) => apiConfig.reportType === cron.reportType
          );
          if (filteredCron && Object.keys(filteredCron?.config).length == 0) {
            filteredCron.config = cron.config;
          }
          return { ...cron, ...filteredCron };
        });
        setCrons(newCrons);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChangeTextArea = ({ target: { name, value } }) => {
    setCrons((prev) =>
      prev.map((cron) => {
        if (cron.reportType === name)
          cron.config[Object.keys(cron.config)[0]] = value;
        return cron;
      })
    );
  };
  const handleChangeSegmentFields = (e, indx, reportType) => {
    const { name, value } = e.target;
    setCrons((prev) =>
      prev.map((cron) => {
        if (cron.reportType === reportType)
          cron.config["segments"][indx][name] = value;
        return cron;
      })
    );
  };
  const toggle = ({ target: { name, checked } }) => {
    setCrons((prev) =>
      prev.map((cron) => {
        if (cron.reportType === name)
          cron.status = checked ? "active" : "inactive";
        return cron;
      })
    );
  };
  const handleAddSegment = (name) => {
    setCrons((prev) =>
      prev.map((cron) => {
        if (cron.reportType === name)
          cron.config["segments"] = [
            ...cron.config["segments"],
            ...[{ id: "", name: "" }],
          ];
        return cron;
      })
    );
  };
  const handleRemoveSegment = (indx, reportType) => {
    setCrons((prev) =>
      prev.map((cron) => {
        if (cron.reportType === reportType)
          cron.config["segments"].splice(indx, 1);
        return cron;
      })
    );
  };

  const handleSubmit = async () => {
    try {
      setFullPageLoader(true);
      let validation = { validate: true, cron: "", field: "" };
      let postData = crons;
      postData = postData.map((cron) => {
        if (cron.id === "") delete cron.id;
        if (cron.vendorCode === "") cron.vendorCode = vendorState?.selected;
        if (
          cron.status === "active" &&
          (Object.keys(cron.config)[0] === "asins" ||
            Object.keys(cron.config)[0] === "searchTerms")
        ) {
          if (Object.values(cron.config)[0] === "") {
            validation = {
              validate: false,
              cron: cron.title,
              field:
                Object.keys(cron.config)[0] === "asins"
                  ? "ASINs are"
                  : "Search Terms are",
            };
          }
        }
        if (
          cron.status === "active" &&
          Object.keys(cron.config)[0] === "segments"
        ) {
          cron.config["segments"] = cron.config["segments"].filter(
            (segment) => segment.id !== "" || segment.name !== ""
          );
          cron.config["segments"].forEach((segment) => {
            if (segment.id !== "" && segment.name === "") {
              validation = {
                validate: false,
                cron: cron.title,
                field: "Segment Name is",
              };
            } else if (segment.id === "" && segment.name !== "") {
              validation = {
                validate: false,
                cron: cron.title,
                field: "Segment ID is",
              };
            }
          });
        }
        return cron;
      });
      postData = {
        cronsData: postData,
      };
      if (validation?.validate) {
        axios
          .post(BASE_URL + api.postApiConfig, postData, tokenConfig())
          .then((result) => {
            getApiConfigData();
            setFullPageLoader(false);
          })
          .catch((err) => {
            console.log(err);
            setFullPageLoader(false);
            alert(err.response.message || err.message);
          });
      } else {
        setFullPageLoader(false);
        alert(`${validation.cron} ${validation.field} missing`);
      }
    } catch (error) {
      setFullPageLoader(false);
      console.log(error);
    }
  };

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Crons Management"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.link} color="primary">
                Crons Management
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                alignItems: "end",
              }}
            >
              <FormControl variant="outlined">
                <label htmlFor="upload-xlsx">
                  <input
                    style={{ display: "none" }}
                    id="upload-xlsx"
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={async (e) => {
                      const reader = new FileReader();
                      reader.onload = async (event) => {
                        try {
                          const workbook = XLSX.read(event.target.result, {
                            type: "array",
                          });
                          const worksheetName = workbook.SheetNames[0];
                          const worksheet = workbook.Sheets[worksheetName];
                          const data = XLSX.utils.sheet_to_json(worksheet);
                          const response = await saveShareOfVoiceApiConfig(
                            data
                              .map((item) => {
                                return {
                                  vendorCode: item["Vendor Code"],
                                  searchTerms: item["Search Terms"],
                                }
                              })
                          );
                          window.alert(response.message);
                        } catch (err) {
                          console.error(err);
                          window.alert(err?.message || "Upload failed");
                        }
                      };
                      reader.readAsArrayBuffer(e.target.files[0]);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                    startIcon={<CloudUploadIcon />}
                    component={"span"}
                  >
                    Upload Share of Voice
                  </Button>
                </label>
              </FormControl>
              <a
                href={ShareOfVoiceTemplate}
                download="ShareOfVoiceTemplate.xlsx"
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                  component="span"
                  startIcon={<PrintIcon />}
                >
                  Download Share of Voice Template
                </Button>
              </a>
            </div>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
            >
              <Grid container spacing={6} className={classes.containerWrapper}>
                {crons.map((cron) => (
                  <Grid item xs={6} key={cron.reportType}>
                    <FormLabel id="cron-title">{cron.title}</FormLabel>
                    <Switch
                      className={classes.toggle}
                      checked={cron.status === "active"}
                      name={cron.reportType}
                      onChange={toggle}
                    />
                    <Divider />
                    {Object.keys(cron.config)[0] === "segments" ? (
                      <>
                        {Object.values(cron.config)[0].map((segment, indx) => (
                          <SegmentFields
                            key={indx}
                            indx={indx}
                            disabled={cron.status !== "active"}
                            segment={segment}
                            onRemoveSegment={handleRemoveSegment}
                            reportType={cron.reportType}
                            onValuesChange={handleChangeSegmentFields}
                          />
                        ))}
                        <Grid
                          component="div"
                          style={{
                            textAlign: "end",
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{ marginTop: "15px" }}
                            color="primary"
                            size="small"
                            disabled={cron.status !== "active"}
                            onClick={() => handleAddSegment(cron.reportType)}
                          >
                            Add Segment
                          </Button>
                        </Grid>
                      </>
                    ) : Object.keys(cron.config)[0] === "asins" ||
                      Object.keys(cron.config)[0] === "searchTerms" ? (
                      <TextArea
                        cron={cron}
                        disabled={cron.status !== "active"}
                        onValuesChange={handleChangeTextArea}
                      />
                    ) : (
                      ""
                    )}
                  </Grid>
                ))}
                <Box
                  sx={{
                    pt: 2,
                  }}
                >
                </Box>
                <Box className={classes.saveBtnWrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
          onClick={() => setFullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
