import {
  Button,
  Grid,
  Modal,
  TextField,
  Backdrop,
  Box,
  Fade,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import useStyles from "./styles";
import { useState } from "react";

const AddSPPackageModal = ({
  open,
  handleClose,
  tableData,
  setPackageListTableData,
  selectedRow,
  setSelectedRow,
}) => {
  const classes = useStyles();

  console.log({ selectedRow });

  function saveDetails() {
    const updated = tableData.map((obj) => {
      if (obj.SellerSKU === selectedRow?.SellerSKU) {
        return selectedRow;
      } else {
        return obj;
      }
    });
    setPackageListTableData(updated);
  }

  function handlePackageDetailsChange(event) {
    event.persist();
    console.log({ name: event.target.name, value: event.target.value });
    setSelectedRow((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h1 style={{ textAlign: "center" }}>Add Package Information</h1>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            style={{ marginBottom: 10 }}
          >
            <Grid item>
              <TextField
                name="length"
                label="Length (IN)"
                type="number"
                variant="outlined"
                value={selectedRow?.length}
                onChange={handlePackageDetailsChange}
              />
            </Grid>

            <Grid item>
              <TextField
                name="width"
                label="Width (IN)"
                type="number"
                variant="outlined"
                value={selectedRow?.width}
                onChange={handlePackageDetailsChange}
              />
            </Grid>
            <Grid item>
              <TextField
                name="height"
                label="Height (IN)"
                type="number"
                variant="outlined"
                value={selectedRow?.height}
                onChange={handlePackageDetailsChange}
              />
            </Grid>
            <Grid item>
              <TextField
                name="weight"
                label="Weight (LB)"
                type="number"
                variant="outlined"
                value={selectedRow?.weight}
                onChange={handlePackageDetailsChange}
              />
            </Grid>
          </Grid>
          <Box
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ width: "150px" }}
              onClick={saveDetails}
              disabled={
                !(
                  selectedRow?.length &&
                  selectedRow?.width &&
                  selectedRow?.height &&
                  selectedRow?.weight
                )
              }
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginLeft: "10px", width: "150px" }}
              onClick={handleClose}
            >
              close
            </Button>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddSPPackageModal;
