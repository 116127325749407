import React, { useState, useRef , forwardRef, useImperativeHandle } from "react";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import { Button} from "@material-ui/core";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import '../styles'
import { toProperCase } from "../../../healpers/utilityFunctions";

export default forwardRef ( function Tables(props,ref) {

  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);


  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if(gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({onlySelected: true});
      else 
      gridApi.exportDataAsCsv();
    }
  }));

  const rowStyle = { background: 'white' };

  const EditButtonRenderer = (buttonProps) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        style={{ textTransform: "capitalize" }}
        onClick={() => {props.openEditModel(buttonProps?.data)}}
      >
        Edit
      </Button>
    );
  };

  const DeleteButtonRenderer = (buttonProps) => {
    return (
      <Button
        variant="contained"
        color="secondary"
        size="medium"
        style={{ textTransform: "capitalize" }}
        onClick={() => props.deleteConfirmation(buttonProps?.data)}
      >
        Delete
      </Button>
    );
  };

  return (
    <div className="ag-theme-material" style={{height: 620, width: '100%'}} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable:true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={50}
        rowStyle={rowStyle}
        frameworkComponents={{
          editButtonRenderer: EditButtonRenderer,
          deleteButtonRenderer: DeleteButtonRenderer,
        }}
        fullWidth
        suppressDragLeaveHidesColumns={true}
        suppressRowClickSelection={true}
      >
            <AgGridColumn headerName="Slide" field="slide" width={200}/>
            <AgGridColumn headerName="Created Date" field="createdAt" width={150} valueFormatter={parseDate}/>
            <AgGridColumn headerName="Description" field="description" width={250} valueFormatter={properCase}/>
            <AgGridColumn headerName="Owner" field="owner" width={250}/>
            {/* <AgGridColumn headerName="Assigned" field="assignedTo" width={250}/> */}
            <AgGridColumn headerName="Status" field="status" width={250}/>
            <AgGridColumn headerName="Due Date" field="dueDate" width={150} valueFormatter={parseDate}/>
            <AgGridColumn headerName="Closed Date" field="closedDate" width={150} valueFormatter={parseDate}/>
            <AgGridColumn headerName="Comments" field="comments" width={300} wrapText autoHeight valueFormatter={stringFromArray}/>
            <AgGridColumn width={100} cellRenderer="editButtonRenderer" cellEditorParams={{cellEditorParams:"editButtonRenderer"}} />
            <AgGridColumn width={100} cellRenderer="deleteButtonRenderer" cellEditorParams={{cellEditorParams:"deleteButtonRenderer"}} />
      </AgGridReact>
    </div>
  );
})


const stringFromArray = (params) => {
  if(params.value) {
    return params.value.map(comment => `${comment.comment}`).join("\n");
  }
}
const properCase = (params) => params.value && toProperCase(params.value);

const parseDate = (params) => params.value && new Date(params.value).toLocaleDateString();


