const SP_REPORT_TYPES = {
  SALES_TRAFFIC_REPORT: "SALES_TRAFFIC_REPORT",
  SALES_GEO_REPORT: "SALES_GEO_REPORT",
  VENDOR_SALES_REPORT: "VENDOR_SALES_REPORT",
}

const TIME_PERIOD = {
  MONTHLY: 'MONTHLY',
  WEEKLY: 'WEEKLY',
  QUARTERLY: 'QUARTERLY',
}

export {
  SP_REPORT_TYPES,
  TIME_PERIOD
}