import React, { useEffect, useState } from "react";
import {
  Breadcrumbs,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Link,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Chip,
  FormHelperText,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Switch,
} from "@mui/material";
import { styled } from "@mui/system";
import _ from "lodash";
import XLSX from "xlsx";
import { useLocation } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
import { default as ContentsTable } from "./components/Table/Table";
import EditContent from "./components/EditContent/EditContent";
import DeleteContent from "./components/DeleteContent/DeleteContent";
import {
  deleteContent,
  fetchCompetitorASINs,
  fetchContentRealtimeInfo,
  fetchContents,
  fetchCustomerConfig,
  fetchRelevantASINs,
  fetchTopKeywordsByASINs,
  generateContents,
  generateContentsReviewsSummary,
  generateProductName,
  saveContentChangelogs,
  saveContents,
  sendContentsMail,
} from "../../http/api";
import { useVendorState } from "../../context/VendorContext";
import Widget from "../../components/Widget/Widget";
import {
  CloseOutlined,
  ExpandMore,
  FileCopyOutlined,
} from "@material-ui/icons";
import { pdfjs } from "react-pdf";
import {
  CHATGPT_TITLE,
  CHATGPT_DESCRIPTION,
  CHATGPT_BULLET_POINTS,
  CHATGPT_TAG_LINES,
  CHATGPT_RECOMMENDATIONS,
  CREATION_STEPS,
  KEYWORDS_COUNT,
  REVIEWS_RATING_OPTIONS,
  REVIEWS_SORT_OPTIONS,
  REVIEWS_COUNT_OPTIONS,
  CHATGPT_PREFIX,
  CHATGPT_SUFFIX,
  STATUS,
  CHANGELOG_ACTION,
  BLACKLIST_KEYWORDS,
  RAPID_API_COUNTRY,
} from "../../constant/content";
import { BASE_URL } from "../../healpers/api";
import { useUserState } from "../../context/UserContext";
import { getChangelog, getHighlightIndices } from "../../healpers/content";
import {
  Document,
  Packer,
  Paragraph,
  SectionType,
  ShadingType,
  Table,
  TableCell,
  TableRow,
  TextRun,
} from "docx";
import { saveAs } from "file-saver";
import KeywordsTable from "./components/KeywordsTable/KeywordsTable";
import TextInputCounter from "./components/TextInputCounter/TextInputCounter";
import KeywordsHighlight from "./components/KeywordsHighlight/KeywordsHighlight";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const asinFeatureSeparator = (title = "") => {
  if (!title) return "";
  const nonAlphanumericChars = title.replace(/[a-zA-Z0-9\s]/g, "");
  const charCount = {};
  let maxChar = "";

  [...nonAlphanumericChars].forEach((char) => {
    charCount[char] = (charCount[char] || 0) + 1;

    if (!maxChar || charCount[char] > charCount[maxChar]) {
      maxChar = char;
    }
  });

  return maxChar;
};

const getAsinFeatures = (brandName, title = "") => {
  if (!title) return [];

  // Remove brand name
  title = title.replaceAll(new RegExp(`\\b${brandName}\\b`, "gi"), "");
  // Replace space separated hyphens between words with comma
  title = title.replace(/([a-zA-Z]) - ([a-zA-Z])/g, "$1, $2");
  // Replace | with comma
  title = title.replaceAll("|", ",");

  return title
    .split(",")
    .map(
      (item) =>
        item
          .trim()
          .replace(/^-+|-+$/g, "") // Remove trailing hyphens
          .replace(/  +/g, " ") // Remove trailing spaces
    )
    .filter((item) => item);
};

const getBulletedText = (text = []) => {
  return text.length > 0 ? "\u2022\u00A0" + text.join("\n\u2022\u00A0") : "";
};

const StepContentContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
});

const StepActionsContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  padding: "0px 8px",
});

const StepActionButton = ({ label, ...props }) => {
  return (
    <Button color="primary" variant="contained" size="small" {...props}>
      {label}
    </Button>
  );
};

const ProductInfoContainer = ({ product, highlight = false }) => {
  return (
    <>
      <img alt="" width={30} height={30} src={product.image} loading="lazy" />
      <Typography>{`${product.asin}${
        highlight && product.contentExists ? "*" : ""
      } - ${product.title}`}</Typography>
    </>
  );
};

const CustomTextField = ({
  label,
  shrinkLabel,
  value,
  placeholder = "",
  readOnly = false,
  multiline = false,
  minRows = 2,
  onChange,
  width,
}) => {
  return (
    <TextField
      multiline={multiline}
      minRows={multiline ? minRows : undefined}
      label={label}
      InputLabelProps={{
        shrink: shrinkLabel,
      }}
      placeholder={placeholder}
      InputProps={{
        readOnly: readOnly,
      }}
      variant="outlined"
      value={value}
      onChange={(e) => (readOnly ? undefined : onChange(e))}
      style={{
        width: width,
      }}
    />
  );
};

const CustomAutocompleteTextField = ({
  label,
  value = [],
  placeholder = "",
  onChange,
  readOnly = false,
  width,
  helperText = "",
  copy = [],
  copyAll = "",
}) => {
  return (
    <Autocomplete
      multiple
      freeSolo
      options={[]}
      value={value}
      readOnly={readOnly}
      onChange={(e, value) => onChange?.(e, value)}
      renderTags={(value, getTagProps) => (
        <div>
          {copyAll && (
            <Typography
              variant="caption"
              component="div"
              onClick={async () => {
                await navigator.clipboard.writeText(copyAll);
              }}
              style={{
                cursor: "pointer",
                color: "#4B50DC",
              }}
            >
              Copy all
            </Typography>
          )}
          {value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              icon={
                copy[index] ? (
                  <FileCopyOutlined
                    onClick={async () => {
                      await navigator.clipboard.writeText(copy[index]);
                    }}
                  />
                ) : undefined
              }
              size="small"
              style={{
                border: "1px solid #4B50DC",
                borderRadius: "16px",
              }}
            />
          ))}
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          helperText={helperText}
          variant="outlined"
          style={{
            margin: "8px 0px",
          }}
        />
      )}
      style={{
        width: width,
      }}
    />
  );
};

const ReviewOptionsSelectionMenu = ({ label, value, menus, onClick }) => {
  return (
    <TextField
      select
      label={label}
      value={value}
      variant="outlined"
      style={{
        width: "12%",
        margin: "8px 0px",
      }}
    >
      {menus.map((item) => (
        <MenuItem
          key={item.value}
          value={item.value}
          onClick={() => onClick(item)}
        >
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

const AccordionContainer = ({ title, detail }) => {
  return (
    <Accordion
      style={{
        boxShadow: "none",
        border: "1px solid #DADADA",
        borderRadius: "4px",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography color="textSecondary">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{detail}</AccordionDetails>
    </Accordion>
  );
};

const StepPreviewElementsItemContainer = styled("div")({
  display: "flex",
  gap: "4px",
});

const StepPreviewElementsItemLabelContainer = styled(Typography)({
  width: "15%",
  textAlign: "right",
});

const StepPreviewElementsItemValueContainer = styled("div")({
  display: "flex",
  gap: "8px",
  width: "85%",
});

export default function UpdateContent() {
  const location = useLocation();
  const userState = useUserState();
  const vendorState = useVendorState();
  const [contents, setContents] = useState([]);

  const [showEditContent, setShowEditContent] = useState(false);
  const [editContent, setEditContent] = useState({});

  const [showDeleteContent, setShowDeleteContent] = useState(false);
  const [deleteContentData, setDeleteContentData] = useState({});

  const [showSendMail, setShowSendMail] = useState(false);
  const [mailContent, setMailContent] = useState({
    to: [],
    subject: "SalesDuo - Attention needed for contents update",
    message: "",
    link:
      BASE_URL.replace(
        /:[0-9]+/,
        BASE_URL.includes("localhost") ? ":3000" : ""
      ) + "/app/content/approve",
  });
  const [mailAsins, setMailAsins] = useState([]);

  const [showCreateContent, setShowCreateContent] = useState(
    (location.state?.asins || []).length > 0
  );
  const [isOnboardingMode, setIsOnboardingMode] = useState(false);
  const [country, setCountry] = useState("US");
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [providedAsins, setProvidedAsins] = useState(
    (location.state?.asins || []).join(",")
  );
  const [fetchedAsins, setFetchedAsins] = useState([]);
  const [selectedAsins, setSelectedAsins] = useState([]);
  const [competitorAsins, setCompetitorAsins] = useState([]);
  const [selectedCompetitorAsins, setSelectedCompetitorAsins] = useState([]);
  const [reviewsRatingOption, setReviewsRatingOption] = useState(
    REVIEWS_RATING_OPTIONS.find((item) => item.value === "ALL")
  );
  const [reviewsSortOption, setReviewsSortOption] = useState(
    REVIEWS_SORT_OPTIONS.find((item) => item.value === "TOP_REVIEWS")
  );
  const [reviewsCountOption, setReviewsCountOption] = useState(
    REVIEWS_COUNT_OPTIONS.find((item) => item.value === 50)
  );
  const [showLiveContent, setShowLiveContent] = useState(false);
  const [previewContents, setPreviewContents] = useState([]);
  const [selectedPreviewContent, setSelectedPreviewContent] = useState({});
  const [selectedAsinsLiveContent, setSelectedAsinsLiveContent] = useState([]);

  const [chatGptPromptParts, setChatGptPromptParts] = useState({
    prefix: CHATGPT_PREFIX,
    productName: "",
    brandName: "",
    variations: [],
    title: CHATGPT_TITLE,
    description: CHATGPT_DESCRIPTION,
    bulletPoints: CHATGPT_BULLET_POINTS,
    tagLines: CHATGPT_TAG_LINES,
    recommendations: CHATGPT_RECOMMENDATIONS,
    reviewsSummary: {},
    commonFeatures: [],
    keywords: [],
    hiddenKeywords: "",
    websites: [],
    rawDescription: "",
    fileReferences: "",
    suffix: CHATGPT_SUFFIX,
  });

  const loadContents = async (vendorCode) => {
    try {
      const { status, data } = await fetchContents(vendorCode);
      if (status === "Success") {
        setContents(data);
      } else {
        setContents([]);
      }
    } catch (err) {
      console.log(err);
      setContents([]);
    }
  };

  const loadLiveContents = async () => {
    const asins = _.difference(
      selectedAsins.map((item) => item.asin),
      selectedAsinsLiveContent.map((item) => item.asin)
    );
    if (asins.length === 0) return;
    try {
      setShowLoader(true);
      const { status, data } = await fetchContentRealtimeInfo(asins.join(","), RAPID_API_COUNTRY.find((item) => item.value === country).label);
      setShowLoader(false);
      if (status === "Success") {
        setSelectedAsinsLiveContent(
          _.uniqBy([..._.cloneDeep(selectedAsinsLiveContent), ...data]),
          "asin"
        );
      } else {
        setSelectedAsinsLiveContent([]);
      }
    } catch (err) {
      console.log(err);
      setShowLoader(false);
      setSelectedAsinsLiveContent([]);
    }
  };

  const showMessage = (message) => {
    setMessage(message);
    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const resetStep = (stepNumber) => {
    switch (stepNumber) {
      case 1:
        setIsOnboardingMode(false);
        setCountry("US");
        setProvidedAsins("");
        break;
      case 2:
        setFetchedAsins([]);
        setSelectedAsins([]);
        setSelectedAsinsLiveContent([]);
        setCompetitorAsins([]);
        setSelectedCompetitorAsins([]);
        setReviewsRatingOption(
          REVIEWS_RATING_OPTIONS.find((item) => item.value === "ALL")
        );
        setReviewsSortOption(
          REVIEWS_SORT_OPTIONS.find((item) => item.value === "TOP_REVIEWS")
        );
        setReviewsCountOption(
          REVIEWS_COUNT_OPTIONS.find((item) => item.value === 50)
        );
        break;
      case 3:
        setChatGptPromptParts({
          ...chatGptPromptParts,
          reviewsSummary: {},
          productName: "",
          brandName: "",
          commonFeatures: [],
          keywords: [],
          hiddenKeywords: "",
          variations: [],
          websites: [],
          rawDescription:
            selectedAsins.find((item) => item.description?.length > 0)
              ?.description || "",
          fileReferences: "",
        });
        break;
      case 4:
        setChatGptPromptParts({
          ...chatGptPromptParts,
          title: CHATGPT_TITLE,
          description: CHATGPT_DESCRIPTION,
          bulletPoints: CHATGPT_BULLET_POINTS,
          tagLines: CHATGPT_TAG_LINES,
          recommendations: CHATGPT_RECOMMENDATIONS,
        });
        break;
      case 5:
        setSelectedPreviewContent({});
        setPreviewContents([]);
        setShowLiveContent(false);
        break;
      default:
        break;
    }
  };

  const resetAllSteps = () => {
    CREATION_STEPS.forEach((step, index) => {
      resetStep(CREATION_STEPS.length - index);
    });
    setActiveStepIndex(0);
  };

  const handleStepperNext = async () => {
    switch (activeStepIndex + 1) {
      case 1:
        setShowLoader(true);
        const relevantASINs = await fetchRelevantASINs(
          isOnboardingMode ? "ONBOARDING" : vendorState.selected,
          providedAsins,
          RAPID_API_COUNTRY.find((item) => item.value === country).label,
          isOnboardingMode
        );
        setShowLoader(false);
        if (
          relevantASINs.status === "Success" &&
          relevantASINs.data?.length > 0
        ) {
          if (
            _.uniq(relevantASINs.data.map((item) => item.productType))
              .length === 1
          ) {
            setFetchedAsins(relevantASINs.data);
            setShowLoader(true);
            const competitorASINs = await fetchCompetitorASINs(providedAsins, RAPID_API_COUNTRY.find((item) => item.value === country).label);
            setShowLoader(false);
            if (competitorASINs.status === "Success") {
              setCompetitorAsins(competitorASINs.data);
              setSelectedCompetitorAsins(
                competitorASINs.data.map((item) => item.asin).slice(0, 5)
              );
            }
            setActiveStepIndex(activeStepIndex + 1);
          } else {
            showMessage(
              `Provided ASINs contains different product types. Verify the ASIN and try again.`
            );
          }
        } else {
          showMessage(
            `No ASINs found for "${providedAsins}". Verify the ASIN and try again.${
              relevantASINs?.message || ""
            }`
          );
        }
        break;
      case 2:
        const topKeywords = [];
        setShowLoader(true);
        const productName = await generateProductName(
          selectedAsins
            .filter((item) => item.title?.length > 0)
            .map((item) => item.title)
        );
        const topKeywordsByASINs = await fetchTopKeywordsByASINs(
          selectedAsins.map((item) => item.asin).join(","),
          selectedCompetitorAsins.join(","),
          RAPID_API_COUNTRY.find((item) => item.value === country).label,
          100
        );
        const reviewsSummary = await generateContentsReviewsSummary(
          selectedCompetitorAsins,
          RAPID_API_COUNTRY.find((item) => item.value === country).label,
          reviewsRatingOption.value,
          reviewsSortOption.value,
          reviewsCountOption.value
        );
        setShowLoader(false);
        if (topKeywordsByASINs.status === "Success") {
          topKeywordsByASINs.data.forEach((asin) =>
            topKeywords.push(
              ...asin.keywords.filter(
                (item) =>
                  !BLACKLIST_KEYWORDS.some((blacklistedKeyword) =>
                    item.keyword.includes(blacklistedKeyword)
                  )
              )
            )
          );
        }
        const keywords = _.sortBy(
          _.uniqBy(topKeywords, "keyword"),
          "searchVolume"
        )
          .reverse()
          .slice(0, KEYWORDS_COUNT);
        const features = selectedAsins.map((asin) =>
          getAsinFeatures(
            selectedAsins.find((item) => item.brandName?.length > 0).brandName,
            asin?.title
          )
        );
        const hiddenKeywords = _.sortBy(
          Object.entries(
            _.countBy(
              keywords
                .map((item) => item.keyword)
                .join(" ")
                .toLowerCase()
                .split(" ")
            )
          ),
          1
        )
          .reverse()
          .slice(0, KEYWORDS_COUNT)
          .map((item) => item[0])
          .join(" ");
        const commonFeatures = _.intersection(...features);
        setChatGptPromptParts({
          ...chatGptPromptParts,
          productName:
            _.uniq(productName.data)[0] ||
            selectedAsins
              .find((item) => item.title?.length > 0)
              .title.split(
                asinFeatureSeparator(
                  selectedAsins.find((item) => item.title?.length > 0).title
                )
              )[0],
          brandName: selectedAsins.find((item) => item.brandName?.length > 0)
            .brandName,
          rawDescription:
            selectedAsins.find((item) => item.description?.length > 0)
              ?.description || "",
          keywords: keywords,
          hiddenKeywords: hiddenKeywords,
          commonFeatures: commonFeatures,
          variations: features.map((item) =>
            _.difference(item, commonFeatures)
          ),
        });
        if (reviewsSummary.status === "Success") {
          setChatGptPromptParts((previousState) => ({
            ...previousState,
            reviewsSummary: reviewsSummary.data,
          }));
          setActiveStepIndex(activeStepIndex + 1);
        } else {
          showMessage(
            `Cannot generate reviews summary for "${selectedCompetitorAsins.join(
              ","
            )}". Verify the ASINs and try again.${
              reviewsSummary?.message || ""
            }`
          );
        }
        break;
      case 3:
        setActiveStepIndex(activeStepIndex + 1);
        break;
      case 4:
        setShowLoader(true);
        const generatedContents = await generateContents(
          isOnboardingMode ? "ONBOARDING" : vendorState.selected,
          selectedAsins,
          chatGptPromptBuilder()
        );
        setShowLoader(false);
        if (generatedContents.status === "Success") {
          setPreviewContents(generatedContents.data);
          setSelectedPreviewContent(
            _.cloneDeep(generatedContents.data?.[0]) || {}
          );
          if (generatedContents.data?.length > 0) {
            setActiveStepIndex(activeStepIndex + 1);
            if (Object.keys(generatedContents.data?.[0] || {}).length !== 6) {
              showMessage("Generated contents malformed. Try again.");
            }
          } else {
            showMessage(
              `Unable to generate contents for "${selectedAsins
                .map((item) => item.asin)
                .join(
                  ","
                )}". Verify the selected ASINs / references and try again.`
            );
          }
        } else {
          showMessage(
            `Unable to generate contents for "${selectedAsins
              .map((item) => item.asin)
              .join(",")}". Try again.${generatedContents?.message || ""}`
          );
        }
        break;
      case 5:
        setShowLoader(true);
        const saveContentsData = previewContents.map((item, index) => {
          return {
            vendorCode: vendorState.selected,
            asin: item.asin,
            title: item.title,
            description: item.description,
            bulletPoints: item.bulletPoints,
            keywords: _.uniqBy(chatGptPromptParts.keywords, "keyword"),
            hiddenKeywords: chatGptPromptParts.hiddenKeywords,
            tagLines: item.tagLines,
            recommendations: item.recommendations,
            status: STATUS.CONTENT_GENERATED,
            internallyApproved: false,
            internalFeedback: [],
            feedback: [],
            createdAt: new Date(),
            createdBy: userState?.userData?.email,
            internallyApprovedBy: "",
            approvedBy: "",
          };
        });
        const saveContent = await saveContents(
          vendorState.selected,
          saveContentsData
        );
        setShowLoader(false);
        if (saveContent.status === "Success") {
          const changelogs = [];

          previewContents.forEach((content) => {
            let action = "";
            let beforeChange = {};

            if (contents.map((item) => item.asin).includes(content.asin)) {
              const existingContent = contents.find(
                (item) => item.asin === content.asin
              );

              action = CHANGELOG_ACTION.RECREATE;
              beforeChange = {
                Title: existingContent.title,
                Description: existingContent.description,
                "Bullet Points": existingContent.bulletPoints,
                Keywords: existingContent.keywords.map((item) =>
                  typeof item === "string" ? item : item.keyword
                ),
                "Hidden Keywords": existingContent.hiddenKeywords || "",
                "Tag Lines": existingContent.tagLines,
                Recommendations: existingContent.recommendations,
                Status: existingContent.status,
              };
            } else {
              action = CHANGELOG_ACTION.CREATE;
            }

            changelogs.push({
              asin: content.asin,
              vendorCode: vendorState?.selected,
              action,
              beforeChange,
              afterChange: {
                Title: content.title,
                Description: content.description,
                "Bullet Points": content.bulletPoints,
                Keywords: chatGptPromptParts.keywords.map((item) =>
                  typeof item === "string" ? item : item.keyword
                ),
                "Hidden Keywords": chatGptPromptParts.hiddenKeywords,
                "Tag Lines": content.tagLines,
                Recommendations: content.recommendations,
              },
              user: userState?.userData?.email,
            });
          });
          setMessage(saveContent.data);
          setTimeout(() => {
            setMessage("");
            setShowCreateContent(false);
            resetAllSteps();
          }, 3000);
          await loadContents(vendorState.selected);
          saveContentChangelogs(vendorState?.selected, changelogs);
        } else {
          showMessage(
            saveContent?.message ||
              "Error on saving generated content. Try again."
          );
        }
        break;
      default:
        break;
    }
  };

  const handleStepperBack = () => {
    resetStep(activeStepIndex + 1);
    setActiveStepIndex(activeStepIndex - 1);
  };

  const handleExport = () => {
    const exportFields = {
      asin: "ASIN",
      title: "Title",
      description: "Description",
      bulletPoints: "Bullet Points",
      tagLines: "Tag Lines",
      recommendations: "Recommendations",
    };

    const exportContentsData = previewContents.map((item) => {
      const mappedItem = {};
      Object.keys(exportFields).forEach((key) => {
        if (item[key] !== undefined) {
          mappedItem[exportFields[key]] = _.isArray(item[key])
            ? item[key].join("\n")
            : item[key];
        }
      });
      return mappedItem;
    });

    const worksheet = XLSX.utils.json_to_sheet(exportContentsData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Contents");

    const exportFile = `Contents_${
      isOnboardingMode ? "Onboarding" : vendorState?.selected
    }.xlsx`;
    XLSX.writeFile(workbook, exportFile);

    const changelogs = [];

    previewContents.forEach((content) => {
      changelogs.push({
        asin: content.asin,
        vendorCode: isOnboardingMode ? "ONBOARDING" : vendorState?.selected,
        action: CHANGELOG_ACTION.EXPORT,
        afterChange: {
          Title: content.title,
          Description: content.description,
          "Bullet Points": content.bulletPoints,
          Keywords: chatGptPromptParts.keywords.map((item) => item.keyword),
          "Hidden Keywords": chatGptPromptParts.hiddenKeywords,
          "Tag Lines": content.tagLines,
          Recommendations: content.recommendations,
        },
        user: userState?.userData?.email,
      });
    });

    saveContentChangelogs(
      isOnboardingMode ? "ONBOARDING" : vendorState?.selected,
      changelogs
    );
  };

  const handleExportDoc = async () => {
    const { data } = await fetchContentRealtimeInfo(
      previewContents.map((item) => item.asin).join(","),
      RAPID_API_COUNTRY.find((item) => item.value === country).label
    );
    const keywords = chatGptPromptParts.keywords.map((item) =>
      typeof item === "string" ? item : item.keyword
    );

    for (const previewContent of previewContents) {
      const titleHighlightIndices = previewContent.title
        .split("")
        .map((item, index) => {
          return {
            term: item,
            highlight: getHighlightIndices(
              previewContent.title,
              keywords,
              true
            ).includes(index),
          };
        });

      const descriptionHighlightIndices = previewContent.description
        .split("")
        .map((item, index) => {
          return {
            term: item,
            highlight: getHighlightIndices(
              previewContent.description,
              keywords,
              true
            ).includes(index),
          };
        });

      const bulletPointsHighlightIndices = previewContent.bulletPoints.map(
        (bulletPoint) => {
          return bulletPoint.split("").map((item, index) => {
            return {
              term: item,
              highlight: getHighlightIndices(
                bulletPoint,
                keywords,
                true
              ).includes(index),
            };
          });
        }
      );

      const bulletPointHighlightIndices = [];
      bulletPointsHighlightIndices.forEach((item) => {
        bulletPointHighlightIndices.push({
          term: "• ",
          highlight: false,
        });
        bulletPointHighlightIndices.push(...item);
        bulletPointHighlightIndices.push({
          term: "\n",
          highlight: false,
        });
      });

      const DocTextColorRed = "#FF0000";
      const DocTextColorBlue = "#0000FF";

      const DocHorizontalLine = () => {
        return new Paragraph({
          text: "",
          thematicBreak: true,
        });
      };

      const DocLineBreak = () => {
        return new Paragraph({
          children: [
            new TextRun({
              text: "",
            }),
          ],
        });
      };

      const DocHeader = (header) => {
        return new Paragraph({
          children: [
            new TextRun({
              text: header,
              bold: true,
              underline: {},
            }),
          ],
        });
      };

      const DocTableCell = (value) => {
        return new TableCell({
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: `${value}`,
                  color: DocTextColorRed,
                }),
              ],
            }),
          ],
        });
      };

      const asinContentDocument = new Document({
        styles: {
          default: {
            document: {
              run: {
                size: `${11 * 2}`, // Font size is 11, but need to double it due to docx standards
                font: "Calibri",
              },
            },
          },
        },
        sections: [
          {
            properties: {
              type: SectionType.CONTINUOUS,
            },
            children: [
              new Paragraph({
                shading: {
                  type: ShadingType.SOLID,
                  color: "F4CCCC",
                  fill: "000000",
                },
                children: [
                  new TextRun({
                    text:
                      "Please review the curated content in RED and BLUE. The BLUE texts are search terms and keywords that have been added for SEO optimization. The RED text is the revised copy. ",
                  }),
                  new TextRun({
                    text: "Kindly focus on evaluating the RED and BLUE text. ",
                    bold: true,
                  }),
                  new TextRun({
                    text:
                      "The BLACK text represents your current Amazon content and requires no review. Thank you.",
                  }),
                  new TextRun({
                    text: "",
                    break: 2,
                  }),
                  new TextRun({
                    text: "The SEO Amazon guidelines are as follows: ",
                  }),
                  new TextRun({
                    text: "",
                    break: 1,
                  }),
                  new TextRun({
                    text:
                      "• The character limit per bullet point (For the “About the Product” section) is 255 characters.",
                    break: 1,
                  }),
                  new TextRun({
                    text:
                      "• The word limit for the “Long Description” is about 200 words.",
                    break: 1,
                  }),
                  new TextRun({
                    text:
                      "• The character limit for the “Title” is 200 characters but it is recommended to limit it to 160 characters for better optimization.",
                    break: 1,
                  }),
                  new TextRun({
                    text:
                      "• The “About the Product” section involves incorporating 5 key bullet points that highlight the core features and benefits of the product that instantly capture attention.",
                    break: 1,
                  }),
                ],
              }),
              DocHorizontalLine(),
              DocLineBreak(),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "ASIN:",
                    bold: true,
                    underline: {},
                  }),
                  new TextRun({
                    text: ` ${previewContent.asin}`,
                  }),
                ],
              }),
              DocLineBreak(),
              DocHeader("Revised Title:"),
              DocLineBreak(),
              new Paragraph({
                children: [
                  ...titleHighlightIndices.map(
                    (item) =>
                      new TextRun({
                        text: item.term,
                        color: item.highlight
                          ? DocTextColorBlue
                          : DocTextColorRed,
                      })
                  ),
                ],
              }),
              DocLineBreak(),
              DocHeader("Revised About the Product:"),
              DocLineBreak(),
              new Paragraph({
                children: bulletPointHighlightIndices.map(
                  (item) =>
                    new TextRun({
                      text: item.term === "\n" ? "" : item.term,
                      color: item.highlight
                        ? DocTextColorBlue
                        : DocTextColorRed,
                      break: item.term === "\n" ? 1 : undefined,
                    })
                ),
              }),
              DocHeader("Revised Product Description:"),
              DocLineBreak(),
              new Paragraph({
                children: descriptionHighlightIndices.map(
                  (item) =>
                    new TextRun({
                      text: item.term,
                      color: item.highlight
                        ? DocTextColorBlue
                        : DocTextColorRed,
                    })
                ),
              }),
              DocLineBreak(),
              DocHeader("Recommended Visible Keywords:"),
              DocLineBreak(),
              new Table({
                columnWidths: [4700, 1646, 1646, 1646], // Total page width is 9638 for A4 portrait, widths splitted based on columns content
                rows: [
                  new TableRow({
                    children: [
                      DocTableCell("Keyword Phrases"),
                      DocTableCell("Search Volume"),
                      DocTableCell("Search Volume Trend"),
                      DocTableCell("Competing Products"),
                    ],
                  }),
                  ...chatGptPromptParts.keywords.map(
                    (item) =>
                      new TableRow({
                        children: [
                          DocTableCell(item.keyword),
                          DocTableCell(item.searchVolume),
                          DocTableCell(item.searchVolumeTrend),
                          DocTableCell(item.competingProducts),
                        ],
                      })
                  ),
                ],
              }),
              DocLineBreak(),
              DocHeader("Hidden Keywords:"),
              DocLineBreak(),
              new Paragraph({
                children: [
                  new TextRun({
                    text: chatGptPromptParts.hiddenKeywords,
                    color: DocTextColorRed,
                  }),
                ],
              }),
              DocLineBreak(),
              DocHeader("Product Taglines:"),
              new Paragraph({
                children: previewContent.tagLines.map((item) => {
                  return new TextRun({
                    text: "• " + item,
                    color: DocTextColorRed,
                    break: 1,
                  });
                }),
              }),
              DocLineBreak(),
              DocHeader("Product Recommendations:"),
              new Paragraph({
                children: previewContent.recommendations.map((item) => {
                  return new TextRun({
                    text: "• " + item,
                    color: DocTextColorRed,
                    break: 1,
                  });
                }),
              }),
              DocHorizontalLine(),
              DocLineBreak(),
              DocHeader("Title:"),
              DocLineBreak(),
              new Paragraph({
                children: [
                  new TextRun({
                    text: data.find((item) => item.asin === previewContent.asin)
                      ?.title,
                  }),
                ],
              }),
              DocLineBreak(),
              DocHeader("About the Product:"),
              new Paragraph({
                children: data
                  .find((item) => item.asin === previewContent.asin)
                  ?.bulletPoints?.map((item) => {
                    return new TextRun({
                      text: "• " + item,
                      break: 1,
                    });
                  }),
              }),
              DocLineBreak(),
              DocHeader("Product Description:"),
              DocLineBreak(),
              new Paragraph({
                children: [
                  new TextRun({
                    text: data.find((item) => item.asin === previewContent.asin)
                      ?.description,
                  }),
                ],
              }),
            ],
          },
        ],
      });
      Packer.toBlob(asinContentDocument).then((asinContentDocumentBlob) => {
        saveAs(
          asinContentDocumentBlob,
          `Contents_${
            isOnboardingMode ? "ONBOARDING" : vendorState?.selected
          }_${previewContent.asin}.docx`
        );
      });
    }

    const changelogs = [];

    previewContents.forEach((content) => {
      changelogs.push({
        asin: content.asin,
        vendorCode: isOnboardingMode ? "ONBOARDING" : vendorState?.selected,
        action: CHANGELOG_ACTION.EXPORT,
        afterChange: {
          Title: content.title,
          Description: content.description,
          "Bullet Points": content.bulletPoints,
          Keywords: chatGptPromptParts.keywords.map((item) => item.keyword),
          "Hidden Keywords": chatGptPromptParts.hiddenKeywords || "",
          "Tag Lines": content.tagLines,
          Recommendations: content.recommendations,
        },
        user: userState?.userData?.email,
      });
    });

    saveContentChangelogs(
      isOnboardingMode ? "ONBOARDING" : vendorState?.selected,
      changelogs
    );
  };

  const handleEditContent = (content) => {
    setEditContent(_.cloneDeep(content));
    setShowEditContent(true);
  };

  const handleDeleteContent = async () => {
    setShowDeleteContent(false);
    setShowLoader(true);
    const contentDelete = await deleteContent(
      deleteContentData?.vendorCode,
      deleteContentData?.asin
    );
    setShowLoader(false);
    showMessage(contentDelete.data);
    if (contentDelete.status === "Success") {
      const changelogs = [];
      changelogs.push({
        asin: deleteContentData.asin,
        vendorCode: deleteContentData.vendorCode,
        action: CHANGELOG_ACTION.DELETE,
        ...getChangelog(deleteContentData, {}),
        attributes: [],
        afterChange: {},
        user: userState?.userData?.email,
      });
      await loadContents(deleteContentData?.vendorCode);
      saveContentChangelogs(deleteContentData?.vendorCode, changelogs);
    }
  };

  const handleSendMailClose = () => {
    setShowSendMail(false);
    setMailContent({
      ...mailContent,
      subject: "SalesDuo - Attention needed for contents update",
      message: "",
      to: [],
    });
    setMailAsins([]);
  };

  const handleCreateContentClose = () => {
    setShowCreateContent(false);
    resetAllSteps();
  };

  const chatGptPromptBuilder = () => {
    let prompt = "";
    prompt = prompt + chatGptPromptParts.prefix;
    prompt = prompt + `\nProduct: ${chatGptPromptParts.productName}`;
    if (chatGptPromptParts.brandName.length > 0)
      prompt = prompt + `\nBrand: ${chatGptPromptParts.brandName}`;
    prompt =
      prompt +
      `\nKeywords: ${JSON.stringify(
        _.uniq(chatGptPromptParts.keywords.map((item) => item.keyword)),
        null,
        2
      )}`;
    prompt =
      prompt +
      `\nVariations: ${JSON.stringify(
        chatGptPromptParts.variations.map((item, index) => ({
          asin: selectedAsins[index].asin,
          features: _.uniq([
            ...chatGptPromptParts.commonFeatures,
            ...chatGptPromptParts.variations[index],
          ]),
        })),
        null,
        2
      )}`;
    prompt =
      prompt +
      `\nReviews: ${JSON.stringify(
        chatGptPromptParts.reviewsSummary,
        null,
        2
      )}`;
    if (chatGptPromptParts.rawDescription.length > 0)
      prompt =
        prompt + `\nProduct details: ${chatGptPromptParts.rawDescription}`;
    if (chatGptPromptParts.fileReferences.length > 0)
      prompt =
        prompt +
        `\nAdditional product details: ${chatGptPromptParts.fileReferences}`;
    if (chatGptPromptParts.websites.length > 0)
      prompt =
        prompt +
        `\nProduct details from websites: ${JSON.stringify(
          chatGptPromptParts.websites
        )}`;
    prompt = prompt + `\nFollow below constraints:`;
    prompt =
      prompt +
      `\nTitle:\n${chatGptPromptParts.title}\nDescription:\n${chatGptPromptParts.description}\nBullet Points:\n${chatGptPromptParts.bulletPoints}\nTag Lines:\n${chatGptPromptParts.tagLines}\nRecommendations:\n${chatGptPromptParts.recommendations}`;
    prompt = prompt + `${chatGptPromptParts.suffix}`;
    return prompt;
  };

  useEffect(() => {
    if (vendorState.selected) {
      loadContents(vendorState.selected);
      setCountry(vendorState.country)
    }
  }, [vendorState.selected]);

  useEffect(() => {
    const newContents = mailAsins.filter(
      (item) =>
        item.status === STATUS.CONTENT_GENERATED && item.internallyApproved
    );
    const feedbackAddressedContents = mailAsins.filter(
      (item) =>
        item.status === STATUS.FEEDBACK_ADDRESSED && item.internallyApproved
    );
    if (newContents.length > 0 && feedbackAddressedContents.length > 0) {
      setMailContent({
        ...mailContent,
        message: `There are ${newContents.length} new contents created and ${feedbackAddressedContents.length} contents feedbacks have been addressed based on the feedback provided.`,
      });
    } else if (newContents.length > 0) {
      setMailContent({
        ...mailContent,
        message: `There are ${newContents.length} new contents created.`,
      });
    } else if (feedbackAddressedContents.length > 0) {
      setMailContent({
        ...mailContent,
        message: `There are ${feedbackAddressedContents.length} contents updated based on the feedback provided.`,
      });
    } else {
      setMailContent({
        ...mailContent,
        message: "",
      });
    }
  }, [mailAsins]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Update Content"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit">Content Revisions</Link>
              <Typography color="primary">Update Content</Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {showEditContent && (
              <EditContent
                content={editContent}
                country={country}
                reloadContents={async (vendorCode) =>
                  await loadContents(vendorCode)
                }
                handleClose={() => setShowEditContent(false)}
              />
            )}
            {showDeleteContent && (
              <DeleteContent
                content={deleteContentData}
                handleConfirm={handleDeleteContent}
                handleCancel={() => {
                  setDeleteContentData({});
                  setShowDeleteContent(false);
                }}
              />
            )}
            <Dialog
              open={showCreateContent}
              onClose={handleCreateContentClose}
              maxWidth="xl"
              fullWidth={true}
              PaperProps={{
                style: {
                  height: "80vh",
                },
              }}
              scroll="paper"
            >
              <DialogTitle>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {"Create Content"}
                  <CloseOutlined
                    onClick={handleCreateContentClose}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </div>

                <Stepper
                  activeStep={activeStepIndex}
                  style={{ padding: "16px 0px" }}
                >
                  {CREATION_STEPS.map((step, index) => (
                    <Step key={index}>
                      <StepLabel>{step}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {showLoader && <LinearProgress style={{ margin: "0px 8px" }} />}
                <Typography style={{ margin: "0px", padding: "0px 8px" }}>
                  {message}
                </Typography>
                <Divider style={{ margin: "4px 8px" }} />
              </DialogTitle>
              <DialogContent
                style={{
                  padding: "4px 24px",
                }}
              >
                {activeStepIndex === 0 && (
                  <StepContentContainer>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography>
                        Enter the ASIN to get the relevant ASINs for proceeding
                        with content creation.
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isOnboardingMode}
                            onChange={(e) => {
                              setIsOnboardingMode(e.target.checked);
                            }}
                          />
                        }
                        label="Onboarding Mode"
                        labelPlacement="start"
                        title="Enable for prospective customers awaiting onboarding.&#10;NOTE: Save option is not available in this mode."
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                    <CustomTextField
                      label="ASINs *"
                      value={providedAsins}
                      placeholder="Enter ASINs (separated by comma)"
                      onChange={(e) => setProvidedAsins(e.target.value)}
                      width={"60%"}
                    />
                    <TextField
                        select
                        label={"Country"}
                        value={country}
                        variant="outlined"
                      >
                        {RAPID_API_COUNTRY.map((item) => (
                          <MenuItem
                            key={item.value}
                            value={item.value}
                            onClick={() => setCountry(item.value)}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </StepContentContainer>
                )}
                {activeStepIndex === 1 && (
                  <StepContentContainer>
                    <Typography>{`Found ${fetchedAsins.length} related ASINs for "${providedAsins}". Select the ASINs to create content.`}</Typography>
                    {fetchedAsins.filter((item) => item.contentExists).length >
                      0 && (
                      <Typography>
                        Note: Proceed with caution when choosing items
                        identified with * near the asin, as selecting them will
                        overwrite their current content.
                      </Typography>
                    )}
                    <div
                      style={{
                        padding: "8px 4px",
                        maxHeight: "36vh",
                        overflow: "scroll",
                      }}
                    >
                      <FormControl
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <Checkbox
                          color="primary"
                          checked={fetchedAsins.length === selectedAsins.length}
                          onChange={(event) => {
                            if (event.target.checked) {
                              setSelectedAsins(_.cloneDeep(fetchedAsins));
                            } else {
                              setSelectedAsins([]);
                            }
                          }}
                        />
                        <Typography>Select all</Typography>
                      </FormControl>
                      {fetchedAsins.map((item, index) => {
                        return (
                          <FormControl
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <Checkbox
                              color="primary"
                              checked={selectedAsins
                                .map((item) => item.asin)
                                .includes(item.asin)}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  setSelectedAsins(
                                    [...selectedAsins].concat([item])
                                  );
                                } else {
                                  setSelectedAsins(
                                    [...selectedAsins].filter(
                                      (selectedItem) =>
                                        selectedItem.asin !== item.asin
                                    )
                                  );
                                }
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                                gap: "4px",
                              }}
                            >
                              <ProductInfoContainer
                                product={item}
                                highlight={true}
                              />
                            </div>
                          </FormControl>
                        );
                      })}
                    </div>
                    <Divider />
                    <Typography>Competitor Products Selection</Typography>
                    <div
                      style={{
                        display: "flex",
                        gap: "16px",
                      }}
                    >
                      <Autocomplete
                        multiple
                        freeSolo
                        options={[]}
                        value={competitorAsins}
                        renderTags={(value, getTagProps) => (
                          <div>
                            <Typography
                              variant="caption"
                              component="div"
                              onClick={async () => {
                                await navigator.clipboard.writeText(
                                  selectedCompetitorAsins.join(", ")
                                );
                              }}
                              style={{
                                cursor: "pointer",
                                color: "#4B50DC",
                              }}
                            >
                              Copy Selected ASINs
                            </Typography>
                            {value.map((option, index) => (
                              <Chip
                                variant={"filled"}
                                label={
                                  option?.asin
                                    ? `${option?.asin} - ${option?.title}`
                                    : option
                                }
                                {...getTagProps({ index })}
                                icon={
                                  <FileCopyOutlined
                                    onClick={async () => {
                                      await navigator.clipboard.writeText(
                                        option?.asin || option
                                      );
                                    }}
                                  />
                                }
                                deleteIcon={
                                  <Checkbox
                                    size="small"
                                    disabled={
                                      selectedCompetitorAsins.length === 5 &&
                                      !selectedCompetitorAsins.includes(
                                        option?.asin || option
                                      )
                                    }
                                    checked={selectedCompetitorAsins.includes(
                                      option?.asin || option
                                    )}
                                    style={{
                                      padding: 0,
                                    }}
                                  />
                                }
                                onDelete={() => {
                                  if (
                                    selectedCompetitorAsins.includes(
                                      option?.asin || option
                                    )
                                  ) {
                                    setSelectedCompetitorAsins(
                                      [...selectedCompetitorAsins].filter(
                                        (item) =>
                                          item !== (option?.asin || option)
                                      )
                                    );
                                  } else {
                                    setSelectedCompetitorAsins([
                                      ...selectedCompetitorAsins,
                                      option?.asin || option,
                                    ]);
                                  }
                                }}
                                size="small"
                                sx={{
                                  border: "1px solid #4B50DC",
                                  borderRadius: "16px",
                                  height: "fit-content",
                                  ".MuiChip-label": {
                                    whiteSpace: "break-spaces",
                                  },
                                }}
                              />
                            ))}
                          </div>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Competitor ASINs *"
                            placeholder="Enter Competitor ASINs "
                            variant="outlined"
                            onKeyDown={(e) => {
                              if (e.key === "Enter" && e.target.value) {
                                setCompetitorAsins(
                                  _.uniq([
                                    ...competitorAsins,
                                    ...e.target.value
                                      .trim()
                                      .toUpperCase()
                                      .split(/[^a-zA-Z0-9]+/),
                                  ])
                                );
                                e.target.value = "";
                              }
                            }}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: selectedCompetitorAsins.length === 5,
                            }}
                            helperText="Select up to 5 ASINs (at least 1 required), if they're available. Modify your choices by clicking on an ASIN or entering your own."
                            style={{
                              margin: "8px 0px",
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                        style={{
                          width: "60%",
                        }}
                      />
                      <ReviewOptionsSelectionMenu
                        label="Review Ratings"
                        value={reviewsRatingOption.value}
                        menus={REVIEWS_RATING_OPTIONS}
                        onClick={(item) => setReviewsRatingOption(item)}
                      />
                      <ReviewOptionsSelectionMenu
                        label="Sort Ratings"
                        value={reviewsSortOption.value}
                        menus={REVIEWS_SORT_OPTIONS}
                        onClick={(item) => setReviewsSortOption(item)}
                      />
                      <ReviewOptionsSelectionMenu
                        label="Reviews Count"
                        value={reviewsCountOption.value}
                        menus={REVIEWS_COUNT_OPTIONS}
                        onClick={(item) => setReviewsCountOption(item)}
                      />
                    </div>
                  </StepContentContainer>
                )}
                {activeStepIndex === 2 && (
                  <StepContentContainer>
                    <AccordionContainer
                      title={"Competitor ASINs reviews summary"}
                      detail={
                        <div
                          style={{
                            display: "flex",
                            gap: "8px",
                          }}
                        >
                          <CustomTextField
                            label={"Positives"}
                            value={getBulletedText(
                              chatGptPromptParts.reviewsSummary.positives
                            )}
                            readOnly={true}
                            multiline={true}
                            width={"50%"}
                          />
                          <CustomTextField
                            label={"Negatives"}
                            value={getBulletedText(
                              chatGptPromptParts.reviewsSummary.negatives
                            )}
                            readOnly={true}
                            multiline={true}
                            width={"50%"}
                          />
                        </div>
                      }
                    />
                    <Divider />

                    <Typography>Provide product details.</Typography>

                    <CustomTextField
                      label="Product Name *"
                      value={chatGptPromptParts.productName}
                      placeholder="Enter product name"
                      onChange={(e) => {
                        setChatGptPromptParts({
                          ...chatGptPromptParts,
                          productName: e.target.value,
                        });
                      }}
                      width={"75%"}
                    />

                    <CustomAutocompleteTextField
                      label="Product Features"
                      value={chatGptPromptParts.commonFeatures}
                      placeholder="Enter features of product"
                      onChange={(e, value) => {
                        setChatGptPromptParts({
                          ...chatGptPromptParts,
                          commonFeatures: value,
                        });
                      }}
                      width={"75%"}
                    />

                    <KeywordsTable
                      rows={chatGptPromptParts.keywords}
                      width={"75%"}
                      updateHandler={(keywords) =>
                        setChatGptPromptParts((prevState) => ({
                          ...prevState,
                          keywords,
                        }))
                      }
                      hiddenKeywords={chatGptPromptParts?.hiddenKeywords || ""}
                      setHiddenKeywords={(hiddenKeywords) => {
                        setChatGptPromptParts((prevState) => ({
                          ...prevState,
                          hiddenKeywords,
                        }));
                      }}
                    />

                    <CustomAutocompleteTextField
                      label="Websites (Optional)"
                      value={chatGptPromptParts.websites}
                      placeholder="Enter website urls of product"
                      onChange={(e, value) => {
                        setChatGptPromptParts({
                          ...chatGptPromptParts,
                          websites: value,
                        });
                      }}
                      width={"75%"}
                    />

                    <CustomTextField
                      label="Additional Details (Optional)"
                      value={chatGptPromptParts.rawDescription}
                      placeholder="Enter additional details"
                      multiline={true}
                      minRows={4}
                      onChange={(e) => {
                        setChatGptPromptParts({
                          ...chatGptPromptParts,
                          rawDescription: e.target.value,
                        });
                      }}
                      width={"75%"}
                    />
                    <>
                      <input
                        id="file-upload-input"
                        type="file"
                        multiple
                        accept=".pdf,.txt"
                        onChange={async (e) => {
                          const promises = [];
                          for (const file of e.target.files) {
                            const reader = new FileReader();
                            let filePromise;
                            if (file.type === "text/plain") {
                              filePromise = new Promise((resolve) => {
                                reader.readAsText(file);
                                reader.onload = () => resolve(reader.result);
                              });
                            } else if (file.type === "application/pdf") {
                              filePromise = new Promise((resolve) => {
                                reader.readAsDataURL(file);
                                reader.onload = async () => {
                                  const pdf = await pdfjs.getDocument(
                                    reader.result
                                  ).promise;
                                  const pdfText = [];
                                  for (let i = 1; i <= pdf.numPages; i++) {
                                    const page = await pdf.getPage(i);
                                    const textContents = await page.getTextContent();
                                    const text = textContents.items
                                      .map((s) => s.str)
                                      .join("");
                                    pdfText.push(text);
                                  }
                                  resolve(pdfText);
                                };
                              });
                            }
                            promises.push(filePromise);
                          }
                          Promise.all(promises).then((fileContents) => {
                            setChatGptPromptParts({
                              ...chatGptPromptParts,
                              fileReferences: fileContents.join("\n"),
                            });
                          });
                        }}
                        style={{ display: "none" }}
                      />
                      <label htmlFor="file-upload-input">
                        <Button
                          component="span"
                          variant="contained"
                          color="primary"
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "baseline",
                              gap: "4px",
                              textTransform: "none",
                            }}
                          >
                            UPLOAD FILE [.pdf, .txt]
                            <Typography
                              variant="caption"
                              style={{ textTransform: "none" }}
                            >
                              (Optional)
                            </Typography>
                          </div>
                        </Button>
                      </label>
                      {chatGptPromptParts.fileReferences.length > 0 && (
                        <CustomTextField
                          label="Selected Upload File Contents"
                          value={chatGptPromptParts.fileReferences}
                          multiline={true}
                          minRows={4}
                          onChange={(e) => {
                            setChatGptPromptParts({
                              ...chatGptPromptParts,
                              fileReferences: e.target.value,
                            });
                          }}
                          width={"75%"}
                        />
                      )}
                    </>

                    <Divider />
                    <Typography>Variant features</Typography>
                    {selectedAsins.map((item, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: "flex",
                            gap: "4px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "4px",
                              width: "33%",
                            }}
                          >
                            <ProductInfoContainer product={item} />
                          </div>
                          <CustomAutocompleteTextField
                            label="Features"
                            value={chatGptPromptParts.variations[index]}
                            placeholder="Enter features of variant"
                            onChange={(e, value) => {
                              const chatGptPromptPartsClone = _.cloneDeep(
                                chatGptPromptParts
                              );
                              chatGptPromptPartsClone.variations[index] = value;
                              setChatGptPromptParts({
                                ...chatGptPromptPartsClone,
                              });
                            }}
                            width={"33%"}
                          />
                        </div>
                        <Divider variant="middle" />
                      </div>
                    ))}
                  </StepContentContainer>
                )}
                {activeStepIndex === 3 && (
                  <StepContentContainer>
                    <CustomTextField
                      label="Title *"
                      value={chatGptPromptParts.title}
                      multiline={true}
                      onChange={(e) =>
                        setChatGptPromptParts({
                          ...chatGptPromptParts,
                          title: e.target.value,
                        })
                      }
                      width={"60%"}
                    />
                    <CustomTextField
                      label="Description *"
                      value={chatGptPromptParts.description}
                      multiline={true}
                      onChange={(e) =>
                        setChatGptPromptParts({
                          ...chatGptPromptParts,
                          description: e.target.value,
                        })
                      }
                      width={"60%"}
                    />
                    <CustomTextField
                      label="Bullet Points *"
                      value={chatGptPromptParts.bulletPoints}
                      multiline={true}
                      onChange={(e) =>
                        setChatGptPromptParts({
                          ...chatGptPromptParts,
                          bulletPoints: e.target.value,
                        })
                      }
                      width={"60%"}
                    />
                    <CustomTextField
                      label="Tag Lines *"
                      value={chatGptPromptParts.tagLines}
                      multiline={true}
                      onChange={(e) =>
                        setChatGptPromptParts({
                          ...chatGptPromptParts,
                          tagLines: e.target.value,
                        })
                      }
                      width={"60%"}
                    />
                    <CustomTextField
                      label="Recommendations *"
                      value={chatGptPromptParts.recommendations}
                      multiline={true}
                      onChange={(e) =>
                        setChatGptPromptParts({
                          ...chatGptPromptParts,
                          recommendations: e.target.value,
                        })
                      }
                      width={"60%"}
                    />
                    <CustomTextField
                      label="Prompt Preview"
                      value={chatGptPromptBuilder()}
                      readOnly={true}
                      multiline={true}
                      width={"60%"}
                    />
                  </StepContentContainer>
                )}
                {activeStepIndex === 4 && (
                  <StepContentContainer>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography>
                        Click on an ASIN to preview its created content.
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={showLiveContent}
                            onChange={async (e) => {
                              setShowLiveContent(e.target.checked);
                              if (e.target.checked) await loadLiveContents();
                            }}
                          />
                        }
                        label="Show Live Content"
                        labelPlacement="start"
                      />
                    </div>
                    <Divider />
                    <div style={{ display: "flex", gap: "8px" }}>
                      <List
                        style={{
                          width: "15%",
                          maxHeight: "500px",
                          overflow: "scroll",
                        }}
                      >
                        {previewContents.map((item, index) => (
                          <ListItemButton
                            key={index}
                            selected={
                              selectedPreviewContent?.asin === item.asin
                            }
                            onClick={() => {
                              if (selectedPreviewContent?.asin !== item.asin) {
                                previewContents[
                                  previewContents.findIndex(
                                    (previewContent) =>
                                      previewContent.asin ===
                                      selectedPreviewContent?.asin
                                  )
                                ] = _.cloneDeep(selectedPreviewContent);
                                setSelectedPreviewContent({ ...item });
                              }
                            }}
                            style={{
                              textAlign: "center",
                              backgroundColor:
                                selectedPreviewContent?.asin === item.asin
                                  ? "#4B50DC"
                                  : undefined,
                            }}
                          >
                            <ListItemText
                              style={{
                                color:
                                  selectedPreviewContent?.asin === item.asin
                                    ? "#FFFFFF"
                                    : undefined,
                              }}
                            >
                              <Typography>{item.asin}</Typography>
                            </ListItemText>
                          </ListItemButton>
                        ))}
                      </List>
                      <Divider
                        orientation="vertical"
                        flexItem={true}
                        style={{ margin: "0px 4px" }}
                      />
                      <div
                        style={{
                          width: "85%",
                          display: "flex",
                          flexDirection: "column",
                          padding: "8px",
                          gap: "8px",
                        }}
                      >
                        <StepPreviewElementsItemContainer>
                          <StepPreviewElementsItemLabelContainer>
                            ASIN:
                          </StepPreviewElementsItemLabelContainer>
                          <StepPreviewElementsItemValueContainer>
                            <Typography>
                              {selectedPreviewContent?.asin}
                            </Typography>
                          </StepPreviewElementsItemValueContainer>
                        </StepPreviewElementsItemContainer>
                        <StepPreviewElementsItemContainer>
                          <StepPreviewElementsItemLabelContainer>
                            Title:
                          </StepPreviewElementsItemLabelContainer>
                          <StepPreviewElementsItemValueContainer>
                            <div
                              style={{
                                width: showLiveContent ? "50%" : "100%",
                              }}
                            >
                              <div
                                style={{
                                  border: "1px solid #E8E8E8",
                                  borderRadius: "4px",
                                  width: "100%",
                                  padding: "12px",
                                }}
                              >
                                <KeywordsHighlight
                                  text={selectedPreviewContent?.title}
                                  keywords={chatGptPromptParts?.keywords}
                                  onChange={(value) => {
                                    setSelectedPreviewContent({
                                      ...selectedPreviewContent,
                                      title: value,
                                    });
                                    const previewContentsCloned = _.cloneDeep(
                                      previewContents
                                    );
                                    previewContentsCloned[
                                      previewContents.findIndex(
                                        (item) =>
                                          item.asin ===
                                          selectedPreviewContent?.asin
                                      )
                                    ].title = value;
                                    setPreviewContents(previewContentsCloned);
                                  }}
                                />
                              </div>
                              <TextInputCounter
                                textInput={selectedPreviewContent?.title}
                              />
                            </div>
                            {showLiveContent && (
                              <CustomTextField
                                label="Live Content"
                                value={
                                  selectedAsinsLiveContent.find(
                                    (item) =>
                                      item.asin === selectedPreviewContent?.asin
                                  )?.title
                                }
                                readOnly={true}
                                shrinkLabel={true}
                                width="50%"
                              />
                            )}
                          </StepPreviewElementsItemValueContainer>
                        </StepPreviewElementsItemContainer>
                        <StepPreviewElementsItemContainer>
                          <StepPreviewElementsItemLabelContainer>
                            Description:
                          </StepPreviewElementsItemLabelContainer>
                          <StepPreviewElementsItemValueContainer>
                            <div
                              style={{
                                width: showLiveContent ? "50%" : "100%",
                              }}
                            >
                              <div
                                style={{
                                  border: "1px solid #E8E8E8",
                                  borderRadius: "4px",
                                  minHeight: "150px",
                                  width: "100%",
                                  padding: "12px",
                                }}
                              >
                                <KeywordsHighlight
                                  text={selectedPreviewContent?.description}
                                  keywords={chatGptPromptParts?.keywords}
                                  onChange={(value) => {
                                    setSelectedPreviewContent({
                                      ...selectedPreviewContent,
                                      description: value,
                                    });
                                    const previewContentsCloned = _.cloneDeep(
                                      previewContents
                                    );
                                    previewContentsCloned[
                                      previewContents.findIndex(
                                        (item) =>
                                          item.asin ===
                                          selectedPreviewContent?.asin
                                      )
                                    ].description = value;
                                    setPreviewContents(previewContentsCloned);
                                  }}
                                />
                              </div>
                              <TextInputCounter
                                textInput={selectedPreviewContent?.description}
                              />
                            </div>
                            {showLiveContent && (
                              <CustomTextField
                                label="Live Content"
                                value={
                                  selectedAsinsLiveContent.find(
                                    (item) =>
                                      item.asin === selectedPreviewContent?.asin
                                  )?.description
                                }
                                multiline={true}
                                minRows={4}
                                readOnly={true}
                                shrinkLabel={true}
                                width="50%"
                              />
                            )}
                          </StepPreviewElementsItemValueContainer>
                        </StepPreviewElementsItemContainer>
                        <StepPreviewElementsItemContainer>
                          <StepPreviewElementsItemLabelContainer>
                            Bullet Points:
                          </StepPreviewElementsItemLabelContainer>
                          <StepPreviewElementsItemValueContainer>
                            <div
                              style={{
                                width: showLiveContent ? "33%" : "50%",
                              }}
                            >
                              <div
                                style={{
                                  border: "1px solid #E8E8E8",
                                  borderRadius: "4px",
                                  minWidth: "300px",
                                  width: "100%",
                                  padding: "12px",
                                }}
                              >
                                <KeywordsHighlight
                                  text={selectedPreviewContent?.bulletPoints?.join(
                                    "\n"
                                  )}
                                  keywords={chatGptPromptParts?.keywords}
                                  onChange={(value) => {
                                    setSelectedPreviewContent({
                                      ...selectedPreviewContent,
                                      bulletPoints: value?.split("\n") || "",
                                    });
                                    const previewContentsCloned = _.cloneDeep(
                                      previewContents
                                    );
                                    previewContentsCloned[
                                      previewContents.findIndex(
                                        (item) =>
                                          item.asin ===
                                          selectedPreviewContent?.asin
                                      )
                                    ].bulletPoints = value?.split("\n") || "";
                                    setPreviewContents(previewContentsCloned);
                                  }}
                                />
                              </div>
                              <FormHelperText
                                component={"div"}
                                style={{
                                  margin: 0,
                                }}
                              >
                                <div>
                                  {`Bullet Points (all) - Characters: ${
                                    selectedPreviewContent?.bulletPoints?.join(
                                      "\n"
                                    )
                                      ? selectedPreviewContent?.bulletPoints?.join(
                                          "\n"
                                        ).length -
                                        selectedPreviewContent?.bulletPoints
                                          ?.length +
                                        1
                                      : 0
                                  }, Words: ${
                                    selectedPreviewContent?.bulletPoints?.join(
                                      "\n"
                                    )
                                      ? selectedPreviewContent?.bulletPoints
                                          ?.join("\n")
                                          .trim()
                                          .split(/\s+/)?.length || 0
                                      : 0
                                  }`}
                                </div>
                                {selectedPreviewContent?.bulletPoints?.map(
                                  (item, index) => {
                                    return (
                                      <div>{`Bullet Point ${
                                        index + 1
                                      } - Characters: ${item.length}, Words: ${
                                        item
                                          ? item.trim().split(/\s+/)?.length ||
                                            0
                                          : 0
                                      }`}</div>
                                    );
                                  }
                                )}
                              </FormHelperText>
                            </div>
                            <CustomTextField
                              label="Preview"
                              value={getBulletedText(
                                selectedPreviewContent?.bulletPoints
                              )}
                              readOnly={true}
                              multiline={true}
                              width={showLiveContent ? "33%" : "50%"}
                            />
                            {showLiveContent && (
                              <CustomTextField
                                label="Live Content"
                                shrinkLabel={true}
                                value={getBulletedText(
                                  selectedAsinsLiveContent.find(
                                    (item) =>
                                      item.asin === selectedPreviewContent?.asin
                                  )?.bulletPoints
                                )}
                                readOnly={true}
                                multiline={true}
                                width="33%"
                              />
                            )}
                          </StepPreviewElementsItemValueContainer>
                        </StepPreviewElementsItemContainer>
                        <StepPreviewElementsItemContainer>
                          <StepPreviewElementsItemLabelContainer>
                            Keywords:
                          </StepPreviewElementsItemLabelContainer>
                          <StepPreviewElementsItemValueContainer>
                            <KeywordsTable
                              rows={chatGptPromptParts.keywords}
                              width={"100%"}
                              hideAdd={true}
                              hideRemove={true}
                              updateHandler={(keywords) => {
                                setChatGptPromptParts((prevState) => ({
                                  ...prevState,
                                  keywords,
                                }));
                              }}
                              hiddenKeywords={
                                chatGptPromptParts?.hiddenKeywords || ""
                              }
                              setHiddenKeywords={(hiddenKeywords) => {
                                setChatGptPromptParts((prevState) => ({
                                  ...prevState,
                                  hiddenKeywords,
                                }));
                              }}
                            />
                          </StepPreviewElementsItemValueContainer>
                        </StepPreviewElementsItemContainer>
                        <StepPreviewElementsItemContainer>
                          <StepPreviewElementsItemLabelContainer>
                            Tag Lines:
                          </StepPreviewElementsItemLabelContainer>
                          <StepPreviewElementsItemValueContainer>
                            <CustomTextField
                              value={selectedPreviewContent?.tagLines?.join(
                                "\n"
                              )}
                              multiline={true}
                              onChange={(e) => {
                                setSelectedPreviewContent({
                                  ...selectedPreviewContent,
                                  tagLines: e.target.value?.split("\n"),
                                });
                                const previewContentsCloned = _.cloneDeep(
                                  previewContents
                                );
                                previewContentsCloned[
                                  previewContents.findIndex(
                                    (item) =>
                                      item.asin === selectedPreviewContent?.asin
                                  )
                                ].tagLines = e.target.value?.split("\n") || "";
                                setPreviewContents(previewContentsCloned);
                              }}
                              width={"50%"}
                            />
                            <CustomTextField
                              label="Preview"
                              value={getBulletedText(
                                selectedPreviewContent?.tagLines
                              )}
                              readOnly={true}
                              multiline={true}
                              width={"50%"}
                            />
                          </StepPreviewElementsItemValueContainer>
                        </StepPreviewElementsItemContainer>
                        <StepPreviewElementsItemContainer>
                          <StepPreviewElementsItemLabelContainer>
                            Recommendations:
                          </StepPreviewElementsItemLabelContainer>
                          <StepPreviewElementsItemValueContainer>
                            <CustomTextField
                              value={selectedPreviewContent?.recommendations?.join(
                                "\n"
                              )}
                              multiline={true}
                              onChange={(e) => {
                                setSelectedPreviewContent({
                                  ...selectedPreviewContent,
                                  recommendations: e.target.value?.split("\n"),
                                });
                                const previewContentsCloned = _.cloneDeep(
                                  previewContents
                                );
                                previewContentsCloned[
                                  previewContents.findIndex(
                                    (item) =>
                                      item.asin === selectedPreviewContent?.asin
                                  )
                                ].recommendations =
                                  e.target.value?.split("\n") || "";
                                setPreviewContents(previewContentsCloned);
                              }}
                              width={"50%"}
                            />
                            <CustomTextField
                              label="Preview"
                              value={getBulletedText(
                                selectedPreviewContent?.recommendations
                              )}
                              readOnly={true}
                              multiline={true}
                              width={"50%"}
                            />
                          </StepPreviewElementsItemValueContainer>
                        </StepPreviewElementsItemContainer>
                      </div>
                    </div>
                  </StepContentContainer>
                )}
              </DialogContent>
              <DialogActions>
                <StepActionsContainer>
                  <StepActionButton
                    label="Back"
                    disabled={activeStepIndex === 0}
                    onClick={handleStepperBack}
                  />
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                    }}
                  >
                    {activeStepIndex === 4 && (
                      <StepActionButton
                        label="Export (.XLSX)"
                        onClick={handleExport}
                      />
                    )}
                    {activeStepIndex === 4 && (
                      <StepActionButton
                        label="Export (.DOCX)"
                        onClick={handleExportDoc}
                      />
                    )}
                    <StepActionButton
                      label={activeStepIndex === 4 ? "Save" : "Next"}
                      disabled={
                        (activeStepIndex === 0 && !providedAsins.trim()) ||
                        (activeStepIndex === 1 &&
                          (selectedAsins.length === 0 ||
                            selectedCompetitorAsins.length === 0)) ||
                        (activeStepIndex === 2 &&
                          !chatGptPromptParts.productName.trim()) ||
                        (activeStepIndex === 4 && isOnboardingMode)
                      }
                      onClick={handleStepperNext}
                    />
                  </div>
                </StepActionsContainer>
              </DialogActions>
            </Dialog>
            <Dialog
              open={showSendMail}
              onClose={handleSendMailClose}
              maxWidth="xl"
              fullWidth={true}
            >
              <DialogTitle>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {"Send Mail"}
                  <CloseOutlined
                    onClick={handleSendMailClose}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </div>
              </DialogTitle>
              <DialogContent
                style={{
                  padding: "0px",
                  paddingBottom: "8px",
                }}
              >
                {showLoader && (
                  <LinearProgress style={{ margin: "0px 32px" }} />
                )}
                <DialogContentText
                  style={{ margin: "0px", padding: "0px 32px" }}
                >
                  {message}
                </DialogContentText>
                <div
                  style={{
                    display: "flex",
                    padding: "0px 24px",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    <Typography
                      style={{
                        paddingBottom: "8px",
                      }}
                    >
                      {mailAsins.length > 0
                        ? "Select the ASINs to send for approval."
                        : "No ASINs available to send for approval."}
                    </Typography>
                    <Divider />
                    <div
                      style={{
                        padding: "8px 4px",
                        maxHeight: "500px",
                        overflow: "scroll",
                      }}
                    >
                      {contents
                        .filter(
                          (content) =>
                            [
                              STATUS.CONTENT_GENERATED,
                              STATUS.FEEDBACK_ADDRESSED,
                            ].includes(content.status) &&
                            content.internallyApproved
                        )
                        .map((item, index) => (
                          <div key={index}>
                            <FormControl
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              <Checkbox
                                color="primary"
                                checked={mailAsins
                                  .map((item) => item.asin)
                                  .includes(item.asin)}
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    setMailAsins([...mailAsins].concat([item]));
                                  } else {
                                    setMailAsins(
                                      [...mailAsins].filter(
                                        (selectedItem) =>
                                          selectedItem.asin !== item.asin
                                      )
                                    );
                                  }
                                }}
                              />
                              <Typography>{`${item.asin} - ${item.status} - ${item.title}`}</Typography>
                            </FormControl>
                          </div>
                        ))}
                    </div>
                  </div>
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{
                      margin: "0px 8px",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      width: "50%",
                    }}
                  >
                    <Typography>Mail</Typography>
                    <Divider />
                    <TextField
                      variant="outlined"
                      label="From"
                      value={"cohesity@salesduo.com"}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <Autocomplete
                      multiple
                      freeSolo
                      options={[]}
                      value={mailContent.to}
                      onChange={(e, value) => {
                        setMailContent({
                          ...mailContent,
                          to: value,
                        });
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                            size="small"
                            style={{
                              border: "1px solid #4B50DC",
                              borderRadius: "16px",
                            }}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="To *"
                          placeholder="Enter recipients"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                    <TextField
                      variant="outlined"
                      label="Subject *"
                      value={mailContent.subject}
                      onChange={(e) =>
                        setMailContent({
                          ...mailContent,
                          subject: e.target.value,
                        })
                      }
                    />
                    <TextField
                      variant="outlined"
                      multiline
                      label="Message *"
                      value={mailContent.message}
                      onChange={(e) =>
                        setMailContent({
                          ...mailContent,
                          message: e.target.value,
                        })
                      }
                    />
                    <TextField
                      variant="outlined"
                      multiline
                      label="Preview"
                      value={
                        `Subject: ${mailContent.subject || "<subject>"}` +
                        "\n\nBody:" +
                        "\n\nHi there," +
                        `\n\n${mailContent.message || "<message>"}` +
                        "\n\nPlease login to the dashboard using the link below and review those for proceeding further." +
                        `\n\nLink: ${mailContent.link}` +
                        "\n\n#NOTE: Below details within { } will be shown only for new users!\n\n{\nYour initial login details are as follows:\n\n" +
                        "Username: <email>" +
                        "\nPassword: <password>" +
                        "\n\nFor security purposes, please make sure to modify your password after login.\n}" +
                        "\n\nIf you have any issues, you can let your Account Manager know about it."
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={
                    mailAsins.length === 0 ||
                    mailContent.to.length === 0 ||
                    !mailContent.subject ||
                    !mailContent.message
                  }
                  onClick={async () => {
                    setShowLoader(true);
                    const sendEmail = await sendContentsMail(
                      vendorState.selected,
                      mailAsins.map((item) => item.asin),
                      mailContent
                    );
                    setShowLoader(false);
                    setMessage(sendEmail.data);
                    if (sendEmail.status === "Success") {
                      const changelogs = [];

                      mailAsins.forEach((item) => {
                        changelogs.push({
                          asin: item.asin,
                          vendorCode: vendorState?.selected,
                          action: CHANGELOG_ACTION.CUSTOMER_APPROVAL_REQUEST,
                          attributes: ["Status"],
                          beforeChange: {
                            Status: item.status,
                          },
                          afterChange: {
                            Status: STATUS.PENDING_APPROVAL,
                          },
                          user: userState?.userData?.email,
                        });
                      });

                      setTimeout(() => {
                        setMessage("");
                        handleSendMailClose();
                      }, 3000);
                      await loadContents(vendorState.selected);
                      saveContentChangelogs(vendorState?.selected, changelogs);
                    } else {
                      setTimeout(() => {
                        setMessage(sendEmail?.message || "");
                      }, 3000);
                    }
                  }}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Send
                </Button>
              </DialogActions>
            </Dialog>

            <Widget
              upperTitle
              noBodyPadding
              header={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      width: "50%",
                    }}
                  ></div>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={async () => {
                        setMailAsins(
                          _.cloneDeep(
                            contents.filter(
                              (item) =>
                                [
                                  STATUS.CONTENT_GENERATED,
                                  STATUS.FEEDBACK_ADDRESSED,
                                ].includes(item.status) &&
                                item.internallyApproved
                            )
                          )
                        );
                        setShowSendMail(true);
                        const customerConfig = await fetchCustomerConfig(
                          vendorState?.selected
                        );
                        customerConfig?.email &&
                          setMailContent({
                            ...mailContent,
                            to: [customerConfig.email],
                          });
                      }}
                    >
                      Send Mail
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setShowCreateContent(true)}
                    >
                      Create
                    </Button>
                  </div>
                </div>
              }
            >
              <ContentsTable
                rows={contents}
                editHandler={(content) => handleEditContent(content)}
                deleteHandler={(content) => {
                  setDeleteContentData(content);
                  setShowDeleteContent(true);
                }}
              />
            </Widget>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
