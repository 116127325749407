import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  CircularProgress,
  Backdrop,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormLabel,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";

//icons
import RefreshIcon from "@material-ui/icons/Refresh";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import ConfirmDialog from "./components/ComfirmDialog/ConfirmDialog";
import Table from "./components/Table/Table";
import CancelShipmentDialog from "./components/VoidTransportDialog/VoidTransportDialog";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { useUserState } from "../../context/UserContext";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useVendorState } from "../../context/VendorContext";
import DateFnsUtils from "@date-io/date-fns";

export default function Page() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var userState = useUserState();
  const vendorState = useVendorState();

  // local
  const [packageListTableData, setPackageListTableData] = useState([]);
  const [palletListTableData, setPalletListTableData] = useState([]);
  const [file, setfile] = useState({ name: "No File uploaded" });
  const [confirmShipmentDialog, setconfirmShipmentDialog] = useState(false);
  const [cancelShipmentDialog, setCancelShipmentDialog] = useState(false);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [shipmentType, setShipmentType] = useState("SP");
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);
  const [shipmentId, setShipmentId] = useState("");
  const [mainTableData, setmainTableData] = useState([]);
  const [freightDate, setFreightDate] = useState(new Date());

  const handleDateChange = (date) => {
    setFreightDate(date);
  };

  useEffect(() => {
    // if (userState.userData && vendor) {
    //   fetchData();
    // }
    // fetchData()
  }, [userState.userData]);

  async function initiateShipmentCost() {
    try {
      setmainTableData([]);
      setfullPageLoader(true);
      const reponse = await axios.post(BASE_URL + api.estimateShippingCost, {
        shipmentId,
        vendorCode: vendorState?.selected,
        shipmentType,
        freightDate
      });
      setnotification("Initiated Shipment Cost Estimation");
      setTimeout(() => setnotification(null), 3000);
      setmainTableData(reponse.data);
    } catch (err) {
      console.log(err);
      const errmsg = err.response?.data?.msg || err.response?.data?.message;
      seterr(errmsg || "Error occured");
      setTimeout(() => seterr(null), 3000);
    } finally {
      setfullPageLoader(false);
    }
  }

  async function getTransportDetails() {
    try {
      setmainTableData([]);
      setfullPageLoader(true);
      const response = await axios.get(BASE_URL + api.getTransportDetails, {
        params: { shipmentId, vendorCode: vendorState?.selected },
      });
      setmainTableData([response.data]);
      setnotification("Fetching Transport Details");
      setTimeout(() => setnotification(null), 3000);
    } catch (err) {
      console.log(err);
      const errmsg = err.response?.data?.msg || err.response?.data?.message;
      seterr(errmsg || "Error occured");
      setTimeout(() => seterr(null), 3000);
    } finally {
      setfullPageLoader(false);
    }
  }

  async function confirmTransport() {
    try {
      setfullPageLoader(true);
      const response = await axios.post(BASE_URL + api.confirmTransport, {
        shipmentId,
        vendorCode: vendorState?.selected,
        shipmentType
      });
      setnotification("Transport Confirmed");
      setTimeout(() => setnotification(null), 3000);
    } catch (err) {
      console.log(err);
      const errmsg = err.response?.data?.msg || err.response?.data?.message;
      seterr(errmsg || "Error occured");
      setTimeout(() => seterr(null), 3000);
    } finally {
      setfullPageLoader(false);
    }
  }

  async function voidTransport() {
    try {
      setfullPageLoader(true);
      const response = await axios.post(BASE_URL + api.voidTransport, {
        shipmentId,
        vendorCode: vendorState?.selected,
      });
      setnotification("Transport voided");
      setTimeout(() => setnotification(null), 3000);
    } catch (err) {
      console.log(err);
      const errmsg = err.response?.data?.msg || err.response?.data?.message;
      seterr(errmsg || "Error occured");
      setTimeout(() => seterr(null), 3000);
    } finally {
      setfullPageLoader(false);
    }
  }

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Estimate Shipping Cost"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                FBA Shipment
              </Link>
              <Typography className={classes.link} color="primary">
                Estimate Shipping Cost
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={<></>}
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <div className={classes.mainChartHeader}>
                  <Grid
                    container
                    spacing={1}
                    style={{ marginBottom: 10 }}
                    alignItems="center"
                  >
                    <Grid item>
                      <TextField
                        name="shipmentId"
                        label="Shipment Id"
                        type="text"
                        variant="outlined"
                        value={shipmentId}
                        onChange={(e) => setShipmentId(e.target.value)}
                        style={{ width: 400 }}
                      />
                    </Grid>
                    <Grid item>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          name="freightReadyDate"
                          variant="inline"
                          format="yyyy-MM-dd"
                          // margin="normal"
                          id="date-picker-inline"
                          label="Freight Ready Date"
                          value={freightDate}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          style={{ marginLeft: 10 }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ width: 200, marginLeft: 10 }}
                        disabled={!shipmentId}
                        size="large"
                        onClick={initiateShipmentCost}
                      >
                        Get Estimate
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              }
            ></Widget>
          </Grid>
        </Grid>
      </Container>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            disableWidgetMenu
            header={
              <div className={classes.mainChartHeader}>
                <div />
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<RefreshIcon />}
                  onClick={getTransportDetails}
                  disabled={!shipmentId}
                >
                  Refresh
                </Button>
              </div>
            }
          >
            <Table ref={exportFunctionRef} tableData={mainTableData} />
          </Widget>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            disableWidgetMenu
            header={
              <div className={classes.mainChartHeader}>
                <Grid
                  container
                  spacing={1}
                  style={{ marginBottom: 10 }}
                  alignItems="center"
                >
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Select Shipment Type
                    </FormLabel>
                    <RadioGroup
                      value={shipmentType}
                      onChange={(e) => setShipmentType(e.target.value)}
                    >
                      <FormControlLabel
                        value="SP"
                        control={<Radio />}
                        label="SP"
                      />
                      <FormControlLabel
                        value="LTL"
                        control={<Radio />}
                        label="LTL"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </div>
            }
          ></Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            disableWidgetMenu
            header={
              <div className={classes.mainChartHeader}>
                <Grid
                  container
                  spacing={1}
                  style={{ marginBottom: 10 }}
                  alignItems="center"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ width: 250 }}
                      disabled={!shipmentId}
                      size="large"
                      onClick={() => setconfirmShipmentDialog(true)}
                    >
                      Confirm Transport
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ width: 250, marginLeft: 50 }}
                      disabled={!shipmentId}
                      size="large"
                      onClick={() => setCancelShipmentDialog(true)}
                    >
                      Void Transport
                    </Button>
                  </Grid>
                </Grid>
              </div>
            }
          ></Widget>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ConfirmDialog
        open={confirmShipmentDialog}
        toggleDialog={() => setconfirmShipmentDialog(!confirmShipmentDialog)}
        setconfirmDialog={setconfirmShipmentDialog}
        confirmTransport={confirmTransport}
      />
      <CancelShipmentDialog
        open={cancelShipmentDialog}
        toggleDialog={() => setCancelShipmentDialog(!cancelShipmentDialog)}
        setCancelShipmentDialog={setCancelShipmentDialog}
        cancelTransport={voidTransport}
      />
      {notification ? (
        <Alert
          severity="success"
          style={{ position: "absolute", bottom: 200, left: "48%" }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{ position: "absolute", bottom: 200, left: "48%" }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
