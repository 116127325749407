import React, { useState, useRef , forwardRef, useImperativeHandle } from "react";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import { IconButton, } from "@material-ui/core";
import Version from '@mui/icons-material/Filter1';
import Start from '@mui/icons-material/PlayCircleOutline';
import Stop from '@mui/icons-material/DoNotDisturbAlt';
import Schedule from '@mui/icons-material/AddCircleOutline';
import Optimize from '@mui/icons-material/AutoFixHigh';
import { Tooltip } from "@mui/material";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'

export default forwardRef ( function Tables(props,ref) {

  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);


  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if(gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({onlySelected: true});
      else 
      gridApi.exportDataAsCsv();
    }
  }));

  const rowStyle = { background: 'white' };

  const EditButtonRenderer = (buttonProps) => {
    return (
      <IconButton
        variant="contained"
        color="primary"
        style={{ textTransform: "capitalize" }}
        onClick={() => props.openEditModel(buttonProps.data)}
      >
        Edit
      </IconButton>
    );
  };
  const VersionButtonRenderer = (buttonProps) => {
   return  !buttonProps?.data?.createdFromDashboard ? null : 
      <Tooltip title="Versions">
        <IconButton
          variant="contained"
          color="primary"
          style={{ textTransform: "capitalize"}}
          onClick={() => props.navigate(`/app/ams/campaignVersions/${buttonProps?.data?.campaignId}`)}
          >
          <Version/>
        </IconButton>
      </Tooltip>
  };

  const scheduleButtonRender = (buttonProps) => {
  if (buttonProps.data.targetingType === "AUTO") {
    return  <Tooltip title="Create Campaign">
      <IconButton
        variant="contained"
        color="primary"
        size="medium"
        style={{ textTransform: "capitalize", padding: "0px !important" }}
        onClick={() => props.openSchedule(buttonProps?.data)}
      >
        <Schedule/>
      </IconButton>
    </Tooltip>
    } else {
      return null;
    }
  }

  const optimizationButtonRender = (buttonProps) => {
    if (!buttonProps.data.runOptimization) {
      return <Tooltip title="Start Optimisation">
          <IconButton
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => props.openOptimization(buttonProps?.data)}
          >
            <Start/>
          </IconButton>
        </Tooltip>
    } else {
      return <Tooltip title="Stop Optimisation">
          <IconButton
            variant="contained"
            size="medium"
            onClick={() => {props.stopOptimization(buttonProps?.data)}}
          >
            <Stop color="error"/>
          </IconButton>
        </Tooltip>
    }
  };

  const optimizeNowButtonRender = (buttonProps) => {
    if (buttonProps?.data?.runOptimization) {
      return <Tooltip title="Start Optimisation">
          <IconButton
          variant="contained"
          size="medium"
          color="primary"
          style={{
            textTransform: "capitalize",
          }}
          onClick={() => props.optimizeNow(buttonProps?.data)}
        >
          <Optimize/>
        </IconButton>
      </Tooltip>
    } else return <div/>
  };

  const dayPartingButtonRenderer = (buttonProps) => {
      return <IconButton
        variant="contained"
        color="primary"
        size="medium"
        style={{ textTransform: "capitalize" }}
        onClick={() => props.openWeeklySchedule(buttonProps?.data)}
      >
        Add Day Parting
      </IconButton>
    }

  const onSelectionChanged = () => {
    const selectedRows = gridApi.getSelectedRows();
    props.selectedRowData(selectedRows)
  };

  return (
    <div className="ag-theme-material ams-campaigns" style={{height: 620, width: '100%'}} id="#grid-theme-wrapper">
      <AgGridReact
        onSelectionChanged={onSelectionChanged}
        headerCheckboxSelectionCurrentPageOnly={true} 
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable:true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={50}
        rowStyle={rowStyle}
        frameworkComponents={{
          editButtonRenderer: EditButtonRenderer,
          versionButtonRenderer: VersionButtonRenderer,
          scheduleButtonRender: scheduleButtonRender,
          optimizeButtonRender: optimizationButtonRender,
          optimizeNowButtonRender: optimizeNowButtonRender,
          dayPartingButtonRenderer:dayPartingButtonRenderer
        }}
        fullWidth
        suppressDragLeaveHidesColumns={true}
        suppressRowClickSelection={true}
        suppressLoadingOverlay
      >
        <AgGridColumn headerName='' width={50} headerCheckboxSelection={true}  pinned="left" checkboxSelection={true}/>
        <AgGridColumn headerName="Campaign" field="name" width={290}/>
        <AgGridColumn headerName="ACOS" field="report.ACOS" width={100} valueFormatter = {calcACOS}/>
        <AgGridColumn headerName="Impressions" field="report.Impressions" width={120} valueFormatter = {intParse} comparator={sortNumber}/>
        <AgGridColumn headerName="Sales" field="report.Sales(USD)" width={100} valueFormatter = {(params) => floatParseWithSymbol(params, "$")} comparator={sortNumber}/>
        <AgGridColumn headerName="Spend" field="report.Spend(USD)" width={100} valueFormatter = {(params) => floatParseWithSymbol(params, "$")} comparator={sortNumber}/>
        <AgGridColumn headerName="Clicks" field="report.Clicks" width={80} valueFormatter = {intParse} comparator={sortNumber}/>
        <AgGridColumn headerName="Orders" field="report.Orders" width={100} valueFormatter = {intParse} comparator={sortNumber}/>
        <AgGridColumn
          width={60}
          cellRenderer={"scheduleButtonRender"}
          cellEditorParams={{
            cellRenderer: "scheduleButtonRender",
          }}
        />
        <AgGridColumn
          width={60}
          cellRenderer="optimizeButtonRender"
          cellEditorParams={{
            cellRenderer: "optimizeButtonRender",
          }}
        />
        <AgGridColumn
          width={60}
          cellRenderer="versionButtonRenderer"
          cellEditorParams={{
            cellRenderer: "versionButtonRenderer",
          }}
        />
        <AgGridColumn
          width={60}
          cellRenderer="optimizeNowButtonRender"
          cellEditorParams={{
            cellRenderer: "optimizeNowButtonRender",
          }}
        />
      </AgGridReact>
    </div>
  );
})

const calcACOS = (params) => params.value ? `${(parseFloat(params.value) * 100).toFixed(2)}%` : "-";
const intParse = (params) => params.value ? (parseInt(params.value)) : "-";
const floatParseWithSymbol = (params, symbol) => params.value ? (symbol + Number(parseFloat(params.value)).toFixed(2)) : "-";
const sortNumber = (a, b) => a - b;
