import React, { useState, useEffect } from "react";
import {
  Dialog,
  Grid,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  FormControlLabel,
  TextField,
  Divider,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

//helpers
import { BASE_URL } from "../../../../healpers/api";
import api from "../../../../healpers/apiRoutes";

//context
import { tokenConfig } from "../../../../context/UserContext";
import { useVendorState } from "../../../../context/VendorContext";

//styles
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight: 600,
    fontSize: 20,
  },
}));

export default function PageModal({
  showmodal,
  closeModal,
  toggleError,
  toggleNotification,
  setfullPageLoader,
}) {
  const vendorState = useVendorState();

  const classes = useStyles();
  const [casePack, setCasePack] = useState({});

  async function onCasePackChange(event) {
    event.persist();
    setCasePack((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  }

  async function addCasePackDetails() {
    setfullPageLoader(true);
    try {
      await axios.post(BASE_URL + api.addCasePackDetails, {
        vendorCode: vendorState?.selected,
        asin: casePack["asin"],
        sku: casePack["sku"],
        unitsPerCase: Number(casePack["unitsPerCase"]),
        length: Number(casePack["length"]),
        width: Number(casePack["width"]),
        height: Number(casePack["height"]),
        weight: Number(casePack["weight"]),
      });
      toggleNotification("Added");
    } catch (err) {
      console.log(err);
      toggleError("Error occured");
    } finally {
      setfullPageLoader(false);
    }
  }

  async function validateAddress() {
    if (
      Object.keys(casePack).length !== 7 ||
      Object.values(casePack).some((value) => !value)
    ) {
      toggleError("Please fill all the required fields");
      return;
    }

    await addCasePackDetails();
    setCasePack({});
  }

  return (
    <Dialog onClose={closeModal} open={showmodal} maxWidth="lg">
      <DialogContent>
        <form autoComplete="off">
          <Grid container spacing={2} style={{ padding: 30 }}>
            <Grid item xs={12}>
              <Typography color="textPrimary" variant="h3">
                Case Pack Details
              </Typography>
              <br />
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="asin"
                value={casePack?.asin ?? ""}
                onChange={onCasePackChange}
                label="ASIN"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="sku"
                value={casePack?.sku ?? ""}
                onChange={onCasePackChange}
                label="SKU"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="unitsPerCase"
                value={casePack?.unitsPerCase ?? ""}
                onChange={onCasePackChange}
                label="Units Per Case"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="length"
                value={casePack?.length ?? ""}
                onChange={onCasePackChange}
                label="Length"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="width"
                value={casePack?.width ?? ""}
                onChange={onCasePackChange}
                label="Width"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="height"
                value={casePack?.height ?? ""}
                onChange={onCasePackChange}
                label="Height"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="weight"
                value={casePack?.weight ?? ""}
                onChange={onCasePackChange}
                label="Weight"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            closeModal();
            setCasePack({});
          }}
        >
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={validateAddress}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
