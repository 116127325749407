import React, { useState, useEffect } from "react";
import {
  Grid,
  Breadcrumbs,
  Container,
  CircularProgress,
  Backdrop,
  Link,
} from "@material-ui/core";
import axios from "axios";
import "react-responsive-modal/styles.css";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import PageTitle from "../../components/PageTitle/PageTitle";
import { tokenConfig } from "../../context/UserContext";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import Versions from "./components/Version/Version";
import Campaign from "./components/Version/Campaign";

export default function CampaignVersions(props) {
  var classes = useStyles();
  // local

  const INITIAL_FORM_STATE = {
    keywords: [],
    defaultKeywords: [],
    negativeKeywordsExact: [],
    negativeKeywordsPhrase: [],
    defaultNegativeKeywords: [],
    productTargets: [],
    defaultProductTargets: [],
    negativeProducts: [],
    defaultNegativeProducts: [],
    negativeKeywordType: "Negative exact",
    manualTargetingType: null,
    adGroupId: null,
  };

  const AMS_TARGETING_GROUPS = {
    QUERY_BROAD_REL_MATCHES: "QUERY_BROAD_REL_MATCHES",
    QUERY_HIGH_REL_MATCHES: "QUERY_HIGH_REL_MATCHES",
    ASIN_ACCESSORY_RELATED: "ASIN_ACCESSORY_RELATED",
    ASIN_SUBSTITUTE_RELATED: "ASIN_SUBSTITUTE_RELATED"
  }

  const [mainTableData, setmainTableData] = useState(null);
  const [campaignData, setCampaignData] = useState(INITIAL_FORM_STATE);
  const [fullPageLoader, setfullPageLoader] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(false);

  async function loadData(campaignId) {
    try {
      setfullPageLoader(true);

      axios
        .get(
          BASE_URL + api.getAMSCampaignVersions,
          tokenConfig({ campaignId })
        )
        .then((result) => {
          if (result.data.data) {
            const data = [];
            result.data.data.forEach(value => {
              const keywords = [];
              value.keywords.forEach(keyword => {
                keywords.push(`${keyword.keywordText}-${keyword.matchType}-$${keyword.bid}`);
              });
              value.keywords = keywords;
              const negativeKeywords = [];
              value.negativeKeywords.forEach(keyword => {
                negativeKeywords.push(`${keyword.keywordText}-${keyword.matchType}`);
              });
              value.negativeKeywords = negativeKeywords;
              value.productTargets = value.productTargets.map(target => `${target.expression[0].value}-$${target.bid}`);
              if(value.campaignType === "AUTO" && value.targetGroups){
                value.targetGroups = value.targetGroups.map(target => `${parseTargetingGroup(target.expression[0].type)}-$${target.bid}`);
              }
              value.negativeProductTargets = value.negativeProductTargets.map(target => `${target.expression[0].value}`);
              value.placementTop = value.biddingadjustments?.find(data => data.placement === "PLACEMENT_TOP")?.percentage || 0
              value.placementProductPage = value.biddingadjustments?.find(data => data.placement === "PLACEMENT_PRODUCT_PAGE")?.percentage || 0
              value.placementRestOfSearch = value.biddingadjustments?.find(data => data.placement === "PLACEMENT_REST_OF_SEARCH")?.percentage || 0
              data.push(value);
            })
            console.log("data", data);
            setmainTableData(data);
            setCampaignData((prevState) => ({ ...prevState, ...data[0]}));
            setfullPageLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setfullPageLoader(false);
        });
    } catch (error) {
      console.log(error);
      setfullPageLoader(false);
    }
  }

  const parseTargetingGroup = (name) => {
    if(name === AMS_TARGETING_GROUPS.QUERY_BROAD_REL_MATCHES) {
      return "loosematch"
    } else if(name === AMS_TARGETING_GROUPS.QUERY_HIGH_REL_MATCHES) {
      return "closematch"
    }else if(name === AMS_TARGETING_GROUPS.ASIN_ACCESSORY_RELATED) {
      return "complements"
    }else if(name === AMS_TARGETING_GROUPS.ASIN_SUBSTITUTE_RELATED) {
      return "substitutes"
    }
  }

  useEffect(() => {
    if (props?.match?.params?.id) {
      loadData(props?.match?.params?.id);
    }
    return () => {
      setmainTableData(null);
    };
  }, []);

  const getKeywords = async (campaign) => {
      setfullPageLoader(true);
      const result = await axios.get(
        BASE_URL + api.getAMSCampaign,
        tokenConfig({
          campaignId: campaign.campaignId,
          campaignType: campaign.campaignType,
          targetingType: campaign.targetingType,
        })
      );
      if (result.data.data) {
        let newCampaignData = {
          ...campaignData,
          keywords: result.data.data.keywords
            .filter((keywords) => keywords.state === "ENABLED")
            .map((keyword) => keyword.keywordText)
            .reduce(
              (unique, item) =>
                unique.includes(item) ? unique : [...unique, item],
              []
            ),

          productTargets: result.data.data.productTargets
            .filter((asin) => asin.state === "ENABLED")
            .map((asin) => asin.expression[0].value)
            .reduce(
              (unique, item) =>
                unique.includes(item) ? unique : [...unique, item],
              []
            ),

          negativeKeywordsExact: result.data.data.negativeKeywords
            .filter(
              (keyword) =>
                keyword.matchType === "NEGATIVE_EXACT" &&
                keyword.state === "ENABLED"
            )
            .map((keyword) => keyword.keywordText)
            .reduce(
              (unique, item) =>
                unique.includes(item) ? unique : [...unique, item],
              []
            ),
          negativeKeywordsPhrase: result.data.data.negativeKeywords
            .filter(
              (keyword) =>
                keyword.matchType === "NEGATIVE_PHRASE" &&
                keyword.state === "ENABLED"
            )

            .map((keyword) => keyword.keywordText)
            .reduce(
              (unique, item) =>
                unique.includes(item) ? unique : [...unique, item],
              []
            ),
          negativeProducts: result.data.data.negativeProductTargets
            .filter((asin) => asin.state === "ENABLED")
            .map((asin) => asin.expression[0].value)
            .reduce(
              (unique, item) =>
                unique.includes(item) ? unique : [...unique, item],
              []
            ),
          defaultKeywords: result.data.data.keywords,
          defaultProductTargets: result.data.data.productTargets,
          defaultNegativeKeywords: result.data.data.negativeKeywords,
          defaultNegativeProducts: result.data.data.negativeProductTargets,
          manualTargetingType: result.data.data.manualTargetingType,
          adGroupId: result.data.data.adGroupId,
        };
        setfullPageLoader(false);
        return newCampaignData;
      }
  };

  const editCampaign = async (campaign) => {
    const campaignData = await getKeywords(campaign);
    console.log('campaignData', campaignData);
    let body = {
      campaignId: campaign.campaignId,
      dailyBudget: parseFloat(campaign.dailyBudget),
      campaignState: campaign.state,
      endDate: campaign.endDate,
      campaignType: "sp",
      vendorCode: campaign.vendorCode,
      campaignName: campaign.name,
      manualTargetingType: campaignData.manualTargetingType,
      adGroupId: campaignData.adGroupId,
      keywords: {
        shouldDelete: campaignData.defaultKeywords
          .filter(
            (keyword) => !campaign.keywords.includes(keyword.keywordText)
          )
          .map((keyword) => keyword.keywordId),
        shouldInsert: campaign.keywords.filter((keyword) => {
          if (
            campaignData.defaultKeywords.find(
              (dk) => dk.keywordText === keyword
            )
          )
            return false;
          else return true;
        }),
      },

      negativeKeywords: {
        shouldDelete: campaignData.defaultNegativeKeywords
          .filter((keyword) => {
            if (
              keyword.matchType === "NEGATIVE_EXACT" &&
              !campaign.negativeKeywords.includes(keyword.keywordText)
            )
              return true;

            if (
              keyword.matchType === "NEGATIVE_PHRASE" &&
              !campaign.negativeKeywords.includes(keyword.keywordText)
            )
              return true;
            return false;
          })
          .map((keyword) => keyword.keywordId),
        shouldInsert: {
          exact: campaign.negativeKeywords.filter((keyword) => {
            if (
              campaignData.defaultNegativeKeywords.find(
                (dnk) =>
                  dnk.matchType === "NEGATIVE_EXACT" &&
                  dnk.keywordText === keyword
              )
            )
              return false;
            else return true;
          }),
          phrase: campaign.negativeKeywords.filter((keyword) => {
            if (
              campaignData.defaultNegativeKeywords.find(
                (dnk) =>
                  dnk.matchType === "NEGATIVE_PHRASE" &&
                  dnk.keywordText === keyword
              )
            )
              return false;
            else return true;
          }),
        },
      },

      negativeProductTargets: {
        shouldDelete: campaignData.defaultNegativeProducts
          .filter(
            (product) =>
              !campaign.negativeProductTargets.includes(
                product.expression[0].value
              )
          ) 
          .map((product) => product.targetId),
        shouldInsert: campaign.negativeProductTargets.filter((asin) => {
          if (
            campaignData.defaultNegativeProducts.find(
              (dk) => dk.expression[0].value === asin
            )
          )
            return false;
          else return true;
        }),
      },

      productTargets: {
        shouldDelete: campaignData.defaultProductTargets
          .filter(
            (product) =>
              !campaign.productTargets.includes(product.expression[0].value)
          )
          .map((product) => product.targetId),
        shouldInsert: campaign.productTargets.filter((asin) => {
          if (
            campaignData.defaultProductTargets.find(
              (dk) => dk.expression[0].value === asin
            )
          )
            return false;
          else return true;
        }),
      },
    };
    console.log({ body, campaignData });
    axios
      .put(BASE_URL + api.editAMSCampaigns, body, tokenConfig())
      .then(() => {
        loadData(props?.match?.params?.id);
        setfullPageLoader(false);
        setDisableSubmit(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        loadData(props?.match?.params?.id);
        setfullPageLoader(false);
        setDisableSubmit(false);
      });
  };

  return (
    <Container maxWidth={false}>
      <PageTitle
        title="Campaign versions"
        breadCrump={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className={classes.link}>
              AMS
            </Link>
            <Typography className={classes.link} color="primary">
              Campaign versions
            </Typography>
          </Breadcrumbs>
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            upperTitle
            bodyClass={classes.tableOverflow}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    color: "black",
                  }}
                >
                  Versions
                </Typography>
              </div>
            }
          >
            <Grid container>
              {campaignData && <Campaign campaignData={Object.entries(campaignData)} />}
            </Grid>
            <br /> <br />
            {mainTableData &&
              mainTableData.map((data, indx) => (
                <Versions
                  key={indx}
                  actualVersion={data}
                  oldVersion={
                    mainTableData[
                      indx + 1 < mainTableData.length ? indx + 1 : indx
                    ]
                  }
                  rollbackDisabled={disableSubmit}
                  editCampaign={editCampaign}
                  navigate={props.history.push}
                />
              ))}
          </Widget>
        </Grid>
      </Grid>
      <Backdrop
        className={classes.backdrop}
        open={fullPageLoader}
        onClick={() => setfullPageLoader(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
