import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  CircularProgress,
  Backdrop,
  TextField,
  Box,
  FormControl,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import * as csv from "csvtojson";
import SearchOrganisationsTemplate from "../../healpers/assets/SearchOrganisationsTemplate.csv";

//icons
import PrintIcon from "@material-ui/icons/Print";
import SearchIcon from "@material-ui/icons/Search";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { tokenConfig, useUserState } from "../../context/UserContext";

export default function SearchOrganisations() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var userState = useUserState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);
  const [searchValue, setSearchValue] = useState();

  const fetchContacts = (search) => {
    const list = search?.split(" ") || searchValue?.split(" ");
    console.log(`list`, list);
    setfullPageLoader(true);
    axios
      .get(BASE_URL + api.searchOrganisationsFromApolloApi, tokenConfig({ domain: list }))
      .then((res) => {
        setmainTableData(res.data?.data);
        setfullPageLoader(false);
      })
      .catch((err) => {
        console.log(err);
        seterr(err.response?.data?.msg ?? "Something went wrong");
        setTimeout(() => seterr(null), 3000);
        setfullPageLoader(false);
      });
  };

  useEffect(() => {
    if (userState.userData && searchValue) {
      fetchContacts();
    }
  }, [userState.userData]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!isCSVFile(file)) {
      alert("Provide valid CSV file");
      return;
    }

    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = function () {
      uploadCSV(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
    e.target.value = null;
  };

  function uploadCSV(parsedCSVData) {
    setfullPageLoader(true);
    csv()
      .fromString(parsedCSVData)
      .then(function (data) {
        let str = "";
        data.forEach(value => {
          str += `${value.Domain} `
        });
        setSearchValue(str);
        fetchContacts(str);
      });
  }

  function isCSVFile(file) {
    return file.name.split(".")[1] === "csv";
  }

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Search Organisations"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Brand Finder
              </Link>
              <Typography className={classes.link} color="primary">
                Search Organisations
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <form
                className={classes.root}
                noValidate
                autoComplete="off"
                style={{ minWidth: 800 }}
                onSubmit={(e) => {
                  e.preventDefault();
                  fetchContacts()
                }}
              >
                <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                  <TextField
                    id="outlined-search"
                    label="Search"
                    variant="outlined"
                    fullWidth
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    size="small"
                    style={{ marginRight: 20 }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    style={{ minWidth: 50, paddingLeft: 25 }}
                    startIcon={<SearchIcon />}
                    size="medium"
                    disabled={!searchValue}
                    onClick={fetchContacts}
                  >
                  </Button>
                  <a
                  href={SearchOrganisationsTemplate}
                  download="SearchOrganisationTemplate.csv"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    component="span"
                    size="small"
                    startIcon={<GetAppIcon />}
                    style={{ marginLeft: ".5rem", width: 200 }}
                  >
                    Download Template
                  </Button>
                </a>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <label htmlFor="upload-csv">
                      <input
                        style={{ display: "none" }}
                        id="upload-csv"
                        name="upload-csv"
                        type="file"
                        accept=".csv"
                        onChange={handleFileChange}
                      />
                      <Button
                        color="primary"
                        variant="contained"
                        component="span"
                        size="small"
                        startIcon={<PublishIcon />}
                      >
                        Upload CSV
                      </Button>
                    </label>
                  </FormControl>
                </Box>
              </form>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} />
            </Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {notification ? (
        <Alert
          severity="success"
          style={{ position: "absolute", bottom: 30, left: "48%" }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{ position: "absolute", bottom: 30, left: "48%" }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
