import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { IconButton } from "@material-ui/core";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import '../styles'
import { getStatusColor, numberWithCommas } from "../../../healpers/utilityFunctions";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TableRowsIcon from '@mui/icons-material/TableRows';
import { RestartAlt } from "@mui/icons-material";

export default forwardRef(function Tables(props, ref) {

  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);


  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else
        gridApi.exportDataAsCsv();
    }
  }));

  const rowStyle = { background: 'white', width: '100%' };

  const EditButtonRenderer = (buttonProps) => {
    return (
      <IconButton
        onClick={() => { props.openEditModel(buttonProps?.data) }}
        style={{padding: "0px !important" }}
        variant="contained"
        size="medium"
      >
        <EditIcon color="primary" />
      </IconButton>
    );
  };

  const RestartRuleButtonRenderer = (buttonProps) => {
    return (
      <IconButton
        onClick={() => props.restartRule(buttonProps?.data?.id, buttonProps?.data?.vendorCode)}
        style={{ padding: "0px !important" }}
        variant="contained"
        size="medium"
      >
        <RestartAlt color="primary" />
      </IconButton>
    );
  };

  const DeleteButtonRenderer = (buttonProps) => {
    return (
      <IconButton
        onClick={() => props.deleteConfirmation(buttonProps?.data)}
        style={{padding: "0px !important" }}
        variant="contained"
        size="medium"
      >
        <DeleteIcon color="error" />
      </IconButton>
    );
  };

  const ViewButtonRenderer = (buttonProps) => {
    return (
      !buttonProps.data?.overallStatus?.status ? null :
      <IconButton
        onClick={() => props.view(buttonProps?.data)}
        style={{padding: "0px !important" }}
        variant="contained"
        size="medium"
      >
        <TableRowsIcon color="primary" />
      </IconButton>
    );
  };

  const StatusRenderer = (buttonProps) => {
    return (
      <div style={
        { 
          color: getStatusColor(buttonProps.data?.overallStatus?.status || "PROCESSING"),
        }
      }>
        {buttonProps.data?.overallStatus?.status ? buttonProps.data?.overallStatus?.status : buttonProps.data.isActive ? "PROCESSING" : "INACTIVE"}
      </div>
    );
  };

  return (
    <div className="ag-theme-material" style={{ height: 620, width: '100%' }} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            "  </div>" +
            "</div>",
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={50}
        rowStyle={rowStyle}
        frameworkComponents={{
          editButtonRenderer: EditButtonRenderer,
          restartRuleButtonRenderer: RestartRuleButtonRenderer,
          deleteButtonRenderer: DeleteButtonRenderer,
          viewButtonRenderer: ViewButtonRenderer,
          statusRenderer: StatusRenderer
        }}
        fullWidth
        suppressDragLeaveHidesColumns={true}
        suppressRowClickSelection={true}
      >
        <AgGridColumn headerName="Rule Name" field="name" />
        <AgGridColumn headerName="Status" width={130} cellRenderer={"statusRenderer"}  valueGetter={(params) => params.data?.overallStatus?.status || (params.data.isActive ? "PROCESSING" : "INACTIVE")} />
        <AgGridColumn headerName="Metric" field="metric" />
        <AgGridColumn headerName="Type" field="type" width={100}/>
        <AgGridColumn headerName="Reports Available ASINs" field="overallStatus.reportAvailableASINs" />
        <AgGridColumn headerName="Current" field="overallStatus.currentValue" valueFormatter={(params) => roundValue(params)} width={150} />
        <AgGridColumn headerName="Target" width={150} field="overallStatus.target" valueFormatter={(params) => roundValue(params)} />
        <AgGridColumn headerName="Current Range" width={170} field="range" />
        <AgGridColumn headerName="Current Date Range" field="currentDateRange"  />
        <AgGridColumn headerName="Comparison Range" field="comparingRange" />
        <AgGridColumn headerName="Comparison Date Range" field="comparisonDateRange"  />
        <AgGridColumn headerName="Sales (Units)" field="overallStatus.actualSales" valueFormatter={(params) => formatSales(params)} width={150} />
        <AgGridColumn headerName="Forecast" field="overallStatus.forecast" valueFormatter={(params) => formatSales(params)} width={150} />
        <AgGridColumn headerName="Is Active" field="isActive"  width={120} valueFormatter={(params) => params.value ? "Yes" : "No"}/>
        <AgGridColumn headerName="Red" field="asinsInRed" width={100}/>
        <AgGridColumn headerName="Amber" field="asinsInAmber" width={120}/>
        <AgGridColumn headerName="Green" field="asinsInGreen" width={120}/>
        <AgGridColumn
          width={80}
          cellRenderer="viewButtonRenderer"
        />
        <AgGridColumn
          width={80}
          cellRenderer="restartRuleButtonRenderer"
        />
        <AgGridColumn
          width={80}
          cellRenderer="editButtonRenderer"
        />
        <AgGridColumn
          width={80}
          cellRenderer="deleteButtonRenderer"
        />
      </AgGridReact>
    </div>
  );
})


const roundValue = (params) => {
  if (params.value) {
    return numberWithCommas(params.value, 2)
  }
}

const formatSales = (params) => {
  if (params.value) {
    return numberWithCommas(params.value)
  }
}



