import {
  Button,
  Grid,
  Modal,
  TextField,
  Backdrop,
  Box,
  Fade,
} from "@material-ui/core";
import useStyles from "./styles";

const AddShipmentItemModal = ({
  open,
  handleClose,
  shipmentItem,
  addShipmentItem,
  handleShipmentItemChange,
}) => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h1 style={{ textAlign: "center" }}>Add Shipment Item</h1>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            style={{ marginBottom: 10 }}
          >
            <Grid item>
              <TextField
                name="asin"
                label="ASIN"
                type="text"
                variant="outlined"
                value={shipmentItem?.asin}
                onChange={handleShipmentItemChange}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            justifyContent="center"
            style={{ marginBottom: 10 }}
          >
            <Grid item>
              <TextField
                name="sku"
                label="SKU"
                type="text"
                variant="outlined"
                value={shipmentItem?.sku}
                onChange={handleShipmentItemChange}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            justifyContent="center"
            style={{ marginBottom: 10 }}
          >
            <Grid item>
              <TextField
                name="quantity"
                label="Quantity"
                type="number"
                variant="outlined"
                value={shipmentItem?.quantity}
                onChange={handleShipmentItemChange}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            justifyContent="center"
            style={{ marginBottom: 10 }}
          >
            <Grid item>
              <TextField
                name="quantityInCase"
                label="Quantity In Case"
                type="number"
                variant="outlined"
                value={shipmentItem?.quantityInCase}
                onChange={handleShipmentItemChange}
              />
            </Grid>
          </Grid>

          <Box style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              color="primary"
              style={{ width: "150px" }}
              onClick={addShipmentItem}
              disabled={
                !(
                  shipmentItem?.asin &&
                  shipmentItem?.sku &&
                  shipmentItem?.quantity &&
                  shipmentItem?.quantityInCase
                )
              }
            >
              add
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginLeft: "10px", width: "150px" }}
              onClick={handleClose}
            >
              close
            </Button>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddShipmentItemModal;
