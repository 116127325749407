import React, { useState, useRef, useEffect } from "react";
import {
  Breadcrumbs,
  Select,
  FormControl,
  MenuItem,
  Container,
  CircularProgress,
  Backdrop,
  InputLabel,
  Link,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  Button,
} from "@material-ui/core";
import moment from "moment";

//icons
import PrintIcon from "@material-ui/icons/Print";

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import Table from "./components/Table";
import Widget from "../../components/Widget/Widget";


//context
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";

//helpers
import { cloneAndSortDescending } from "../../healpers/dateRange.helpers";
import { calcACOS, getMonthDatesBetween, getQuarterDatesBetween, getWeekDatesBetween, getYearDatesBetween, numberWithCommas } from "../../healpers/utilityFunctions";
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import axios from "axios";
import _ from "lodash";
import * as XLSX from "xlsx";
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export default function ASINPerformance(props) {
  var classes = useStyles();
  const topPerformingExportRef = useRef();
  const lowPerformingExportRef = useRef();
  const exportFunctionRef = useRef();

  //global
  var vendorState = useVendorState();
  // local
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [radioValue, setRadioValue] = useState("monthly");
  const [dateRange, setDateRange] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(1);
  const [weekRange, setWeekRange] = useState(null);
  const [monthRange, setMonthRange] = useState(null);
  const [quarterRange, setQuarterRange] = useState(null);
  const [annualRange, setAnnualRange] = useState(null);
  const [range, setRange] = useState({
    startDate: new Date(moment("01-01-2023", "DD-MM-YYYY")),
    endDate: new Date(),
  });
  const [mainTableData, setMainTableData] = useState([]);
  const [portfolios, setPortfolios] = useState([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState("all");
  const [toDate, setToDate] = useState(moment().endOf('month'))
  const [fromDate, setFromDate] = useState(moment().startOf('month'))

  useEffect(() => {
    if (range) {
      const weekDateRange = cloneAndSortDescending([...getWeekDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const monthDateRange = cloneAndSortDescending([...getMonthDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const quarterDateRange = cloneAndSortDescending([...getQuarterDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const annualDateRange = cloneAndSortDescending([...getYearDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      setDateRange(monthDateRange);
      if (monthDateRange.length !== 0) setSelectedDateRange(1);
      setWeekRange(weekDateRange);
      setMonthRange(monthDateRange);
      setQuarterRange(quarterDateRange);
      setAnnualRange(annualDateRange);
      if (monthDateRange.length) {
        setFromDate(moment(monthDateRange[1].startdate, 'MM/DD/YYYY').format())
        setToDate(moment(monthDateRange[1].enddate, 'MM/DD/YYYY').format())
      }
    }
    return () => {
      setDateRange(null);
      setWeekRange([]);
      setMonthRange([]);
      setQuarterRange([]);
      setAnnualRange([]);
    };
  }, [range]);

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    if (event.target.value === "weekly") {
      setDateRange(weekRange);
      setSelectedDateRange(1);
    } else if (event.target.value === "monthly") {
      setDateRange(monthRange);
      setSelectedDateRange(1);
    } else if (event.target.value === "quarterly") {
      setDateRange(quarterRange);
      setSelectedDateRange(1);
    } else {
      setDateRange(annualRange);
      setSelectedDateRange(0);
    }
  };

  useEffect(() => {
    if(vendorState.selected) {
      loadData()
      fetchPortfolios()
    }
  }, [vendorState.selected, selectedDateRange, dateRange, selectedPortfolio, fromDate, toDate])

  async function loadData() {
    try {
      setFullPageLoader(true);
      let params = {
        vendorCode: vendorState?.selected,
        startDate: moment(dateRange[selectedDateRange].startdate).format("MM-DD-YYYY"),
        endDate: moment(dateRange[selectedDateRange].enddate).format("MM-DD-YYYY"),
        selectedPortfolio: selectedPortfolio
      };
      if(radioValue === 'custom') {
        params.startDate = moment(fromDate).format("MM-DD-YYYY")
        params.endDate = moment(toDate).format("MM-DD-YYYY")
      }
      const result = await axios.get(BASE_URL + api.asinPerformance, tokenConfig(params))
      if (result.data) {
        setMainTableData(result.data.reverse())
        setFullPageLoader(false);
      }
    } catch (error) {
      console.log(error);
      setFullPageLoader(false);
    }
  }

  async function fetchPortfolios() {
    try {
      let params = {
        vendorCode: vendorState?.selected,
      };
      const result = await axios.get(BASE_URL + api.fetchPortfolios, tokenConfig(params))
      if (result.data) {
        setPortfolios(result.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const exportTable = async (asinReports) => {
    try {
      const reports = [];
      const workbook = XLSX.utils.book_new();
      asinReports.forEach(report => {
        reports.push({
          "ASIN": report.asin,
          "Campaign Name": report.campaignName,
          "ACOS": calcACOS(report.acos),
          "ROAS": calcACOS(report.roas),
          "Sales": numberWithCommas(report.sales, 2),
          "Impressions": numberWithCommas(report.impressions),
          "Spend": numberWithCommas(report.cost, 2),
          "Clicks": numberWithCommas(report.clicks),
          "CTR": report.ctr,
          "CPC": report.cpc
        })
      }) 
      const worksheet = XLSX.utils.json_to_sheet(reports);

      const fileName = `${vendorState?.selected} ASIN Report`;
      XLSX.utils.book_append_sheet(workbook, worksheet, fileName);

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const excelBlob = new Blob([excelBuffer], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      var worksheetFileDownloadEle = document.createElement("a");
      worksheetFileDownloadEle.download = fileName + ".xlsx";
      worksheetFileDownloadEle.href = window.URL.createObjectURL(excelBlob);
      worksheetFileDownloadEle.click();
    } catch (error) {
      console.log("Export asin reports error", error)
    }
  }

  return (
    <Container maxWidth={false}>
      <PageTitle
        title="ASINs Performance"
        breadCrump={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className={classes.link}>
              AMS Reports
            </Link>
            <Typography className={classes.link} color="primary">
              ASINs Performance
            </Typography>
          </Breadcrumbs>
        }
        rightAlignComponent={
          <Grid container style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexWrap: "nowrap", width: '100%', alignItems:'center' }}>
            <FormControl>
              <RadioGroup value={radioValue} onChange={handleRadioChange}>
                <FormControlLabel
                  value="weekly"
                  control={<Radio color="primary" />}
                  label="Weekly"
                />
                <FormControlLabel
                  value="monthly"
                  control={<Radio color="primary" />}
                  label="Monthly"
                />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <RadioGroup value={radioValue} onChange={handleRadioChange}>
                <FormControlLabel
                  value="quarterly"
                  control={<Radio color="primary" />}
                  label="Quarterly"
                />
                <FormControlLabel
                  value="custom"
                  control={<Radio color="primary" />}
                  label="Custom"
                />
              </RadioGroup>
            </FormControl>
            {radioValue !== 'custom' && <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Select Range</InputLabel>
              <Select
                value={selectedDateRange}
                onChange={(e) => {
                  setSelectedDateRange(e.target.value);
                }}
                label="Select Range"
                disabled={dateRange && dateRange.length === 0 ? true : false}
              >
                {dateRange ? (
                  dateRange.map((range, index) => {
                    return <MenuItem value={index}>{range.startdate + " - " + range.enddate}</MenuItem>;
                  })
                ) : (
                  <MenuItem value={0}>
                    <i>No Dates</i>
                  </MenuItem>
                )}
              </Select>
            </FormControl>}
            {radioValue === 'custom' &&
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <FormControl
                  variant="outlined"
                  className={classes.dateFormControl}
                  style={{paddingRight:'5px'}}
                >
                  <KeyboardDatePicker
                    disableToolbar
                    inputVariant="outlined"
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="startDate"
                    label="From"
                    value={fromDate}
                    onChange={(date) => setFromDate(date)}
                    style={{ width: '170px' }}
                  />
                </FormControl>
                <FormControl
                  variant="outlined"
                  className={classes.dateFormControl}
                  style={{paddingRight:'5px'}}
                >
                  <KeyboardDatePicker
                    disableToolbar
                    inputVariant="outlined"
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="startDate"
                    label="To"
                    value={toDate}
                    onChange={(date) => setToDate(date)}
                    minDate={fromDate}
                    style={{ width: '170px' }}
                  />
                </FormControl>
              </MuiPickersUtilsProvider>}
              <FormControl variant="outlined" className={classes.formControl} style={radioValue === 'custom' ? { paddingTop: '5px' } : {}}>
              <InputLabel id="demo-simple-select-outlined">
                Select Portfolio
              </InputLabel>
              <Select
                value={selectedPortfolio}
                onChange={(e) => setSelectedPortfolio(e.target.value)}
                label="Select Portfolio"
                className={classes.select}
              >
                <MenuItem value={"all"}>
                  All
                </MenuItem>
                {portfolios.length ? (
                  portfolios.map((value, index) => {
                    return (
                      <MenuItem value={value.portfolioId}>
                        {value.name}
                      </MenuItem>
                    );
                  })
                ) : (
                  null
                )}
              </Select>
            </FormControl>
          </Grid>
        }
      />
      <Grid container >
        <Grid item xs={12}>
          <Table ref={exportFunctionRef} tableData={mainTableData} height={600} exportTable={exportTable}/>
        </Grid>
      </Grid>
      <Backdrop
        className={classes.backdrop}
        open={fullPageLoader}
        onClick={() => setFullPageLoader(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
