import React, { useEffect, useState } from 'react';
import './contributionChart.css';
import _ from 'lodash';
import moment from 'moment';
import {
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import useStyles from "./styles";
import { getAllDatesInYears, getDatesInRange, getYearsBetween } from '../../healpers/utilityFunctions';

const ContributionChart = ({ reports, type }) => {
  const [allYears, setAllYears] = useState([])
  const [gridData, setGridData] = useState([])
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [totalGridData, setTotalGridData] = useState({});
  const classes = useStyles();

  useEffect(() => {
    if (reports && reports.length > 0) {
      getGridData();
    } else {
      setGridData([]);
    }
  }, [reports])

  const getGridData = () => {
    setFullPageLoader(true)
    const newGrid = {};
    const currentGrid = {};
    const contributionsByMonthWeekday = {};
    let startYear = new Date(reports[0]).getFullYear()
    let endYear = new Date(reports[reports.length - 1]).getFullYear()
    let yearRange = getYearsBetween(startYear, endYear);
    if (!_.isEmpty(type)) {
      startYear = new Date(reports[0].startDate).getFullYear()
      endYear = new Date(reports[reports.length - 1].endDate).getFullYear()
      yearRange = getYearsBetween(startYear, endYear);
    }
    setAllYears(yearRange);
    setCurrentYear(endYear)
    const reportDates = reports.map((item) => moment(item).format('DD/MM/YYYY'))
    const allDatesInYear = getAllDatesInYears(yearRange)
    allDatesInYear.map((date) => {
      const month = moment(date).month() + 1;
      const dayOfWeek = moment(date).day()
      const year = moment(date).year()
      const key = `${month}-${dayOfWeek}-${year}`
      if (!contributionsByMonthWeekday[key]) {
        contributionsByMonthWeekday[key] = [];
      }
      if (!_.isEmpty(type)) {
        const reportDate = moment(date).format('YYYY/MM/DD')
        const weeklyReports = reports.map((item) => { return { startDate: moment(item.startDate).format('YYYY/MM/DD'), endDate: moment(item.endDate).format('YYYY/MM/DD') } })
        const weekRange = _.find(weeklyReports, { startDate: reportDate })
        if (!_.isEmpty(weekRange)) {
          const getWeekRanges = getDatesInRange(weekRange.startDate, weekRange.endDate)
          getWeekRanges.map((date) => {
            const keyOfWeek = `${new Date(date).getMonth() + 1}-${new Date(date).getDay()}-${new Date(date).getFullYear()}`
            const index = _.findIndex(contributionsByMonthWeekday[keyOfWeek], { date: date })
            if (!contributionsByMonthWeekday[keyOfWeek]) {
              contributionsByMonthWeekday[keyOfWeek] = []
            }
            if (index === -1) {
              contributionsByMonthWeekday[keyOfWeek].push({ date: date, active: true })
            }
          })
        } else {
          const index = _.findIndex(contributionsByMonthWeekday[key], { date: date })
          if (index === -1) {
            contributionsByMonthWeekday[key].push({ date: date, active: false })
          }

        }
      } else {
        const reportDate = moment(date).format('DD/MM/YYYY')
        const index = _.findIndex(reportDates, (item) => { return item === reportDate })
        if (index !== -1) {
          contributionsByMonthWeekday[key].push({ date: date, active: true })
        } else {
          contributionsByMonthWeekday[key].push({ date: date, active: false })
        }

      }
    })
    Object.entries(contributionsByMonthWeekday).forEach(([key, count]) => {
      const [month, dayOfWeek, year] = key.split('-').map(Number);
      if (year === endYear) {
        if (!currentGrid[year]) {
          currentGrid[year] = []
        }
        if (!currentGrid[year][dayOfWeek]) {
          currentGrid[year][dayOfWeek] = []
        }
        currentGrid[year][dayOfWeek].push(...count);
      }
      if (!newGrid[year]) {
        newGrid[year] = []
      }
      if (!newGrid[year][dayOfWeek]) {
        newGrid[year][dayOfWeek] = []
      }
      newGrid[year][dayOfWeek].push(...count);
    });
    setTotalGridData(newGrid)
    const data = []
    Object.values(currentGrid).map((item) => {
      item.map((newItem, index) => {
        if (!data[index]) {
          data[index] = []
        }
        data[index].push(newItem)
      })
    })
    setGridData(data)
    setFullPageLoader(false)
  };

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const filterYearData = (year) => {
    const data = []
    if (!_.isEmpty(totalGridData)) {
      [totalGridData[year]]?.map((item) => {
        item.map((newItem, index) => {
          if (!data[index]) {
            data[index] = []
          }
          data[index].push(newItem)
        })
      })
      setGridData(data)
    }
  }

  return (
    <div className="contribution-chart-container">
      <Grid spacing={4}>
        <Grid item xs={12} className={classes.yearSelect}>
          <FormControl variant="outlined" className={classes.yearFormControl}>
            <InputLabel id="demo-simple-select-outlined-label">
              Year
            </InputLabel>
            <Select
              label="Year"
              value={currentYear}
              onChange={(e) => {
                setCurrentYear(e.target.value);
                filterYearData(e.target.value)
              }}
            >
              {allYears.map((year) => (
                <MenuItem value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {!fullPageLoader && <div className="contribution-chart-scroll">
        <div className="weekdays">
          <div>Sun</div>
          <div>Mon</div>
          <div>Tue</div>
          <div>Wed</div>
          <div>Thu</div>
          <div>Fri</div>
          <div>Sat</div>
        </div>
        <div className="contribution-grid">
          <div className="month-names">
            {monthNames.map((month) => (
              <div >{month}</div>
            ))}
          </div>
          {gridData.map((item, index) => (
            item.map((newItem) => (
              <div className="weekday">
                <div style={{ display: "flex" }}>
                  {newItem.map((data, index) => (
                    <div style={{ display: "flex" }}>
                      <div
                        key={index}
                        className={data.active ? 'day active' : 'day inactive'}
                      >
                        <div className={index === newItem.length - 1 ? "last_element" : "count"}>{moment(data.date).format('DD/MM/YYYY')}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
          )
          )}
        </div>
      </div>}
      <Backdrop
        className={classes.backdrop}
        open={fullPageLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ContributionChart;
