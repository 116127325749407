import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'

import { renderFavicon } from "../../../../healpers/utilityFunctions";
// components

export default forwardRef(function Tables(props, ref) {

  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const getClass = (params) => {
    if (params?.data?.violated) {
      return {
        backgroundColor: '#d8e9d2',
        borderBottom: '1px solid rgba(178,178,178,0.25)'
      };
    } else {
          return {
        backgroundColor: '#ff0000',
        color: 'white',
        borderBottom: '1px solid rgba(178,178,178,0.25)'
      };
    
    }
  }

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else
        gridApi.exportDataAsCsv();
    }
  }));

  const rowStyle = { background: 'white' };

  return (
    <div className="ag-theme-material" style={{ height: 620, width: '100%' }} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        suppressDragLeaveHidesColumns={true}
      >
        <AgGridColumn headerName={''} field={'asin'} width={100} cellRenderer={({ value }) => renderFavicon(value)}/>
        <AgGridColumn headerName="Seller ID" field="sellerId" width={180} />
        <AgGridColumn headerName="SKU" field="sku" width={180} />
        <AgGridColumn headerName="ASIN" field="asin" width={180} />
        <AgGridColumn cellStyle={(params) => getClass(params)} headerName="Offer Price" field="offerPrice" width={300} />
        <AgGridColumn cellStyle={(params) => getClass(params)} headerName="MAP Price" field="mapPrice" width={300} />
      </AgGridReact>
    </div>
  );
})