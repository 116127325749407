import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  FormControl,
  TextField,
  Typography,
  Backdrop,
  IconButton,
  MenuItem,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
} from "@material-ui/core";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useVendorState } from "../../../context/VendorContext";
import axios from "axios";
import api from "../../../healpers/apiRoutes";
import { tokenConfig, useUserState } from "../../../context/UserContext";
import { BASE_URL } from "../../../healpers/api";
import { AMS_STRATEGY, AM_METRICS, AM_METRICS_DATA, AM_RULES_TYPES, COMPARING_RANGE_TYPES, CURRENT_RANGE_TYPES, NOTIFICATION_FREQUENCY, RULE_TYPES, TARGET_TYPES } from "../../../constant/amRules";
import { checkIfReportExists, fetchCategoriesList, fetchUserGroupNames } from "../../../http/api";
import useStyles from "../styles";
import { FormLabel } from "react-bootstrap";
import _ from "lodash";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from '@mui/icons-material/Add';

export default function RuleModal({ open, close, fetchRulesList, edit, ruleId }) {
  const vendorState = useVendorState();
  const userState = useUserState();
  const [ruleName, setRuleName] = useState("");
  const [ruleMetric, setRuleMetric] = useState("");
  const [currentRange, setCurrentRange] = useState(CURRENT_RANGE_TYPES.WTD);
  const [comparingRange, setComparingRange] = useState(COMPARING_RANGE_TYPES.PREVIOUS_PERIOD_WEEKS);
  const [target, setTarget] = useState("");
  const [loading, setLoading] = useState(false);
  const [rule, setRule] = useState("");
  const [targetType, setTargetType] = useState("");
  const [notificationGroup, setNotificationGroup] = useState([{ notificationGroup: "", notificationFrequency: "" }])
  const [notificationFrequency, setNotificationFrequency] = useState("")
  const [comparingValue, setComparingValue] = useState("")
  const [type, setType] = useState(AM_RULES_TYPES.ASINS)
  const [ASIN, setASIN] = useState("")
  const [category, setCategory] = useState("")
  const [notificationGroups, setNotificationGroups] = useState([])
  const [notificationFrequencyList, setNotificationFrequencyList] = useState(NOTIFICATION_FREQUENCY)
  const [categoryList, setCategoryList] = useState([])
  const [fullPageLoader, setFullPageLoader] = useState(false)
  const [active, setActive] = useState(true)
  const [metricsData, setMetricsData] = useState(AM_METRICS_DATA)
  const [errorAsins, setErrorAsins] = useState([])
  const [errorCheck, setErrorCheck] = useState(false)
  
  const classes = useStyles();

  const onModalClose = () => {
    setRuleName("")
    setRuleMetric("")
    setCurrentRange(CURRENT_RANGE_TYPES.WTD)
    setComparingRange(COMPARING_RANGE_TYPES.PREVIOUS_PERIOD_WEEKS)
    setTarget("")
    setTargetType("")
    setNotificationGroup([{ notificationGroup: "", notificationFrequency: "" }])
    setNotificationFrequency("")
    setRule("")
    setComparingValue("")
    setASIN("")
    setCategory("")
    setType(AM_RULES_TYPES.ASINS)
  };

  const createRule = async () => {
    setLoading(true);
    try {
      let body = {
        vendorCode: vendorState?.selected,
        name: ruleName,
        metric: ruleMetric,
        range: currentRange,
        rule,
        comparingRange,
        targetType,
        notificationGroup,
        notificationFrequency,
        createdBy: userState.email,
        target,
        type,
        isActive: active
      };
      if (comparingValue) {
        body.comparingValue = comparingValue
      }
      if (type === AM_RULES_TYPES.ASINS && ASIN !== "") {
        body.asins = ASIN.split(",")
      }
      if (type === AM_RULES_TYPES.CATEGORY && category !== "") {
        body.category = category
      }
      await axios.post(BASE_URL + api.createRule, body, tokenConfig());
      close();
      onModalClose();
      fetchRulesList();
    } catch (error) {
      console.error(error);
      alert("Failed to Create Rule ");
    } finally {
      setLoading(false);
    }
  };

  const fetchNotificationData = async () => {
    try {
      const { data } = await fetchUserGroupNames()
      setNotificationGroups(data)
      console.log("notification groups", data)
    } catch (error) {
      console.log('error -  Failed to load notification data', error)
    }
  }

  const fetchCategoryList = async () => {
    try {
      const { data } = await fetchCategoriesList({ vendorCode: vendorState.selected })
      console.log('data', data);
      setCategoryList(data)
    } catch (error) {
      console.log("error in category list", error)
    }
  }

  useEffect(() => {
    if (edit) {
      getRule();
    }
  }, [edit]);

  useEffect(() => {
    if (vendorState.selected) {
      fetchNotificationData()
      fetchCategoryList()
      filterMetricData()
    }
  }, [vendorState.selected])

  const getRule = async () => {
    try {
      setFullPageLoader(true)
      const params = {
        id: ruleId,
      };
      const response = await axios.get(BASE_URL + api.getRule, tokenConfig(params));
      const ruleData = response.data.data
      setRuleName(ruleData.name)
      setRuleMetric(ruleData.metric)
      setCurrentRange(ruleData.range)
      setComparingRange(ruleData.comparingRange)
      setTarget(ruleData.target)
      setTargetType(ruleData.targetType)
      setNotificationGroup(ruleData.notificationGroup)
      setNotificationFrequency(ruleData.notificationFrequency)
      setRule(ruleData.rule)
      setASIN(ruleData?.asins?.join(","))
      setCategory(ruleData?.category)
      setType(ruleData?.type)
      setComparingValue(ruleData?.comparingValue)
      setFullPageLoader(false)
      setActive(ruleData.isActive)
    } catch (error) {
      console.error(error);
      setFullPageLoader(false)
      alert("Failed to get rule");
    }
  }

  const editRule = async () => {
    try {
      setLoading(true);
      let body = {
        vendorCode: vendorState?.selected,
        name: ruleName,
        metric: ruleMetric,
        range: currentRange,
        rule,
        comparingRange,
        targetType,
        notificationGroup,
        notificationFrequency,
        createdBy: userState.email,
        target,
        id: ruleId,
        type,
        isActive: active
      };
      if (comparingValue) {
        body.comparingValue = comparingValue
      }
      if (category) {
        body.category = category
      }
      if (ASIN) {
        body.asins = ASIN.split(",")
      }
      await axios.post(BASE_URL + api.updateRule, body, tokenConfig());
      close();
      onModalClose();
      fetchRulesList();
    } catch (error) {
      console.error(error);
      alert("Failed to edit action item");
    }
    finally {
      setLoading(false);
    }
  };

  const handleChangeRuleMetric = (e) => {
    setRuleMetric(e.target.value);
    let targetType = _.find(AM_METRICS_DATA, { name: e.target.value })?.targetType;
    if(targetType) {
      setTargetType(targetType);
    } else if(comparingRange !== COMPARING_RANGE_TYPES.VALUE) {
      setTargetType(TARGET_TYPES.PERCENTAGE);
    } else {
      setTargetType(TARGET_TYPES.VALUE);
    }
  }

  const handleComparingRange = (e) => {
    setComparingRange(e.target.value);
    let targetType = _.find(AM_METRICS_DATA, { name: ruleMetric })?.targetType;
    if(targetType) {
      setTargetType(targetType);
    } else if(e.target.value !== COMPARING_RANGE_TYPES.VALUE) {
      setTargetType(TARGET_TYPES.PERCENTAGE);
    } else {
      setTargetType(TARGET_TYPES.VALUE);
    }
  }

  const handleAddNotificationGroup = () => {
    setNotificationGroup([...notificationGroup, { group: "", notificationFrequency: "" }])
  }

  const handleSetNotification = (value, index, group) => {
    let groups = [...notificationGroup];
    if (group) {
      groups[index].notificationGroup = value
    } else {
      groups[index].notificationFrequency = value
    }
    setNotificationGroup(groups)
  }

  const handleDeleteGroup = (index) => {
    const groups = [...notificationGroup];
    groups.splice(index, 1)
    setNotificationGroup(groups)
  }

  const handleSetCurrentRange = (value) => {
    setCurrentRange(value)
    if (value === CURRENT_RANGE_TYPES.OVERALL) {
      setTargetType(TARGET_TYPES.VALUE)
      setComparingRange(COMPARING_RANGE_TYPES.VALUE)
    } else {
      if (ruleMetric) {
        let targetType = _.find(AM_METRICS_DATA, { name: ruleMetric }).targetType
        setTargetType(targetType)
      }
    }
  }

  const filterMetricData = () => {
    let accountType = vendorState.selected.split('_').pop() === 'SC' ? 'seller' : 'vendor'
    const metricsData = AM_METRICS_DATA.filter(metric => metric.accountType.includes(accountType))
    setMetricsData(metricsData)
  }

  const reportExists = async () => {
    try {
      setErrorCheck(true)
      const trimmedASINs = ASIN.split(',').map(asin => asin.trim());
      let params = { vendorCode: vendorState.selected, metric: ruleMetric, asins: trimmedASINs }
      if (ruleMetric && ASIN) {
        const asins = await checkIfReportExists(params)
        setErrorAsins(asins)
      }
      setErrorCheck(false)
    } catch (error) {
      console.log('Failed to check if report exists')
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          close();
          onModalClose();
        }}
        styles={{ modal: { width: 1500, borderRadius: 10 }, modalContainer: { padding: 10, paddingTop: 30 } }}
        center
        closeOnOverlayClick
      >
        <Backdrop />
        <Grid container>
          <Typography style={{ fontSize: 16, fontWeight: 600 }}>
            {edit ? "Edit" : "Create"} Rule
          </Typography>
        </Grid>
        <Grid container spacing={2} style={{ paddingTop: "10px", justifyContent: 'space-between', alignItems: 'center' }}>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <TextField
                type="text"
                value={ruleName}
                name="name"
                onChange={(event) => setRuleName(event.target.value)}
                label="Name"
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <TextField
                value={ruleMetric}
                onChange={(e) => handleChangeRuleMetric(e)}
                select
                label="Metric"
                variant="outlined"
              >
                {metricsData.map((item) => <MenuItem value={item.name}>{item.name}</MenuItem>)}
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ paddingTop: "10px", justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Grid item xs={6}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">Type</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <FormControlLabel value={AM_RULES_TYPES.ASINS} control={<Radio color="primary" />} label={AM_RULES_TYPES.ASINS} />
                <FormControlLabel value={AM_RULES_TYPES.CATEGORY} control={<Radio color="primary" />} label="Category" />
                <FormControlLabel value={AM_RULES_TYPES.ACCOUNT} control={<Radio color="primary" />} label="Account" />
                <FormControlLabel value={AM_RULES_TYPES.TOP_80} control={<Radio color="primary" />} label="Top 80" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {type === AM_RULES_TYPES.ASINS && <Grid container style={{ paddingTop: "10px" }}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  type="text"
                  value={ASIN}
                  name={AM_RULES_TYPES.ASIN}
                  onChange={(event) => {
                    setErrorAsins([])
                    setErrorCheck(false)
                    setASIN(event.target.value)
                  }}
                  label={'ASINs Separated by Comma'}
                  variant="outlined"
                  multiline
                  error={errorAsins?.length > 0 ? true : false}
                  helperText={errorAsins?.length > 0 ? `Reports not available for ${[errorAsins].join(", ")}` : ""}
                  onBlur={() => {
                    if (_.isEmpty(errorAsins) && !errorCheck) {
                      reportExists()
                    }
                  }}
                />
              </FormControl>
            </Grid>}
            {type === AM_RULES_TYPES.CATEGORY && <Grid container style={{ paddingTop: "10px" }}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  select
                  label="Category"
                  variant="outlined"
                >
                  {categoryList.map((category) => (
                    <MenuItem value={category}>{category}</MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ paddingTop: "10px", justifyContent: 'space-between', alignItems: 'center' }}>
          <Grid item xs={6} style={{ paddingTop: "10px" }}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                value={currentRange}
                onChange={(e) => handleSetCurrentRange(e.target.value)}
                select
                label="Current Range"
                variant="outlined"
              >
                <MenuItem value={CURRENT_RANGE_TYPES.WTD}>{CURRENT_RANGE_TYPES.WTD}</MenuItem>
                <MenuItem value={CURRENT_RANGE_TYPES.MTD}>{CURRENT_RANGE_TYPES.MTD}</MenuItem>
                <MenuItem value={CURRENT_RANGE_TYPES.YTD}>{CURRENT_RANGE_TYPES.YTD}</MenuItem>
                <MenuItem value={CURRENT_RANGE_TYPES.DAILY}>{CURRENT_RANGE_TYPES.DAILY}</MenuItem>
                <MenuItem value={CURRENT_RANGE_TYPES.OVERALL}>{CURRENT_RANGE_TYPES.OVERALL}</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={6} style={{ paddingTop: "10px" }}>
            <FormControl fullWidth sx={{ m: 1 }} >
              <TextField
                value={rule}
                onChange={(e) => setRule(e.target.value)}
                select
                label="Rule"
                variant="outlined"
              >
                <MenuItem value={RULE_TYPES.GREATER_THAN}>{RULE_TYPES.GREATER_THAN}</MenuItem>
                <MenuItem value={RULE_TYPES.LESS_THAN}>{RULE_TYPES.LESS_THAN}</MenuItem>
                <MenuItem value={RULE_TYPES.EQUAL}>{RULE_TYPES.EQUAL}</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ paddingTop: "10px", justifyContent: 'space-between', alignItems: 'center' }}>
          <Grid item xs={6} style={{ paddingTop: "10px" }}>
            <FormControl fullWidth sx={{ m: 1 }} >
              <TextField
                value={comparingRange}
                onChange={handleComparingRange}
                select
                label="Comparing Range"
                variant="outlined"
              >
                {currentRange === CURRENT_RANGE_TYPES.WTD && <MenuItem value={COMPARING_RANGE_TYPES.PREVIOUS_PERIOD_WEEKS}>PREVIOUS X WEEKS</MenuItem>}
                {currentRange === CURRENT_RANGE_TYPES.MTD && <MenuItem value={COMPARING_RANGE_TYPES.PREVIOUS_PERIOD_MONTHS}>PREVIOUS X MONTHS</MenuItem>}
                {<MenuItem value={COMPARING_RANGE_TYPES.PREVIOUS_YEAR_SAME_RANGE}>{COMPARING_RANGE_TYPES.PREVIOUS_YEAR_SAME_RANGE}</MenuItem>}
                {currentRange === CURRENT_RANGE_TYPES.DAILY && 
                <MenuItem value={COMPARING_RANGE_TYPES.PREVIOUS_PERIOD_DAYS}>PREVIOUS X DAYS</MenuItem>
                }
                <MenuItem value={COMPARING_RANGE_TYPES.VALUE}>{COMPARING_RANGE_TYPES.VALUE}</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
          {(comparingRange === COMPARING_RANGE_TYPES.PREVIOUS_PERIOD_WEEKS ||
            comparingRange === COMPARING_RANGE_TYPES.PREVIOUS_PERIOD_MONTHS ||
            comparingRange === COMPARING_RANGE_TYPES.PREVIOUS_PERIOD_DAYS) &&
            <Grid xs={6} item style={{ paddingTop: "10px" }}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  type="number"
                  value={comparingValue}
                  name="target"
                  onChange={(event) => setComparingValue(parseInt(event.target.value))}
                  label={`No of ${
                    currentRange === CURRENT_RANGE_TYPES.WTD ? "weeks" : 
                    currentRange === CURRENT_RANGE_TYPES.MTD ? "months" : "days"
                  }`}
                  variant="outlined"
                />
              </FormControl>
            </Grid>}
        </Grid>
        <Grid container spacing={2} style={{ paddingTop: "10px", justifyContent: 'space-between', alignItems: 'center' }}>
          {/* {comparingRange !== COMPARING_RANGE_TYPES.VALUE && <Grid item xs={6} style={{ paddingTop: "10px" }}>
            <FormControl fullWidth sx={{ m: 1 }} >
              <TextField
                value={targetType}
                onChange={(e) => setTargetType((e.target.value))}
                select
                label="Target Type"
                variant="outlined"
              >
                <MenuItem value={TARGET_TYPES.FORECAST}>{TARGET_TYPES.FORECAST}</MenuItem>
                <MenuItem value={TARGET_TYPES.PERCENTAGE}>{TARGET_TYPES.PERCENTAGE}</MenuItem>
                <MenuItem value={TARGET_TYPES.VALUE}>{TARGET_TYPES.VALUE}</MenuItem>
              </TextField>
            </FormControl>
          </Grid>} */}
          {/* {(ruleMetric === AM_METRICS.AMS_IMPRESSIONS || ruleMetric === AM_METRICS.GLANCE_VIEW_CONVERSION || ruleMetric === AM_METRICS.SALES_CONVERSION) ? <><Grid container fullWidth direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} style={{ paddingLeft: '6px' }}>
              <Typography style={{ fontSize: 16 }}>
                Are the content and creatives optimized?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={seoOptimization}
                  onChange={(e) => setSeoOptimization(parseInt(e.target.value))}
                >
                  <FormControlLabel value={0} control={<Radio color="primary" />} label={'Optimized'} />
                  <FormControlLabel value={10} control={<Radio color="primary" />} label="Not Optimized" />
                  <FormControlLabel value={5} control={<Radio color="primary" />} label="Minimum" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
            <Grid
              container
              fullWidth
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{ paddingTop: '15px' }}>
              <Grid item xs={12} style={{ paddingLeft: '6px' }}>
                <Typography style={{ fontSize: 16 }}>
                  What is the AMS strategy?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={amsStrategy}
                    onChange={(e) => setAmsStrategy(e.target.value)}
                  >
                    <FormControlLabel value={AMS_STRATEGY.NO_AMS} control={<Radio color="primary" />} label={'No AMS'} />
                    <FormControlLabel value={AMS_STRATEGY.TOP_OF_SEARCH_IMPRESSIONS} control={<Radio color="primary" />} label="Top of search impressions" />
                    <FormControlLabel value={AMS_STRATEGY.MIX_OF_ALL} control={<Radio color="primary" />} label="Mix of all" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid></> :  */}
            {(ruleMetric !== AM_METRICS.AMS_IMPRESSIONS && ruleMetric !== AM_METRICS.GLANCE_VIEW_CONVERSION && ruleMetric !== AM_METRICS.SALES_CONVERSION) ? < Grid item xs={6} style={{ paddingTop: "10px" }}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                type="number"
                value={target}
                name="target"
                onChange={(event) => setTarget(parseInt(event.target.value))}
                label={comparingRange === COMPARING_RANGE_TYPES.VALUE ? "Target" : "Target(%)"}
                variant="outlined"
                helperText={targetType === TARGET_TYPES.FORECAST && target ? `by ${target}% of forecast sales growth` : targetType === TARGET_TYPES.PERCENTAGE && target ? `by ${target} %` : ""}
              />
            </FormControl>
          </Grid> : null}
        </Grid>
        <Grid container spacing={2} style={{ paddingTop: "10px", justifyContent: 'space-between', alignItems: 'center' }}>
          {notificationGroup.map((item, index) => (
            <>
              <Grid item xs={6} style={{ paddingTop: "10px" }}>
                <FormControl fullWidth sx={{ m: 1 }} >
                  <TextField
                    value={item.notificationGroup}
                    onChange={(e) => handleSetNotification(e.target.value, index, true)}
                    select
                    label="Notification Group"
                    variant="outlined"
                  >
                    {notificationGroups.map(
                      (item) => (
                        <MenuItem
                          key={item}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6} style={{ paddingTop: "10px" }}>
                <Grid container spacing={2} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <Grid item xs={notificationGroup.length > 1 ? 10 : 12} style={{}}>
                    <FormControl fullWidth sx={{ m: 1 }} >
                      <TextField
                        value={item.notificationFrequency}
                        onChange={(e) => handleSetNotification(e.target.value, index)}
                        select
                        label="Notification Frequency"
                        variant="outlined"
                      >
                        {Object.entries(notificationFrequencyList).map(
                          ([key, value]) => (
                            <MenuItem
                              key={key}
                              value={value}
                            >
                              {value}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </FormControl>
                  </Grid>
                  {notificationGroup.length > 1 && <Grid item xs={2} style={{}}>
                    <IconButton
                      onClick={() => handleDeleteGroup(index)}
                    >
                      <DeleteIcon className={classes.title} color="error" />
                    </IconButton>
                  </Grid>}
                </Grid>
              </Grid>
            </>
          ))
          }
        </Grid>
        <Grid
          container
          fullWidth
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <IconButton
            onClick={handleAddNotificationGroup}
          >
            <AddIcon className={classes.title} color="primary" />
          </IconButton>
        </Grid>
        <Grid
          container
          fullWidth
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <FormGroup onChange={(e) => {
            if (active) {
              setActive(false)
            } else {
              setActive(true)
            }
          }}
          >
            <FormControlLabel control={<Checkbox checked={active} color="primary" />} label="Active" />
          </FormGroup>
        </Grid>
        <Grid
          container
          fullWidth
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginTop: "20px" }}
            disabled={loading}
            onClick={edit ? editRule : createRule}
          >
            {edit ? "Edit" : "Create"} Rule
          </Button>
        </Grid>
        <Backdrop className={classes.backdrop} open={fullPageLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Modal>
    </div>
  );
}
