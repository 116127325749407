import moment from "moment";

export const DATEFORMAT = (value) =>
  value ? moment(value, "YYYYMMDD").format("MM-DD-YYYY") : value;

export const CAMPAIGN_KEYS = [
  "campaignId",
  "vendorCode",
  "name",
  "campaignType",
  "manualTargetingType",
  "advertisingGoal",
  "advertisingTarget",
  "budget",
  "budgetType",
  "startDate",
  "premiumBidAdjustment",
  "biddingstrategy",
  "biddingadjustments",
  "defaultBid",
  "productAsins",
  "productAdState",
  "scheduledCampaignStartDate",
  "status",
];

export const CAMPAIGN_LABELS = [
  { campaignId: "Campaign ID" },
  { vendorCode: "Vendor Code" },
  { name: "Name" },
  { campaignType: "Campaign Type" },
  { manualTargetingType: "Manual Targeting Type" },
  { advertisingGoal: "Advertising Goal" },
  { advertisingTarget: "Target ACOS" },
  { budget: "Budget" },
  { budgetType: "Budget Type" },
  { startDate: "Start Date" },
  { premiumBidAdjustment: "Premium Bid Adjustment" },
  { biddingstrategy: "Bidding Strategy" },
  { biddingadjustments: "Bidding Adjustments" },
  { defaultBid: "Default Bid" },
  { productAsins: "Product ASINs" },
  { productAdState: "Product Ad State" },
  { scheduledCampaignStartDate: "Scheduled Campaign Start Date" },
  { status: "Status" },
];

export const VERSION_LABELS = [
  { version: "Version" },
  { campaignState: "Campaign State" },
  { endDate: "End Date" },
  { dailyBudget: "Daily Budget" },
  { keywords: "Keywords" },
  { productTargets: "Product Targeting ASINs" },
  { negativeKeywords: "Negative Keywords" },
  { negativeProductTargets: "Negative Product Targeting ASINs" },
  { versionCreatedDate: "Version Created Date" },
  { placementTop: "Placement Top" },
  { placementProductPage: "Placement Product Page" },
  { targetGroups: "Target Groups" },
  { numberOfOrders: "Number of Orders" },
  { matchTypes: "Match Types" },
];
