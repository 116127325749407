import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  Breadcrumbs,
  Link,
  Backdrop,
  CircularProgress,
  Select,
  FormControl,
  MenuItem,
  TextField,
} from "@material-ui/core";
import useStyles from "./styles.js";
import { BASE_URL } from "../../healpers/api.js";
import axios from "axios";
import api from "../../healpers/apiRoutes.js";
import { tokenConfig } from "../../context/UserContext.js";
import { useVendorState } from "../../context/VendorContext.js";
import BarChart from "./components/BarChart.js";
import Widget from "../../components/Widget/Widget.js";
import PrintIcon from "@material-ui/icons/Print";
import { exportReport } from "../../healpers/utilityFunctions.js";
import CreateActionItemModal from "../actionItems/components/createActionItemModal.js";
import { ACTION_ITEMS, chartLegend, months, inventoryLegend, FILTER_OPTIONS } from "../../constant/wbr.js";
import ChartLegend from "../../components/ChartLegend/ChartLegend.js";
import MixedChart from "../wbrAMS/components/MixedChart.js";


export default function KeyInputMetrics(props) {
  const vendorState = useVendorState();
  const classes = useStyles();
  const exportFunctionRef = useRef();
  const [metricsData, setMetricsData] = useState([]);
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [actionItemModal, setActionItemModal] = useState(false)
  const [asin, setAsin] = useState("")
  const [category, setCategory] = useState("")
  const [selectedFilter, setSelectedFilter] = useState("")

  const fetchKeyInputMetrics = async () => {
    try {
      setFullPageLoader(true);
      const params = {
        vendorCode: vendorState?.selected,
      };
      if (asin !== "" && selectedFilter === FILTER_OPTIONS.ASIN) {
        params.asins = asin.split(",")
      }
      if (category !== "" && selectedFilter === FILTER_OPTIONS.CATEGORY) {
        params.category = category
      }
      const response = await axios(
        BASE_URL + api.keyInputMetrics,
        tokenConfig(params)
      );
      setMetricsData(response.data.data);
      setFullPageLoader(false);
    } catch (error) {
      setFullPageLoader(false);
      alert(error.message || "Failed to Fetch Key Output Metrics Data")
      console.log("fetchKeyInputMetrics - error", error);
    }
  };

  useEffect(() => {
    if (vendorState?.selected) {
      fetchKeyInputMetrics();
    }
  }, [vendorState?.selected]);

  const handleOnChange = (event) => {
    if (selectedFilter === FILTER_OPTIONS.CATEGORY) {
      setCategory(event.target.value)
    } else {
      setAsin(event.target.value)
    }
  }

  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.header}
      >
        <Grid item>
          <Typography
            variant="h4"
            weight="medium"
            style={{ paddingBottom: "20px" }}
          >
            Key Input Metrics
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className={classes.link}>
              WBR
            </Link>
            <Typography className={classes.link} color="primary">
              Key Input Metrics
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={6}>
          <Grid container style={{ gap: 20 }} justifyContent="flex-end" alignItems="center">
            <Grid item>
              <FormControl style={{ minWidth: 100 }} fullWidth variant="outlined">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(event) => setSelectedFilter(event.target.value)}
                  value={selectedFilter}
                >
                  <MenuItem value={FILTER_OPTIONS.CATEGORY}>Category</MenuItem>
                  <MenuItem value={FILTER_OPTIONS.ASIN}>Asin</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {selectedFilter !== "" && <Grid item>
              <FormControl fullWidth variant="outlined">
                <TextField
                  type="text"
                  value={selectedFilter === FILTER_OPTIONS.CATEGORY ? category : asin}
                  id="action-item"
                  name="asin"
                  onChange={(event) => handleOnChange(event)}
                  label={selectedFilter.toLowerCase()}
                  variant="outlined"
                />
              </FormControl>
            </Grid>}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  fetchKeyInputMetrics();
                }}
              >
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  setActionItemModal(true)
                }}
              >
                Add Action Item
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!fullPageLoader && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    style={{
                      fontWeight: 600,
                      fontSize: 20,
                      color: "black",
                    }}
                  >
                    Total Impressions
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportReport("SearchImpressions")}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Grid container>
                <Grid item xs={6}>
                  <BarChart
                    currentWeekData={metricsData?.weeklySearchImpressionsChartData?.data}
                    previousWeekData={metricsData?.previousWeeklySearchImpressionsChartData?.data}
                    labels={metricsData?.weeklySearchImpressionsChartData?.labels}
                    id="weeklySearchImpressions"
                    yAxisLabel="Impressions"
                  />
                </Grid>
                <Grid item xs={6}>
                  <BarChart
                    data={metricsData?.yearlySearchImpressionsChartData?.data}
                    previousYearData={metricsData?.previousYearlySearchImpressionsChartData?.data}
                    labels={months}
                    id="yearlySearchImpressions"
                    yAxisLabel="Impressions"
                  />
                </Grid>
              </Grid>
              <ChartLegend legendData={chartLegend} heading={'Impressions'}/>
            </Widget>
          </Grid>
        </Grid>
      )}
      {!fullPageLoader && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    style={{
                      fontWeight: 600,
                      fontSize: 20,
                      color: "black",
                    }}
                  >
                    Page Views
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportReport("Impressions")}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Grid container>
                <Grid item xs={6}>
                  <BarChart
                    data={metricsData?.weeklyImpressionsChartData?.data}
                    labels={metricsData?.weeklyImpressionsChartData?.labels}
                    previousWeekData={metricsData?.previousWeeklyImpressionsChartData?.data}
                    id="weeklyImpressions"
                    yAxisLabel="Page Views"
                  />
                </Grid>
                <Grid item xs={6}>
                  <BarChart
                    data={metricsData?.yearlyImpressionsChartData?.data}
                    previousYearData={metricsData?.previousYearlyImpressionsChartData?.data}
                    labels={months}
                    id="yearlyImpressions"
                    yAxisLabel="Page Views"
                  />
                </Grid>
                <ChartLegend legendData={chartLegend} heading={'Page Views'}/>
              </Grid>
            </Widget>
          </Grid>
        </Grid>
      )}
      {!fullPageLoader && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    style={{
                      fontWeight: 600,
                      fontSize: 20,
                      color: "black",
                    }}
                  >
                    Inventory Availability - OOS ASINs
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportReport("Inventory")}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Grid container>
                <Grid item xs={6}>
                  <MixedChart
                    data={metricsData?.weeklyMissedSales?.data}
                    labels={metricsData?.weeklyMissedSales?.labels}
                    asins={metricsData?.weeklyUniqueAsins?.data}
                    id="weeklyInventory"
                    yAxisLabel="Available Inventory" />
                </Grid>
                <Grid item xs={6}>
                  <MixedChart data={metricsData?.monthlyMissedSales?.data}
                    labels={months}
                    asins={metricsData?.monthlyUniqueAsins?.data}
                    id="yearlyInventory"
                    yAxisLabel="Available Inventory" />
                </Grid>
                <ChartLegend legendData={inventoryLegend} />
                {metricsData?.weeklyMissedSales?.data.every(value => value===0) && 
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width:'100%', paddingBottom:'10px' }}>Note: No ASINs were out of stock.</div>
                }
              </Grid>
            </Widget>
          </Grid>
        </Grid>
      )}
      {!fullPageLoader && metricsData?.buyBox &&  (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    style={{
                      fontWeight: 600,
                      fontSize: 20,
                      color: "black",
                    }}
                  >
                    BUYABILITY
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportReport('Buyability')}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Grid container>
                <Grid item xs={6}>
                  <BarChart
                    data={metricsData?.weeklyBuyabilityChartData?.data}
                    labels={metricsData?.weeklyBuyabilityChartData?.labels}
                    previousWeekData={metricsData?.previousWeeklyBuyabilityChartData?.data}
                    id="weeklyBuyability"
                    yAxisLabel="Buybox Percentage"
                    percentage={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <BarChart
                    data={metricsData?.yearlyBuyabilityChartData?.data}
                    labels={metricsData?.yearlyBuyabilityChartData?.labels}
                    previousYearData={metricsData?.previousBuyabilityChartData?.data}
                    id="yearlyBuyability"
                    yAxisLabel="Buybox Percentage"
                    percentage={true}
                  />
                </Grid>
                <ChartLegend legendData={chartLegend} heading={'Buyability'}/>
              </Grid>
            </Widget>
          </Grid>
        </Grid>
      )}
      
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CreateActionItemModal
        open={actionItemModal}
        close={() => {
          setActionItemModal(false);
        }} 
        slideName={ACTION_ITEMS.KEY_INPUT_METRICS}
        />
    </div>
  );
}
