import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  CircularProgress,
  Backdrop,
  TextField,
  Box,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";

//icons
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { useUserState } from "../../context/UserContext";
import { useVendorState } from "../../context/VendorContext";

export default function Page() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var userState = useUserState();
  const vendorState = useVendorState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [confirmDialog, setconfirmDialog] = useState(false);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const fetchData = () => {
    setmainTableData([]);
    setfullPageLoader(true);
    axios
      .get(BASE_URL + api.getShipments, {
        params: { vendorCode: vendorState?.selected, shipmentId: searchValue },
      })
      .then((res) => {
        setmainTableData(res.data);
      })
      .catch((err) => {
        seterr("Error occured");
        setTimeout(() => seterr(null), 5000);
        console.log(err);
      })
      .finally(() => {
        setfullPageLoader(false);
      });
  };

  useEffect(() => {
    // if (userState.userData && vendor) {
    //   fetchData();
    // }
    // fetchData();
  }, [userState.userData, vendorState?.selected]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Manage Shipments"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                FBA Shipment
              </Link>
              <Typography className={classes.link} color="primary">
                Manage Shipments
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <Box
                display={"flex"}
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <TextField
                  label="Shipment Id"
                  variant="outlined"
                  size="small"
                  style={{ width: "300px" }}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<SearchIcon />}
                  style={{ height: "36px", width: "150px", marginLeft: 20 }}
                  onClick={fetchData}
                >
                  SEARCH
                </Button>
              </Box>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <div className={classes.mainChartHeader}>
                  <div />
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<RefreshIcon />}
                    onClick={() => fetchData()}
                  >
                    Refresh
                  </Button>
                </div>
              }
            >
              <Table
                ref={exportFunctionRef}
                tableData={mainTableData}
                vendorCode={vendorState?.selected}
                setfullPageLoader={setfullPageLoader}
                setnotification={setnotification}
                seterr={seterr}
                fetchData={fetchData}
              />
            </Widget>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={<></>}
            ></Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {notification ? (
        <Alert
          severity="success"
          style={{
            position: "absolute",
            bottom: 30,
            left: "48%",
            zIndex: 99999,
          }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{
            position: "absolute",
            bottom: 30,
            left: "48%",
            zIndex: 99999,
          }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
