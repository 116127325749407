import React, { useEffect, useState } from "react";
import {
  Breadcrumbs,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import {
  FormHelperText,
  List,
  ListItemButton,
  ListItemText,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { styled } from "@mui/system";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  fetchApproveContents,
  fetchContentRealtimeInfo,
  saveContentChangelogs,
  saveContents,
} from "../../http/api";
import { useUserState } from "../../context/UserContext";
import { useVendorState } from "../../context/VendorContext";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import moment from "moment";
import _ from "lodash";
import {
  ATTRIBUTES,
  CHANGELOG_ACTION,
  DEFAULT_LIVE_CONTENT,
  RAPID_API_COUNTRY,
  ROLES,
  STATUS,
} from "../../constant/content";
import { getChangelog } from "../../healpers/content";
import KeywordsTable from "../updateContent/components/KeywordsTable/KeywordsTable";
import TextInputCounter from "../updateContent/components/TextInputCounter/TextInputCounter";
import KeywordsHighlight from "../updateContent/components/KeywordsHighlight/KeywordsHighlight";

const ContentsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
});

const ContentsActionsContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  padding: "0px 8px",
});

const ContentsActionsLeftContainer = styled("div")({
  display: "flex",
  gap: "8px",
});

const ContentsActionsRightContainer = styled("div")({
  display: "flex",
  gap: "8px",
});

const ContentsActionButton = ({ label, ...props }) => {
  return (
    <Button color="primary" variant="contained" size="small" {...props}>
      {label}
    </Button>
  );
};

const ContentsItemContainer = styled("div")({
  display: "flex",
  gap: "4px",
});

const ContentsItemLabelContainer = styled(Typography)({
  width: "15%",
  textAlign: "right",
});

const getBulletedText = (text = []) => {
  return text.length > 0 ? "\u2022\u00A0" + text.join("\n\u2022\u00A0") : "";
};

const getModifiedValues = (before, after) => {
  const keyNames = {
    title: "Title",
    description: "Description",
    bulletPoints: "Bullet Points",
    keywords: "Keywords",
    hiddenKeywords: "Hidden Keywords",
    tagLines: "Tag Lines",
    recommendations: "Recommendations",
  };
  const modifiedValues = {};

  for (const key in before) {
    if (!_.isEqual(before[key], after[key])) {
      modifiedValues[keyNames[key]] = before[key];
    }
  }

  return modifiedValues;
};

const FeedbackBox = ({
  show,
  attribute,
  value,
  onChange,
  placeholder,
  width,
  content,
  setContent,
  reloadContents,
  email,
}) => {
  return show ? (
    <TextField
      value={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
      multiline
      minRows={3}
      variant="outlined"
      InputProps={{
        startAdornment: content?.internalFeedback?.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              marginBottom: "4px",
              maxHeight: "100px",
              width: "100%",
              overflow: "scroll",
            }}
          >
            {content?.internalFeedback
              ?.filter((item) => item.attribute === attribute)
              ?.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      padding: "4px 0px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                        }}
                      >
                        <Typography
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {item?.role === ROLES.REVIEWER ? "You" : "Creator"}
                        </Typography>
                        {item?.user && <Typography>({item.user})</Typography>}
                      </div>
                      <Typography variant="caption" component="div">
                        {moment(moment(item?.time).toISOString()).format(
                          "DD MMM, YYYY hh:mm A"
                        )}
                      </Typography>
                    </div>
                    <Typography style={{ whiteSpace: "break-spaces" }}>
                      {item?.message}
                    </Typography>
                    <Divider />
                  </div>
                );
              })}
          </div>
        ),
        endAdornment: (
          <>
            <Button
              onClick={async () => {
                const internalFeedbackDetails = {
                  user: email,
                  role: ROLES.REVIEWER,
                  attribute,
                  message: value,
                  time: moment.utc(),
                };
                const internalFeedback = [
                  internalFeedbackDetails,
                  ...content.internalFeedback,
                ];
                await saveContents(content.vendorCode, [
                  {
                    asin: content.asin,
                    vendorCode: content.vendorCode,
                    internalFeedback,
                  },
                ]);
                setContent({
                  ...content,
                  internalFeedback,
                });
                reloadContents();
                onChange("");
              }}
              disabled={!value}
              variant="contained"
              color="primary"
              size="small"
              style={{
                position: "absolute",
                bottom: "24px",
                right: "12px",
              }}
            >
              Save
            </Button>
          </>
        ),
        style: {
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        },
        inputProps: {
          style: {
            border: "1px solid #DADADA",
            borderRadius: "4px",
            padding: "4px",
            paddingRight: "0px",
          },
        },
      }}
      style={{
        width,
      }}
    />
  ) : (
    <></>
  );
};

export default function ApproveContent() {
  const userState = useUserState();
  const isInternal =
    (userState?.userData?.email || "").split("@")[1] === "salesduo.com";
  const vendorState = useVendorState();
  const [contents, setContents] = useState([]);

  const [liveContent, setLiveContent] = useState(DEFAULT_LIVE_CONTENT);
  const [showLiveContent, setShowLiveContent] = useState(false);
  const [showFeedbackBox, setShowFeedbackBox] = useState(false);
  const [showMore, setShowMore] = useState(null);

  const [showLoader, setShowLoader] = useState(false);
  const [showVerifyContent, setShowVerifyContent] = useState(false);
  const [selectedContent, setSelectedContent] = useState({});
  const [selectedContentUnmodified, setSelectedContentUnmodified] = useState(
    {}
  );

  const [titleFeedback, setTitleFeedback] = useState("");
  const [descriptionFeedback, setDescriptionFeedback] = useState("");
  const [bulletPointsFeedback, setBulletPointsFeedback] = useState("");

  const [feedback, setFeedback] = useState("");

  const [internalFeedback, setInternalFeedback] = useState("");

  const loadContents = async (vendorCode) => {
    try {
      setShowLoader(true);
      const index = contents.findIndex(
        (item) => item.asin === selectedContent?.asin
      );
      const { status, data } = await fetchApproveContents(
        vendorCode,
        isInternal
      );
      setShowLoader(false);
      if (status === "Success") {
        setContents(data);
      } else {
        setContents([]);
      }
      if (showVerifyContent) {
        if (data?.length === 0) {
          setShowVerifyContent(false);
        } else {
          let content = (data || []).find(
            (item) => item.asin === selectedContent.asin
          );
          if (!content) {
            content = data[index] ? { ...data[index] } : { ...data[index - 1] };
          }
          setSelectedContent(_.cloneDeep(content));
          setSelectedContentUnmodified(_.cloneDeep(content));
        }
      }
    } catch (err) {
      console.log(err);
      setShowLoader(false);
      setContents([]);
      setSelectedContent({});
      setSelectedContentUnmodified({});
    }
  };

  const loadLiveContent = async () => {
    try {
      setShowLoader(true);
      setLiveContent(DEFAULT_LIVE_CONTENT);
      const { status, data } = await fetchContentRealtimeInfo(
        selectedContent.asin,
        RAPID_API_COUNTRY.find((item) => item.value === vendorState.country).label
      );
      setShowLoader(false);
      if (status === "Success") {
        setLiveContent({ ...data?.[0] });
      } else {
        setLiveContent(DEFAULT_LIVE_CONTENT);
      }
    } catch (err) {
      console.log(err);
      setShowLoader(false);
      setLiveContent(DEFAULT_LIVE_CONTENT);
    }
  };

  const handleCustomerApprove = async () => {
    setShowLoader(true);
    const saveData = { ...selectedContent };
    if (feedback) {
      const feedbackDetails = {
        user: userState?.userData?.email,
        role: ROLES.CUSTOMER,
        message: feedback,
        time: moment.utc(),
      };
      saveData.feedback = [feedbackDetails, ...saveData.feedback];
    }
    const saveContentData = await saveContents(
      selectedContent.vendorCode,
      [
        {
          vendorCode: selectedContent.vendorCode,
          asin: selectedContent.asin,
          feedback: saveData.feedback,
          status: STATUS.PENDING_PUBLISH,
        },
      ],
      "APPROVE"
    );
    setShowLoader(false);
    if (saveContentData.status === "Success") {
      const changelogs = [];

      changelogs.push({
        asin: selectedContent.asin,
        vendorCode: selectedContent.vendorCode,
        action: CHANGELOG_ACTION.CUSTOMER_APPROVE,
        attributes: ["Status"],
        beforeChange: { Status: selectedContent.status },
        afterChange: { Status: STATUS.PENDING_PUBLISH },
        user: userState?.userData?.email,
      });
      if (feedback) {
        changelogs.push({
          asin: selectedContent.asin,
          vendorCode: selectedContent.vendorCode,
          action: CHANGELOG_ACTION.CUSTOMER_APPROVE_FEEDBACK,
          attributes: ["Feedback"],
          beforeChange: { Feedback: "" },
          afterChange: { Feedback: feedback },
          user: userState?.userData?.email,
        });
      }

      setSelectedContent({
        ...saveData,
        status: STATUS.PENDING_PUBLISH,
      });
      setFeedback("");
      await loadContents(selectedContent.vendorCode);
      saveContentChangelogs(selectedContent.vendorCode, changelogs);
    }
  };

  const handleCustomerSave = async () => {
    setShowLoader(true);
    const changelogs = [];
    const saveData = { ...selectedContent };
    saveData.status = STATUS.CUSTOMER_FEEDBACK;

    const modifiedValues = getModifiedValues(
      selectedContentUnmodified,
      selectedContent
    );

    if (Object.keys(modifiedValues).length > 0) {
      let message = "\nChanged from:\n";
      Object.keys(modifiedValues).forEach((key) => {
        message =
          message +
          "  " +
          key +
          ": " +
          (_.isArray(modifiedValues[key])
            ? "\n    " + modifiedValues[key].join("\n    ")
            : modifiedValues[key]) +
          "\n";
      });
      const changeDetails = {
        user: userState?.userData?.email,
        role: ROLES.CUSTOMER,
        message:
          "(System generated message)\nContent attribute(s) modified." +
          message,
        time: moment.utc(),
      };
      saveData.feedback = [changeDetails, ...saveData.feedback];

      changelogs.push({
        asin: selectedContent.asin,
        vendorCode: selectedContent.vendorCode,
        action: CHANGELOG_ACTION.CUSTOMER_UPDATE,
        ...getChangelog(selectedContentUnmodified, selectedContent),
        user: userState?.userData?.email,
      });
      if (feedback) {
        changelogs.push({
          asin: selectedContent.asin,
          vendorCode: selectedContent.vendorCode,
          action: CHANGELOG_ACTION.CUSTOMER_UPDATE_FEEDBACK,
          attributes: ["Feedback"],
          beforeChange: { Feedback: "" },
          afterChange: { Feedback: feedback },
          user: userState?.userData?.email,
        });
      }
    }

    if (feedback) {
      const feedbackDetails = {
        user: userState?.userData?.email,
        role: ROLES.CUSTOMER,
        message: feedback,
        time: moment.utc(),
      };
      saveData.feedback = [feedbackDetails, ...saveData.feedback];
      if (changelogs.length === 0) {
        changelogs.push({
          asin: selectedContent.asin,
          vendorCode: selectedContent.vendorCode,
          action: CHANGELOG_ACTION.CUSTOMER_FEEDBACK,
          attributes: ["Feedback"],
          beforeChange: { Feedback: "" },
          afterChange: { Feedback: feedback },
          user: userState?.userData?.email,
        });
      }
    }

    const saveContentData = await saveContents(selectedContent.vendorCode, [
      {
        ...saveData,
      },
    ]);
    setShowLoader(false);
    if (saveContentData.status === "Success") {
      setSelectedContent({
        ...selectedContent,
      });
      setFeedback("");
      await loadContents(selectedContent.vendorCode);
      saveContentChangelogs(selectedContent.vendorCode, changelogs);
    }
  };

  const handleCustomerReject = async () => {
    const feedbackDetails = {
      user: userState?.userData?.email,
      role: ROLES.CUSTOMER,
      message: feedback,
      time: moment.utc(),
    };
    setShowLoader(true);
    const saveContentData = await saveContents(selectedContent.vendorCode, [
      {
        vendorCode: selectedContent.vendorCode,
        asin: selectedContent.asin,
        feedback: [feedbackDetails, ...selectedContent.feedback],
        status: STATUS.CUSTOMER_FEEDBACK,
      },
    ]);
    setShowLoader(false);
    if (saveContentData.status === "Success") {
      const changelogs = [];
      changelogs.push({
        asin: selectedContent.asin,
        vendorCode: selectedContent.vendorCode,
        action: CHANGELOG_ACTION.CUSTOMER_REJECT,
        attributes: ["Feedback"],
        beforeChange: { Feedback: "" },
        afterChange: { Feedback: feedback },
        user: userState?.userData?.email,
      });

      setSelectedContent({
        ...selectedContent,
        feedback: [feedbackDetails, ...selectedContent.feedback],
        status: STATUS.CUSTOMER_FEEDBACK,
      });
      setFeedback("");
      await loadContents(selectedContent.vendorCode);
      saveContentChangelogs(selectedContent.vendorCode, changelogs);
    }
  };

  const handleInternalSave = async (internallyApproved = false) => {
    setShowLoader(true);
    const changelogs = [];
    const saveData = { ...selectedContent };

    const modifiedValues = getModifiedValues(
      selectedContentUnmodified,
      selectedContent
    );

    if (Object.keys(modifiedValues).length > 0) {
      let message = "\nChanged from:\n";
      Object.keys(modifiedValues).forEach((key) => {
        message =
          message +
          "  " +
          key +
          ": " +
          (_.isArray(modifiedValues[key])
            ? "\n    " + modifiedValues[key].join("\n    ")
            : modifiedValues[key]) +
          "\n";
      });
      const changeDetails = {
        user: userState?.userData?.email,
        role: ROLES.REVIEWER,
        message:
          "(System generated message)\nContent attribute(s) modified." +
          message,
        time: moment.utc(),
      };
      saveData.feedback = [changeDetails, ...saveData.feedback];
      saveData.internalFeedback = [changeDetails, ...saveData.internalFeedback];
      changelogs.push({
        asin: selectedContent.asin,
        vendorCode: selectedContent.vendorCode,
        action: CHANGELOG_ACTION.REVIEWER_UPDATE,
        ...getChangelog(selectedContentUnmodified, selectedContent),
        user: userState?.userData?.email,
      });
    }

    if (internalFeedback) {
      const internalFeedbackDetails = {
        user: userState?.userData?.email,
        role: ROLES.REVIEWER,
        message: internalFeedback,
        time: moment.utc(),
      };
      saveData.internalFeedback = [
        internalFeedbackDetails,
        ...saveData.internalFeedback,
      ];
    }
    const saveContentData = await saveContents(
      selectedContent.vendorCode,
      [
        {
          ...saveData,
          internallyApproved: internallyApproved,
        },
      ],
      internallyApproved ? "INTERNAL_APPROVE" : ""
    );
    setShowLoader(false);
    if (saveContentData.status === "Success") {
      if (internallyApproved) {
        changelogs.push({
          asin: selectedContent.asin,
          vendorCode: selectedContent.vendorCode,
          action: CHANGELOG_ACTION.REVIEWER_APPROVE,
          attributes: ["Internal Approve"],
          beforeChange: { "Internal Approve": false },
          afterChange: { "Internal Approve": true },
          user: userState?.userData?.email,
        });
      }
      if (internalFeedback) {
        changelogs.push({
          asin: selectedContent.asin,
          vendorCode: selectedContent.vendorCode,
          action: internallyApproved
            ? CHANGELOG_ACTION.REVIEWER_APPROVE_FEEDBACK
            : Object.keys(modifiedValues).length > 0
            ? CHANGELOG_ACTION.REVIEWER_UPDATE_FEEDBACK
            : CHANGELOG_ACTION.REVIEWER_FEEDBACK,
          attributes: ["Internal Feedback"],
          beforeChange: { "Internal Feedback": "" },
          afterChange: { "Internal Feedback": internalFeedback },
          user: userState?.userData?.email,
        });
      }

      setSelectedContent({
        ...selectedContent,
        internallyApproved: internallyApproved,
      });
      setInternalFeedback("");
      await loadContents(selectedContent.vendorCode);
      saveContentChangelogs(selectedContent.vendorCode, changelogs);
    }
  };

  const handleInternalReject = async () => {
    const internalFeedbackDetails = {
      user: userState?.userData?.email,
      role: ROLES.REVIEWER,
      message: internalFeedback,
      time: moment.utc(),
    };
    setShowLoader(true);
    const saveContentData = await saveContents(selectedContent.vendorCode, [
      {
        vendorCode: selectedContent.vendorCode,
        asin: selectedContent.asin,
        internalFeedback: [
          internalFeedbackDetails,
          ...selectedContent.internalFeedback,
        ],
      },
    ]);
    setShowLoader(false);
    if (saveContentData.status === "Success") {
      const changelogs = [];
      changelogs.push({
        asin: selectedContent.asin,
        vendorCode: selectedContent.vendorCode,
        action: CHANGELOG_ACTION.REVIEWER_REJECT,
        attributes: ["Internal Feedback"],
        beforeChange: { "Internal Feedback": "" },
        afterChange: { "Internal Feedback": internalFeedback },
        user: userState?.userData?.email,
      });

      setSelectedContent({
        ...selectedContent,
        internalFeedback: [
          internalFeedbackDetails,
          ...selectedContent.internalFeedback,
        ],
      });
      setInternalFeedback("");
      await loadContents(selectedContent.vendorCode);
      saveContentChangelogs(selectedContent.vendorCode, changelogs);
    }
  };

  const getContentStatus = (status) => {
    if (status === STATUS.CONTENT_GENERATED) return "Content Generated";
    else if (status === STATUS.FEEDBACK_ADDRESSED) return "Feedback addressed";
    else if (status === STATUS.PENDING_APPROVAL) return "Needs approval";
    else if (status === STATUS.CUSTOMER_FEEDBACK) return "Feedback sent";
    else if (status === STATUS.PENDING_PUBLISH) return "Awaiting publish";
    else if (status === STATUS.LIVE) return "Live";
  };

  useEffect(() => {
    if (vendorState.selected) {
      loadContents(vendorState.selected);
    }
  }, [vendorState.selected]);

  useEffect(() => {
    setTitleFeedback("");
    setDescriptionFeedback("");
    setBulletPointsFeedback("");
    setFeedback("");
    showLiveContent && loadLiveContent();
  }, [selectedContent?.asin]);

  useEffect(() => {
    const handleShowMore = async () => {
      if (!showMore) {
        setShowLiveContent(false);
        setShowFeedbackBox(false);
      } else {
        if (showMore === "showLiveContent") {
          setShowFeedbackBox(false);
          setShowLiveContent(true);
          await loadLiveContent();
        } else if (showMore === "showFeedbackBox") {
          setShowLiveContent(false);
          setShowFeedbackBox(true);
        }
      }
    };
    isInternal && handleShowMore();
  }, [showMore]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Approve Content"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit">Content Revisions</Link>
              <Typography color="primary">Approve Content</Typography>
            </Breadcrumbs>
          }
        />
        <Grid container>
          <Grid item xs={12}>
            <Widget upperTitle noBodyPadding disableWidgetMenu>
              {
                <Dialog
                  open={showVerifyContent}
                  onClose={() => {
                    setShowVerifyContent(false);
                    setSelectedContent({});
                    setSelectedContentUnmodified({});
                    setFeedback("");
                    setInternalFeedback("");
                    setShowMore(null);
                    setShowFeedbackBox(false);
                    setShowLiveContent(false);
                  }}
                  maxWidth={showMore ? "xl" : "lg"}
                  fullWidth={true}
                  scroll="paper"
                >
                  <DialogTitle>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {"Verify Content"}
                      <CloseOutlined
                        onClick={() => {
                          setShowVerifyContent(false);
                          setSelectedContent({});
                          setSelectedContentUnmodified({});
                          setFeedback("");
                          setInternalFeedback("");
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </DialogTitle>
                  <DialogContent>
                    {showLoader && (
                      <LinearProgress style={{ margin: "0px 24px" }} />
                    )}
                    <ContentsContainer>
                      <div style={{ display: "flex", gap: "8px" }}>
                        <List
                          style={{
                            width: "20%",
                            maxHeight: "500px",
                            overflow: "scroll",
                            overflowX: "unset",
                          }}
                        >
                          {contents.map((item, index) => (
                            <ListItemButton
                              key={index}
                              selected={selectedContent?.asin === item.asin}
                              onClick={() => {
                                if (selectedContent?.asin !== item.asin) {
                                  contents[
                                    contents.findIndex(
                                      (previewContent) =>
                                        previewContent.asin ===
                                        selectedContent?.asin
                                    )
                                  ] = _.cloneDeep(selectedContentUnmodified);
                                  setSelectedContent(_.cloneDeep(item));
                                  setSelectedContentUnmodified(
                                    _.cloneDeep(item)
                                  );
                                }
                              }}
                              style={{
                                textAlign: "center",
                                backgroundColor:
                                  selectedContent?.asin === item.asin
                                    ? "#4B50DC"
                                    : undefined,
                              }}
                            >
                              <ListItemText
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  color:
                                    selectedContent?.asin === item.asin
                                      ? "#FFFFFF"
                                      : undefined,
                                }}
                              >
                                <Typography component={"div"}>
                                  {item.asin}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  component={"div"}
                                >{`(${getContentStatus(
                                  item.status
                                )})`}</Typography>
                              </ListItemText>
                            </ListItemButton>
                          ))}
                        </List>
                        <Divider
                          orientation="vertical"
                          flexItem={true}
                          style={{ margin: "0px 4px" }}
                        />
                        <div
                          style={{
                            width: "80%",
                            display: "flex",
                            flexDirection: "column",
                            padding: "8px",
                            gap: "8px",
                          }}
                        >
                          <ContentsItemContainer>
                            <ContentsItemLabelContainer>
                              ASIN:
                            </ContentsItemLabelContainer>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "8px",
                                width: "85%",
                              }}
                            >
                              <Typography>{selectedContent?.asin}</Typography>
                              {isInternal ? (
                                <ToggleButtonGroup
                                  color="primary"
                                  value={showMore}
                                  onChange={(event, showMore) => {
                                    setShowMore(showMore);
                                  }}
                                  exclusive={true}
                                  size="small"
                                >
                                  <ToggleButton
                                    value="showLiveContent"
                                    style={{
                                      padding: "0px 7px",
                                      textTransform: "none",
                                    }}
                                  >
                                    Show Live Content
                                  </ToggleButton>
                                  <ToggleButton
                                    value="showFeedbackBox"
                                    style={{
                                      padding: "0px 7px",
                                      textTransform: "none",
                                    }}
                                  >
                                    Show Feedback Box
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              ) : (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={showLiveContent}
                                      onChange={async (e) => {
                                        setShowLiveContent(e.target.checked);
                                        if (e.target.checked)
                                          await loadLiveContent();
                                      }}
                                    />
                                  }
                                  label="Show Live Content"
                                  labelPlacement="start"
                                />
                              )}
                            </div>
                          </ContentsItemContainer>
                          <ContentsItemContainer>
                            <ContentsItemLabelContainer>
                              Title:
                            </ContentsItemLabelContainer>
                            <div
                              style={{
                                display: "flex",
                                gap: "8px",
                                width: "85%",
                              }}
                            >
                              <div
                                style={{
                                  width:
                                    showLiveContent || showFeedbackBox
                                      ? "50%"
                                      : "100%",
                                }}
                              >
                                <div
                                  style={{
                                    border: "1px solid #E8E8E8",
                                    borderRadius: "4px",
                                    width: "100%",
                                    padding: "12px",
                                  }}
                                >
                                  <KeywordsHighlight
                                    text={selectedContent?.title}
                                    keywords={selectedContent?.keywords}
                                    onChange={(value) => {
                                      setSelectedContent({
                                        ...selectedContent,
                                        title: value,
                                      });
                                    }}
                                  />
                                </div>
                                {isInternal && (
                                  <TextInputCounter
                                    textInput={selectedContent?.title}
                                  />
                                )}
                              </div>
                              {showLiveContent && (
                                <TextField
                                  label="Live Content"
                                  value={liveContent?.title}
                                  multiline
                                  variant="outlined"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  style={{
                                    width: "50%",
                                  }}
                                />
                              )}
                              <FeedbackBox
                                show={showFeedbackBox}
                                attribute={ATTRIBUTES.TITLE}
                                value={titleFeedback}
                                placeholder={"Enter title feedback"}
                                onChange={(value) => setTitleFeedback(value)}
                                content={selectedContent}
                                setContent={(content) =>
                                  setSelectedContent({
                                    ...content,
                                  })
                                }
                                reloadContents={() =>
                                  loadContents(selectedContent.vendorCode)
                                }
                                email={userState?.userData?.email}
                                width={"50%"}
                              />
                            </div>
                          </ContentsItemContainer>
                          <ContentsItemContainer>
                            <ContentsItemLabelContainer>
                              Description:
                            </ContentsItemLabelContainer>
                            <div
                              style={{
                                display: "flex",
                                gap: "8px",
                                width: "85%",
                              }}
                            >
                              <div
                                style={{
                                  width:
                                    showLiveContent || showFeedbackBox
                                      ? "50%"
                                      : "100%",
                                }}
                              >
                                <div
                                  style={{
                                    border: "1px solid #E8E8E8",
                                    borderRadius: "4px",
                                    minHeight: "150px",
                                    width: "100%",
                                    padding: "12px",
                                  }}
                                >
                                  <KeywordsHighlight
                                    text={selectedContent?.description}
                                    keywords={selectedContent?.keywords}
                                    onChange={(value) => {
                                      setSelectedContent({
                                        ...selectedContent,
                                        description: value,
                                      });
                                    }}
                                  />
                                </div>
                                {isInternal && (
                                  <TextInputCounter
                                    textInput={selectedContent?.description}
                                  />
                                )}
                              </div>
                              {showLiveContent && (
                                <TextField
                                  label="Live Content"
                                  value={liveContent?.description}
                                  multiline
                                  minRows={4}
                                  variant="outlined"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  style={{
                                    width: "50%",
                                  }}
                                />
                              )}
                              <FeedbackBox
                                show={showFeedbackBox}
                                attribute={ATTRIBUTES.DESCRIPTION}
                                value={descriptionFeedback}
                                placeholder={"Enter description feedback"}
                                onChange={(value) =>
                                  setDescriptionFeedback(value)
                                }
                                content={selectedContent}
                                setContent={(content) =>
                                  setSelectedContent({
                                    ...content,
                                  })
                                }
                                reloadContents={() =>
                                  loadContents(selectedContent.vendorCode)
                                }
                                email={userState?.userData?.email}
                                width={"50%"}
                              />
                            </div>
                          </ContentsItemContainer>
                          <ContentsItemContainer>
                            <ContentsItemLabelContainer>
                              Bullet Points:
                            </ContentsItemLabelContainer>
                            <div
                              style={{
                                display: "flex",
                                gap: "8px",
                                width: "85%",
                              }}
                            >
                              <div
                                style={{
                                  width:
                                    showLiveContent || showFeedbackBox
                                      ? "33%"
                                      : "50%",
                                }}
                              >
                                <div
                                  style={{
                                    border: "1px solid #E8E8E8",
                                    borderRadius: "4px",
                                    minWidth: "300px",
                                    width: "100%",
                                    padding: "12px",
                                  }}
                                >
                                  <KeywordsHighlight
                                    text={selectedContent?.bulletPoints?.join(
                                      "\n"
                                    )}
                                    keywords={selectedContent?.keywords}
                                    onChange={(value) => {
                                      setSelectedContent({
                                        ...selectedContent,
                                        bulletPoints: value?.split("\n") || "",
                                      });
                                    }}
                                  />
                                </div>
                                {isInternal && (
                                  <FormHelperText
                                    component={"div"}
                                    style={{
                                      margin: 0,
                                    }}
                                  >
                                    <div>
                                      {`Bullet Points (all) - Characters: ${
                                        selectedContent?.bulletPoints?.join(
                                          "\n"
                                        )
                                          ? selectedContent?.bulletPoints?.join(
                                              "\n"
                                            ).length -
                                            selectedContent?.bulletPoints
                                              ?.length +
                                            1
                                          : 0
                                      }, Words: ${
                                        selectedContent?.bulletPoints?.join(
                                          "\n"
                                        )
                                          ? selectedContent?.bulletPoints
                                              ?.join("\n")
                                              .trim()
                                              .split(/\s+/)?.length || 0
                                          : 0
                                      }`}
                                    </div>
                                    {selectedContent?.bulletPoints?.map(
                                      (item, index) => {
                                        return (
                                          <div>{`Bullet Point ${
                                            index + 1
                                          } - Characters: ${
                                            item.length
                                          }, Words: ${
                                            item
                                              ? item.trim().split(/\s+/)
                                                  ?.length || 0
                                              : 0
                                          }`}</div>
                                        );
                                      }
                                    )}
                                  </FormHelperText>
                                )}
                              </div>
                              <TextField
                                variant="outlined"
                                label="Preview"
                                multiline
                                value={getBulletedText(
                                  selectedContent?.bulletPoints
                                )}
                                style={{
                                  width:
                                    showLiveContent || showFeedbackBox
                                      ? "33%"
                                      : "50%",
                                }}
                              />
                              {showLiveContent && (
                                <TextField
                                  variant="outlined"
                                  label="Live Content"
                                  multiline
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  value={getBulletedText(
                                    liveContent?.bulletPoints
                                  )}
                                  style={{
                                    width: "33%",
                                  }}
                                />
                              )}
                              <FeedbackBox
                                show={showFeedbackBox}
                                attribute={ATTRIBUTES.BULLET_POINTS}
                                value={bulletPointsFeedback}
                                placeholder={"Enter bullet points feedback"}
                                onChange={(value) =>
                                  setBulletPointsFeedback(value)
                                }
                                content={selectedContent}
                                setContent={(content) =>
                                  setSelectedContent({
                                    ...content,
                                  })
                                }
                                reloadContents={() =>
                                  loadContents(selectedContent.vendorCode)
                                }
                                email={userState?.userData?.email}
                                width={"33%"}
                              />
                            </div>
                          </ContentsItemContainer>
                          <ContentsItemContainer>
                            <ContentsItemLabelContainer>
                              Keywords:
                            </ContentsItemLabelContainer>
                            <KeywordsTable
                              rows={(selectedContent?.keywords || []).map(
                                (item) => {
                                  return typeof item === "string"
                                    ? { keyword: item }
                                    : item;
                                }
                              )}
                              width={"85%"}
                              hideAdd={true}
                              hideCopy={true}
                              hideRemove={true}
                              updateHandler={(keywords) => {
                                setSelectedContent((prevState) => ({
                                  ...prevState,
                                  keywords,
                                }));
                              }}
                              hiddenKeywords={
                                selectedContent?.hiddenKeywords || ""
                              }
                              setHiddenKeywords={(hiddenKeywords) => {
                                setSelectedContent((prevState) => ({
                                  ...prevState,
                                  hiddenKeywords,
                                }));
                              }}
                            />
                          </ContentsItemContainer>
                          <ContentsItemContainer>
                            <ContentsItemLabelContainer>
                              Tag Lines:
                            </ContentsItemLabelContainer>
                            <div
                              style={{
                                display: "flex",
                                gap: "8px",
                                width: "85%",
                              }}
                            >
                              <TextField
                                variant="outlined"
                                multiline
                                value={selectedContent?.tagLines?.join("\n")}
                                onChange={(e) =>
                                  setSelectedContent({
                                    ...selectedContent,
                                    tagLines: e.target.value?.split("\n"),
                                  })
                                }
                                style={{
                                  width: "50%",
                                }}
                              />
                              <TextField
                                variant="outlined"
                                label="Preview"
                                multiline
                                InputProps={{
                                  readOnly: true,
                                }}
                                value={getBulletedText(
                                  selectedContent?.tagLines
                                )}
                                style={{
                                  width: "50%",
                                }}
                              />
                            </div>
                          </ContentsItemContainer>
                          <ContentsItemContainer>
                            <ContentsItemLabelContainer>
                              Recommendations:
                            </ContentsItemLabelContainer>
                            <div
                              style={{
                                display: "flex",
                                gap: "8px",
                                width: "85%",
                              }}
                            >
                              <TextField
                                variant="outlined"
                                multiline
                                value={selectedContent?.recommendations?.join(
                                  "\n"
                                )}
                                onChange={(e) =>
                                  setSelectedContent({
                                    ...selectedContent,
                                    recommendations: e.target.value?.split(
                                      "\n"
                                    ),
                                  })
                                }
                                style={{
                                  width: "50%",
                                }}
                              />
                              <TextField
                                variant="outlined"
                                label="Preview"
                                multiline
                                InputProps={{
                                  readOnly: true,
                                }}
                                value={getBulletedText(
                                  selectedContent?.recommendations
                                )}
                                style={{
                                  width: "50%",
                                }}
                              />
                            </div>
                          </ContentsItemContainer>
                          {[
                            STATUS.PENDING_APPROVAL,
                            STATUS.CUSTOMER_FEEDBACK,
                          ].includes(
                            contents.find(
                              (content) =>
                                content.asin === selectedContent?.asin
                            )?.status
                          ) &&
                            !isInternal && (
                              <ContentsItemContainer>
                                <ContentsItemLabelContainer>
                                  Feedback:
                                </ContentsItemLabelContainer>
                                <TextField
                                  value={feedback}
                                  placeholder="Enter feedback"
                                  onChange={(e) => setFeedback(e.target.value)}
                                  multiline
                                  minRows={4}
                                  variant="outlined"
                                  InputProps={{
                                    startAdornment: selectedContent?.feedback
                                      ?.length > 0 && (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column-reverse",
                                          marginBottom: "4px",
                                          maxHeight: "180px",
                                          width: "100%",
                                          overflow: "scroll",
                                        }}
                                      >
                                        {selectedContent?.feedback?.map(
                                          (item, index) => {
                                            return (
                                              <div
                                                key={index}
                                                style={{
                                                  padding: "4px 0px",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <Typography
                                                    style={{
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {item?.role ===
                                                    ROLES.CUSTOMER
                                                      ? "You"
                                                      : "SalesDuo"}
                                                  </Typography>
                                                  <Typography
                                                    variant="caption"
                                                    component="div"
                                                  >
                                                    {moment(
                                                      moment(
                                                        item?.time
                                                      ).toISOString()
                                                    ).format(
                                                      "DD MMM, YYYY hh:mm A"
                                                    )}
                                                  </Typography>
                                                </div>
                                                <Typography
                                                  style={{
                                                    whiteSpace: "break-spaces",
                                                  }}
                                                >
                                                  {item?.message}
                                                </Typography>
                                                <Divider />
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    ),
                                    style: {
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                    },
                                    inputProps: {
                                      style: {
                                        border: "1px solid #DADADA",
                                        borderRadius: "4px",
                                        padding: "4px",
                                        paddingRight: "0px",
                                      },
                                    },
                                  }}
                                  style={{
                                    width: "85%",
                                  }}
                                />
                              </ContentsItemContainer>
                            )}
                          {isInternal && (
                            <ContentsItemContainer>
                              <ContentsItemLabelContainer>
                                Internal Feedback:
                              </ContentsItemLabelContainer>
                              <TextField
                                value={internalFeedback}
                                placeholder="Enter feedback"
                                onChange={(e) =>
                                  setInternalFeedback(e.target.value)
                                }
                                multiline
                                minRows={4}
                                variant="outlined"
                                InputProps={{
                                  startAdornment: selectedContent
                                    ?.internalFeedback?.length > 0 && (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column-reverse",
                                        marginBottom: "4px",
                                        maxHeight: "180px",
                                        width: "100%",
                                        overflow: "scroll",
                                      }}
                                    >
                                      {selectedContent?.internalFeedback?.map(
                                        (item, index) => {
                                          return (
                                            <div
                                              key={index}
                                              style={{
                                                padding: "4px 0px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    gap: "4px",
                                                  }}
                                                >
                                                  <Typography
                                                    style={{
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {item?.role ===
                                                    ROLES.REVIEWER
                                                      ? "You"
                                                      : "Creator"}
                                                  </Typography>
                                                  {item?.user && (
                                                    <Typography>
                                                      ({item.user})
                                                    </Typography>
                                                  )}
                                                </div>
                                                <Typography
                                                  variant="caption"
                                                  component="div"
                                                >
                                                  {moment(
                                                    moment(
                                                      item?.time
                                                    ).toISOString()
                                                  ).format(
                                                    "DD MMM, YYYY hh:mm A"
                                                  )}
                                                </Typography>
                                              </div>
                                              <Typography
                                                style={{
                                                  whiteSpace: "break-spaces",
                                                }}
                                              >
                                                {item?.message}
                                              </Typography>
                                              <Divider />
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  ),
                                  style: {
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  },
                                  inputProps: {
                                    style: {
                                      border: "1px solid #DADADA",
                                      borderRadius: "4px",
                                      padding: "4px",
                                      paddingRight: "0px",
                                    },
                                  },
                                }}
                                style={{
                                  width: "85%",
                                }}
                              />
                            </ContentsItemContainer>
                          )}
                        </div>
                      </div>
                    </ContentsContainer>
                  </DialogContent>
                  <DialogActions>
                    <ContentsActionsContainer>
                      <ContentsActionsLeftContainer>
                        <ContentsActionButton
                          disabled={contents[0]?.asin === selectedContent?.asin}
                          onClick={() => {
                            setSelectedContent(
                              _.cloneDeep(
                                contents[
                                  contents.findIndex(
                                    (content) =>
                                      content.asin === selectedContent?.asin
                                  ) - 1
                                ]
                              )
                            );
                            setSelectedContentUnmodified(
                              _.cloneDeep(
                                contents[
                                  contents.findIndex(
                                    (content) =>
                                      content.asin === selectedContent?.asin
                                  ) - 1
                                ]
                              )
                            );
                          }}
                          label="Previous"
                        />
                        <ContentsActionButton
                          disabled={
                            contents[contents.length - 1]?.asin ===
                            selectedContent?.asin
                          }
                          onClick={() => {
                            setSelectedContent(
                              _.cloneDeep(
                                contents[
                                  contents.findIndex(
                                    (content) =>
                                      content.asin === selectedContent?.asin
                                  ) + 1
                                ]
                              )
                            );
                            setSelectedContentUnmodified(
                              _.cloneDeep(
                                contents[
                                  contents.findIndex(
                                    (content) =>
                                      content.asin === selectedContent?.asin
                                  ) + 1
                                ]
                              )
                            );
                          }}
                          label="Next"
                        />
                      </ContentsActionsLeftContainer>
                      {!isInternal && (
                        <ContentsActionsRightContainer>
                          {[
                            STATUS.PENDING_APPROVAL,
                            STATUS.CUSTOMER_FEEDBACK,
                          ].includes(
                            contents.find(
                              (content) =>
                                content.asin === selectedContent?.asin
                            )?.status
                          ) && (
                            <ContentsActionButton
                              disabled={
                                _.isEqual(
                                  selectedContentUnmodified,
                                  selectedContent
                                ) && feedback.length === 0
                              }
                              onClick={() => {
                                setFeedback("");
                                setSelectedContent(
                                  _.cloneDeep(selectedContentUnmodified)
                                );
                              }}
                              label="Reset"
                            />
                          )}
                          {contents.find(
                            (content) => content.asin === selectedContent?.asin
                          )?.status === STATUS.PENDING_APPROVAL && (
                            <ContentsActionButton
                              disabled={
                                !_.isEqual(
                                  selectedContentUnmodified,
                                  selectedContent
                                ) || feedback.length === 0
                              }
                              onClick={handleCustomerReject}
                              label="Reject"
                            />
                          )}
                          {[
                            STATUS.PENDING_APPROVAL,
                            STATUS.CUSTOMER_FEEDBACK,
                          ].includes(
                            contents.find(
                              (content) =>
                                content.asin === selectedContent?.asin
                            )?.status
                          ) && (
                            <ContentsActionButton
                              disabled={
                                _.isEqual(
                                  selectedContentUnmodified,
                                  selectedContent
                                ) && feedback.length === 0
                              }
                              onClick={handleCustomerSave}
                              label="Save"
                            />
                          )}
                          {contents.find(
                            (content) => content.asin === selectedContent?.asin
                          )?.status === STATUS.PENDING_APPROVAL && (
                            <ContentsActionButton
                              disabled={
                                !_.isEqual(
                                  selectedContentUnmodified,
                                  selectedContent
                                )
                              }
                              onClick={handleCustomerApprove}
                              label="Approve"
                            />
                          )}
                        </ContentsActionsRightContainer>
                      )}
                      {isInternal && (
                        <ContentsActionsRightContainer>
                          <ContentsActionButton
                            disabled={
                              _.isEqual(
                                selectedContentUnmodified,
                                selectedContent
                              ) && internalFeedback.length === 0
                            }
                            onClick={() => {
                              setInternalFeedback("");
                              setSelectedContent(
                                _.cloneDeep(selectedContentUnmodified)
                              );
                            }}
                            label={"Reset"}
                          />
                          <ContentsActionButton
                            disabled={internalFeedback.length === 0}
                            onClick={handleInternalReject}
                            label={"Reject"}
                          />
                          <ContentsActionButton
                            disabled={
                              _.isEqual(
                                selectedContentUnmodified,
                                selectedContent
                              ) && internalFeedback.length === 0
                            }
                            onClick={async () =>
                              await handleInternalSave(false)
                            }
                            label={"Save"}
                          />
                          <ContentsActionButton
                            onClick={async () => await handleInternalSave(true)}
                            label={
                              _.isEqual(
                                selectedContentUnmodified,
                                selectedContent
                              )
                                ? "Approve"
                                : "Save & Approve"
                            }
                          />
                        </ContentsActionsRightContainer>
                      )}
                    </ContentsActionsContainer>
                  </DialogActions>
                </Dialog>
              }
              <Table
                rows={contents}
                verifyHandler={(content) => {
                  setShowVerifyContent(true);
                  setSelectedContent(content);
                  setSelectedContentUnmodified(content);
                }}
              />
            </Widget>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
