import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import '../styles'

export default forwardRef(function Tables(props, ref) {
  const getCategories = (performance, relativeMarketShare) => {
    return props.tableData?.filter(item => item.performance === performance && item.relativeMarketShare === relativeMarketShare)
      .map(item => item.category);
  };

  const renderListItems = (performance, relativeMarketShare) => {
    const categories = getCategories(performance, relativeMarketShare);
    return categories?.map((category, index) => <li key={index}>{category}</li>);
  };
  return (
    <>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }} id={props.id}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}>
          <div style={{ writingMode: 'vertical-rl', transform: 'rotate(180deg)', fontWeight: 'bold', fontSize: '16px', paddingLeft: '40px', paddingBottom: '30px' }}>
            Category Growth
          </div>
        </div>
        <table id="bcgMatrixTable" style={{ borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th colSpan="3" style={{ textAlign: 'center', border: 'none', paddingRight: '30px', fontSize: '16px' }}>Market Share</th>
            </tr>
            <tr>
              <th style={{ width: '55px', textAlign: 'center', border: 'none' }}>Leader</th>
              <th style={{ width: '55px', textAlign: 'center', border: 'none' }}>Top-Tier</th>
              <th style={{ width: '55px', textAlign: 'center', border: 'none' }}>Lagger</th>
            </tr>
          </thead>
          <tbody style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', left: '-30px', writingMode: 'vertical-rl', transform: 'rotate(180deg)', fontWeight: 'bold', fontSize: '12px', top: '35px' }}>
              Growing
            </div>
            <tr style={{ height: '100px' }}>
              <td style={{ verticalAlign: 'top', backgroundColor: '#9CFF99', border: '1px solid black', minWidth: '300px' }}>
                <ul>{renderListItems('GROWING', 'LEADER')}</ul>
              </td>
              <td style={{ verticalAlign: 'top', backgroundColor: '#FF7863', border: '1px solid black', minWidth: '300px' }}>
                <ul>{renderListItems('GROWING', 'TOP TIER')}</ul>
              </td>
              <td style={{ verticalAlign: 'top', backgroundColor: '#FFFC69', border: '1px solid black', minWidth: '300px' }}>
                <ul>{renderListItems('GROWING', 'LAGGER')}</ul>
              </td>
            </tr>
            <div style={{ position: 'absolute', left: '-30px', writingMode: 'vertical-rl', transform: 'rotate(180deg)', fontWeight: 'bold', fontSize: '12px', bottom: '30px' }}>
              Declining
            </div>
            <tr style={{ height: '100px' }}>
              <td style={{ verticalAlign: 'top', backgroundColor: '#9CFF99', border: '1px solid black', minWidth: '300px' }}>
                <ul>{renderListItems('DECLINING', 'LEADER')}</ul>
              </td>
              <td style={{ verticalAlign: 'top', backgroundColor: '#FF7863', border: '1px solid black', minWidth: '300px' }}>
                <ul>{renderListItems('DECLINING', 'TOP TIER')}</ul>
              </td>
              <td style={{ verticalAlign: 'top', backgroundColor: '#FFFC69', border: '1px solid black', minWidth: '300px' }}>
                <ul>{renderListItems('DECLINING', 'LAGGER')}</ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="labels" style={{ width: '100%', padding: '20px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
        <div style={{ display: 'flex', gap: '5px' }}>
          <div class="previous" style={{ backgroundColor: '#9CFF99', height: '10px', width: '10px' }}></div>
          <div style={{ fontSize: '8px' }}> INVEST </div>
        </div>
        <div style={{ display: 'flex', gap: '5px' }}>
          <div class="current_actual" style={{ backgroundColor: '#FF7863', height: '10px', width: '10px' }} ></div>
          <div style={{ fontSize: '8px' }}> PROTECT </div>
        </div>
        <div style={{ display: 'flex', gap: '5px' }}>
          <div class="forecast" style={{ backgroundColor: '#FFFC69', height: '10px', width: '10px' }}></div>
          <div style={{ fontSize: '8px' }}> HARVEST </div>
        </div>
      </div>
    </>
  );
})

