const _ = require("lodash");

export const getChangelog = (before, after) => {
  const keyNames = {
    title: "Title",
    description: "Description",
    bulletPoints: "Bullet Points",
    keywords: "Keywords",
    hiddenKeywords: "Hidden Keywords",
    tagLines: "Tag Lines",
    recommendations: "Recommendations",
    status: "Status",
  };
  const attributes = [];
  const beforeChange = {};
  const afterChange = {};

  for (const key in before) {
    if (!_.isEqual(before[key], after[key])) {
      attributes.push(keyNames[key]);
      beforeChange[keyNames[key]] =
        keyNames[key] === keyNames.keywords
          ? before[key].map((item) => item.keyword)
          : before[key];
      afterChange[keyNames[key]] =
        keyNames[key] === keyNames.keywords
          ? after[key].map((item) => item.keyword)
          : after[key];
    }
  }

  return {
    attributes,
    beforeChange,
    afterChange,
  };
};

export const getHighlightIndices = (
  text,
  highlightWords,
  individual = false
) => {
  const textLower = text.toLowerCase();
  const highlightWordsLower = highlightWords
    .sort((a, b) => b.length - a.length)
    .map((item) => item.toLowerCase());

  const highlightIndices = [];

  for (const highlightWord of highlightWordsLower) {
    let startIndex = -1;

    while (
      (startIndex = textLower.indexOf(highlightWord, startIndex + 1)) !== -1
    ) {
      const regex = /[^a-z0-9]/;
      if (
        regex.test(textLower[startIndex - 1]) &&
        regex.test(textLower[startIndex + highlightWord.length])
      ) {
        highlightIndices.push([startIndex, startIndex + highlightWord.length]);
      }
    }
  }

  const highlightIndicesIndividual = [];

  if (individual) {
    for (const highlightIndex of highlightIndices) {
      for (let i = highlightIndex[0]; i <= highlightIndex[1]; i++) {
        !highlightIndicesIndividual.includes(i) &&
          highlightIndicesIndividual.push(i);
      }
    }
  }

  return individual
    ? highlightIndicesIndividual.sort((a, b) => a - b)
    : highlightIndices;
};
