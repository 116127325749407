import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete, Chip } from "@mui/material";
import { styled } from "@mui/system";
import { CloseOutlined } from "@material-ui/icons";
import { fetchUserGroupNames, saveUserGroup } from "../../../../http/api";

const CreateGroupItemContainer = styled("div")({
  display: "flex",
  gap: "4px",
  padding: "0px 4px",
});

const CreateGroupItemLabelContainer = styled(Typography)({
  width: "25%",
  textAlign: "right",
});

export default function CreateGroup(props) {
  const [message, setMessage] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [userEmails, setUserEmails] = useState([]);

  const handleCreate = async () => {
    try {
      setShowLoader(true);
      const groupNames = await fetchUserGroupNames();
      const groupNamesUpperCase = groupNames.data.map((item) =>
        item.toUpperCase()
      );
      if (groupNamesUpperCase.includes(groupName.toUpperCase())) {
        setMessage("Group name already exists. Try a different group name");
      } else {
        const createGroup = await saveUserGroup(groupName, userEmails);
        setShowLoader(false);
        setMessage(createGroup.data);
        setTimeout(() => {
          handleClose();
          props.reload();
        }, 1500);
      }
    } catch (e) {
      setMessage(e?.message || "Failed to create group");
    } finally {
      setShowLoader(false);
    }
  };

  const handleClose = () => {
    setMessage("");
    setShowLoader(false);
    setGroupName("");
    setUserEmails([]);
    props.handleCancel();
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth={true}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {"Create Group"}
            <CloseOutlined
              onClick={handleClose}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          {showLoader && <LinearProgress style={{ margin: "0px 32px" }} />}
          <DialogContentText style={{ margin: "0px", padding: "0px 32px" }}>
            {message}
          </DialogContentText>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "8px",
              gap: "8px",
            }}
          >
            <CreateGroupItemContainer>
              <CreateGroupItemLabelContainer>
                Group Name:
              </CreateGroupItemLabelContainer>
              <TextField
                placeholder="Enter group name"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                variant="outlined"
                style={{
                  width: "50%",
                }}
              />
            </CreateGroupItemContainer>
            <CreateGroupItemContainer>
              <CreateGroupItemLabelContainer>
                User Emails:
              </CreateGroupItemLabelContainer>
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={userEmails}
                onChange={(e, value) => {
                  const validEmails = value.filter((item) =>
                    /\S+@\S+\.\S+/.test(item)
                  );
                  const uniqueEmails = [];
                  validEmails.forEach((item) => {
                    if (
                      !uniqueEmails
                        .map((email) => email.toUpperCase())
                        .includes(item.toUpperCase())
                    ) {
                      uniqueEmails.push(item);
                    }
                  });
                  setUserEmails(uniqueEmails);
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      title={option}
                      size="small"
                      style={{
                        border: "1px solid #4B50DC",
                        borderRadius: "16px",
                      }}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Enter email id of users"
                    helperText={"* Minimum 1 user is required"}
                    variant="outlined"
                    style={{
                      margin: "8px 0px",
                    }}
                  />
                )}
                style={{
                  width: "50%",
                }}
              />
            </CreateGroupItemContainer>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="primary"
            size="small"
          >
            Cancel
          </Button>
          <Button
            disabled={groupName.trim().length === 0 || userEmails.length === 0}
            onClick={handleCreate}
            variant="contained"
            color="primary"
            size="small"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
