import React from "react";
import HighlightWithinTextarea from "react-highlight-within-textarea";
import { getHighlightIndices } from "../../../../healpers/content";

export default function KeywordsHighlight(props) {
  const text = props.text || "";
  const keywords =
    props.keywords?.map((item) =>
      typeof item === "string" ? item : item.keyword
    ) || [];
  const highlight =
    keywords.length > 0
      ? getHighlightIndices(text, keywords).map((item) => {
          return {
            highlight: item,
            className: "highlightKeywords",
          };
        })
      : "";

  return (
    <>
      <HighlightWithinTextarea
        value={text}
        onChange={(value) => props.onChange(value)}
        highlight={highlight}
      />
    </>
  );
}
