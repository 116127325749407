import React, { useEffect, useState } from "react";
import { Button, IconButton, TextField, Typography } from "@material-ui/core";
import {
  CancelOutlined,
  FileCopyOutlined,
  ArrowDownwardOutlined,
  SyncOutlined,
} from "@material-ui/icons";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextInputCounter from "../TextInputCounter/TextInputCounter";
import _ from "lodash";

export default function KeywordsTable(props) {
  const [newKeyword, setNewKeyword] = useState({});

  useEffect(() => {
    return () => {
      setNewKeyword({});
    };
  }, []);

  return (
    <>
      <div
        style={{
          border: "1px solid #E8E8E8",
          borderRadius: "4px",
          width: props.width || "100%",
        }}
      >
        <TableContainer component={Paper} sx={{ maxHeight: 460 }}>
          <Table size="small" stickyHeader={true}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    {!props.hideCopy && (
                      <FileCopyOutlined
                        fontSize="small"
                        onClick={async () => {
                          await navigator.clipboard.writeText(
                            "Keyword Phrases - Search Volume - Search Volume Trend - Competing Products\n" +
                              props.rows
                                .map(
                                  (row) =>
                                    `${row.keyword} - ${
                                      row.searchVolume || ""
                                    } - ${row.searchVolumeTrend || ""} - ${
                                      row.competingProducts || ""
                                    }`
                                )
                                .join("\n")
                          );
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    )}
                    Keyword Phrases
                  </div>
                </TableCell>
                <TableCell align="right">Search Volume</TableCell>
                <TableCell align="right">Search Volume Trend</TableCell>
                <TableCell align="right">Competing Products</TableCell>
                <TableCell>Options</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.rows.map((row, index) => (
                <TableRow
                  key={row.keyword}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {!props.hideCopy && (
                        <FileCopyOutlined
                          fontSize="small"
                          onClick={async () => {
                            await navigator.clipboard.writeText(
                              `${row.keyword} - ${row.searchVolume || ""} - ${
                                row.searchVolumeTrend || ""
                              } - ${row.competingProducts || ""}`
                            );
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      )}
                      {row.keyword}
                    </div>
                  </TableCell>
                  <TableCell align="right">{row.searchVolume}</TableCell>
                  <TableCell align="right">{row.searchVolumeTrend}</TableCell>
                  <TableCell align="right">{row.competingProducts}</TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                      }}
                    >
                      <IconButton
                        title="Copy to Hidden Keywords"
                        size="small"
                        onClick={() => {
                          props?.setHiddenKeywords(
                            _.uniq(
                              `${props?.hiddenKeywords || ""} ${row.keyword}`
                                .toLowerCase()
                                .split(" ")
                            ).join(" ")
                          );
                        }}
                      >
                        <SyncOutlined fontSize="small" />
                      </IconButton>
                      <IconButton
                        title="Move to Hidden Keywords"
                        size="small"
                        onClick={() => {
                          props?.updateHandler(
                            props.rows.filter(
                              (item, rowIndex) => rowIndex !== index
                            )
                          );
                          props?.setHiddenKeywords(
                            _.uniq(
                              `${props?.hiddenKeywords || ""} ${row.keyword}`
                                .toLowerCase()
                                .split(" ")
                            ).join(" ")
                          );
                        }}
                      >
                        <ArrowDownwardOutlined fontSize="small" />
                      </IconButton>
                      {!props.hideRemove && (
                        <IconButton
                          title="Remove Keyword"
                          size="small"
                          onClick={() =>
                            props?.updateHandler(
                              props.rows.filter(
                                (item, rowIndex) => rowIndex !== index
                              )
                            )
                          }
                        >
                          <CancelOutlined color="secondary" fontSize="small" />
                        </IconButton>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            <TableFooter
              style={{
                position: "sticky",
                bottom: 0,
                background: "#FFFFFF",
              }}
            >
              <TableRow>
                <TableCell colSpan={5}></TableCell>
              </TableRow>
              {!props.hideAdd && (
                <TableRow>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      label="Keyword Phrases"
                      value={newKeyword.keyword || ""}
                      onChange={(e) => {
                        setNewKeyword({
                          ...newKeyword,
                          keyword: e.target.value,
                        });
                      }}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      label="Search Volume"
                      value={newKeyword.searchVolume || ""}
                      onChange={(e) => {
                        setNewKeyword({
                          ...newKeyword,
                          searchVolume: e.target.value,
                        });
                      }}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      label="Search Volume Trend"
                      value={newKeyword.searchVolumeTrend || ""}
                      onChange={(e) => {
                        setNewKeyword({
                          ...newKeyword,
                          searchVolumeTrend: e.target.value,
                        });
                      }}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      label="Competing Products"
                      value={newKeyword.competingProducts || ""}
                      onChange={(e) => {
                        setNewKeyword({
                          ...newKeyword,
                          competingProducts: e.target.value,
                        });
                      }}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={!newKeyword.keyword}
                      onClick={() => {
                        props?.updateHandler([...props.rows, newKeyword]);
                        setNewKeyword({});
                      }}
                    >
                      Add
                    </Button>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography>Hidden Keywords:</Typography>
                  <TextField
                    value={props.hiddenKeywords}
                    onChange={(e) => {
                      props.setHiddenKeywords(
                        _.uniq(e.target.value.toLowerCase().split(" ")).join(
                          " "
                        )
                      );
                    }}
                    multiline
                    variant="outlined"
                    fullWidth={true}
                  />
                  <TextInputCounter textInput={props.hiddenKeywords} />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
