import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 400,
  },
  textarea: {
    width: "100%",
    height: "80px !important",
    padding: "10px",
    overflowY: "scroll !important",
    borderColor: "#c4c4c4 !important",
    resize: "none",
    borderRadius: "5px",
    fontSize: "16px",
    fontFamily: "Helvetica",
  },
  header: {
    backgroundColor: "white",
    padding: "20px",
    marginBottom: "30px",
  },
  link: {
    display: 'flex',
  },
  buttonContainer:{
    display: 'flex',
    gap: "30px"
  },
  importButtonContainer:{
    padding: "20px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: theme.palette.primary.main,
    backgroundColor: "rgba(255,255,255,0.5)",
  },
}));
