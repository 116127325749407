import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  CircularProgress,
  Backdrop,
  TextField,
  Box,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";

//icons
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
import CreateAddressModal from "./components/CreateAddressModal/CreateAddressModal";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { useUserState } from "../../context/UserContext";
import { useVendorState } from "../../context/VendorContext";

export default function Page() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var userState = useUserState();
  const vendorState = useVendorState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [deleteAddressDialog, setDeleteAddressDialog] = useState(false);
  const [createAddressModal, setCreateAddressModal] = useState(false);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);

  const fetchData = () => {
    setfullPageLoader(true);
    axios
      .get(BASE_URL + api.getAddressList, {
        params: { vendorCode: vendorState?.selected },
      })
      .then((res) => {
        setmainTableData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setfullPageLoader(false);
      });
  };

  useEffect(() => {
    if (userState.userData && vendorState?.selected) {
      fetchData();
    }
  }, [userState.userData, vendorState?.selected]);

  const EditButtonRenderer = (buttonProps) => {
    return (
      <Button variant="contained" color="primary" size="medium">
        EDIT
      </Button>
    );
  };

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Shipment Address"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                FBA Shipment
              </Link>
              <Typography className={classes.link} color="primary">
                Shipment Address
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={<></>}
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  width={"100%"}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setCreateAddressModal(true);
                    }}
                  >
                    Create Address
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<RefreshIcon />}
                    onClick={() => fetchData()}
                    style={{ marginLeft: 20 }}
                  >
                    Refresh
                  </Button>
                </Box>
              }
            >
              <Table
                ref={exportFunctionRef}
                tableData={mainTableData}
                setnotification={setnotification}
                seterr={seterr}
                setfullPageLoader={setfullPageLoader}
                fetchData={fetchData}
                isDeleteDialogOpen={deleteAddressDialog}
                setDeleteDialogOpen={() => setDeleteAddressDialog(true)}
                setDeleteDialogClose={() => setDeleteAddressDialog(false)}
              />
            </Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CreateAddressModal
        showmodal={createAddressModal}
        closeModal={() => {
          setCreateAddressModal(false);
          fetchData();
        }}
        toggleError={(msg) => {
          seterr(msg);
          setTimeout(() => seterr(null), 3000);
        }}
        toggleNotification={(msg) => {
          setnotification(msg);
          setTimeout(() => setnotification(null), 3000);
        }}
        setfullPageLoader={setfullPageLoader}
      />
      {notification ? (
        <Alert
          severity="success"
          style={{
            position: "absolute",
            bottom: 50,
            left: "48%",
            zIndex: 9999,
          }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{
            position: "absolute",
            bottom: 50,
            left: "48%",
            zIndex: 9999,
          }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
