import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Link } from "react-router-dom";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";
import { BASE_URL } from "../../../../healpers/api";
import api from "../../../../healpers/apiRoutes";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 5,
  },
}));

export default forwardRef(function Tables(
  { tableData, setForcastData, forcastData },
  ref
) {
  const classes = useStyles();
  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = React.useState(false);

  function handleRowChange(event) {
    const name = event.target.name;
    let value = event.target.value;
    const newObj = { ...selectedRow, [name]: value };
    setSelectedRow(newObj);
  }

  function handleSave() {
    const updated = forcastData.map((obj) => {
      if (obj.id === selectedRow.id) {
        return selectedRow;
      } else {
        return obj;
      }
    });
    setForcastData(updated);
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditButton = (event) => {
    setSelectedRow(event.data);
    handleOpen();
  };

  const handleDeleteButton = (event) => {
    const updated = forcastData.filter((obj) => obj.id !== event.data.id);
    setForcastData(updated);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const columnKeys = [
    "asin",
    "sku",
    "quantity",
  ];

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({
          onlySelected: true,
          columnKeys,
        });
      else
        gridApi.exportDataAsCsv({
          columnKeys,
        });
    },
  }));

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#F6F7FF" };
    }
  };

  const rowStyle = { background: "white" };

  const EditButton = (e) => {
    return (
      <Button color="primary" variant="contained">
        EDIT
      </Button>
    );
  };

  const DeleteButton = (e) => {
    return (
      <Button color="secondary" variant="contained">
        DELETE
      </Button>
    );
  };

  return (
    <div
      className="ag-theme-material"
      style={{ height: 620, width: "100%" }}
      id="#grid-theme-wrapper"
    >
      <AgGridReact
        onCellClicked={(event) => {
          if (event.colDef.headerName === "Edit") {
            handleEditButton(event);
          } else if (event.colDef.headerName === "Delete") {
            handleDeleteButton(event);
          }
        }}
        ref={gridRef}
        rowData={tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        getRowStyle={getRowStyle}
        suppressDragLeaveHidesColumns={true}
        frameworkComponents={{
          editButton: EditButton,
          deleteButton: DeleteButton,
        }}
      >
        <AgGridColumn headerName="ASIN" field="asin" width={150} />
        {/* <AgGridColumn headerName="Title" field="title" /> */}
        <AgGridColumn headerName="SKU" field="sku" />
        <AgGridColumn
          headerName="Quantity"
          field="quantity"
          valueFormatter={(params) => Math.round(params.value)}
          width={150}
        />
        <AgGridColumn headerName="Quantity In Case" field="quantityInCase" />
        <AgGridColumn
          headerName="Expiration Date"
          field="expirationDate"
          valueFormatter={(params) => {
            console.log({paramsValue: params.value})
            if (params.value != undefined) {
              if (params.value === 0) {
                return 0;
              }
              return moment(params.value).format("YYYY-MM-DD");
            }
          }}
        />
        <AgGridColumn headerName="Box length (in)" field="length" />
        <AgGridColumn headerName="Box width (in)" field="width" />
        <AgGridColumn headerName="Box height (in)" field="height" />
        <AgGridColumn headerName="Box weight (lb)" field="weight" />
        {/* <AgGridColumn headerName="Condition" field="condition" width={150} /> */}
        <AgGridColumn
          cellRenderer={"editButton"}
          headerName="Edit"
          width={150}
        />
        <AgGridColumn
          cellRenderer={"deleteButton"}
          headerName="Delete"
          width={150}
        />
      </AgGridReact>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h1>Edit Shipment Item</h1>
            <Grid container spacing={1} style={{ marginBottom: 10 }}>
              <Grid item>
                <TextField
                  name="quantity"
                  label="Quantity"
                  type="number"
                  variant="outlined"
                  value={selectedRow?.quantity}
                  onChange={handleRowChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginBottom: 10 }}>
              <Grid item>
                <TextField
                  name="quantityInCase"
                  label="Quantity In Case"
                  type="number"
                  variant="outlined"
                  value={selectedRow?.quantityInCase}
                  onChange={handleRowChange}
                />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent={"flex-end"} marginTop={4}>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: "10px", width: "120px" }}
                onClick={handleSave}
              >
                save
              </Button>
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: "10px", width: "120px" }}
                onClick={handleClose}
              >
                close
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
});
