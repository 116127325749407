import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  CircularProgress,
  Backdrop,
  TextField,
  Box,
  FormControl,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import XLSX from "xlsx";

//icons
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import PrintIcon from "@material-ui/icons/Print";
import PublishIcon from "@material-ui/icons/Publish";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
import AddCasePackModal from "./components/AddCasePackModal/AddCasePackModal";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { useUserState } from "../../context/UserContext";
import { useVendorState } from "../../context/VendorContext";

export default function Page() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var userState = useUserState();
  const vendorState = useVendorState();

  // local
  const [file, setfile] = useState({ name: "No File uploaded" });
  const [mainTableData, setmainTableData] = useState([]);
  const [deleteCasePackDialog, setDeleteCasePackDialog] = useState(false);
  const [addCasePackModal, setAddCasePackModal] = useState(false);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);

  async function uploadCasePackDetails(tableData) {
    try {
      setfullPageLoader(true);
      const response = await axios.post(BASE_URL + api.addCasePackDetails, {
        vendorCode: vendorState?.selected,
        tableData,
      });
      setnotification("Saved");
      setTimeout(() => setnotification(null), 3000);
      setmainTableData(response.data);
    } catch (err) {
      console.log(err);
      seterr("Error occured");
      setTimeout(() => seterr(null), 3000);
    } finally {
      setfullPageLoader(false);
    }
  }

  async function uploadCSV(e) {
    const file = e.target.files[0];
    setfile(file);
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      const tableData = data.map((row) => {
        return {
          asin: row["ASIN"],
          sku: row["SKU"],
          unitsPerCase: row["Units Per Case"],
          length: row["Length (IN)"],
          width: row["Width (IN)"],
          height: row["Height (IN)"],
          weight: row["Weight (LB)"],
          expirationDate: row["Expiration Date (Days)"],
        };
      });

      let errorRow;
      let hasErrors = false;
      for (let i = 0; i < tableData.length; i++) {
        const {
          asin,
          sku,
          unitsPerCase,
          length,
          width,
          height,
          weight,
          expirationDate,
        } = tableData[i];
        if (
          asin === undefined ||
          sku === undefined ||
          unitsPerCase === undefined ||
          length === undefined ||
          width === undefined ||
          height === undefined ||
          weight === undefined ||
          expirationDate === undefined
        ) {
          hasErrors = true;
          errorRow = i + 2;
          break;
        }
      }
      if (hasErrors) {
        seterr(`Error at row ${errorRow}`);
        setTimeout(() => seterr(null), 5000);
        return;
      }
      uploadCasePackDetails(tableData);
    };
    reader.readAsBinaryString(file);
    e.target.value = null;
  }

  const fetchCasePackDetails = () => {
    setfullPageLoader(true);
    axios
      .get(BASE_URL + api.getCasePackDetails, {
        params: { vendorCode: vendorState?.selected },
      })
      .then((res) => {
        setmainTableData(res.data);
      })
      .catch((err) => {
        console.log(err);
        seterr(err.response?.data?.msg || "Failed to fetch");
        setTimeout(() => seterr(null), 3000);
      })
      .finally(() => {
        setfullPageLoader(false);
      });
  };

  useEffect(() => {
    if (userState.userData && vendorState?.selected) {
      fetchCasePackDetails();
    }
  }, [userState.userData, vendorState?.selected]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Case Pack Details"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                FBA Shipment
              </Link>
              <Typography className={classes.link} color="primary">
                Case Pack Details
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={<></>}
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  alignItems={"baseline"}
                  width={"100%"}
                >
                  <FormControl variant="outlined">
                    <label
                      htmlFor="upload-csv"
                      style={{ position: "relative", bottom: "3px" }}
                    >
                      <input
                        style={{
                          display: "none",
                        }}
                        id="upload-csv"
                        name="upload-csv"
                        type="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={(e) => uploadCSV(e)}
                      />
                      <Button
                        color="primary"
                        variant="contained"
                        component="span"
                        size="small"
                        startIcon={<PublishIcon />}
                      >
                        Upload here
                      </Button>
                    </label>
                    <Typography
                      className={classes.link}
                      color="primary"
                      style={{ fontSize: 12, margin: "5% 0% 0% 1%" }}
                    >
                      {file.name}
                    </Typography>
                  </FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                    style={{ marginLeft: 40 }}
                  >
                    EXPORT
                  </Button>
                </Box>
              }
            >
              <Table
                ref={exportFunctionRef}
                tableData={mainTableData}
                setnotification={setnotification}
                seterr={seterr}
                setfullPageLoader={setfullPageLoader}
                fetchCasePackDetails={fetchCasePackDetails}
                isDeleteDialogOpen={deleteCasePackDialog}
                setDeleteDialogOpen={() => setDeleteCasePackDialog(true)}
                setDeleteDialogClose={() => setDeleteCasePackDialog(false)}
              />
            </Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <AddCasePackModal
        showmodal={addCasePackModal}
        closeModal={() => {
          setAddCasePackModal(false);
          fetchCasePackDetails();
        }}
        toggleError={(msg) => {
          seterr(msg);
          setTimeout(() => seterr(null), 3000);
        }}
        toggleNotification={(msg) => {
          setnotification(msg);
          setTimeout(() => setnotification(null), 3000);
        }}
        setfullPageLoader={setfullPageLoader}
      />
      {notification ? (
        <Alert
          severity="success"
          style={{
            position: "absolute",
            bottom: 50,
            left: "48%",
            zIndex: 9999,
          }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{
            position: "absolute",
            bottom: 50,
            left: "48%",
            zIndex: 9999,
          }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
