/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from "react";
import {
    Container,
    Grid,
    Breadcrumbs,
    Link,
    Typography,
    CircularProgress,
    Backdrop,
    Snackbar
} from "@material-ui/core";
import "date-fns";
import Widget from "../../components/Widget/Widget";
import Alert from '@material-ui/lab/Alert';

//icons
import HistoryIcon from "@material-ui/icons/History";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
import Form from "./components/Form/Form";

//context
import { useVendorState } from "../../context/VendorContext";

import {
  fetch3PReport,
  fetchShareOfVoiceReport,
  generateOrderMetricsData,
  fetchGeographicInsightsReport,
  fetchCampaignReports,
  fetchSalesTrafficReports,
  fetchVendorSalesReport,
  fetchJSSegmentHistory,
  catalogRefresh,
  fetchSearchTermsReport,
  fetchKeywordsReport,
  fetchVendorTrafficReport,
  fetchPromotionPerformanceReport,
  fetchCouponPerformanceReport,
  fetchProductPricingReport,
  fetchVendorNPPMarginReport,
  fetchInventoryReports,
  configureAmRuleDefault,
  pullVendorRealTimeSalesReport,
  pullVendorRealTimeTrafficReport,
  pullVendorRealTimeInventoryReport,
  fetchMbrExport,
  saveAsinsApiConfig,
  saveSegmentsApiConfig,
  saveReviewsAsinsApiConfig,
} from "../../http/api";

import useStyles from "./styles";

export default function Charts(props) {
    const exportFunctionRef = useRef();
    //global
    const classes = useStyles();
    const [mainTableData, setmainTableData] = useState([]);
    var vendorState = useVendorState();

    // const Alert = React.forwardRef(function Alert(props, ref) {
    //     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    //   });

    const [selectedVendor, setSelectedVendor] = useState([]);
    const [selectedReportType, setSelectedReportType] = useState('');
    const [segmentId, setSegmentId] = useState('');
    const [snackBar, handleSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    // local
    const [fullPageLoader, setfullPageLoader] = useState(false);

    const generateReport = async (
      vendorCode,
      reportType,
      startDate,
      endDate,
      asinConfig,
    ) => {
      let data;
      try {
        setfullPageLoader(true);
        switch (reportType) {
          case "ordermetricsday":
          case "ordermetricshour":
          case "ordermetricsasinweek":
          case "ordermetricsasinday":
          case "ordermetricsasinhour":
            data = await generateOrderMetricsData(
              vendorCode,
              reportType,
              startDate,
              endDate
            );
            break;
          case "3preport":
            data = await fetch3PReport(vendorCode, startDate, endDate);
            break;
          case "shareofvoice":
            data = await fetchShareOfVoiceReport(vendorCode, startDate, endDate);
            break;
          case "geographicinsights":
            data = await fetchGeographicInsightsReport(
              vendorCode,
              startDate,
              endDate
            );
            break;
          case "campaignreports":
            data = await fetchCampaignReports(vendorCode);
            break;
          case "salestraffic":
            data = await fetchSalesTrafficReports(
              vendorCode,
              startDate,
              endDate
            );
            break;
          case "vendorsalesreport":
            data = await fetchVendorSalesReport(vendorCode, startDate, endDate);
            break;
        case "jssegmentreports":
            data = await fetchJSSegmentHistory(segmentId, startDate, endDate);
            break;
        case "catalogRefresh":
            data = await catalogRefresh(vendorCode);
            break;
        case "searchtermsreport":
            data = await fetchSearchTermsReport();
            break;
        case "keywordsreport":
            data = await fetchKeywordsReport();
            break;
        case "vendortrafficreport":
            data = await fetchVendorTrafficReport(vendorCode, startDate, endDate);
            break;
        case "promotionperformance":
            data = await fetchPromotionPerformanceReport(vendorCode, startDate, endDate);
            break;
        case "couponperformance":
                data = await fetchCouponPerformanceReport(vendorCode, startDate, endDate);
                break;
        case "productpricing":
                data = await fetchProductPricingReport(vendorCode, startDate, endDate);
                break;
        case "vendornppmarginreport":
                data = await fetchVendorNPPMarginReport(vendorCode, startDate, endDate);
                break; 
        case "inventoryreports":
                data = await fetchInventoryReports(vendorCode, startDate, endDate);
                break;
        case "amRulesDefault":
                data = await configureAmRuleDefault(vendorCode);
                break;
        case "realTimeVendorSales":
                data = await pullVendorRealTimeSalesReport(vendorCode);
                break;
        case "realTimeVendorTraffic":
            data = await pullVendorRealTimeTrafficReport(vendorCode);
            break;
        case "realTimeVendorInventory":
            data = await pullVendorRealTimeInventoryReport(vendorCode);
            break;
        case "mbrExport":
            data = await fetchMbrExport(vendorCode);
            break;
        case "apiConfigReviewsAsins":
            data = await saveReviewsAsinsApiConfig(vendorCode, asinConfig);
            break;
        case "apiConfigAsins":
            data = await saveAsinsApiConfig(vendorCode);
            break;
        case "apiConfigSegments":
            data = await saveSegmentsApiConfig(vendorCode);
            break;
        default:
        }
        handleSnackBar(true);
        setSnackBarMessage(data?.message);
      } catch (err) {
        handleSnackBar(true);
        setSnackBarMessage(err?.message || err);
        console.error(err);
      } finally {
        setfullPageLoader(false);
      }
    };

    return (
        <>
            <Container maxWidth={false}>
                <PageTitle
                    title="Onboarding"
                    breadCrump={
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                color="inherit"
                                className={classes.link}
                            >
                                Onboarding
                            </Link>
                            <Typography className={classes.link} style={{ alignItems: "center", }}>
                                <HistoryIcon color="primary" className={classes.icon} />
                                Upload Historic Data
                            </Typography>
                        </Breadcrumbs>
                    }
                />
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        {<Widget
                            upperTitle
                            noBodyPadding
                            bodyClass={classes.tableOverflow}
                            header={
                                <div className={classes.mainChartHeader}>
                                    <Typography
                                        variant="h2"
                                        gutterBottom
                                        style={{
                                            fontWeight: 600,
                                            fontSize: 20,
                                            color: "black",
                                        }}
                                    >
                                        {`Historic Data`}
                                    </Typography>
                                </div>
                            }
                        >
                            <Table ref={exportFunctionRef} tableData={mainTableData} />
                        </Widget>}
                    </Grid>
                    <Grid item xs={12} >
                        <Widget
                            upperTitle
                            noBodyPadding
                            bodyClass={classes.tableOverflow}
                            header={
                                <div className={classes.mainChartHeader}>
                                    <Typography
                                        variant="h2"
                                        gutterBottom
                                        style={{
                                            fontWeight: 600,
                                            fontSize: 20,
                                            color: "black",
                                        }}
                                    >
                                        {`Upload Historic Data`}
                                    </Typography>
                                </div>
                            }
                        >
                            {vendorState?.list ? <Form
                                selectedVendor={selectedVendor}
                                setSelectedVendor={setSelectedVendor}
                                selectedReportType={selectedReportType}
                                setSelectedReportType={setSelectedReportType}
                                vendorList={vendorState?.list ? [{ vendorcode: 'ALL', vendorname: 'All' }, ...vendorState.list] : []}
                                generateReport={generateReport}
                                segmentId={segmentId}
                                setSegmentId={setSegmentId}
                            /> : <></>}
                        </Widget>
                    </Grid>
                </Grid>
                <Backdrop
                    className={classes.backdrop}
                    open={fullPageLoader}
                // onClick={() => setfullPageLoader(false)}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }} open={snackBar} autoHideDuration={6000} onClose={handleSnackBar}>
                    <Alert severity="success" sx={{ width: '100%' }}>
                        {snackBarMessage}
                    </Alert>
                </Snackbar>
            </Container>
        </>
    );
}
