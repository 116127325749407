import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";
import { BASE_URL } from "../../../../healpers/api";
import api from "../../../../healpers/apiRoutes";

import AddSPPackageModal from "../AddSPPackageModal/AddSPPackageModal";

export default forwardRef(function Tables(
  { tableData, setPackageListTableData },
  ref
) {
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleClose = () => {
    setSelectedRow(null);
    setOpen(false);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else gridApi.exportDataAsCsv();
    },
  }));

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#F6F7FF" };
    }
  };

  const rowStyle = { background: "white" };

  const EditButton = (e) => {
    return (
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          setSelectedRow(e.data);
          setOpen(true);
        }}
      >
        Edit
      </Button>
    );
  };

  return (
    <div
      className="ag-theme-material"
      style={{ height: 500, width: "100%" }}
      id="#grid-theme-wrapper"
    >
      <AgGridReact
        ref={gridRef}
        rowData={tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        getRowStyle={getRowStyle}
        suppressDragLeaveHidesColumns={true}
        frameworkComponents={{
          editButton: EditButton,
        }}
      >
        <AgGridColumn headerName="SellerSKU" field="SellerSKU" />
        <AgGridColumn
          headerName="QuantityShipped"
          field="QuantityShipped"
          width={200}
        />
        <AgGridColumn
          headerName="QuantityInCase"
          field="QuantityInCase"
          width={200}
        />
        <AgGridColumn headerName="Length (IN)" field="length" width={150} />
        <AgGridColumn headerName="Width (IN)" field="width" width={150} />
        <AgGridColumn headerName="Height (IN)" field="height" width={150} />
        <AgGridColumn headerName="Weight (LB)" field="weight" width={150} />
        <AgGridColumn headerName="Edit" cellRenderer={"editButton"} />
      </AgGridReact>
      <AddSPPackageModal
        open={open}
        handleClose={handleClose}
        tableData={tableData}
        setPackageListTableData={setPackageListTableData}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </div>
  );
});
