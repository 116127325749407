import React, { useEffect, useRef, useState } from "react";
import { Container, Grid, Breadcrumbs, Typography, Backdrop, CircularProgress } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import { fetchChargebacks, fetchShortageClaims } from "../../http/api";
import { useVendorState } from "../../context/VendorContext";
import { Button } from "@material-ui/core";
import CreateActionItemModal from "../actionItems/components/createActionItemModal";
import { ACTION_ITEMS } from "../../constant/wbr";
import ShortageClaimsTable from "./components/shortageClaimsTable";
import ChargebacksTable from "./components/chargebacksTable";
import PrintIcon from "@material-ui/icons/Print";
import moment from "moment";
import useStyles from "./styles.js";

export default function WbrFinance() {
  const vendorState = useVendorState();
  const [chargebacksData, setChargebacksData] = useState([]);
  const [shortageClaimsData, setShortageClaimsData] = useState([]);
  const [actionItemModal, setActionItemModal] = useState(false);
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const chargebacksExportRef = useRef();
  const shortageClaimsExportRef = useRef();
  const classes = useStyles();

  const fetchShortageClaimsData = async (vendorCode) => {
    try {
      setFullPageLoader(true)
      const { data } = await fetchShortageClaims(vendorCode);
      data.map((item) => item.reportDate = moment(item.reportDate).format('MM/DD/YYYY'))
      if (data.length) {
        setShortageClaimsData(data);
      } else {
        setShortageClaimsData([]);
      }
      await fetchChargebacksData(vendorCode);
      setFullPageLoader(false)
    } catch (err) {
      console.log(err);
      setFullPageLoader(false)
      setShortageClaimsData([]);
    }
  };

  const fetchChargebacksData = async (vendorCode) => {
    try {
      const { data } = await fetchChargebacks(vendorCode);
      data.map((item) => item.reportDate = moment(item.reportDate).format('MM/DD/YYYY'))
      if (data.length) {
        setChargebacksData(data);
      } else {
        setChargebacksData([]);
      }
    } catch (err) {
      console.log(err);
      setChargebacksData([]);
    }
  };

  useEffect(() => {
    if (vendorState?.selected) {
      fetchShortageClaimsData(vendorState?.selected)
    }
  }, [vendorState?.selected]);


  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Finance"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="primary">Finance</Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                component="span"
                size="small"
                style={{
                  whiteSpace: "nowrap",
                }}
                onClick={() => setActionItemModal(true)}
              >
                Add Action Item
              </Button>
            </div>
          }
        />
        <div style={{ background: 'white' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px' }}>
            <Typography
              variant="h2"
              gutterBottom
              style={{
                fontWeight: 600,
                fontSize: 20,
                color: "black",
              }}
            >
              Shortage Claims
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<PrintIcon />}
              style={{
                whiteSpace: "nowrap",
              }}
              onClick={() => shortageClaimsExportRef.current.onBtnExport()}
            >
              Export
            </Button>
          </div>
          <ShortageClaimsTable
            ref={shortageClaimsExportRef}
            tableData={shortageClaimsData}
          />
        </div>
        <div style={{ marginTop: '30px', background: 'white' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px' }}>
            <Typography
              variant="h2"
              gutterBottom
              style={{
                fontWeight: 600,
                fontSize: 20,
                color: "black",
              }}
            >
              Chargebacks
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<PrintIcon />}
              style={{
                whiteSpace: "nowrap",
              }}
              onClick={() => chargebacksExportRef.current.onBtnExport()}
            >
              Export
            </Button>
          </div>
          <ChargebacksTable
            ref={chargebacksExportRef}
            tableData={chargebacksData}
          />
        </div>
        <CreateActionItemModal
          open={actionItemModal}
          close={() => {
            setActionItemModal(false);
          }}
          slideName={ACTION_ITEMS.FINANCE_DISPUTES}
        />
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
