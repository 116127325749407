import { Bar } from "react-chartjs-2";
import { formatChartLabels, formatChartValues } from "../../../healpers/utilityFunctions";

export default function BarChart(props) {
  return (
    <div style={{ padding: '10px' }}>
      <Bar
        id={props.id}
        options={{
          legend: {
            display: false,
          },
          indexAxis: "y",
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                let datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                  return datasetLabel + " " + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }
            }
          },
          scales: {
            x: {
              ticks: {
                autoSkip: false,

              },
            },
            yAxes: [{
              ticks: {
                beginAtZero: true,
                callback: function(value, index, values) {
                  return formatChartLabels(parseInt(value))
                }
              },
              scaleLabel: {
                display: true,
                labelString: props.yAxisLabel ? props.yAxisLabel : ''
              }
            }]
          },
        }}
        data={{
          labels: props.labels,
          datasets: [
            {
              label: props.prevYearLabel || 'Previous Year',
              data: props?.previousYearData,
              backgroundColor: "#ff9200",
              borderColor: "#ff9200",
              barThickness: 25,
            },
            {
              label: props.currentYearLabel || 'Current Year (Actuals)',
              data: props.data,
              backgroundColor: "rgba(66, 135, 245)",
              borderColor: "rgba(66, 135, 245)",
              barThickness: 25,
            },
          ],
        }}
      />
    </div>
  );
}
