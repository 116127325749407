import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete, Chip } from "@mui/material";
import { styled } from "@mui/system";
import { CloseOutlined } from "@material-ui/icons";
import _ from "lodash";
import { deleteUserGroup } from "../../../../http/api";

const DeleteGroupItemContainer = styled("div")({
  display: "flex",
  gap: "4px",
  padding: "0px 4px",
});

const DeleteGroupItemLabelContainer = styled(Typography)({
  width: "25%",
  textAlign: "right",
});

export default function DeleteGroup(props) {
  const [message, setMessage] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [userEmails, setUserEmails] = useState([]);

  const handleDelete = async () => {
    try {
      setShowLoader(true);
      const deleteGroup = await deleteUserGroup(groupName);
      setShowLoader(false);
      setMessage(deleteGroup.data);
      setTimeout(() => {
        handleClose();
        props.reload();
      }, 1500);
    } catch (e) {
      setMessage(e?.message || "Failed to delete group");
    } finally {
      setShowLoader(false);
    }
  };

  const handleClose = () => {
    setMessage("");
    setShowLoader(false);
    setGroupName("");
    setUserEmails([]);
    props.handleCancel();
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth={true}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {"Delete Group"}
            <CloseOutlined
              onClick={handleClose}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          {showLoader && <LinearProgress style={{ margin: "0px 32px" }} />}
          <DialogContentText style={{ margin: "0px", padding: "0px 32px" }}>
            {message}
          </DialogContentText>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "8px",
              gap: "8px",
            }}
          >
            <DeleteGroupItemContainer>
              <DeleteGroupItemLabelContainer>
                Select Group:
              </DeleteGroupItemLabelContainer>
              <Autocomplete
                options={_.uniq(
                  props.userGroups?.map((item) => item.groupName)
                )}
                onChange={(event, value) => {
                  if (!value) {
                    setGroupName("");
                    setUserEmails([]);
                  } else {
                    setGroupName(value);
                    setUserEmails([
                      ...props.userGroups
                        .filter((item) => item.groupName === value)
                        .map((item) => item.userEmail),
                    ]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select group"
                    variant="outlined"
                    style={{
                      margin: "8px 0px",
                    }}
                  />
                )}
                style={{
                  width: "50%",
                }}
              />
            </DeleteGroupItemContainer>
            <DeleteGroupItemContainer>
              <DeleteGroupItemLabelContainer>
                User Emails:
              </DeleteGroupItemLabelContainer>
              <Autocomplete
                multiple
                options={[]}
                value={userEmails}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      title={option}
                      size="small"
                      style={{
                        border: "1px solid #4B50DC",
                        borderRadius: "16px",
                      }}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select group name to see users"
                    helperText={"* Readonly"}
                    variant="outlined"
                    style={{
                      margin: "8px 0px",
                    }}
                  />
                )}
                style={{
                  width: "50%",
                }}
                readOnly={true}
                forcePopupIcon={false}
              />
            </DeleteGroupItemContainer>
            <Typography>
              Warning: Deleting group will permanently remove all its data.
              Proceed with caution.
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="primary"
            size="small"
          >
            Cancel
          </Button>
          <Button
            disabled={groupName.trim().length === 0}
            onClick={handleDelete}
            variant="contained"
            color="secondary"
            size="small"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
