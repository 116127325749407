import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import '../styles'

export default forwardRef(function Tables(props, ref) {

  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);


  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true, fileName: 'chargebacks.csv' });
      else
        gridApi.exportDataAsCsv({ fileName: 'chargebacks.csv' });
    }
  }));

  const rowStyle = { background: 'white' };

  return (
    <div className="ag-theme-material" style={{ height: 620, width: '100%' }} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={50}
        rowStyle={rowStyle}
        fullWidth
        suppressDragLeaveHidesColumns={true}
        suppressRowClickSelection={true}
      >
        <AgGridColumn headerName="Chargeback Type" field="chargebackType" width={200} />
        <AgGridColumn headerName="Sub Type" field="subType" width={200} />
        <AgGridColumn headerName="No of Chargebacks" field="quantity" width={200} />
        <AgGridColumn headerName="Sum of Financial charge" field="financialSum" width={200} />
        <AgGridColumn headerName="Sum of Disputed" field="disputedSum" width={200} />
        <AgGridColumn headerName="Sum of Not Disputed" field="notDisputedSum" width={200} />
        <AgGridColumn headerName="Report Date" field="reportDate" width={200} />
      </AgGridReact>
    </div>
  );
})
