import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";

export default function EditContent(props) {
  return (
    <>
      <Dialog open={true} onClose={props.handleCancel} fullWidth={true}>
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {"Delete Content"}
            <CloseOutlined
              onClick={props.handleCancel}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure to delete the content of "${props.content?.asin} - ${props.content?.title}"?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.handleCancel}
            variant="outlined"
            color="primary"
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={props.handleConfirm}
            variant="contained"
            color="secondary"
            size="small"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
