import React, { useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import { numberWithCommas } from "../../../../healpers/utilityFunctions";
import { restoreDbArchive } from "../../../../http/api";

export default function Table(props) {
  const [infoMessage, setInfoMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const RestoreActionsRenderer = (props) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ textTransform: "capitalize" }}
        onClick={async () => {
          try {
            const response = await restoreDbArchive(props.data);
            if (response.status === "Success") {
              setInfoMessage(response.message);
            } else {
              setErrorMessage(response.message);
            }
          } catch (err) {
            console.log(err);
            setErrorMessage(err.message);
          } finally {
            setTimeout(() => {
              setInfoMessage("");
              setErrorMessage("");
            }, 3000);
          }
        }}
      >
        Restore
      </Button>
    );
  };

  return (
    <>
      {infoMessage && <Alert severity="success">{infoMessage}</Alert>}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <div className="ag-theme-material" style={{ height: 620, width: "100%" }}>
        <AgGridReact
          defaultColDef={{
            resizable: true,
            sortable: true,
            filter: "agTextColumnFilter",
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
          }}
          rowData={props.rows || []}
          headerHeight={50}
          pagination={true}
          paginationPageSize={10}
          frameworkComponents={{
            restoreActionsRenderer: RestoreActionsRenderer,
          }}
        >
          <AgGridColumn headerName={"Table"} field={"tableName"} flex={1} />
          <AgGridColumn
            headerName={"File Name"}
            field={"archiveFileName"}
            flex={3}
          />
          <AgGridColumn
            headerName={"Start Date"}
            field={"startDate"}
            valueFormatter={(param) => moment(param.value).format("MM-DD-YYYY")}
            filter={false}
            flex={1}
          />
          <AgGridColumn
            headerName={"End Date"}
            field={"endDate"}
            valueFormatter={(param) => moment(param.value).format("MM-DD-YYYY")}
            filter={false}
            flex={1}
          />
          <AgGridColumn
            headerName={"Number of Rows"}
            field={"rowCount"}
            valueFormatter={(param) => numberWithCommas(param.value)}
            filter="agNumberColumnFilter"
            flex={1}
          />
          <AgGridColumn
            filter={false}
            sort={false}
            cellRenderer="restoreActionsRenderer"
            flex={1}
          />
        </AgGridReact>
      </div>
    </>
  );
}
