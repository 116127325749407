import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  CircularProgress,
  Backdrop,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import * as XLSX from "xlsx";
import DateFnsUtils from "@date-io/date-fns";

//icons
import RefreshIcon from "@material-ui/icons/Refresh";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import PrintIcon from "@material-ui/icons/Print";
import AddIcon from "@material-ui/icons/Add";
import SendIcon from "@material-ui/icons/Send";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import ConfirmDialog from "./components/ComfirmDialog/ConfirmDialog";
import PackageListTable from "./components/PackageListTable/PackageListTable";
import PalletListTable from "./components/PalletListTable/PalletListTable";
import SPPackgeModal from "./components/AddSPPackageModal/AddSPPackageModal";
import LTLPalletModal from "./components/AddLTLPalletModal/AddLTLPalletModal";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { useUserState } from "../../context/UserContext";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useVendorState } from "../../context/VendorContext";

export default function Page() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var userState = useUserState();
  const vendorState = useVendorState();

  // local
  const [packageListTableData, setPackageListTableData] = useState([]);
  const [palletListTableData, setPalletListTableData] = useState([]);
  const [file, setfile] = useState({ name: "No File uploaded" });
  const [confirmDialog, setconfirmDialog] = useState(false);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [estimatedTime, setestimatedTime] = useState(null);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);
  const [vendor, setVendor] = useState("UNIQU_US_SC");
  const [shipmentId, setShipmentId] = useState("");
  const [shipmentType, setShipmentType] = useState("SP");
  const [spPackageOpen, setSpPackageOpen] = useState(false);
  const [ltlPalletOpen, setLtlPalletOpen] = useState(false);
  const [contact, setContact] = useState({});
  const [shipmentInfo, setShipmentInfo] = useState({ freightClass: 50 });
  const [freightDate, setFreightDate] = useState(new Date());
  const [carrierName, setCarrierName] = useState("UNITED_PARCEL_SERVICE_INC");

  async function getShipmentItems() {
    try {
      setfullPageLoader(true);
      const [response, fromDb] = await Promise.all([
        axios.get(BASE_URL + api.getShipmentItemsByShipmentId, {
          params: { shipmentId, vendorCode: vendorState?.selected },
        }),
        axios.get(BASE_URL + api.getShipmentFromDb, {
          params: { shipmentId },
        }),
      ]);
      console.log({ response });
      console.log({ fromDb });
      const packageList = response.data?.ItemData?.map((row) => {
        const itemFromDb = fromDb.data.shipmentItems.find(
          (item) => item["sku"] === row["SellerSKU"]
        );
        return {
          ...row,
          length: itemFromDb.length,
          width: itemFromDb.width,
          height: itemFromDb.height,
          weight: itemFromDb.weight,
        };
      });
      setPackageListTableData(packageList);
    } catch (err) {
      console.log(err);
      seterr(
        err?.response?.data?.msg ||
          err?.response?.data?.message ||
          "Error occured"
      );
      setTimeout(() => seterr(null), 5000);
    } finally {
      setfullPageLoader(false);
    }
  }

  const handleDateChange = (date) => {
    setFreightDate(date);
  };

  const handleLTLModalOpen = () => {
    setLtlPalletOpen(true);
  };

  const handleLTLModalClose = () => {
    setLtlPalletOpen(false);
  };

  const handleSPModalOpen = () => {
    setSpPackageOpen(true);
  };

  const handleSPModalClose = () => {
    setSpPackageOpen(false);
  };

  useEffect(() => {
    // if (userState.userData && vendor) {
    //   fetchData();
    // }
    // fetchData()
  }, [userState.userData, vendor]);

  function handleContactChange(event) {
    event.persist();
    setContact((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  }

  function handleShipmentInfoChange(event) {
    // event.persist();
    const name = event.target.name;
    const value = event.target.value;
    console.log(name, value);
    setShipmentInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  async function sendSPTranport() {
    try {
      setfullPageLoader(true);
      const data = {
        shipmentType,
        shipmentId,
        packageListTableData,
        carrierName,
        vendorCode: vendorState?.selected,
      };
      await axios.post(BASE_URL + api.sendTransportDetails, data);
      setnotification("Transport Details Sent");
      setTimeout(() => setnotification(null), 3000);
      // setShipmentId("");
      // setPackageListTableData([]);
    } catch (err) {
      console.log(err);
      const errMsg = err.response?.data?.msg || err.response?.data?.message;
      seterr(errMsg || "Error occured");
      setTimeout(() => seterr(null), 3000);
    } finally {
      setfullPageLoader(false);
    }
  }

  async function sendLTLTranport() {
    try {
      setfullPageLoader(true);
      const data = {
        shipmentType,
        shipmentId,
        contact,
        shipmentInfo,
        freightDate,
        palletListTableData,
        vendorCode: vendorState?.selected,
      };
      await axios.post(BASE_URL + api.sendTransportDetails, data);
      setnotification("Transport Details Sent");
      setTimeout(() => setnotification(null), 3000);
      // setShipmentId("");
      // setShipmentInfo({ freightClass: 50 });
      // setPalletListTableData([]);
    } catch (err) {
      console.log(err);
      const errMsg = err.response?.data?.msg || err.response?.data?.message;
      seterr(errMsg || "Error occured");
      setTimeout(() => seterr(null), 3000);
    } finally {
      setfullPageLoader(false);
    }
  }

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Send Transport Details"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                FBA Shipment
              </Link>
              <Typography className={classes.link} color="primary">
                Send Transport Details
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={<></>}
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <div className={classes.mainChartHeader}>
                  <Grid container spacing={1} style={{ marginBottom: 10 }}>
                    <Grid item>
                      <TextField
                        name="shipmentId"
                        label="Shipment Id"
                        type="text"
                        variant="outlined"
                        value={shipmentId}
                        onChange={(e) => setShipmentId(e.target.value)}
                        style={{ width: 400 }}
                      />
                    </Grid>
                    <Grid item>
                      <FormControl
                        variant="outlined"
                        // className={classes.formControl}
                        style={{ minWidth: 230 }}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Shipment Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={shipmentType}
                          onChange={(e) => setShipmentType(e.target.value)}
                          label="Shipment Type"
                        >
                          <MenuItem value={"SP"}>SP</MenuItem>
                          <MenuItem value={"LTL"}>LTL</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {shipmentType === "SP" && (
                      <Grid item>
                        <FormControl
                          variant="outlined"
                          // className={classes.formControl}
                          style={{ minWidth: 230 }}
                        >
                          <InputLabel id="carrierName">Carrier Name</InputLabel>
                          <Select
                            labelId="carrierName"
                            id="carrierName"
                            value={carrierName}
                            onChange={(e) => setCarrierName(e.target.value)}
                            label="Carrier Name"
                          >
                            <MenuItem value={"UNITED_PARCEL_SERVICE_INC"}>
                              UPS
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                </div>
              }
            ></Widget>
          </Grid>
        </Grid>
        {shipmentType === "SP" && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Widget
                upperTitle
                noBodyPadding
                bodyClass={classes.tableOverflow}
                disableWidgetMenu
                header={
                  <div
                    className={classes.mainChartHeader}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {/* <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={handleSPModalOpen}
                    >
                      ADD PACKAGE INFO
                    </Button> */}
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      // startIcon={<PrintIcon />}
                      style={{ marginLeft: 10 }}
                      onClick={getShipmentItems}
                    >
                      Fetch Shipping Items
                    </Button>
                  </div>
                }
              >
                <PackageListTable
                  ref={exportFunctionRef}
                  tableData={packageListTableData}
                  setPackageListTableData={setPackageListTableData}
                />
              </Widget>
            </Grid>
          </Grid>
        )}
        {shipmentType === "LTL" && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Widget
                upperTitle
                noBodyPadding
                bodyClass={classes.tableOverflow}
                header={
                  <div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        marginBottom: 20,
                      }}
                    >
                      <Typography
                        variant="h2"
                        gutterBottom
                        style={{
                          fontWeight: 600,
                          fontSize: 20,
                          color: "black",
                        }}
                      >
                        Contact Information
                      </Typography>
                    </div>
                    <Grid container spacing={1} style={{ marginBottom: 10 }}>
                      <Grid item>
                        <TextField
                          name="name"
                          label="Name"
                          type="text"
                          variant="outlined"
                          value={contact?.Name}
                          onChange={handleContactChange}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          name="phone"
                          label="Phone"
                          type="text"
                          variant="outlined"
                          value={contact?.Phone}
                          onChange={handleContactChange}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          name="email"
                          label="Email"
                          type="text"
                          variant="outlined"
                          value={contact?.Email}
                          onChange={handleContactChange}
                        />
                      </Grid>
                    </Grid>
                  </div>
                }
              ></Widget>
            </Grid>
          </Grid>
        )}
        {shipmentType === "LTL" && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Widget
                upperTitle
                noBodyPadding
                bodyClass={classes.tableOverflow}
                header={
                  <div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        marginBottom: 20,
                      }}
                    >
                      <Typography
                        variant="h2"
                        gutterBottom
                        style={{
                          fontWeight: 600,
                          fontSize: 20,
                          color: "black",
                        }}
                      >
                        Shipment Information
                      </Typography>
                    </div>
                    <Grid container spacing={1} style={{ marginBottom: 20 }}>
                      <Grid item>
                        <TextField
                          name="boxCount"
                          label="Box Count"
                          type="number"
                          variant="outlined"
                          value={shipmentInfo?.boxCount}
                          onChange={handleShipmentInfoChange}
                        />
                      </Grid>
                      {/* <Grid item>
                        <FormControl
                          variant="outlined"
                          style={{ minWidth: 230 }}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Freight Class
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={shipmentInfo?.freightClass}
                            onChange={handleShipmentInfoChange}
                            label="FreightClass"
                            name="freightClass"
                          >
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={55}>55</MenuItem>
                            <MenuItem value={60}>60</MenuItem>
                            <MenuItem value={65}>65</MenuItem>
                            <MenuItem value={70}>70</MenuItem>
                            <MenuItem value={77.5}>77.5</MenuItem>
                            <MenuItem value={85}>85</MenuItem>
                            <MenuItem value={92.5}>92.5</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                            <MenuItem value={110}>110</MenuItem>
                            <MenuItem value={125}>125</MenuItem>
                            <MenuItem value={150}>150</MenuItem>
                            <MenuItem value={175}>175</MenuItem>
                            <MenuItem value={200}>200</MenuItem>
                            <MenuItem value={250}>250</MenuItem>
                            <MenuItem value={300}>300</MenuItem>
                            <MenuItem value={400}>400</MenuItem>
                            <MenuItem value={500}>500</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid> */}
                      <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            name="freightReadyDate"
                            variant="inline"
                            format="yyyy-MM-dd"
                            // margin="normal"
                            id="date-picker-inline"
                            label="Freight Ready Date"
                            value={freightDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            style={{ marginLeft: 5 }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                    {/* <Grid container spacing={1} style={{ marginBottom: 10 }}>
                      <Grid item>
                        <TextField
                          name="totalWeight"
                          label="Total Weight (LB)"
                          type="number"
                          variant="outlined"
                          value={shipmentInfo?.totalWeight}
                          onChange={handleShipmentInfoChange}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          name="totalValue"
                          label="Total Value (USD)"
                          type="number"
                          variant="outlined"
                          value={shipmentInfo?.totalValue}
                          onChange={handleShipmentInfoChange}
                        />
                      </Grid>
                    </Grid> */}
                  </div>
                }
              ></Widget>
            </Grid>
          </Grid>
        )}
        {shipmentType === "LTL" && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Widget
                upperTitle
                noBodyPadding
                bodyClass={classes.tableOverflow}
                disableWidgetMenu
                header={
                  <div
                    className={classes.mainChartHeader}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={handleLTLModalOpen}
                    >
                      ADD PALLET INFO
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<PrintIcon />}
                      style={{ marginLeft: 10 }}
                      onClick={() => exportFunctionRef.current.onBtnExport()}
                    >
                      EXPORT
                    </Button>
                  </div>
                }
              >
                <PalletListTable
                  ref={exportFunctionRef}
                  tableData={palletListTableData}
                  setPalletListTableData={setPalletListTableData}
                />
              </Widget>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <div className={classes.mainChartHeader}>
                  <Grid container spacing={1} style={{ marginBottom: 10 }}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        startIcon={<SendIcon />}
                        onClick={
                          shipmentType === "SP"
                            ? sendSPTranport
                            : sendLTLTranport
                        }
                        disabled={
                          shipmentType === "SP" &&
                          (!shipmentId || packageListTableData.length === 0)
                        }
                      >
                        SEND TRANSPORT DETAILS
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              }
            ></Widget>
          </Grid>
        </Grid>
      </Container>
      <SPPackgeModal
        open={spPackageOpen}
        handleClose={handleSPModalClose}
        setmainTableData={setPackageListTableData}
      />
      <LTLPalletModal
        open={ltlPalletOpen}
        handleClose={handleLTLModalClose}
        setmainTableData={setPalletListTableData}
      />
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ConfirmDialog
        open={confirmDialog}
        toggleDialog={() => setconfirmDialog(!confirmDialog)}
        file={file}
        setFile={setfile}
        estimatedTime={estimatedTime}
        setnotification={setnotification}
        seterr={seterr}
        // fetchData={fetchData}
      />
      {notification ? (
        <Alert
          severity="success"
          style={{ position: "absolute", bottom: -200, left: "48%" }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{ position: "absolute", bottom: -200, left: "48%" }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
