import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import '../styles'

export default forwardRef(function Tables(props, ref) {

    const gridRef = useRef(null);
    const [gridApi, setGridApi] = useState(null);


    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    useImperativeHandle(ref, () => ({
        onBtnExport() {
            if (gridRef.current.api.getSelectedNodes().length)
                gridApi.exportDataAsCsv({ onlySelected: true, fileName: 'shortageClaims.csv' });
            else
                gridApi.exportDataAsCsv({ fileName: 'shortageClaims.csv' });
        }
    }));

    const rowStyle = { background: 'white' };

    return (
        <div className="ag-theme-material" style={{ height: 620, width: '100%' }} id="#grid-theme-wrapper">
            <AgGridReact
                ref={gridRef}
                rowData={props.tableData}
                suppressExcelExport={true}
                defaultColDef={{
                    filter: 'agTextColumnFilter',
                    resizable: true,
                    sortable: true,
                    headerComponentParams: {
                        template:
                            '<div class="ag-cell-label-container" role="presentation">' +
                            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                            '  </div>' +
                            '</div>',
                    },
                }}
                onGridReady={onGridReady}
                pagination={true}
                paginationPageSize={50}
                rowStyle={rowStyle}
                fullWidth
                suppressDragLeaveHidesColumns={true}
                suppressRowClickSelection={true}
            >
                <AgGridColumn headerName="Invoice Number" field="invoiceNumber" width={200} />
                <AgGridColumn headerName="Qty Variance Amount (shortage claim)" field="varianceAmount" width={200} />
                <AgGridColumn headerName="Invoice Amount" field="invoiceAmount" width={200} />
                <AgGridColumn headerName="PO" field="po" width={200} />
                <AgGridColumn headerName="Dispute ID" field="disputeId" width={200} />
                <AgGridColumn headerName="Comment" field="comment" width={200} />
                <AgGridColumn headerName="Report Date" field="reportDate" width={200} />
            </AgGridReact>
        </div>
    );
})



