import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Link } from "react-router-dom";
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Fade,
  Grid,
  Modal,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";
import { BASE_URL } from "../../../../healpers/api";
import api from "../../../../healpers/apiRoutes";
import axios from "axios";
import { useVendorState } from "../../../../context/VendorContext";
import ShipmentItemsTable from "../ShipmentItemsTable/ShipmentItemsTable";
import xlsx from "xlsx";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 5,
  },
}));

export default forwardRef(function Tables(
  {
    tableData,
    setfullPageLoader,
    setnotification,
    seterr,
    labelPrepType,
    displayAddress,
    areCasesRequired,
    forcastData,
    shipToCountryCode,
    isPerishable,
    freightDate,
    labelType,
    freightClass
  },
  ref
) {
  const classes = useStyles();
  const vendorState = useVendorState();
  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [open, setOpen] = useState(false);
  const [shipmentName, setShipmentName] = useState("");

  console.log({ selectedShipment });
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setShipmentName("")
    setOpen(false);
  };
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv();
      else gridApi.exportDataAsCsv();
    },
  }));

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#F6F7FF" };
    }
  };

  const rowStyle = { background: "white" };

  const ViewButton = (e) => {
    return (
      <Button color="primary" variant="contained">
        VIEW
      </Button>
    );
  };

  const handleViewButton = (event) => {
    setSelectedShipment(event.data);
    handleOpen();
  };

  function downloadCartonContents(shipmentId, excelData) {
    const workbook = xlsx.utils.book_new();
    const sheet = xlsx.utils.aoa_to_sheet(excelData);
    xlsx.utils.book_append_sheet(workbook, sheet);
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    var excelFileDownloadEle = document.createElement("a");
    excelFileDownloadEle.download = shipmentId || "Carton Ids" + ".xlsx";
    excelFileDownloadEle.href = window.URL.createObjectURL(excelBlob);
    excelFileDownloadEle.click();
  }

  async function createShipment() {
    try {
      setfullPageLoader(true);
      const response = await axios({
        url: BASE_URL + api.createShipment,
        method: "POST",
        data: {
          isPerishable,
          freightDate,
          shipmentName,
          labelPrepType,
          vendorCode: vendorState?.selected,
          ...selectedShipment,
          shipFromAddress: displayAddress,
          areCasesRequired,
          forcastData,
          shipToCountryCode,
          labelType,
          // freightClass
        },
        timeout: 180000
      })
      const {cartonData, createShipmentResult} = response.data
      // console.log({cartonData})
      downloadCartonContents(createShipmentResult?.ShipmentId, cartonData.excelData)
      setnotification("Shipment created");
      setTimeout(() => setnotification(null), 5000);
    } catch (err) {
      const errMsg = err?.response?.data?.msg || err?.response?.data?.message;
      seterr(errMsg || "Error occured");
      setTimeout(() => seterr(null), 5000);
      console.log(err);
    } finally {
      setfullPageLoader(false);
    }
  }

  function getAddressOneLine(address) {
    if (!address) {
      return "";
    }
    const {
      Name,
      AddressLine1,
      City,
      StateOrProvinceCode,
      PostalCode,
      CountryCode,
    } = address;
    return `${Name}, ${AddressLine1}, ${City}, ${StateOrProvinceCode}, ${PostalCode}, ${CountryCode}`;
  }

  return (
    <div
      className="ag-theme-material"
      style={{ height: 600, width: "100%" }}
      id="#grid-theme-wrapper"
    >
      <AgGridReact
        onCellClicked={(event) => {
          if (event.colDef.headerName === "View") {
            handleViewButton(event);
          }
        }}
        ref={gridRef}
        rowData={tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        getRowStyle={getRowStyle}
        suppressDragLeaveHidesColumns={true}
        frameworkComponents={{
          viewButton: ViewButton,
        }}
      >
        <AgGridColumn headerName="Shipment Id" field="ShipmentId" />
        <AgGridColumn
          headerName="Destination Fulfillment Center Id"
          field="DestinationFulfillmentCenterId"
        />
        <AgGridColumn
          headerName="Ship To Address"
          field="ShipToAddress"
          width={350}
          valueFormatter={(params) => getAddressOneLine(params.value)}
        />
        {/* <AgGridColumn headerName="LabelPrepType" field="LabelPrepType" />
        <AgGridColumn headerName="TotalUnits" field="TotalUnits" />
        <AgGridColumn headerName="FeePerUnit" field="FeePerUnit" />
        <AgGridColumn headerName="TotalFee" field="TotalFee" /> */}
        <AgGridColumn
          cellRenderer={"viewButton"}
          headerName="View"
          width={150}
        />
      </AgGridReact>
      <Dialog onClose={handleClose} open={open} maxWidth="lg">
        <DialogContent>
          <form autoComplete="off">
            <Grid container spacing={4} style={{ padding: 30 }}>
              <Grid item xs={12}>
                <Typography color="textPrimary" variant="h3">
                  Create Shipment
                </Typography>
                <br />
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="ShipmentId"
                  label="Shipment Id"
                  type="text"
                  variant="outlined"
                  disabled={true}
                  value={selectedShipment?.ShipmentId}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="ShipmentName"
                  label="Shipment Name"
                  type="text"
                  variant="outlined"
                  value={shipmentName}
                  onChange={(e) => setShipmentName(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="DestinationFulfillmentCenterId"
                  label="Destination Fulfillment Center Id"
                  type="text"
                  variant="outlined"
                  disabled={true}
                  value={selectedShipment?.DestinationFulfillmentCenterId}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="ShipToAddress"
                  label="Ship To Address"
                  type="text"
                  variant="outlined"
                  disabled={true}
                  value={getAddressOneLine(selectedShipment?.ShipToAddress)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="LabelPrepType"
                  label="Label Prep Type"
                  type="text"
                  variant="outlined"
                  disabled={true}
                  value={selectedShipment?.LabelPrepType}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="TotalUnits"
                  label="Total Units"
                  type="text"
                  variant="outlined"
                  disabled={true}
                  value={selectedShipment?.EstimatedBoxContentsFee?.TotalUnits}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="FeePerUnit"
                  label="Fee Per Unit (USD)"
                  type="text"
                  variant="outlined"
                  disabled={true}
                  value={
                    selectedShipment?.EstimatedBoxContentsFee?.FeePerUnit?.Value
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="TotalFee"
                  label="Total Fee (USD)"
                  type="text"
                  variant="outlined"
                  disabled={true}
                  value={
                    selectedShipment?.EstimatedBoxContentsFee?.TotalFee?.Value
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <ShipmentItemsTable tableData={selectedShipment?.Items} />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={createShipment}
            disabled={!shipmentName}
          >
            create
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginLeft: "10px" }}
            onClick={handleClose}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

// </Grid>
// <Box display="flex" justifyContent={"flex-end"} marginTop={4}>

// </Box>
