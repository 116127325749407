import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  CircularProgress,
  Backdrop,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import * as XLSX from "xlsx";
import DateFnsUtils from "@date-io/date-fns";

//icons
import RefreshIcon from "@material-ui/icons/Refresh";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import PrintIcon from "@material-ui/icons/Print";
import AddIcon from "@material-ui/icons/Add";
import SendIcon from "@material-ui/icons/Send";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { useUserState } from "../../context/UserContext";
import { useVendorState } from "../../context/VendorContext";

export default function Page() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var userState = useUserState();
  const vendorState = useVendorState();

  // local
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [shipmentId, setShipmentId] = useState("");
  const [open, setOpen] = useState(false);

  async function sendTrackingNumbers() {
    try {
      setfullPageLoader(true);
      await axios.post(BASE_URL + api.sendTrackingNumbers, {
        trackingNumbers: tableData,
        vendorCode: vendorState?.selected,
        shipmentId,
      });
    } catch (err) {
      console.log(err);
      seterr(err?.response?.message ?? "Error occured");
      setTimeout(() => seterr(null), 3000);
    } finally {
      setfullPageLoader(false);
    }
  }

  function openModal() {
    setOpen(true);
  }

  function closeModal() {
    setTrackingNumber("");
    setOpen(false);
  }

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Send Tracking Numbers"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                FBA Shipment
              </Link>
              <Typography className={classes.link} color="primary">
                Send Tracking Numbers
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <TextField
                name="shipmentId"
                value={shipmentId}
                onChange={(e) => setShipmentId(e.target.value)}
                label="Shipment Id"
                variant="outlined"
                style={{ marginRight: 100, width: 400 }}
              />
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <div
                  className={classes.mainChartHeader}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {/* <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={handleSPModalOpen}
                    >
                      ADD PACKAGE INFO
                    </Button> */}
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    // startIcon={<PrintIcon />}
                    style={{ marginLeft: 10 }}
                    onClick={openModal}
                  >
                    Add Tracking Number
                  </Button>
                </div>
              }
            >
              <Table
                ref={exportFunctionRef}
                tableData={tableData}
                setTableData={setTableData}
              />
            </Widget>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <div className={classes.mainChartHeader}>
                  <Grid container spacing={1} style={{ marginBottom: 10 }}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        startIcon={<SendIcon />}
                        onClick={sendTrackingNumbers}
                        disabled={tableData.length <= 0 || !shipmentId}
                      >
                        SEND TRACKING NUMBERS
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              }
            ></Widget>
          </Grid>
        </Grid>
      </Container>
      <Dialog onClose={closeModal} open={open} maxWidth="lg">
        <DialogContent>
          <form autoComplete="off">
            <Grid container spacing={2} style={{ padding: 30 }}>
              <Grid item xs={12}>
                <Typography color="textPrimary" variant="h3">
                  Enter Tracking Number
                </Typography>
                <br />
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="trackingNumber"
                  value={trackingNumber}
                  onChange={(e) => setTrackingNumber(e.target.value)}
                  label="Trackng Number"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setTableData((prev) => {
                return [...prev, { id: Math.random(), trackingNumber }];
              });
              setTrackingNumber("");
            }}
            disabled={!trackingNumber}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {notification ? (
        <Alert
          severity="success"
          style={{ position: "absolute", bottom: -200, left: "48%" }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{ position: "absolute", bottom: -200, left: "48%" }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
