const ACTION_ITEMS = {
  KEY_INPUT_METRICS: "Key Input Metrics",
  KEY_OUTPUT_METRICS: "Key Output Metrics",
  OTHER_KEY_METRICS: "Other Key Metrics",
  PROGRESS_AGAINST_GOALS: "Progress Against Goals",
  FINANCE_DISPUTES: "Finance Disputes",
  OPERATION_TICKETS: "Operation Tickets",
  AMS: "AMS",
}
const ACTION_ITEMS_STATUS = {
  TODO: 'TODO',
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  OPEN: 'OPEN',
  CLOSED: 'CLOSED'
}

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
const chartLegend = [{ name: 'Previous Year', backgroundColor: '#ff9200' }, { name: 'Current Year', backgroundColor: 'rgba(66, 135, 245)' }]
const forecastChartLegend = [{ name: 'Previous Year', backgroundColor: '#ff9200' }, { name: 'Current Year (Actuals) ', backgroundColor: 'rgba(66, 135, 245)' }, { name: 'Current Year (Forecast)', backgroundColor: 'green' }]
const amsChartLegend = [{ name: 'Previous Year (Sales)', backgroundColor: '#ff9200' }, { name: 'Current Year (Sales)', backgroundColor: 'rgba(66, 135, 245)' }, { name: 'Previous Year Ad Driven Sales', backgroundColor: 'green' }, { name: 'Current Year Ad Driven Sales', backgroundColor: 'rgba(168, 87, 50)' }]
const categoryPerformance = [{ name: 'Previous Year', backgroundColor: '#ff9200' }, { name: 'Current Year (Actuals) ', backgroundColor: 'rgba(66, 135, 245)' }]
const marketShare = [{ name: 'Our Market Share ', backgroundColor: 'rgba(66, 135, 245)' },{ name: 'Competitor Share ', backgroundColor: '#ff9200' }]
const inventoryLegend = [{ name: 'Out of Stock ASINs ', backgroundColor: '#ff9200' }, { name: 'Number of Days ASINs OOS Weekly / Monthly', backgroundColor: 'rgba(66, 135, 245)' }]

const FILTER_OPTIONS = {
  ASIN: 'ASIN',
  CATEGORY: 'CATEGORY'
}
const countryCurrencyList = [
  { country: 'US', currency: 'USD', currencyIndicator: '$' },
  { country: 'CA', currency: 'CAD', currencyIndicator: '$' },
  { country: 'GB', currency: 'GBP', currencyIndicator: '£' },
  { country: 'FR', currency: 'EUR', currencyIndicator: '€' },
  { country: 'DE', currency: 'EUR', currencyIndicator: '€' },
  { country: 'IT', currency: 'EUR', currencyIndicator: '€' },
  { country: 'ES', currency: 'EUR', currencyIndicator: '€' },
  { country: 'JP', currency: 'JPY', currencyIndicator: '¥' },
  { country: 'CN', currency: 'CNY', currencyIndicator: '¥' },
  { country: 'AU', currency: 'AUD', currencyIndicator: '$' },
  { country: 'NZ', currency: 'NZD', currencyIndicator: '$' },
  { country: 'IN', currency: 'INR', currencyIndicator: '₹' },
  { country: 'SG', currency: 'SGD', currencyIndicator: '$' },
  { country: 'KR', currency: 'KRW', currencyIndicator: '₩' },
  { country: 'MX', currency: 'MXN', currencyIndicator: '$' },
  { country: 'BR', currency: 'BRL', currencyIndicator: 'R$' },
  { country: 'RU', currency: 'RUB', currencyIndicator: '₽' },
  { country: 'ZA', currency: 'ZAR', currencyIndicator: 'R' },
  { country: 'UK', currency: 'GBP', currencyIndicator: '£' },
];

const colors = [
  'rgba(255, 99, 132, 0.6)', 
  'rgba(255, 159, 64, 0.6)', 
  'rgba(255, 205, 86, 0.6)', 
  'rgba(75, 192, 192, 0.6)', 
  'rgba(153, 102, 255, 0.6)', 
  'rgba(255, 159, 64, 0.6)',
  'rgba(255, 99, 71, 0.6)', 
  'rgba(255, 140, 0, 0.6)', 
  'rgba(255, 215, 0, 0.6)', 
  'rgba(124, 252, 0, 0.6)', 
  'rgba(0, 255, 127, 0.6)', 
  'rgba(50, 205, 50, 0.6)', 
  'rgba(46, 139, 87, 0.6)', 
  'rgba(60, 179, 113, 0.6)', 
  'rgba(102, 205, 170, 0.6)', 
  'rgba(32, 178, 170, 0.6)', 
  'rgba(47, 79, 79, 0.6)', 
  'rgba(128, 128, 0, 0.6)', 
  'rgba(128, 0, 128, 0.6)', 
  'rgba(139, 0, 139, 0.6)', 
  'rgba(148, 0, 211, 0.6)', 
  'rgba(153, 50, 204, 0.6)', 
  'rgba(186, 85, 211, 0.6)', 
  'rgba(147, 112, 219, 0.6)', 
  'rgba(138, 43, 226, 0.6)', 
  'rgba(160, 82, 45, 0.6)', 
  'rgba(205, 133, 63, 0.6)', 
  'rgba(210, 105, 30, 0.6)', 
  'rgba(244, 164, 96, 0.6)', 
  'rgba(255, 69, 0, 0.6)', 
  'rgba(255, 99, 71, 0.6)', 
  'rgba(255, 127, 80, 0.6)', 
  'rgba(240, 128, 128, 0.6)', 
  'rgba(233, 150, 122, 0.6)', 
  'rgba(250, 128, 114, 0.6)', 
  'rgba(255, 160, 122, 0.6)', 
  'rgba(255, 228, 196, 0.6)', 
  'rgba(255, 218, 185, 0.6)', 
  'rgba(255, 228, 181, 0.6)', 
  'rgba(255, 222, 173, 0.6)', 
  'rgba(255, 250, 205, 0.6)', 
  'rgba(250, 250, 210, 0.6)', 
  'rgba(255, 239, 213, 0.6)', 
  'rgba(255, 245, 238, 0.6)', 
  'rgba(255, 240, 245, 0.6)', 
  'rgba(255, 228, 225, 0.6)', 
  'rgba(255, 240, 245, 0.6)', 
  'rgba(255, 228, 225, 0.6)', 
  'rgba(255, 20, 147, 0.6)', 
  'rgba(255, 105, 180, 0.6)', 
  'rgba(255, 182, 193, 0.6)' 
];


module.exports = {
  ACTION_ITEMS,
  ACTION_ITEMS_STATUS,
  months,
  chartLegend,
  forecastChartLegend,
  amsChartLegend,
  inventoryLegend,
  FILTER_OPTIONS,
  countryCurrencyList,
  categoryPerformance,
  marketShare,
  colors
}