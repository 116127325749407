import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Breadcrumbs,
  Select,
  FormControl,
  MenuItem,
  Container,
  Button,
  CircularProgress,
  Backdrop,
  InputLabel,
  Link,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import moment from "moment";
import axios from "axios";

//icons
import PrintIcon from "@material-ui/icons/Print";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import Table from "./components/Table/Table";
import RpmMeter from "./components/rpmMeter/RpmMeter";
import SmallTable from "./components/SmallTable/Table";
import PageTitle from "../../components/PageTitle/PageTitle";

//context
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";

//helpers
import { getMonthDatesBetween, getQuarterDatesBetween, getWeekDatesBetween, getYearDatesBetween } from "../../healpers/utilityFunctions";
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import { cloneAndSortDescending } from "../../healpers/dateRange.helpers";
import { SP_REPORT_TYPES } from "../../constant/spReports";

export default function Dashboard() {
  var classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var vendorState = useVendorState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [smallTableData, setsmallTableData] = useState([]);
  const [currentSalesGoal, setcurrentSalesGoal] = useState(null);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [range, setRange] = useState(null);

  const [radioValue, setradioValue] = useState("weekly");

  const [dateRange, setdateRange] = useState(null);

  const [weekRange, setweekRange] = useState(null);
  const [monthRange, setmonthRange] = useState(null);
  const [quarterRange, setquarterRange] = useState(null);
  const [annualRange, setannualRange] = useState(null);
  const [salesGoal, setsalesGoal] = useState(null);
  const [selectedDateRange, setselectedDateRange] = useState(0);  
  const [forecast, setForecast] = useState(0)

  const handleRadioChange = (event) => {
    setradioValue(event.target.value);
    if (event.target.value === "weekly") {
      setdateRange(weekRange);
      setselectedDateRange(0);
      setsalesGoal(vendorState.weeklysalesgoal);
    } else if (event.target.value === "monthly") {
      setdateRange(monthRange);
      setselectedDateRange(0);
      setsalesGoal(vendorState.monthlysalesgoal);
    } else if (event.target.value === "quarterly") {
      setdateRange(quarterRange);
      setselectedDateRange(0);
      setsalesGoal(vendorState.quarterlysalesgoal);
    } else {
      setdateRange(annualRange);
      setselectedDateRange(0);
      setsalesGoal(vendorState.annualsalesgoal);
    }
    // setselectedDateRange(0);
  };

  const calculateRpmMeterData = (cleanSmallTable) => {
    if (cleanSmallTable && cleanSmallTable.length !== 0) {
      console.log('cleanSmallTable', cleanSmallTable);
      const result = cleanSmallTable.find(
        ({ KPIs }) => KPIs === "Ordered Product Sales"
      );
      setcurrentSalesGoal(result["Reported"].slice(1).replace(/,/g, ""));
    }
  };

  useEffect(() => {
    setsalesGoal(vendorState.weeklysalesgoal);
  }, [vendorState.weeklysalesgoal])

  useEffect(() => {
    if(vendorState.selected){
      fetchDateRanges();
    }
  }, [vendorState.selected]);
  

  useEffect(() => {
    if (range) {
      const weekDateRange = cloneAndSortDescending([...getWeekDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const monthDateRange = cloneAndSortDescending([...getMonthDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const quarterDateRange = cloneAndSortDescending([...getQuarterDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const annualDateRange = cloneAndSortDescending([...getYearDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      setdateRange(weekDateRange);
      if (weekDateRange.length !== 0)
        setselectedDateRange(0);

      setweekRange(weekDateRange);
      setmonthRange(monthDateRange);
      setquarterRange(quarterDateRange);
      setannualRange(annualDateRange);
    }
    return () => {
      setdateRange(null);
      setweekRange([]);
      setmonthRange([]);
      setquarterRange([]);
      setannualRange([]);
    };
  }, [range]);

  const fetchDateRanges = async () => {
    try{
      setfullPageLoader(true);
      const params = {
        vendorCode: vendorState.selected,
        reportType: SP_REPORT_TYPES.SALES_TRAFFIC_REPORT
      }
      const result = await axios.get(BASE_URL + api.spDateRange, tokenConfig(params) )
      if (result.data.data) {
        setRange(result.data.data);
        setfullPageLoader(false);
      }          
    }catch(error){
      console.log(error)
      setfullPageLoader(false);
    }
  }

  useEffect(() => {
    async function loadData() {
      try {
        setfullPageLoader(true);
        const params = {
          vendorCode: vendorState.selected,
          startDate: moment(dateRange[selectedDateRange].startdate).format("MM-DD-YYYY"),
          endDate: moment(dateRange[selectedDateRange].enddate).format("MM-DD-YYYY")
        }
        axios
          .get(BASE_URL + api.spSellerCentral, tokenConfig(params) )
          .then((result) => {
            if (result.data.data) {
              setsmallTableData(result.data.data.smallTable);
              calculateRpmMeterData(result.data.data.smallTable);
              setmainTableData(result.data.data.mainTable);
              setfullPageLoader(false);
              setForecast(result.data.data.forecast.toFixed())
            }          
          })
          .catch((err) => {
            console.log(err);
            setfullPageLoader(false);
          });
      } catch (error) {
        setfullPageLoader(false);
        console.log(error);
      }
    }
    if (dateRange && dateRange.length !== 0) {
      loadData();
    }

    return () => {
      setcurrentSalesGoal(null);
      setsmallTableData(null);
      setmainTableData(null);
    };
  }, [radioValue, dateRange, selectedDateRange, vendorState.selected]);

  return (
    <Container maxWidth={false}>
      <PageTitle
        title="Goals & Performance"
        breadCrump={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className={classes.link}>
              Sales
            </Link>
            <Link color="inherit" className={classes.link}> 
              Goals & Performance
            </Link>
            <Typography className={classes.link} color="primary">
              Seller Central
            </Typography>
          </Breadcrumbs>
        }
        rightAlignComponent={
          <>
            <FormControl style={{ marginRight: "8%" }}>
              <RadioGroup value={radioValue} onChange={handleRadioChange}>
                <FormControlLabel
                  value="weekly"
                  control={<Radio color="primary" />}
                  label="Weekly"
                />
                <FormControlLabel
                  value="monthly"
                  control={<Radio color="primary" />}
                  label="Monthly"
                />
              </RadioGroup>
            </FormControl>
            <FormControl style={{ marginRight: "10%" }}>
              <RadioGroup value={radioValue} onChange={handleRadioChange}>
                <FormControlLabel
                  value="quarterly"
                  control={<Radio color="primary" />}
                  label="Quarterly"
                />
                <FormControlLabel
                  value="yearly"
                  control={<Radio color="primary" />}
                  label="Yearly"
                />
              </RadioGroup>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Select Range
              </InputLabel>
              <Select
                value={selectedDateRange}
                onChange={(e) => {
                  setselectedDateRange(e.target.value);
                }}
                label="Select Range"
                disabled={dateRange && dateRange.length === 0 ? true : false}
              >
                {dateRange ? (
                  dateRange.map((range, index) => {
                    return (
                      <MenuItem value={index}>
                        {range.startdate + " - " + range.enddate}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem value={0}>
                    <i>No Dates</i>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </>
        }
      />
      <Grid container spacing={4}>
        <Grid item sm={8} xs={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 24,
                    color: "black",
                  }}
                >
                  Sales Performance
                </Typography>
              </div>
            }
          >
            <SmallTable tableData={smallTableData} />
          </Widget>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 24,
                    color: "black",
                  }}
                >
                  Sales Goal
                </Typography>
              </div>
            }
          >
            <RpmMeter
              currentSalesGoal={currentSalesGoal}
              salesGoal={forecast}
            />
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    color: "black",
                  }}
                >
                  Sales Performance
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<PrintIcon />}
                  onClick={() => exportFunctionRef.current.onBtnExport()}
                >
                  Export
                </Button>
              </div>
            }
          >
            <Table ref={exportFunctionRef} tableData={mainTableData} />
          </Widget>
        </Grid>
      </Grid>
      <Backdrop
        className={classes.backdrop}
        open={fullPageLoader}
        // onClick={() => setfullPageLoader(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
