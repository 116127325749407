import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";

export default function DraggableDialog({
  open,
  onCancel,
  onConfirm,
}) {

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
      >
        <Paper style={{ paddingBottom: 10, paddingRight: 10 }}>
          <DialogTitle>Delete Action Item</DialogTitle>
          <DialogContent>
              <DialogContentText>
                Are you sure to delete
              </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              variant="outlined"
              onClick={() => {
                onCancel();
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                onConfirm();
              }}
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
}
