import { Pie } from "react-chartjs-2";
import { formatChartLabels, formatChartValues } from "../../../healpers/utilityFunctions";
import { colors } from "../../../constant/wbr";


export default function PieChart(props) {
    let dataSet = []
    const currentWTD = props.data.map((item) => item.currentWeekSales);
    const percentageChange = props.data.map((item) => item.salesPercentageChange);
    if (props.biAccess) {
        dataSet = currentWTD
    } else {
        dataSet = percentageChange
    }
    const backgroundColors = props.data.map((item, index) => {
        if (item.type === 'competitor') {
            return colors[index];
        } else {
            return 'rgba(66, 135, 245)';
        }
    });

    const labels = [
        ...props.data.map((item) => item.brandName)
    ];
    const data = {
        labels,
        datasets: [{
            data: dataSet,
            backgroundColor: backgroundColors,
        }],
    };
    return (
        <div style={{ padding: '10px' }}>
            <Pie
                id={props.id}
                options={{
                    legend: {
                        display: true,
                        position: 'top'
                    },
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                let datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                                let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                return datasetLabel + " " + value.toFixed(2);
                            }
                        }
                    }
                }}
                data={data}
            />
        </div>
    );
}
