import React from 'react';
import { Bar } from 'react-chartjs-2';

const MixedChart = (props) => {
  const data = {
    labels: props.labels,
    datasets: [
      {
        data: props.data,
        fill: false,
        borderColor: 'rgba(66, 135, 245)',
        tension: 0.1,
        type: 'line'
      },
      {
        data: props.asins,
        backgroundColor: '#ff9200',
        borderColor: '#ff9200',
        borderWidth: 1,
        type: 'bar'
      },
      
    ]
  };

  const options = {
    legend:{display: false},
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          stepSize: 10
        },
        scaleLabel: {
          display: true,
          labelString: 'Inventory Availability'
        }
      }]
    }
  }

  return (
    <div>
      <Bar data={data} id={props.id} options={options} />
    </div>
  );
};

export default MixedChart;
