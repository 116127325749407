import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";
import { BASE_URL } from "../../../../healpers/api";
import api from "../../../../healpers/apiRoutes";
import axios from "axios";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

export default forwardRef(function Tables(
  { tableData, setShipmentItems, setfullPageLoader, setnotification, seterr, isPerishable },
  ref
) {
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [editItem, setEditItem] = useState(false);
  const [selectedShipmentItem, setSelectedShipmentItem] = useState(null);
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const handleDateChange = (date) => {
    console.log({ date });
    setSelectedDate(date);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else gridApi.exportDataAsCsv();
    },
  }));

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#F6F7FF" };
    }
  };

  const rowStyle = { background: "white" };

  const EditButtonRenderer = (props) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={(e) => {
          setSelectedShipmentItem(props.data);
          setEditItem(true);
        }}
      >
        EDIT
      </Button>
    );
  };

  console.log({ shipmentItem: selectedShipmentItem });

  return (
    <div
      className="ag-theme-material"
      style={{ height: 620, width: "100%" }}
      id="#grid-theme-wrapper"
    >
      <AgGridReact
        ref={gridRef}
        rowData={tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        getRowStyle={getRowStyle}
        suppressDragLeaveHidesColumns={true}
        frameworkComponents={{
          editButtonRenderer: EditButtonRenderer,
        }}
      >
        <AgGridColumn headerName="Seller SKU" field="SellerSKU" />
        {isPerishable && <AgGridColumn
          headerName="Expiration Date"
          field="expirationDate"
          valueFormatter={(params) => {
            if (params.value) {
              return moment(new Date(params?.value)).format("YYYY-MM-DD");
            }
          }}
        />}
        <AgGridColumn headerName="Quantity Shipped" field="QuantityShipped" />
        <AgGridColumn headerName="Quantity In Case" field="QuantityInCase" />
        <AgGridColumn headerName="Quantity Received" field="QuantityReceived" />
        {/* <AgGridColumn cellRenderer={"editButtonRenderer"} width={100} /> */}
      </AgGridReact>
      <Dialog
        onClose={() => {
          setSelectedShipmentItem(null);
          setEditItem(false);
        }}
        open={editItem}
        maxWidth="lg"
      >
        <DialogContent>
          <form autoComplete="off">
            <Grid container spacing={2} style={{ padding: 30 }}>
              <Grid item xs={12}>
                <Typography color="textPrimary" variant="h3">
                  Edit Shipment Item
                </Typography>
                <br />
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Expiration Date"
                      value={selectedShipmentItem?.expirationDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const updatedShipmentItem = {
                ...selectedShipmentItem,
                expirationDate: selectedDate,
              };
              const updatedShipmentItems = tableData.map((item) => {
                if (item?.SellerSKU != updatedShipmentItem?.SellerSKU) {
                  return item;
                } else {
                  return updatedShipmentItem;
                }
              });
              setShipmentItems(updatedShipmentItems);
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setSelectedShipmentItem(null);
              setEditItem(false);
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});
