import React, { useState, useRef, useEffect } from "react";
import {
	Grid,
	Button,
	Typography,
	Breadcrumbs,
	Link,
	Backdrop,
	CircularProgress,
	FormControl,
	Select,
	MenuItem,
	TextField,
} from "@material-ui/core";
import useStyles from "./styles.js";
import { BASE_URL } from "../../healpers/api.js";
import axios from "axios";
import api from "../../healpers/apiRoutes.js";
import { tokenConfig } from "../../context/UserContext.js";
import { useVendorState } from "../../context/VendorContext.js";
import Widget from "../../components/Widget/Widget.js";
import { FILTER_OPTIONS } from "../../constant/wbr.js";
import Table from "./components/table.js"
import RuleModal from "./components/RuleModal.js";
import Confirmation from "./components/confirmation.js";
import { AM_METRICS, AM_STATUS, COMPARING_RANGE_TYPES } from "../../constant/amRules.js";
import useQuery from "../../hooks/useQuery.js"
import _ from "lodash";
import RefreshIcon from "@material-ui/icons/Refresh";
import PrintIcon from "@material-ui/icons/Print";
import moment from "moment";

export default function Summary(props) {
	const vendorState = useVendorState();
	const classes = useStyles();
	const exportFunctionRef = useRef();
	const [ruleData, setRuleData] = useState([]);
	const [fullPageLoader, setFullPageLoader] = useState(false);
	const [asin, setAsin] = useState("")
	const [category, setCategory] = useState("")
	const [selectedFilter, setSelectedFilter] = useState(FILTER_OPTIONS.CATEGORY)
	const [openModal, setModalOpen] = useState(false)
	const [ruleId, setRuleId] = useState("")
	const [edit, setEdit] = useState(false)
	const [deleteRuleModal, setDeleteRuleModal] = useState(false)
	const [selectedMetric, setSelectedMetric] = useState("")
	const [rulesList, setRulesList] = useState([])

	const query = useQuery()
	const metric = query.get('metric')

	const fetchRulesList = async () => {
		try {
			setFullPageLoader(true);
			const params = {
				vendorCode: vendorState?.selected,
			};
			if (asin !== "" && selectedFilter === FILTER_OPTIONS.ASIN) {
				params.asins = asin.split(",")
			}
			if (category !== "" && selectedFilter === FILTER_OPTIONS.CATEGORY) {
				params.category = category
			}
			const response = await axios(
				BASE_URL + api.listRules,
				tokenConfig(params)
			);
			let metricData = response.data.data;
			const dateFormat = "MM/DD/YYYY";
			metricData = metricData.map((item) => {
				if (item.status) {
					item.asinsInRed = 0;
					item.asinsInAmber = 0;
					item.asinsInGreen = 0;
					item.status.forEach((status) => {
						if (status.status === AM_STATUS.RED) {
							item.asinsInRed += 1;
						} else if (status.status === AM_STATUS.AMBER) {
							item.asinsInAmber += 1;
						} else if (status.status === AM_STATUS.GREEN) {
							item.asinsInGreen += 1;
						}
					});
				}
				item.currentDateRange = `${moment(item.currentDateRange?.startDate).format(dateFormat)} - ${moment(item.currentDateRange?.endDate).format(dateFormat)}`
				if (item.comparingRange !== COMPARING_RANGE_TYPES.VALUE) {
					item.comparisonDateRange = `${moment(item.comparisonDateRange?.comparisonStartDate).format(dateFormat)} - ${moment(item.comparisonDateRange?.comparisonEndDate).format(dateFormat)}`
				}
				return item;
			});
			if ((metric && metric !== "") || (selectedMetric && selectedMetric !== "")) {
				setSelectedMetric(metric)
				let filter = metric || selectedMetric
				filterDataBasedOnMetric(metricData, filter)
			} else {
				setRuleData(metricData);
			}
			setRulesList(metricData)
			setFullPageLoader(false);
		} catch (error) {
			setFullPageLoader(false);
			alert(error.message || "Failed to fetch Rules list")
			console.log("rules list api - error", error);
		}
	};

	useEffect(() => {
		if (vendorState?.selected) {
			fetchRulesList()
		}
	}, [vendorState?.selected, metric]);

	const handleOnChange = (event) => {
		if (selectedFilter === FILTER_OPTIONS.CATEGORY) {
			setCategory(event.target.value)
		} else {
			setAsin(event.target.value)
		}
	}

	const deleteRule = async () => {
		try {
			const params = {
				id: ruleId
			}
			await axios.post(BASE_URL + api.deleteRule, params, tokenConfig());
			setDeleteRuleModal(false);
			fetchRulesList();
		} catch (error) {
			console.error("deleteRule error", error);
			alert("Failed to delete rule");
		}
	}

	const filterDataBasedOnMetric = (metricData, metric) => {
		console.log('filterDataBasedOnMetric metric', metric);
		console.log('filterDataBasedOnMetric metricData', metricData);
		let rules = _.filter(metricData, { metric })
		setRuleData(rules)
	}

	const restartRule = async (id, vendorCode) => {
		try {
			await axios.post(
				BASE_URL + api.restartRule,
				{ id, vendorCode },
				tokenConfig()
			);
			alert("Rule restarted successfully");
		} catch (error) {
			alert("Failed to restart rule");
			console.log(error)
		}
	}

	const exportRule = () => {
		exportFunctionRef.current.onBtnExport()
	}

	return (
		<div>
			<Grid
				container
				justifyContent="space-between"
				alignItems="center"
				className={classes.header}
			>
				<Grid item xs={4}>
					<Typography
						variant="h4"
						weight="medium"
						style={{ paddingBottom: "20px" }}
					>
						AM Rules
					</Typography>
					<Breadcrumbs aria-label="breadcrumb">
						<Link color="inherit" className={classes.link}>
							WBR
						</Link>
						<Typography className={classes.link} color="primary">
							AM Rules
						</Typography>
					</Breadcrumbs>
				</Grid>
				<Grid item xs={8}>
					<Grid container style={{ gap: 20 }} justifyContent="flex-end" alignItems="center">
						<Grid item>
							<FormControl fullWidth style={{ minWidth: 200 }} >
								<TextField
									value={selectedMetric}
									onChange={(e) => {
										let metric = e.target.value;
										setSelectedMetric(metric);
										filterDataBasedOnMetric(rulesList, metric)
									}}
									select
									label="Metric"
									variant="outlined"
								>
									{Object.entries(AM_METRICS).map(
										([key, value]) => (
											<MenuItem
												key={key}
												value={value}
											>
												{value}
											</MenuItem>
										)
									)}
								</TextField>
							</FormControl>
						</Grid>
						<Grid item>
							<FormControl style={{ minWidth: 100 }} fullWidth variant="outlined">
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									onChange={(event) => setSelectedFilter(event.target.value)}
									value={selectedFilter}
								>
									<MenuItem value={FILTER_OPTIONS.CATEGORY}>Category</MenuItem>
									<MenuItem value={FILTER_OPTIONS.ASIN}>ASIN</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						{selectedFilter !== "" && <Grid item>
							<FormControl fullWidth variant="outlined">
								<TextField
									type="text"
									value={selectedFilter === FILTER_OPTIONS.CATEGORY ? category : asin}
									onChange={(event) => {
										handleOnChange(event)
									}}
									label={selectedFilter === FILTER_OPTIONS.CATEGORY ? 'Category' : 'ASIN'}
									variant="outlined"
								/>
							</FormControl>
						</Grid>}
						<Grid item>
							<Button
								variant="contained"
								color="primary"
								size="large"
								onClick={fetchRulesList}
								disabled={selectedFilter === "" && (asin === "" || category === "")}
							>
								Submit
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								color="primary"
								size="large"
								onClick={() => {
									setModalOpen(true)
								}}
							>
								Create Rule
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Widget
						upperTitle
						noBodyPadding
						bodyClass={classes.tableOverflow}
						header={
							<div className={classes.mainChartHeader}>
								<div>
									<Button
										variant="contained"
										color="primary"
										size="small"
										startIcon={<PrintIcon />}
										onClick={() => exportRule()}
									>
										Export
									</Button>
								</div>
								<div>
									<Button
										variant="contained"
										color="primary"
										size="small"
										startIcon={<RefreshIcon />}
										onClick={() => fetchRulesList()}
									>
										Refresh
									</Button>
								</div>
							</div>
						}
					>
						<Grid container>
							<Grid item xs={12} >
								<Table
									ref={exportFunctionRef}
									tableData={ruleData}
									restartRule={restartRule}
									openEditModel={(data) => {
										setModalOpen(true);
										setRuleId(data.id);
										setEdit(true);
									}}
									deleteConfirmation={(data) => {
										setRuleId(data.id);
										setDeleteRuleModal(true);
									}}
									view={(data) => props.history.push(`/app/am/details?ruleId=${data?.id}`)}
								/>
							</Grid>
						</Grid>
					</Widget>
				</Grid>
			</Grid>
			<Backdrop className={classes.backdrop} open={fullPageLoader}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<RuleModal edit={edit} ruleId={ruleId} open={openModal} close={() => { setModalOpen(false); setEdit(false); setRuleId("") }} fetchRulesList={fetchRulesList} />
			<Confirmation
				open={deleteRuleModal}
				onCancel={() => setDeleteRuleModal(false)}
				onConfirm={deleteRule}
			/>
		</div>
	);
}
