
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  CircularProgress,
  Backdrop,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField
} from "@material-ui/core";
import "date-fns";
//icons
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
//context
import { useVendorState } from "../../context/VendorContext";

//helpers
import useStyles from "./styles";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { fetchViews } from "../../http/api";

export default function GlanceViews(props) {
  const exportFunctionRef = useRef();
  //global
  
  const classes = useStyles();
  var vendorState = useVendorState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(1, 'months')));
  const [toDate, setToDate] = useState(new Date());
  const [radioValue, setRadioValue] = useState("monthly");
  const [ASINs, setASINs] = useState("")
  const [result, setResult] = useState([])
  const [reportStartDate, setReportStartDate] = useState("")
  const [reportEndDate, setReportEndDate] = useState("")
  const [labels, setLabels] = useState({})

  useEffect(() => {
    if (vendorState?.selected) {
      fetchImpressionsReport()
    }
  }, [vendorState?.selected])

  const fetchImpressionsReport = async () => {
    try {
      setfullPageLoader(true)
      let startDate = fromDate;
      let endDate = toDate
      let params = { vendorCode: vendorState.selected, startDate, endDate, period: radioValue }
      const result = await fetchViews(params)
      if (result.data) {
        setmainTableData(result.data.data)
        setfullPageLoader(false)
        setResult(result.data.data)
        if (ASINs.length) {
          handleFilterData(ASINs, result.data.data)
        }
        setReportStartDate(result.reportStartDate)
        setReportEndDate(result.reportEndDate)
        setLabels(result.data.labels)
      }
    } catch (error) {
      setfullPageLoader(false)
      alert(error)
    }
  }

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleFilterData = (value, data) => {
    const asinsToFilter = value.split(/[,\s]+/).map(asin => asin.trim());
    let totalList = result
    if (data) { totalList = data }
    if (asinsToFilter.length && value.length) {
      const filteredList = totalList.filter((data) => asinsToFilter.includes(data.asin))
      setmainTableData(filteredList)
    } else {
      setmainTableData(totalList)
    }
  }

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Glance Views"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                className={classes.link}
              >
                Creative
              </Link>
              <Typography className={classes.link}>
                Glance Views
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <Grid container style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexWrap: "nowrap" }}>
              <Grid item style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexWrap: "nowrap" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <FormControl
                    variant="outlined"
                    className={classes.dateFormControl}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      inputVariant="outlined"
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="startDate"
                      label="From"
                      value={fromDate}
                      onChange={(date) => setFromDate(date)}
                    />
                    {reportStartDate && reportEndDate && <Typography
                      weight="medium"
                      style={{ paddingTop: "10px", fontSize: '12px', width: '300px' }}
                    >
                      Report available: {moment(reportStartDate).format("MM/DD/YYYY")} - {moment(reportEndDate).format("MM/DD/YYYY")}
                    </Typography>}
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className={classes.dateFormControl}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      inputVariant="outlined"
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="startDate"
                      label="To"
                      value={toDate}
                      onChange={(date) => setToDate(date)}
                      minDate={fromDate}
                    />
                  </FormControl>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexWrap: "nowrap" }}>
                <FormControl>
                  <RadioGroup value={radioValue} onChange={handleRadioChange}>
                    <FormControlLabel
                      value="weekly"
                      control={<Radio color="primary" />}
                      label="Weekly"
                    />
                    <FormControlLabel
                      value="monthly"
                      control={<Radio color="primary" />}
                      label="Monthly"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl >
                  <RadioGroup value={radioValue} onChange={handleRadioChange}>
                    <FormControlLabel
                      value="quarterly"
                      control={<Radio color="primary" />}
                      label="Quarterly"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <FormControl
                variant="outlined"
                className={classes.formControl}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: 53 }}
                  onClick={() => fetchImpressionsReport()}
                >
                  Apply
                </Button>
              </FormControl>
            </Grid>
          }
        />
        <Grid container spacing={4} className="js_product_grid">
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <Grid container spacing={2} style={{ justifyContent: 'space-between' }}>
                  <Grid item xs={4} >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<PrintIcon />}
                      onClick={() => exportFunctionRef.current.onBtnExport()}
                    >
                      Export
                    </Button>
                  </Grid>
                  <Grid item xs={4} >
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        type="text"
                        value={ASINs}
                        onChange={(event) => {
                          setASINs(event.target.value)
                          handleFilterData(event.target.value)
                        }}
                        label={'Search ASINs'}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} labels={labels} />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
