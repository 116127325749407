/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  CircularProgress,
  Backdrop,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  TextField,
  FormControl,
  Box,
} from "@material-ui/core";
import "date-fns";
import Widget from "../../components/Widget/Widget";
import Alert from "@material-ui/lab/Alert";


// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";

// helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";

import useStyles from "./styles";
import axios from "axios";
import { Autocomplete } from "@material-ui/lab";
import { toProperCase } from "../../healpers/utilityFunctions";
import { tokenConfig } from "../../context/UserContext";

export default function Charts(props) {
  const classes = useStyles();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [fullpageloader, setfullpageloader] = useState(false);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);
  const [open, setOpen] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const openModal = () => {
    setOpen(true);
    if(!selectedAccount?.vendorCode) {
      generateVendorCode();
    }
  };

  const closeModal = () => {
    setfullpageloader(false);
    setOpen(false);
  }

  const fetchAmsProfiles = async () => {
    try {
      const response = await axios.get(BASE_URL + api.getAmsOnboardingProfiles, tokenConfig());
      return response.data;
    } catch (err) {
      seterr("Failed to fetch profiles");
      console.log(err);
    }
  };

  const generateVendorCode = async () => {
    try {
      const response = await axios.get(BASE_URL + api.generateVendorCode,  tokenConfig({
          customerName: selectedAccount?.customerName,
          countryCode: selectedAccount?.countryCode,
          accountType: selectedAccount?.accountType,
        }),
      );
      setSelectedAccount((prev) => { return { ...prev, vendorCode: response.data.vendorCode }});
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(BASE_URL + api.listCustomers, tokenConfig());
      return response.data;
    } catch (err) {
      seterr("Failed to customers");
      console.log(err);
    }
  };

  const addProfileToAms = async () => {
    try {
      setfullpageloader(true);
      const response = await axios.post(
        BASE_URL + api.addToAms,
        selectedAccount,
        tokenConfig()
      );
      setnotification(response.data?.msg);
      setTimeout(() => setnotification(null), 3000);
      fetchData();
    } catch (err) {
      console.log(err);
    } finally {
      setfullpageloader(false);
      setOpen(false);
    }
  };

  async function fetchData() {
    setfullpageloader(true);
    let [profiles, customers] = await Promise.all([
      fetchAmsProfiles(),
      fetchCustomers(),
    ]);

    profiles = profiles?.map((profile) => {
      return {
        countryCode: profile.countryCode,
        customerName: profile.accountInfo?.name,
        displayName: profile.accountInfo?.name,
        domainName: "",
        marketplaceId: profile.accountInfo?.marketplaceStringId,
        vendorCode: "",
        accountType: profile.accountInfo?.type,
        amsProfileId: String(profile.profileId),
        entityId: profile.accountInfo.id,
      };
    });

    profiles?.sort((profile1, profile2) => {
      const name1 = profile1.customerName;
      const name2 = profile2.customerName;
      if (name1 < name2) {
        return -1;
      } else if (name1 > name2) {
        return 1;
      } else {
        return 0;
      }
    });

    setAccounts(profiles);
    setSelectedAccount(profiles?.[0]);
    setmainTableData(customers);
    setfullpageloader(false);
  }

  function handleAccountChange(selected) {
    if (!selected) {
      return;
    }
    const fromDB = mainTableData.find(
      (profile) => profile.amsProfileId === selected.amsProfileId
    );
    if (fromDB) {
      selected["vendorCode"] = fromDB["vendorCode"];
      selected["domainName"] = fromDB["domainName"];
      selected["fromDB"] = true;
    }
    setSelectedAccount(selected);
  }

  useEffect(() => {
    if(!accounts.length) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    const { countryCode, customerName, accountType, vendorCode } = selectedAccount || {};
    if (!vendorCode && countryCode && customerName && accountType) {
      generateVendorCode();
    }
  }, [selectedAccount]);

  console.log({ selectedAccount, accounts });
  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="AMS Onboarding"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Onboarding
              </Link>
              <Typography className={classes.link}>AMS Onboarding</Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              // noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={<></>}
            >
              <Box display={"flex"} justifyContent={"flex-end"}>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={openModal}
                >
                  ADD TO AMS
                </Button>
              </Box>
            </Widget>
          </Grid>
        </Grid>
        <Table
          tableData={mainTableData}
          setnotification={setnotification}
          seterr={seterr}
          setfullpageloader={setfullpageloader}
          fetchData={fetchData}
        />
        <Dialog onClose={closeModal} open={open} maxWidth="lg">
          <DialogContent>
            <form autoComplete="off">
              <Grid container spacing={2} style={{ padding: 30 }}>
                <Grid item xs={12}>
                  <Typography color="textPrimary" variant="h3">
                    Add To AMS
                  </Typography>
                  <br />
                  <Divider />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                  <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                      value={selectedAccount}
                      size="medium"
                      id="combo-box-demo"
                      options={accounts}
                      getOptionLabel={(option) => option.customerName + " - " + option.countryCode + " - " + toProperCase(option.accountType)}
                      onChange={(e, newValue) => handleAccountChange(newValue)}
                      renderInput={(params) => {
                        return accounts.length ? (
                          <TextField
                            {...params}
                            label="Select Account"
                            variant="outlined"
                            size="medium"
                            fullWidth
                          />
                        ) : (
                          <TextField
                            disabled
                            variant="outlined"
                            value="No Accounts"
                            size="medium"
                            fullWidth
                          />
                        );
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={selectedAccount?.customerName || ""}
                    label="Customer Name"
                    variant="outlined"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={selectedAccount?.marketplaceId || ""}
                    label="Marketplace Id"
                    variant="outlined"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={selectedAccount?.countryCode || ""}
                    label="Country Code"
                    variant="outlined"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={selectedAccount?.vendorCode || ""}
                    onChange={(e) =>
                      setSelectedAccount((prev) => {
                        return {
                          ...prev,
                          vendorCode: e.target.value,
                        };
                      })
                    }
                    label="Vendor Code"
                    variant="outlined"
                    fullWidth
                    disabled={selectedAccount?.fromDB}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={selectedAccount?.domainName || ""}
                    onChange={(e) =>
                      setSelectedAccount((prev) => {
                        return {
                          ...prev,
                          domainName: e?.target?.value || prev.domainName,
                        };
                      })
                    }
                    label="Domain Name"
                    variant="outlined"
                    fullWidth
                    disabled={selectedAccount?.fromDB}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={selectedAccount?.entityId || ""}
                    label="Entity Id"
                    variant="outlined"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={selectedAccount?.amsProfileId || ""}
                    label="Profile Id"
                    variant="outlined"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={selectedAccount?.accountType || ""}
                    label="Account Type"
                    variant="outlined"
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="outlined" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={addProfileToAms}
              disabled={!selectedAccount}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop className={classes.backdrop} open={fullpageloader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
      {notification ? (
        <Alert
          color="success"
          style={{
            position: "absolute",
            bottom: 30,
            left: "48%",
            zIndex: 99999,
          }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          color="error"
          style={{
            position: "absolute",
            bottom: 30,
            left: "48%",
            zIndex: 99999,
          }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
