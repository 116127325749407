import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  CircularProgress,
  Backdrop,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Radio,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";

//icons
import GetAppIcon from "@material-ui/icons/GetApp";
import SearchIcon from "@material-ui/icons/Search";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { useUserState } from "../../context/UserContext";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useVendorState } from "../../context/VendorContext";

export default function Page() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  const userState = useUserState();
  const vendorState = useVendorState();

  // local
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);
  const [contact, setContact] = useState({});
  const [pageType, setPageType] = useState("PackageLabel_Letter_2");
  const [customerEmails, setCustomerEmails] = useState(null);

  async function getContactDetails() {
    try {
      setContact({});
      setfullPageLoader(true);
      const response = await axios.get(BASE_URL + api.getShipmentContact, {
        params: { vendorCode: vendorState?.selected },
      });
      const data = response.data;
      setContact({ name: data?.name, phone: data?.phone, email: data?.email });
      setPageType(data?.pageType);
      setCustomerEmails(data.customerEmails)
    } catch (err) {
      console.log(err);
      seterr("Failed to fetch");
      setTimeout(() => seterr(null), 3000);
    } finally {
      setfullPageLoader(false);
    }
  }

  async function saveContactDetails() {
    try {
      setfullPageLoader(true);
      const data = {
        ...contact,
        pageType,
        customerEmails,
        vendorCode: vendorState?.selected,
      };
      await axios.post(BASE_URL + api.saveShipmentContact, data);
      setnotification("Saved");
      setTimeout(() => setnotification(null), 3000);
      getContactDetails();
    } catch (err) {
      console.log(err);
      seterr("Failed to save");
      setTimeout(() => seterr(null), 3000);
    } finally {
      setfullPageLoader(false);
    }
  }

  useEffect(() => {
    if (vendorState?.selected) {
      getContactDetails();
    }
  }, [vendorState]);

  function handleContactChange(event) {
    event.persist();
    setContact((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  }

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Shipment Config"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                FBA Shipment
              </Link>
              <Typography className={classes.link} color="primary">
                Shipment Config
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={<></>}
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginBottom: 20,
                    }}
                  >
                    <Typography
                      variant="h2"
                      gutterBottom
                      style={{
                        fontWeight: 600,
                        fontSize: 20,
                        color: "black",
                      }}
                    >
                      Contact Information
                    </Typography>
                  </div>
                  <Grid
                    container
                    spacing={1}
                    style={{ marginBottom: 10 }}
                    alignItems="center"
                  >
                    <Grid item>
                      <TextField
                        name="name"
                        label="Name"
                        type="text"
                        variant="outlined"
                        value={contact?.name ?? ""}
                        onChange={handleContactChange}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        name="phone"
                        label="Phone"
                        type="text"
                        variant="outlined"
                        value={contact?.phone ?? ""}
                        onChange={handleContactChange}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        name="email"
                        label="Email"
                        type="text"
                        variant="outlined"
                        value={contact?.email ?? ""}
                        onChange={handleContactChange}
                      />
                    </Grid>
                  </Grid>
                </div>
              }
            ></Widget>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginBottom: 20,
                    }}
                  >
                    <Typography
                      variant="h2"
                      gutterBottom
                      style={{
                        fontWeight: 600,
                        fontSize: 20,
                        color: "black",
                      }}
                    >
                      Label Information
                    </Typography>
                  </div>
                  <Grid
                    container
                    spacing={4}
                    style={{ marginBottom: 10 }}
                    alignItems="center"
                  >
                    <Grid item xs={6}>
                      <FormControl
                        variant="outlined"
                        // className={classes.formControl}
                        fullWidth
                        style={{ width: 300 }}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Page Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={pageType}
                          onChange={(e) => setPageType(e.target.value)}
                          label="Page Type"
                          fullWidth
                        >
                          <MenuItem value={"PackageLabel_Letter_2"}>
                            PackageLabel_Letter_2
                          </MenuItem>
                          <MenuItem value={"PackageLabel_Letter_4"}>
                            PackageLabel_Letter_4
                          </MenuItem>
                          <MenuItem value={"PackageLabel_Letter_6"}>
                            PackageLabel_Letter_6
                          </MenuItem>
                          <MenuItem value={"PackageLabel_Letter_6_CarrierLeft"}>
                            PackageLabel_Letter_6_CarrierLeft
                          </MenuItem>
                          <MenuItem value={"PackageLabel_A4_2"}>
                            PackageLabel_A4_2
                          </MenuItem>
                          <MenuItem value={"PackageLabel_A4_4"}>
                            PackageLabel_A4_4
                          </MenuItem>
                          <MenuItem value={"PackageLabel_Plain_Paper"}>
                            PackageLabel_Plain_Paper
                          </MenuItem>
                          <MenuItem
                            value={"PackageLabel_Plain_Paper_CarrierBottom"}
                          >
                            PackageLabel_Plain_Paper_CarrierBottom
                          </MenuItem>
                          <MenuItem value={"PackageLabel_Thermal"}>
                            PackageLabel_Thermal
                          </MenuItem>
                          <MenuItem value={"PackageLabel_Thermal_Unified"}>
                            PackageLabel_Thermal_Unified
                          </MenuItem>
                          <MenuItem value={"PackageLabel_Thermal_NonPCP"}>
                            PackageLabel_Thermal_NonPCP
                          </MenuItem>
                          <MenuItem
                            value={"PackageLabel_Thermal_No_Carrier_Rotation"}
                          >
                            PackageLabel_Thermal_No_Carrier_Rotation
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              }
            ></Widget>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginBottom: 20,
                    }}
                  >
                    <Typography
                      variant="h2"
                      gutterBottom
                      style={{
                        fontWeight: 600,
                        fontSize: 20,
                        color: "black",
                      }}
                    >
                      Customer Emails
                    </Typography>
                  </div>
                  <Grid
                    container
                    spacing={4}
                    style={{ marginBottom: 10 }}
                    alignItems="center"
                  >
                    <TextField
                      name="emails"
                      label="Emails"
                      type="text"
                      variant="outlined"
                      value={customerEmails ?? ""}
                      onChange={(e) => setCustomerEmails(e.target.value)}
                    />
                  </Grid>
                </div>
              }
            ></Widget>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <div className={classes.mainChartHeader}>
                  <Grid container spacing={1} style={{ marginBottom: 10 }}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        // startIcon={<SendIcon />}
                        onClick={saveContactDetails}
                        disabled={
                          !contact.name ||
                          !contact.phone ||
                          !contact.email ||
                          !customerEmails
                        }
                      >
                        Save Details
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              }
            ></Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {notification ? (
        <Alert
          severity="success"
          style={{ position: "absolute", bottom: 50, left: "48%" }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{ position: "absolute", bottom: 50, left: "48%" }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
