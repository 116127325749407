import React, { useEffect, useState } from "react";
import {
  Breadcrumbs,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  LinearProgress,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { useVendorState } from "../../context/VendorContext";
import { mergeAccount } from "../../http/api";

export default function MergeAccount() {
  const vendorState = useVendorState();
  const [aliasVendorAccount, setAliasVendorAccount] = useState({});
  const [showMergeConfirmDialog, setShowMergeConfirmDialog] = useState(false);
  const [mergeConfirmationText, setMergeConfirmationText] = useState("");
  const [mergeInProgress, setMergeInProgress] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");

  const handleMergeConfirm = async () => {
    setMergeInProgress(true);
    try {
      const merge = await mergeAccount(
        vendorState?.selected,
        aliasVendorAccount.vendorcode
      );
      setInfoMessage(merge.message);
      setTimeout(() => {
        setInfoMessage("");
        handleMergeClose();
      }, 2000);
    } catch (e) {
      console.error(e);
      setInfoMessage(`Error: ${e.message}`);
    } finally {
      setMergeInProgress(false);
    }
  };

  const handleMergeClose = async () => {
    setMergeConfirmationText("");
    setShowMergeConfirmDialog(false);
  };

  useEffect(() => {
    setAliasVendorAccount({});
    setMergeConfirmationText("");
    setMergeInProgress(false);
    setInfoMessage("");
  }, [vendorState?.selected]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Merge Account"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit">Onboarding</Link>
              <Typography color="primary">Merge Account</Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget noHeaderPadding noBodyPadding disableWidgetMenu>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  padding: "16px",
                }}
              >
                <Typography
                  style={{
                    whiteSpace: "break-spaces",
                  }}
                >
                  {`You are about to merge the account data from the dashboard. This includes permanent update of data stored related to the accounts involved in merge.\nProceed with caution.`}
                </Typography>
                <Divider />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <Typography style={{ textAlign: "end", width: "30%" }}>
                    Root vendor code:
                  </Typography>
                  <Typography style={{ width: "50%" }}>
                    {vendorState?.selected}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <Typography style={{ textAlign: "end", width: "30%" }}>
                    Select the alias account to merge:
                  </Typography>
                  <TextField
                    select
                    value={aliasVendorAccount?.vendorcode || ""}
                    variant="outlined"
                    size="small"
                    style={{ width: "50%" }}
                  >
                    {(vendorState?.list || [])
                      .filter((item) =>
                        item.vendorcode.endsWith(
                          vendorState.accounttype === "seller" ? "SC" : "VC"
                        )
                      )
                      .filter(
                        (item) => item.vendorcode !== vendorState.selected
                      )
                      .map((item) => (
                        <MenuItem
                          key={item.vendorcode}
                          value={item.vendorcode}
                          onClick={() => setAliasVendorAccount({ ...item })}
                        >
                          {item.vendorname}
                        </MenuItem>
                      ))}
                  </TextField>
                </div>
                <Divider />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowMergeConfirmDialog(true)}
                  disabled={
                    mergeInProgress ||
                    JSON.stringify(aliasVendorAccount) === "{}"
                  }
                  style={{
                    alignSelf: "end",
                  }}
                >
                  Merge
                </Button>
              </div>
            </Widget>
            <Dialog
              open={showMergeConfirmDialog}
              onClose={handleMergeClose}
              maxWidth={"md"}
              fullWidth={true}
            >
              <DialogTitle>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {"Confirm Merge"}
                  <CloseOutlined
                    onClick={handleMergeClose}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </div>
              </DialogTitle>
              <DialogContent>
                {mergeInProgress && (
                  <LinearProgress style={{ margin: "0px 32px" }} />
                )}
                {infoMessage && <Typography>{infoMessage}</Typography>}
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <Typography>
                    Please enter the root vendor code to proceed
                  </Typography>
                  <TextField
                    value={mergeConfirmationText}
                    placeholder="Vendor Code"
                    variant="outlined"
                    size="small"
                    onChange={(event) =>
                      setMergeConfirmationText(
                        (event.target.value || "").toUpperCase()
                      )
                    }
                    style={{
                      width: "40%",
                    }}
                  />
                </div>
                <Typography>{`You are about to merge the root vendor account "${vendorState?.selected}" with alias vendor account "${aliasVendorAccount.vendorcode}"`}</Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleMergeClose}
                  variant="outlined"
                  size="small"
                >
                  Cancel
                </Button>
                <Button
                  disabled={
                    mergeInProgress ||
                    mergeConfirmationText !== vendorState?.selected
                  }
                  onClick={handleMergeConfirm}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Merge
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
