import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  CircularProgress,
  Backdrop,
  Box,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import XLSX from "xlsx";
import _ from "lodash";

//icons
import PrintIcon from "@material-ui/icons/Print";
import AddIcon from "@material-ui/icons/Add";
import HouseIcon from "@material-ui/icons/House";
import CreateIcon from "@material-ui/icons/Create";
import PublishIcon from "@material-ui/icons/Publish";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { useUserState } from "../../context/UserContext";
import AddShipmentItemModal from "./components/AddShipmentItemModal/AddShipmentItemModal";
import ShipFromAddressModal from "./components/ShipFromAddressModal/ShipFromAddressModal";
import ShipmentsTable from "./components/shipmentsTable/shipmentsTable";
import { fetchASINPIR, fetchASINPIRRanges } from "../../http/api";
import { useVendorState } from "../../context/VendorContext";
import moment from "moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export default function Page() {
  const classes = useStyles();
  const userState = useUserState();
  const vendorState = useVendorState();
  const exportFunctionRef = useRef();

  const [forcastData, setForcastData] = useState();
  const [open, setOpen] = React.useState(false);
  const [shipmentItem, setShipmentItem] = useState({});
  const [addressModal, setAddressModal] = useState(false);
  const [file, setfile] = useState({ name: "No File uploaded" });
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [displayAddress, setDisplayAddress] = useState(null);
  const [labelPrepType, setLabelPrepType] = useState("SELLER_LABEL");
  const [labelType, setLabelType] = useState("SELLER_LABEL");
  const [areCasesRequired, setAreCasesRequired] = useState(false);
  const [shipToCountryCode, setShipToCountryCode] = useState("US");
  const [dateRange, setDateRange] = useState([]);
  const [selectedDate, setSelectedDate] = useState({});
  const [shipments, setShipments] = useState([]);
  const [isPerishable, setIsPerishable] = useState(false);
  const [freightDate, setFreightDate] = useState(new Date());
  const [freightClass, setFreightClass] = useState(50);

  async function getCasePackDetails() {
    try {
      setfullPageLoader(true);
      const response = await axios.get(BASE_URL + api.getCasePackDetails, {
        params: { vendorCode: vendorState.selected },
      });
      return response.data;
    } catch (err) {
      console.log(err);
      seterr("Failed to fetch case pack details");
      setTimeout(() => seterr(null), 5000);
    } finally {
      setfullPageLoader(false);
    }
  }

  async function updateTableData() {
    const casePackDetails = await getCasePackDetails();
    const updatedTableData = _.cloneDeep(forcastData);
    for (const row of updatedTableData) {
      const casePackFromDB = casePackDetails.find(
        (obj) => obj["asin"] === row["asin"]
      );
      if (casePackFromDB) {
        row["quantityInCase"] = casePackFromDB["unitsPerCase"];
        row["length"] = casePackFromDB["length"];
        row["width"] = casePackFromDB["width"];
        row["height"] = casePackFromDB["height"];
        row["weight"] = casePackFromDB["weight"];
        row["expirationDate"] = casePackFromDB["expirationDate"];
      }
    }
    setForcastData(updatedTableData);
  }

  const handleDateChange = (date) => {
    setFreightDate(date);
  };

  useEffect(() => {
    setDisplayAddress(
      addresses.find((address) => address.id == selectedAddress)
    );
  }, [selectedAddress]);

  const handleAddressModalOpen = () => {
    setAddressModal(true);
  };

  const handleAddressModalClose = () => {
    setAddressModal(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShipmentItem({ asin: "", sku: "", quantity: "" });
  };

  function addShipmentItem() {
    setForcastData((prevState) => [
      ...prevState,
      { ...shipmentItem, id: prevState.length + 1 },
    ]);
    setShipmentItem({ asin: "", sku: "", quantity: "", quantityInCase: "" });
  }

  function handleShipmentItemChange(event) {
    const name = event.target.name;
    let value = event.target.value;
    if (name === "asin") {
      value = value.toUpperCase();
    }
    setShipmentItem((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  useEffect(() => {
    fetchASINPIRRanges(useVendorState?.selected)
      .then((dates) => {
        setDateRange(dates);
        setSelectedDate(dates[dates.length - 1]);
      })
      .catch((err) => {
        console.error("Failed to fetch the ranges");
      });

    return () => {
      setDateRange([]);
      setSelectedDate("");
    };
  }, [vendorState?.selected]);

  useEffect(() => {
    async function fetchData() {
      try {
        setfullPageLoader(true);
        const granularity = "week";
        const { filteredData } = await fetchASINPIR(
          vendorState.selected,
          granularity,
          selectedDate
        );
        const data = filteredData.map((obj, index) => {
          return {
            asin: obj["asin"],
            sku: obj["sku"],
            quantity: obj["pir"],
            id: index + 1,
          };
        });
        const casePackDetails = await getCasePackDetails();
        const updatedData = data.map((obj) => {
          const casePack = casePackDetails.find(
            (row) => row["asin"] === obj["asin"]
          );
          const newRow = {
            ...obj,
            length: casePack?.length,
            width: casePack?.width,
            height: casePack?.height,
            weight: casePack?.weight,
            quantityInCase: casePack?.unitsPerCase,
            expirationDate: undefined,
          };
          if (casePack?.expirationDate != undefined) {
            if (casePack?.expirationDate === 0) {
              newRow.expirationDate = 0;
            } else {
              newRow.expirationDate = moment().add(
                casePack?.expirationDate,
                "days"
              );
            }
          }
          return newRow;
        });
        setForcastData(updatedData || []);
      } catch (error) {
        console.error(error);
      } finally {
        setfullPageLoader(false);
      }
    }

    if (vendorState?.selected && selectedDate) {
      fetchData();
    }

    return () => {
      setForcastData([]);
    };
  }, [vendorState?.selected, selectedDate]);

  const fetchAddresses = async () => {
    try {
      setAddresses([]);
      setSelectedAddress(null);
      setfullPageLoader(true);
      const response = await axios.get(BASE_URL + api.getAddressList, {
        params: { vendorCode: vendorState?.selected },
      });
      setAddresses(response.data);
      if (response.data?.length > 0) {
        const defaultAddress = response.data.find(
          (address) => address?.default == true
        );
        if (defaultAddress) {
          setSelectedAddress(defaultAddress.id);
        } else {
          setSelectedAddress(response.data?.[0]?.id);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setfullPageLoader(false);
    }
  };

  const createShipmentPlan = async () => {
    try {
      for (const row of forcastData) {
        const {
          asin,
          sku,
          quantity,
          quantityInCase,
          expirationDate,
          length,
          width,
          height,
          weight,
        } = row;
        if (
          asin == undefined ||
          sku == undefined ||
          quantity == undefined ||
          quantityInCase == undefined ||
          expirationDate == undefined ||
          length == undefined ||
          width == undefined ||
          height == undefined ||
          weight == undefined
        ) {
          seterr("GPI data is missing");
          setTimeout(() => seterr(null), 5000);
          return;
        }
      }
      setfullPageLoader(true);
      setShipments([]);
      const response = await axios.post(BASE_URL + api.createPlan, {
        vendorCode: vendorState?.selected,
        address: displayAddress,
        labelPrepType,
        tableData: forcastData,
        areCasesRequired,
        shipToCountryCode,
      });
      setnotification("Shipment plan created");
      setTimeout(() => setnotification(null), 5000);
      setShipments(response.data);
    } catch (err) {
      console.log(err);
      const msg = err.response?.data?.message || err.response?.data?.msg;
      seterr(msg || "Error occured");
      setTimeout(() => seterr(null), 5000);
      setShipments([]);
    } finally {
      setfullPageLoader(false);
    }
  };

  const getPrepInstructions = async () => {
    try {
      axios.get(BASE_URL + api, { responseType: "blob" }).then((response) => {
        const href = window.URL.createObjectURL(response.data);

        const anchorElement = document.createElement("a");

        anchorElement.href = href;
        anchorElement.download = "hello.csv";

        document.body.appendChild(anchorElement);
        anchorElement.click();

        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const uploadCSV = (e) => {
    const files = e.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = async (event) => {
        const wb = XLSX.read(event.target.result, { cellDates: true });
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = XLSX.utils.sheet_to_json(wb.Sheets[sheets[0]]);
          const entries = rows.map((row, index) => {
            return {
              id: index + 1,
              asin: row["ASIN"],
              sku: row["SKU"],
              quantity: row["Quantity"],
            };
          });

          const casePackDetails = await getCasePackDetails();
          const updatedEntries = entries.map((entry) => {
            const casePack = casePackDetails.find(
              (row) => row["asin"] === entry["asin"]
            );
            console.log(casePack);
            const newRow = {
              ...entry,
              length: casePack?.length,
              width: casePack?.width,
              height: casePack?.height,
              weight: casePack?.weight,
              quantityInCase: casePack?.unitsPerCase,
              expirationDate: undefined,
            };
            if (casePack?.expirationDate != undefined) {
              if (casePack?.expirationDate === 0) {
                newRow.expirationDate = 0;
              } else {
                newRow.expirationDate = moment().add(
                  casePack?.expirationDate,
                  "days"
                );
              }
            }
            return newRow;
          });

          setfile(file);
          setForcastData(updatedEntries);
        }
      };
      reader.readAsArrayBuffer(file);
    }
    e.target.value = null;
  };

  useEffect(() => {
    if (vendorState?.selected) {
      fetchAddresses();
    }
  }, [vendorState?.selected]);

  const DisplayAddress = ({ displayAddress }) => {
    if (!displayAddress) {
      return null;
    }
    //  style={{ marginLeft: 100, fontSize: 18, fontWeight: "bolder" }}
    return (
      <>
        <span style={{ marginLeft: 30 }}>
          {`${displayAddress.name} ${displayAddress.addressLine1} ${displayAddress.city}, ${displayAddress.stateOrProvinceCode} ${displayAddress.postalCode} ${displayAddress.countryCode}`}
        </span>
      </>
    );
  };

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Create New Shipment"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                FBA Shipment
              </Link>
              <Typography className={classes.link} color="primary">
                Create New Shipment
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={<></>}
        />
      </Container>
      <AddShipmentItemModal
        open={open}
        handleClose={handleClose}
        shipmentItem={shipmentItem}
        addShipmentItem={addShipmentItem}
        handleShipmentItemChange={handleShipmentItemChange}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            // upperTitle
            // noBodyPadding
            bodyClass={classes.tableOverflow}
            disableWidgetMenu
            header={<div className={classes.mainChartHeader}></div>}
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<HouseIcon />}
              onClick={handleAddressModalOpen}
            >
              SELECT SHIPMENT ADDRESS
            </Button>
            <DisplayAddress displayAddress={displayAddress} />
            <Box marginTop={4}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="labelPreference">
                  Label Prep Preference
                </InputLabel>
                <Select
                  labelId="labelPreference"
                  id="labelPreference"
                  value={labelPrepType}
                  onChange={(e) => setLabelPrepType(e.target.value)}
                  label="Label Prep Preference"
                >
                  <MenuItem value={"SELLER_LABEL"}>SELLER_LABEL</MenuItem>
                  <MenuItem value={"AMAZON_LABEL_ONLY"}>
                    AMAZON_LABEL_ONLY
                  </MenuItem>
                  <MenuItem value={"AMAZON_LABEL_PREFERRED"}>
                    AMAZON_LABEL_PREFERRED
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="areCasesRequired">
                  Are Cases Required
                </InputLabel>
                <Select
                  labelId="areCasesRequired"
                  id="areCasesRequired"
                  value={areCasesRequired}
                  onChange={(e) => setAreCasesRequired(e.target.value)}
                  label="Are Cases Required"
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="areCasesRequired">
                  Ship To Country Code
                </InputLabel>
                <Select
                  labelId="shipToCountryCode"
                  id="shipToCountryCode"
                  value={shipToCountryCode}
                  onChange={(e) => setShipToCountryCode(e.target.value)}
                  label="Ship To Country Code"
                >
                  <MenuItem value={"US"}>US</MenuItem>
                  <MenuItem value={"CA"}>CA</MenuItem>
                  <MenuItem value={"MX"}>MX</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="labelPreference">
                  Label Type
                </InputLabel>
                <Select
                  labelId="labelType"
                  id="labelType"
                  value={labelType}
                  onChange={(e) => setLabelType(e.target.value)}
                  label="Label Type"
                >
                  <MenuItem value={"SELLER_LABEL"}>SELLER_LABEL</MenuItem>
                  <MenuItem value={"UNIQUE"}>
                    UNIQUE
                  </MenuItem>
                  <MenuItem value={"BARCODE_2D"}>
                    BARCODE_2D
                  </MenuItem>
                  <MenuItem value={"PALLET"}>
                    PALLET
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="areCasesRequired">Perishable</InputLabel>
                <Select
                  labelId="isPerishable"
                  id="isPerishable"
                  value={isPerishable}
                  onChange={(e) => setIsPerishable(e.target.value)}
                  label="Perishable"
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    name="freightReadyDate"
                    variant="inline"
                    format="yyyy-MM-dd"
                    // margin="normal"
                    id="date-picker-inline"
                    label="Freight Ready Date"
                    value={freightDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    style={{ marginLeft: 10 }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              {/* <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Freight Class
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={freightClass}
                  onChange={(e) => setFreightClass(e.target.value)}
                  label="FreightClass"
                  name="freightClass"
                >
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={55}>55</MenuItem>
                  <MenuItem value={60}>60</MenuItem>
                  <MenuItem value={65}>65</MenuItem>
                  <MenuItem value={70}>70</MenuItem>
                  <MenuItem value={77.5}>77.5</MenuItem>
                  <MenuItem value={85}>85</MenuItem>
                  <MenuItem value={92.5}>92.5</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={110}>110</MenuItem>
                  <MenuItem value={125}>125</MenuItem>
                  <MenuItem value={150}>150</MenuItem>
                  <MenuItem value={175}>175</MenuItem>
                  <MenuItem value={200}>200</MenuItem>
                  <MenuItem value={250}>250</MenuItem>
                  <MenuItem value={300}>300</MenuItem>
                  <MenuItem value={400}>400</MenuItem>
                  <MenuItem value={500}>500</MenuItem>
                </Select>
              </FormControl> */}
            </Box>
          </Widget>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            disableWidgetMenu
            header={
              <div
                // className={classes.mainChartHeader}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <FormControl variant="outlined">
                  <label htmlFor="upload-csv">
                    <input
                      style={{ display: "none" }}
                      id="upload-csv"
                      name="upload-csv"
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(e) => uploadCSV(e)}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      component="span"
                      size="small"
                      startIcon={<PublishIcon />}
                      style={{ position: "relative", top: "18px" }}
                    >
                      Upload here
                    </Button>
                  </label>
                  <Typography
                    // className={classes.link}
                    color="primary"
                    style={{ fontSize: 12, marginTop: "18px" }}
                  >
                    {file?.name}
                  </Typography>
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={handleOpen}
                  style={{ marginLeft: 20 }}
                >
                  ADD ITEM
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<PrintIcon />}
                  onClick={() => exportFunctionRef.current.onBtnExport()}
                  style={{ marginLeft: 20 }}
                >
                  EXPORT
                </Button>
              </div>
            }
          >
            <Table
              ref={exportFunctionRef}
              tableData={forcastData}
              setForcastData={setForcastData}
              forcastData={forcastData}
              fullPageLoader={fullPageLoader}
              setnotification={setnotification}
              seterr={seterr}
            />
          </Widget>
        </Grid>
      </Grid>
      <ShipFromAddressModal
        open={addressModal}
        handleAddressModalClose={handleAddressModalClose}
        addresses={addresses}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        setfullPageLoader={setfullPageLoader}
        setnotification={setnotification}
        seterr={seterr}
        fetchAddresses={fetchAddresses}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            // upperTitle
            // noBodyPadding
            bodyClass={classes.tableOverflow}
            disableWidgetMenu
            header={<div className={classes.mainChartHeader}></div>}
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<CreateIcon />}
              onClick={createShipmentPlan}
              disabled={!selectedAddress || forcastData?.length === 0}
              style={{ marginBottom: 50 }}
            >
              CREATE SHIPMENT PLAN
            </Button>
            <ShipmentsTable
              tableData={shipments}
              setfullPageLoader={setfullPageLoader}
              setnotification={setnotification}
              seterr={seterr}
              labelPrepType={labelPrepType}
              displayAddress={displayAddress}
              areCasesRequired={areCasesRequired}
              forcastData={forcastData}
              shipToCountryCode={shipToCountryCode}
              isPerishable={isPerishable}
              freightDate={freightDate}
              // freightClass={freightClass}
              labelType={labelType}
            />
          </Widget>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {notification ? (
        <Alert
          severity="success"
          style={{
            position: "absolute",
            bottom: -550,
            left: "48%",
            zIndex: 99999,
          }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{
            position: "absolute",
            bottom: -550,
            left: "48%",
            zIndex: 99999,
          }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
