import { Bar } from "react-chartjs-2";

export default function BarChart(props) {
  return (
    <div style={{ padding: '10px' }}>
    <Bar
      id={props.id}
      options={{
        indexAxis: "y",
        scales: {
          x: {
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 45
            },
          },
          y: {
            beginAtZero: true,
          },
        },
      }}
      data={{
        labels: props.labels,
        datasets: [
          {
            label: props.label,
            data: props.data,
            backgroundColor: "rgba(66, 135, 245)",
            barThickness: 25,
          },
        ],
      }}
    />
    </div>
  );
}
