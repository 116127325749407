import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Button } from "@material-ui/core";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import '../styles'
import { useVendorState } from "../../../context/VendorContext";
import { getCurrencyIndicator } from "../../../healpers/utilityFunctions";

export default forwardRef(function Tables(props, ref) {
  const vendorState = useVendorState();
  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);
  const [currencyIndicator, setCurrencyIndicator] = useState("");

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else
        gridApi.exportDataAsCsv();
    }
  }));

  const rowStyle = { background: 'white', width: '100%' };

  const EditButtonRenderer = (buttonProps) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        style={{ textTransform: "capitalize" }}
        onClick={() => { props.openEditModel(buttonProps?.data) }}
      >
        Edit
      </Button>
    );
  };

  const DeleteButtonRenderer = (buttonProps) => {
    return (
      <Button
        variant="contained"
        color="secondary"
        size="medium"
        style={{ textTransform: "capitalize" }}
        onClick={() => props.deleteConfirmation(buttonProps?.data)}
      >
        Delete
      </Button>
    );
  };

  const roundValue = (params) => {
    if (params.value > 0) {
      return `${currencyIndicator}${params.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    }
  }

  useEffect(() => {
    if (vendorState?.selected) {
      setCurrencyIndicator(getCurrencyIndicator(vendorState?.selected?.split('_')?.[1]) || "$");
    }
  }, [vendorState?.selected]);

  return (
    <div className="ag-theme-material" style={{ height: 620, width: '100%' }} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={50}
        rowStyle={rowStyle}
        frameworkComponents={{
          editButtonRenderer: EditButtonRenderer,
          deleteButtonRenderer: DeleteButtonRenderer,
        }}
        fullWidth
        suppressDragLeaveHidesColumns={true}
        suppressRowClickSelection={true}

      >
        <AgGridColumn headerName="Sub Category" field="subCategory" width={300} />
        <AgGridColumn headerName="Category" field="category" width={300} />
        <AgGridColumn headerName="YTD" field="ytd" valueFormatter={(params) => roundValue(params)} width={300} />
        <AgGridColumn headerName="Previous YTD" field="previousYtd" valueFormatter={(params) => roundValue(params)} width={300} />
        <AgGridColumn headerName="Forecast" field="forecast" valueFormatter={(params) => roundValue(params)} width={500} />
      </AgGridReact>
    </div>
  );
})
