import React, { useState, useRef, useEffect } from "react";
import { Grid, Button, Typography, Breadcrumbs, Link, CircularProgress, Backdrop } from "@material-ui/core";
import useStyles from "./styles.js";
import Table from "./components/table.js";
import { BASE_URL } from "../../healpers/api.js";
import axios from "axios";
import api from "../../healpers/apiRoutes.js";
import { useVendorState } from "../../context/VendorContext.js";

export default function AuditTool(props) {
    const vendorState = useVendorState();
    const classes = useStyles();
    const exportFunctionRef = useRef();
    const [tableData, setTableData] = useState([]);
    const [fullPageLoader, setFullPageLoader] = useState(false)

    const fetchAuditDetails = async () => {
        try {
            setFullPageLoader(true)
            const response = await axios(BASE_URL + api.fetchAuditDetails);
            setTableData(response.data.data);
            setFullPageLoader(false)
        } catch (error) {
            setFullPageLoader(false)
            alert(error.message || 'Failed to Action Items Data')
            console.log("error in fetching action items", error)
        }
    }

    useEffect(() => {
        if (vendorState?.selected) {
            fetchAuditDetails();
        }
    }, [vendorState?.selected]);


    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center" className={classes.header}>
                <Grid>
                    <Typography variant="h4" weight="medium" style={{ paddingBottom: "20px" }}>
                        AMS PPC Audit Leads
                    </Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" className={classes.link}>
                            AMS
                        </Link>
                        <Typography className={classes.link} color="primary">
                            AMS PPC Audit Leads
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid className={classes.buttonContainer} >
                </Grid>
            </Grid>
            <Table
                ref={exportFunctionRef}
                tableData={tableData}
            ></Table>
            <Backdrop className={classes.backdrop} open={fullPageLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
