import React, { useEffect, useState } from "react";
import { Container, Grid, Breadcrumbs, Typography } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
import { fetchDbArchives } from "../../http/api";

export default function DbArchives() {
  const [dbArchives, setDbArchives] = useState([]);

  const loadDbArchives = async () => {
    try {
      const { status, data } = await fetchDbArchives();
      if (status === "Success") {
        setDbArchives(data);
      } else {
        setDbArchives([]);
      }
    } catch (err) {
      console.log(err);
      setDbArchives([]);
    }
  };

  useEffect(() => {
    loadDbArchives();
  }, []);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Database Archives"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="primary">Database Archives</Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12} style={{ backgroundColor: "white" }}>
            <Table rows={dbArchives} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
