import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import moment from "moment";
import xlsx from "xlsx";

//helpers
import { BASE_URL } from "../../../../healpers/api";
import api from "../../../../healpers/apiRoutes";

//context
import { useUserState } from "../../../../context/UserContext";
import { useVendorState } from "../../../../context/VendorContext";
import { Typography } from "@material-ui/core";

export default function DraggableDialog({
  errorMessages,
  cartonIdList,
  open,
  closeDialog,
  excelData,
  shipmentId,
}) {
  function downloadCartonContents() {
    const workbook = xlsx.utils.book_new();
    const sheet = xlsx.utils.aoa_to_sheet(excelData);
    xlsx.utils.book_append_sheet(workbook, sheet);
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    var excelFileDownloadEle = document.createElement("a");
    excelFileDownloadEle.download = shipmentId || "Carton Ids" + ".xlsx";
    excelFileDownloadEle.href = window.URL.createObjectURL(excelBlob);
    excelFileDownloadEle.click();
  }

  return (
    <div>
      <Dialog open={open} onClose={closeDialog} fullWidth={true}>
        <Paper style={{ padding: 10 }}>
          <DialogTitle>Download Carton IDs</DialogTitle>

          <DialogContent>
            {errorMessages?.length > 0 && (
              <Typography variant="h6">Errors</Typography>
            )}
            {errorMessages?.map((msg) => {
              return (
                <DialogContentText>
                  <li>{msg}</li>
                </DialogContentText>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={closeDialog} color="primary">
              Close
            </Button>
            <Button
              autoFocus
              variant="contained"
              color="primary"
              // disabled={errorMessages.length > 0}
              onClick={downloadCartonContents}
            >
              Download
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
}
