export const CREATION_STEPS = [
  "Enter ASIN",
  "Select ASINs",
  "Product Details",
  "Prompt",
  "Preview",
];

export const PREPOSITION_LIST = [
  "about",
  "above",
  "across",
  "after",
  "against",
  "along",
  "amid",
  "among",
  "around",
  "as",
  "at",
  "before",
  "behind",
  "below",
  "beneath",
  "beside",
  "between",
  "beyond",
  "but",
  "by",
  "concerning",
  "considering",
  "despite",
  "down",
  "during",
  "except",
  "for",
  "from",
  "in",
  "inside",
  "into",
  "like",
  "near",
  "of",
  "off",
  "on",
  "onto",
  "out",
  "over",
  "past",
  "regarding",
  "round",
  "since",
  "through",
  "throughout",
  "to",
  "toward",
  "under",
  "until",
  "unto",
  "up",
  "upon",
  "with",
  "within",
  "without",
];

export const PRONOUN_LIST = [
  "all",
  "another",
  "any",
  "anybody",
  "anyone",
  "anything",
  "both",
  "each",
  "either",
  "everybody",
  "everyone",
  "everything",
  "few",
  "i",
  "it",
  "itself",
  "many",
  "more",
  "most",
  "nobody",
  "none",
  "nothing",
  "one",
  "others",
  "she",
  "some",
  "somebody",
  "someone",
  "something",
  "such",
  "that",
  "their",
  "theirs",
  "them",
  "themselves",
  "these",
  "they",
  "this",
  "those",
  "whatever",
  "which",
  "you",
  "your",
  "yourself",
];

export const FILLER_TERMS_LIST = [...PREPOSITION_LIST, ...PRONOUN_LIST];

export const REVIEWS_RATING_OPTIONS = [
  { label: "All", value: "ALL" },
  { label: "5 Stars", value: "5_STARS" },
  { label: "4 Stars", value: "4_STARS" },
  { label: "3 Stars", value: "3_STARS" },
  { label: "2 Stars", value: "2_STARS" },
  { label: "1 Star", value: "1_STARS" },
  { label: "Positive", value: "POSITIVE" },
  { label: "Critical", value: "CRITICAL" },
];

export const REVIEWS_SORT_OPTIONS = [
  { label: "Top reviews", value: "TOP_REVIEWS" },
  { label: "Most recent", value: "MOST_RECENT" },
];

export const REVIEWS_COUNT_OPTIONS = [
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "25", value: 25 },
  { label: "50", value: 50 },
];

export const CHATGPT_PREFIX =
  "Write product title, description, bullet points, tag lines and recommendations for listing a product on Amazon.";

export const CHATGPT_SUFFIX =
  "\nFinal response should be in JSON following the below criteria:" +
  "\n- Separate similar contents for each product variations with below JSON schema:" +
  "\n- { result: [{ asin: string, title: string, description: string, bulletPoints: Array of bullet point strings, tagLines: Array of tag line strings, recommendations: Array of suggestion strings }] }" +
  "\n- Strictly the response should match the provided JSON schema.";

export const CHATGPT_TITLE =
  "Write a title for the product which will be keyword rich and you need to follow the rules of grammar and I want you to include as many keywords as possible." +
  "\nStrictly follow this template: Brand name, keywords, keywords, keywords, keywords, keywords, variation details." +
  "\nStrictly the title should be 199 characters or a little less.";

export const CHATGPT_DESCRIPTION =
  "Write an engaging and informative marketing description in 200 words in a conversational way. Use a persuasive copywriting technique focusing on unique features, benefits, and ease of use." +
  "\nIdentify the target audience for the product and explain why it's the perfect solution for their needs." +
  "\nConclude with a compelling call to action, urging potential customers to invest in the product for an enhanced experience.";

export const CHATGPT_BULLET_POINTS =
  "Write 5 very informative and marketing bullet points having more than 2500 characters. Use the below template and add a catchy customer-focused title in all caps followed by a fully detailed marketing description in a persuasive way." +
  "\nTemplate: [ Primary Feature, Secondary Feature, Other Features, Enhancing Experience, Usage Benefits ]" +
  "\nStrictly follow this structure for all 5 points: BENEFIT FOR THE CUSTOMER IN ALL CAPS - Detailed marketing description of the feature in a persuasive way." +
  "\nStrictly each bullet point should have at least 500 characters and should have a minimum of 25 words in each bullet point.";

export const CHATGPT_TAG_LINES =
  "Based on top benefits of the product write 10 catchy tag lines with each of them should be between 5 to 10 words for using in big banner advertisements.";

export const CHATGPT_RECOMMENDATIONS =
  "Based on the top 5 benefits customers get from this product and the top 5 pain points customers have with this product, write 5 suggestions on how a company making this product can improve this product. This can include any new features, or improving any specification of the product, etc.";

export const KEYWORDS_COUNT = 30;

export const BLACKLIST_KEYWORDS = ["amazon"];

export const DEFAULT_LIVE_CONTENT = {
  asin: "",
  title: "",
  description: "",
  bulletPoints: [],
};

export const ROLES = {
  CREATOR: "CREATOR",
  CUSTOMER: "CUSTOMER",
  REVIEWER: "REVIEWER",
  SALESDUO: "SALESDUO",
};

export const STATUS = {
  CONTENT_GENERATED: "CONTENT_GENERATED",
  PENDING_APPROVAL: "PENDING_APPROVAL",
  CUSTOMER_FEEDBACK: "CUSTOMER_FEEDBACK",
  FEEDBACK_ADDRESSED: "FEEDBACK_ADDRESSED",
  PENDING_PUBLISH: "PENDING_PUBLISH",
  LIVE: "LIVE",
};

export const CHANGELOG_ACTION = {
  CREATE: "CREATE",
  CUSTOMER_APPROVAL_REQUEST: "CUSTOMER_APPROVAL_REQUEST",
  CUSTOMER_APPROVE: "CUSTOMER_APPROVE",
  CUSTOMER_APPROVE_FEEDBACK: "CUSTOMER_APPROVE_FEEDBACK",
  CUSTOMER_FEEDBACK: "CUSTOMER_FEEDBACK",
  CUSTOMER_REJECT: "CUSTOMER_REJECT",
  CUSTOMER_UPDATE: "CUSTOMER_UPDATE",
  CUSTOMER_UPDATE_FEEDBACK: "CUSTOMER_UPDATE_FEEDBACK",
  DELETE: "DELETE",
  EXPORT: "EXPORT",
  RECREATE: "RECREATE",
  REVIEWER_APPROVE: "REVIEWER_APPROVE",
  REVIEWER_APPROVE_FEEDBACK: "REVIEWER_APPROVE_FEEDBACK",
  REVIEWER_FEEDBACK: "REVIEWER_FEEDBACK",
  REVIEWER_REJECT: "REVIEWER_REJECT",
  REVIEWER_UPDATE: "REVIEWER_UPDATE",
  REVIEWER_UPDATE_FEEDBACK: "REVIEWER_UPDATE_FEEDBACK",
  UPDATE: "UPDATE",
};

export const CHANGELOG_ACTION_MESSAGE = {
  CREATE: "Created",
  CUSTOMER_APPROVAL_REQUEST: "Customer Approval Requested",
  CUSTOMER_APPROVE: "Customer Approved",
  CUSTOMER_APPROVE_FEEDBACK: "Customer Approval Feedback",
  CUSTOMER_FEEDBACK: "Customer Feedback",
  CUSTOMER_REJECT: "Customer Rejected",
  CUSTOMER_UPDATE: "Customer Updated",
  CUSTOMER_UPDATE_FEEDBACK: "Customer Update Feedback",
  DELETE: "Deleted",
  EXPORT: "Exported",
  RECREATE: "Recreated",
  REVIEWER_APPROVE: "Reviewer Approved",
  REVIEWER_APPROVE_FEEDBACK: "Reviewer Approval Feedback",
  REVIEWER_FEEDBACK: "Reviewer Feedback",
  REVIEWER_REJECT: "Reviewer Rejected",
  REVIEWER_UPDATE: "Reviewer Updated",
  REVIEWER_UPDATE_FEEDBACK: "Reviewer Update Feedback",
  UPDATE: "Updated",
};

export const ATTRIBUTES = {
  TITLE: "TITLE",
  DESCRIPTION: "DESCRIPTION",
  BULLET_POINTS: "BULLET_POINTS"
};

export const RAPID_API_COUNTRY = [
  { label: "US", value: "US" },
  { label: "UK", value: "UK" },
  { label: "GERMANY", value: "DE" },
  { label: "CANADA", value: "CA" },
  { label: "SWEDEN", value: "SE" },
  { label: "AUSTRALIA", value: "AU" },
  { label: "MEXICO", value: "MX" },
  { label: "FRANCE", value: "FR" },
  { label: "SPAIN", value: "ES" },
  { label: "NETHERLANDS", value: "NL" },
  { label: "SINGAPORE", value: "SG" },
  { label: "SAUDI_ARABIA", value: "SA" },
  { label: "UAE", value: "AE" },
  { label: "BELGIUM", value: "BE" },
  { label: "ITALY", value: "IT" },
  { label: "POLAND", value: "PL" },
  { label: "BRAZIL", value: "BR" },
  { label: "SOUTH_AFRICA", value: "ZA" },
  { label: "TURKEY", value: "TR" },
  { label: "EGYPT", value: "EG" },
  { label: "INDIA", value: "IN" },
];