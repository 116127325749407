import React, { useState, useEffect } from "react";
import {
	Grid,
	Typography,
	Backdrop,
	CircularProgress,
} from "@material-ui/core";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import useStyles from "../styles";
import _ from "lodash";
import { numberWithCommas } from "../../../healpers/utilityFunctions";
import { TARGET_TYPES } from "../../../constant/amRules";

export default function DetailModal({ open, close, ruleData, asin }) {
	const [fullPageLoader, setFullPageLoader] = useState(false)
	const classes = useStyles();
	const [ASIN, setASINData] = useState({})

	const onModalClose = () => { };
	useEffect(() => {
		if (!_.isEmpty(ruleData)) {
			getASINData()
		}
	}, [asin, ruleData])

	const getASINData = () => {
		let asinData = ruleData.status.find((item) => item.asin === asin)
		setASINData(asinData)
	}

	const isNotValueTarget = ruleData?.targetType !== TARGET_TYPES.VALUE;

	return (
		<div>
			<Modal
				open={open}
				onClose={() => {
					close();
					onModalClose();
				}}
				styles={{ modal: { borderRadius: 10 }, modalContainer: { padding: 10, paddingTop: 30 } }}
				center
				closeOnOverlayClick
			>
				<Backdrop />
				<Grid container direction="column">
					<Typography style={{ fontSize: 16, paddingBottom: 5 }}>
						<span style={{ fontWeight: "bold" }}>ASIN:</span> {asin}
					</Typography>
					<Typography style={{ fontSize: 16, paddingBottom: 5 }}>
						<span style={{ fontWeight: "bold" }}>Metric:</span> {ruleData?.metric}
					</Typography>
					<Grid item>
						<Typography style={{ fontSize: 16 }} color="text.secondary" gutterBottom>
							<span style={{ fontWeight: "bold" }}>Current Date Range:</span> {ruleData?.currentDateRange}
						</Typography>
					</Grid>
					{
						isNotValueTarget && (
							<Grid item>
								<Typography style={{ fontSize: 16 }} color="text.secondary" gutterBottom>
									<span style={{ fontWeight: "bold" }}>Comparing Date Range:</span> {ruleData?.comparisonDateRange}
								</Typography>
							</Grid>
						)
					}
					<Grid item>
						<Typography style={{ fontSize: 16 }} color="text.secondary" gutterBottom>
							<span style={{ fontWeight: "bold" }}>Sales for Current Range:</span> {numberWithCommas(ASIN?.actualSales)}
						</Typography>
					</Grid>
					<Grid item>
						<Typography style={{ fontSize: 16 }} color="text.secondary" gutterBottom>
							<span style={{ fontWeight: "bold" }}>Forecast for Current Range(A):</span> {numberWithCommas(ASIN?.forecast)}
						</Typography>
					</Grid>
					{
						isNotValueTarget && (
							<Grid item>
								<Typography style={{ fontSize: 16 }} color="text.secondary" gutterBottom>
									<span style={{ fontWeight: "bold" }}>Sales for Comparison Range(B):</span> {numberWithCommas(ASIN?.previousSales)}
								</Typography>
							</Grid>
						)
					}
					{
						ruleData?.targetType === TARGET_TYPES.FORECAST && (
							<Grid item>
								<Typography style={{ fontSize: 16 }} color="text.secondary" gutterBottom>
									<span style={{ fontWeight: "bold" }}>Forecast Sales Growth Needed(C=(A-B)/B*100):</span> {numberWithCommas(ASIN?.asinForecastTarget, 2)}
								</Typography>
							</Grid>
						)
					}
					<Grid item>
						<Typography style={{ fontSize: 16 }} color="text.secondary" gutterBottom>
							<span style={{ fontWeight: "bold" }}>Rule Target(D):</span> {ruleData?.target}
						</Typography>
					</Grid>
					{
						ruleData?.targetType === TARGET_TYPES.FORECAST && (
							<>
								<Grid item>
									<Typography style={{ fontSize: 16 }} color="text.secondary" gutterBottom>
										<span style={{ fontWeight: "bold" }}>Metric Growth Target(E=C*(D/100)):</span> {numberWithCommas(ASIN?.asinTargetPercentage, 2)}
									</Typography>
								</Grid>
							</>
						)
					}
					<Grid item>
						<Typography style={{ fontSize: 16 }} color="text.secondary" gutterBottom>
							<span style={{ fontWeight: "bold" }}>Comparison Value(F):</span> {numberWithCommas(ASIN?.comparisonValue, 2)}
						</Typography>
					</Grid>
					<Grid item>
						<Typography style={{ fontSize: 16 }} color="text.secondary" gutterBottom>
							<span style={{ fontWeight: "bold" }}>Current Value:</span> {numberWithCommas(ASIN?.currentValue, 2)}
						</Typography>
					</Grid>
					<Grid item>
						<Typography style={{ fontSize: 16 }} color="text.secondary" gutterBottom>
							<span style={{ fontWeight: "bold" }}>
								Metric Target
								{
									ruleData?.targetType === TARGET_TYPES.FORECAST ? "((E/100)*F)" : ruleData?.targetType === TARGET_TYPES.PERCENTAGE ? "((D/100)*F)" : ""
								}
								:
							</span> {numberWithCommas(ASIN?.target, 2)}
						</Typography>
					</Grid>
					<Typography style={{ fontSize: 16, paddingBottom: 5 }}>
						<span style={{ fontWeight: "bold" }}>Status:</span> {ASIN?.status}
					</Typography>
				</Grid>
				<Grid container style={{ paddingTop: "10px", justifyContent: 'space-between', alignItems: 'center' }}>
					<Grid item style={{ width: 150 }}>
						<Typography style={{ fontSize: 16, fontWeight: 600 }} color="text.secondary" gutterBottom>
							Key
						</Typography>
					</Grid>
					<Grid item style={{ width: 150 }}>
						<Typography style={{ fontSize: 16, fontWeight: 600 }} color="text.secondary" gutterBottom>
							Current Value
						</Typography>
					</Grid>
					{
						isNotValueTarget && (
							<Grid item style={{ width: 150 }}>
								<Typography style={{ fontSize: 16, fontWeight: 600 }} color="text.secondary" gutterBottom>
									Comparison Value
								</Typography>
							</Grid>
						)
					}
					{ASIN?.metrics && ASIN.metrics.map((item) => (
						<Grid container style={{ justifyContent: 'space-between', alignItems: 'center' }}>
							<Grid item style={{ width: 150 }}>
								<Typography style={{ fontSize: 14, textAlign: 'left' }} color="text.secondary" gutterBottom>
									{item.key}
								</Typography>
							</Grid>
							<Grid item style={{ width: 150, textAlign: 'left' }}>
								<Typography style={{ fontSize: 14 }} color="text.secondary" gutterBottom>
									{numberWithCommas(item.currentValue, 2)}
								</Typography>
							</Grid>
							{
								isNotValueTarget && (
									<Grid item style={{ width: 150, textAlign: 'left' }}>
										<Typography style={{ fontSize: 14 }} color="text.secondary" gutterBottom>
											{numberWithCommas(item.comparisonValue, 2)}
										</Typography>
									</Grid>
								)
							}
						</Grid>
					))}
				</Grid>
				<Backdrop className={classes.backdrop} open={fullPageLoader}>
					<CircularProgress color="inherit" />
				</Backdrop>
			</Modal>
		</div>
	);
}
