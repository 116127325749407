import { Bar, Line } from "react-chartjs-2";
import { formatChartLabels, formatChartValues } from "../../../healpers/utilityFunctions";

export default function BarChart(props) {
  return (
    <div style={{ padding: '10px' }}>
      <Line
        id={props.id}
        options={{
          legend: { display: false },
          indexAxis: "y",
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                return formatChartValues(tooltipItem, data, props.percentage)
              }
            }
          },
          scales: {
            x: {
              ticks: {
                autoSkip: false,
                maxRotation: 90,
                minRotation: 45
              },
            },
            yAxes: [{
              ticks: {
                beginAtZero: true,
                callback: function(value, index, values) {
                  return formatChartLabels(value)
                }
              },
              scaleLabel: {
                display: true,
                labelString: props.yAxisLabel ? props.yAxisLabel : '',
              }
            }]
          },
        }}
        data={{
          labels: props.labels,
          datasets: [
            {
              label: 'Current Year (Actuals)',
              data: props.data,
              borderColor: "rgba(66, 135, 245)",
              barThickness: 25,
              backgroundColor:'transparent'
            },
            {
              label: 'Current Year (Actuals)',
              data: props.currentWeekData,
              borderColor: "rgba(66, 135, 245)",
              barThickness: 25,
              backgroundColor:'transparent'
            },
            {
              label: 'Previous Year',
              data: props?.previousYearData,
              backgroundColor: "transparent",
              borderColor: "#ff9200",
              barThickness: 25,
            },
            {
              label: 'Previous Year',
              data: props?.previousWeekData,
              backgroundColor: "transparent",
              borderColor: "#ff9200",
              barThickness: 25,
            },
          ],
        }}
      />
    </div>
  );
}
