
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  CircularProgress,
  Backdrop,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import "date-fns";
//icons
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
//context
import { useVendorState } from "../../context/VendorContext";

//helpers
import useStyles from "./styles";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { fetchSalesFunnelReports } from "../../http/api";
import { TIME_PERIOD } from "../../constant/spReports";

export default function SalesFunnel(props) {
  const exportFunctionRef = useRef();
  //global
  
  const classes = useStyles();
  var vendorState = useVendorState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [fromDate, setFromDate] = useState((moment().subtract(1, 'month').startOf('month').startOf('day').format('MM/DD/YYYY')));
  const [toDate, setToDate] = useState(moment().endOf('month').endOf('day').format('MM/DD/YYYY'));
  const [radioValue, setRadioValue] = useState(TIME_PERIOD.MONTHLY);
  const [reportStartDate, setReportStartDate] = useState("")
  const [reportEndDate, setReportEndDate] = useState("")
  const [labels, setLabels] = useState({})

  useEffect(() => {
    if (vendorState?.selected) {
      fetchSalesFunnelData()
    }
  }, [vendorState?.selected])

  const fetchSalesFunnelData = async () => {
    try {
      setfullPageLoader(true)
      let startDate = fromDate;
      let endDate = toDate
      let dateFormat = 'MM/DD/YYYY'
      let params = { vendorCode: vendorState.selected, startDate: moment(startDate).format(dateFormat), endDate: moment(endDate).format(dateFormat), period: radioValue }
      const result = await fetchSalesFunnelReports(params)
      if (result.data) {
        setmainTableData(result.data.reverse())
        setfullPageLoader(false)
        setReportStartDate(result.reportStartDate)
        setReportEndDate(result.reportEndDate)
        setLabels(result.data.labels)
      }
    } catch (error) {
      setfullPageLoader(false)
      alert(error)
    }
  }

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Key Metrics"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                className={classes.link}
              >
                Sales
              </Link>
              <Typography className={classes.link}>
                Key Metrics
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <Grid container style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexWrap: "nowrap" }}>
              <Grid item style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexWrap: "nowrap" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <FormControl
                    variant="outlined"
                    className={classes.dateFormControl}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      inputVariant="outlined"
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="startDate"
                      label="From"
                      value={fromDate}
                      onChange={(date) => setFromDate(date)}
                    />
                    {reportStartDate && reportEndDate && <Typography
                      weight="medium"
                      style={{ paddingTop: "10px", fontSize: '12px', width: '300px' }}
                    >
                      Report available: {moment(reportStartDate).format("MM/DD/YYYY")} - {moment(reportEndDate).format("MM/DD/YYYY")}
                    </Typography>}
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className={classes.dateFormControl}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      inputVariant="outlined"
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="startDate"
                      label="To"
                      value={toDate}
                      onChange={(date) => setToDate(date)}
                      minDate={fromDate}
                    />
                  </FormControl>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexWrap: "nowrap" }}>
                <FormControl>
                  <RadioGroup value={radioValue} onChange={handleRadioChange}>
                    <FormControlLabel
                      value={TIME_PERIOD.WEEKLY}
                      control={<Radio color="primary" />}
                      label={'Weekly'}
                    />
                    <FormControlLabel
                      value={TIME_PERIOD.MONTHLY}
                      control={<Radio color="primary" />}
                      label={'Monthly'}
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl >
                  <RadioGroup value={radioValue} onChange={handleRadioChange}>
                    <FormControlLabel
                      value={TIME_PERIOD.QUARTERLY}
                      control={<Radio color="primary" />}
                      label={'Quarterly'}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <FormControl
                variant="outlined"
                className={classes.formControl}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: 53 }}
                  onClick={() => fetchSalesFunnelData()}
                >
                  Apply
                </Button>
              </FormControl>
            </Grid>
          }
        />
        <Grid container spacing={4} className="js_product_grid">
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <Grid container spacing={2} style={{ justifyContent: 'space-between' }}>
                  <Grid item xs={4} >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<PrintIcon />}
                      onClick={() => exportFunctionRef.current.onBtnExport()}
                    >
                      Export
                    </Button>
                  </Grid>
                </Grid>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} labels={labels} period={radioValue} />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
