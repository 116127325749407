import React, { useState, useRef, useEffect } from "react";
import { Grid, Button, Typography , Breadcrumbs, Link, CircularProgress, Backdrop } from "@material-ui/core";
import useStyles from "./styles.js";
import Table from "./components/table.js";
import ActionItemModel from "./components/actionItemModal.js";
import Confirmation from "./components/confirmation.js";
import { BASE_URL } from "../../healpers/api.js";
import axios from "axios";
import api from "../../healpers/apiRoutes.js";
import { tokenConfig } from "../../context/UserContext.js";
import { useVendorState } from "../../context/VendorContext.js";
import { fetchWbrConfigs } from "../../http/api.js";

export default function ActionItems(props) {
  const vendorState = useVendorState();
  const classes = useStyles();
  const exportFunctionRef = useRef();

  const [actionItemModal, setActionItemModal] = useState(false);
  const [deleteActionItemModal, setDeleteActionItemModal] = useState(false);
  const [actionId, setActionId] = useState(null);
  const [actionItems, setActionItems] = useState([]);
  const [edit, setEdit] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [fullPageLoader, setFullPageLoader] = useState(false)

  const fetchActionItems = async () => {
    try {
      setFullPageLoader(true)
      const params = {
        vendorCode: vendorState?.selected,
      }
      const response = await axios(BASE_URL + api.fetchActionItems, tokenConfig(params));
      setActionItems(response.data.data);
      setFullPageLoader(false)
    } catch (error) {
      setFullPageLoader(false)
      alert(error.message || 'Failed to Action Items Data')
      console.log("error in fetching action items", error)
    }
  }

  const deleteActionItem = async () => {
    try{
      const params = {
        id: actionId
      }
      await axios.post(BASE_URL + api.deleteActionItem, params,  tokenConfig());
      setDeleteActionItemModal(false);
      fetchActionItems();
    }catch(error){
      console.error("deleteActionItem error", error);
      alert("Failed to delete segement");
    }
  }

  const exportReport = async () => {
    try {
      setExporting(true)
      const params = {
        vendorCode: vendorState?.selected,
      }
      await fetchWbrConfigs(params)
      const response = await axios.get(BASE_URL + api.exportReport, { responseType: 'blob', params });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      let customerName = vendorState?.selected
      link.download = `${customerName}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setExporting(false)
    } catch (error) {
      setExporting(false)
      alert(error.message || "Failed to Export Report");
    }
  }

  useEffect(() => {
    if(vendorState?.selected) {
      fetchActionItems();
    }
  }, [vendorState?.selected]);
  

  return (
    <div>
      <Grid container justifyContent="space-between" alignItems="center" className={classes.header}>
        <Grid>
        <Typography variant="h4" weight="medium" style={{paddingBottom:"20px"}}>
          Action Items
        </Typography>
        <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className={classes.link}>
              WBR
            </Link>
            <Typography className={classes.link} color="primary">
              Action Items
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid className={classes.buttonContainer} >
        <Grid item>
        {exporting ? <CircularProgress color="primary"/>
          : <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              exportReport();
            }}
          >
            Export Report
          </Button>}
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              setActionItemModal(true);
            }}
          >
            Create
          </Button>
        </Grid>
        </Grid>
      </Grid>
      <Table
        ref={exportFunctionRef}
        tableData={actionItems}
        openEditModel={(data) => {
          setActionItemModal(true);
          setActionId(data.id);
          setEdit(true);
        }}
        deleteConfirmation={(data) => {
          setDeleteActionItemModal(true);
          setActionId(data.id);
        }}
      ></Table>
      <ActionItemModel
        open={actionItemModal}
        close={() => {
          setActionItemModal(false);
          setEdit(false);
        }}
        fetchActionItems={fetchActionItems}
        actionId={actionId}
        edit={edit}
      ></ActionItemModel>
      <Confirmation
        open={deleteActionItemModal}
        onCancel={() => setDeleteActionItemModal(false)}
        onConfirm={deleteActionItem}
      />
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
