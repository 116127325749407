import React, { useState, useRef, useEffect } from "react";
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Link,
    Backdrop,
    CircularProgress,
} from "@material-ui/core";
import useStyles from "./styles.js";
import { BASE_URL } from "../../healpers/api.js";
import axios from "axios";
import api from "../../healpers/apiRoutes.js";
import { tokenConfig } from "../../context/UserContext.js";
import { useVendorState } from "../../context/VendorContext.js";
import BarChart from "./components/BarChart.js";
import Widget from "../../components/Widget/Widget.js";
import PrintIcon from "@material-ui/icons/Print";
import { exportReport, generateReportPdf, getCurrency } from "../../healpers/utilityFunctions.js";
import { categoryPerformance, chartLegend, forecastChartLegend, marketShare, months } from "../../constant/wbr.js";
import CategoryPerformanceTable from "./components/categoryPerformanceTable.js"
import ChartLegend from "../../components/ChartLegend/ChartLegend.js";
import MarketShareTable from "./components/marketShareTable.js";
import SalesVsForecastTable from "./components/salesVsForecastTable.js";
import BcgMatrix from "./components/bcgMatrix.js";
import BsrRank from "./components/bsrRank.js";
import LineChart from "./components/LineChart.js";
import html2canvas from "html2canvas";
import CompetitiveBenchmarking from "./components/competitiveBenchmarking.js";
import PieChart from "./components/pieChart.js";
import { fetchCustomerConfig } from "../../http/api.js";
import BenchmarkChart from "./components/benchmarkingBarChart.js";

export default function WeeklyInsights(props) {
    const vendorState = useVendorState();
    const classes = useStyles();
    const categoryMarketShareRef = useRef();
    const relativeMarketShareRef = useRef();
    const keyBusinessMetricsRef = useRef();
    const bsrRankRef = useRef();
    const bcgMatrixRef = useRef();
    const [fullPageLoader, setFullPageLoader] = useState(false);
    const [metricData, setMetricData] = useState([])
    const [exporting, setExporting] = useState(false);
    const [currency, setCurrency] = useState("USD")
    const [customerConfig, setCustomerConfig] = useState({ biAccess: false })
    const refs = useRef([]);

    const fetchCustomerConfigData = async () => {
        try {
            let vendorCode = vendorState?.selected
            if (vendorCode) {
                const data = await fetchCustomerConfig(vendorCode);
                setCustomerConfig(data)
            }
        } catch (error) {
            console.log('error - fetchCustomerConfig', error);
        }
    }

    const fetchWeeklyInsights = async () => {
        try {
            setFullPageLoader(true);
            const params = {
                vendorCode: vendorState?.selected,
            };
            const response = await axios(
                BASE_URL + api.weeklyInsights,
                tokenConfig(params)
            );
            setMetricData(response.data.data)
            const benchmarkData = Object.values(response.data.data.benchmarkingData)
            refs.current = benchmarkData.map(() => React.createRef())
            setFullPageLoader(false);
        } catch (error) {
            setFullPageLoader(false);
            alert(error.message || "Failed to Fetch weekly insights data")
            console.log("fetchWeeklyInsights - error", error);
        }
    };

    const generateReport = async () => {
        setExporting(true)
        try {
            const params = {
                vendorCode: vendorState?.selected,
            }
            const response = await axios.get(BASE_URL + api.generateWeeklyInsights, { responseType: 'blob', params });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data]));
            link.download = 'Weekly Insights.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setExporting(false)
        } catch (error) {
            setExporting(false)
            alert(error.message || "Failed to Export Report");
        }
    }

    useEffect(() => {
        if (vendorState?.selected) {
            fetchWeeklyInsights();
            fetchCustomerConfigData()
            const currency = getCurrency(vendorState?.selected.split('_')[1])
            setCurrency(currency)
        }
    }, [vendorState?.selected]);


    const exportChart = (id, name) => {
        const categoryPerformance = document.getElementById(id)
        generateReportPdf([categoryPerformance], name)
    }

    const exportBSR = async (id, name) => {
        try {
            const imageContainer = document.getElementById(id);
            let canvas = await html2canvas(imageContainer, { useCORS: true, scale: 2, removeContainer: true })
            generateReportPdf([canvas], name)
        } catch (error) {
            console.log('exportBSR', error)
            alert("Failed to Export BSR Rank")
        }
    }

    return (
        <div>
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.header}
            >
                <Grid item xs={6}>
                    <Typography
                        variant="h4"
                        weight="medium"
                        style={{ paddingBottom: "20px" }}
                    >
                        Weekly Insights
                    </Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" className={classes.link}>
                            AM
                        </Link>
                        <Typography className={classes.link} color="primary">
                            Weekly Insights
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item>
                    {exporting ? <CircularProgress color="primary" /> : <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<PrintIcon />}
                        onClick={() => generateReport()}
                    >
                        Export Overall Report
                    </Button>}
                </Grid>
            </Grid>
            {!fullPageLoader && (
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Widget
                            upperTitle
                            noBodyPadding
                            bodyClass={classes.tableOverflow}
                            header={
                                <Grid item xs={12} >
                                    <div className={classes.mainChartHeader}>
                                        <Typography
                                            variant="h2"
                                            gutterBottom
                                            style={{
                                                fontWeight: 600,
                                                fontSize: 20,
                                                color: "black",
                                            }}
                                        >
                                            Market Outlook - Overall Category Performance
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            startIcon={<PrintIcon />}
                                            onClick={() => exportChart('categoryPerformance', 'Market Outlook.pdf')}
                                            style={{ marginRight: 20 }}
                                        >
                                            Export Chart
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            startIcon={<PrintIcon />}
                                            onClick={() => categoryMarketShareRef.current.onBtnExport()}
                                        >
                                            Export Table
                                        </Button>
                                    </div>
                                </Grid>
                            }
                        >
                            <Grid container id="shippedRevenue">
                                <Grid item xs={6}>
                                    <BarChart
                                        data={metricData?.categoryPerformance?.currentYTDValue}
                                        previousYearData={metricData?.categoryPerformance?.previousYTDValue}
                                        labels={metricData?.labels}
                                        label="Category Performance"
                                        id="categoryPerformance"
                                        yAxisLabel={`Revenue (In ${currency})`}
                                    />
                                </Grid>
                                <ChartLegend legendData={categoryPerformance} />
                            </Grid>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <CategoryPerformanceTable
                                                ref={categoryMarketShareRef}
                                                tableData={metricData?.categoryPerformance?.data}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Widget>
                    </Grid>
                </Grid>
            )}
            {/* {!fullPageLoader && (
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Widget
                            upperTitle
                            noBodyPadding
                            bodyClass={classes.tableOverflow}
                            header={
                                <Grid item xs={12}>
                                    <div className={classes.mainChartHeader}>
                                        <Typography
                                            variant="h2"
                                            gutterBottom
                                            style={{
                                                fontWeight: 600,
                                                fontSize: 20,
                                                color: "black",
                                            }}
                                        >
                                            Market Share
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            startIcon={<PrintIcon />}
                                            onClick={() => exportChart('relativeMarketShare', 'Relative Market Share.pdf')}
                                            style={{ marginRight: 20 }}
                                        >
                                            Export Chart
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            startIcon={<PrintIcon />}
                                            onClick={() => relativeMarketShareRef.current.onBtnExport()}
                                            style={{ marginRight: 20 }}
                                        >
                                            Export Table
                                        </Button>
                                    </div>
                                </Grid>
                            }
                        >
                            <Grid container id="shippedRevenue">
                                <Grid item xs={6}>
                                    <BarChart
                                        data={metricData?.categoryMarketShare?.currentWTD}
                                        previousYearData={metricData?.categoryMarketShare?.competitorShare}
                                        labels={metricData?.labels}
                                        label="Relative Market Share"
                                        id="relativeMarketShare"
                                        prevYearLabel="Competitor Share"
                                        currentYearLabel="Our Market Share"
                                        yAxisLabel="Revenue (In Percentage)"
                                    />
                                </Grid>
                                <ChartLegend legendData={marketShare} />
                            </Grid>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <MarketShareTable
                                                ref={relativeMarketShareRef}
                                                tableData={metricData?.categoryMarketShare?.data}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Widget>
                    </Grid>
                </Grid>
            )} */}
            {!fullPageLoader && (
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Widget
                            upperTitle
                            noBodyPadding
                            bodyClass={classes.tableOverflow}
                            header={
                                <Grid item xs={12}>
                                    <div className={classes.mainChartHeader}>
                                        <Typography
                                            variant="h2"
                                            gutterBottom
                                            style={{
                                                fontWeight: 600,
                                                fontSize: 20,
                                                color: "black",
                                            }}
                                        >
                                            Competitive Benchmarking
                                        </Typography>
                                    </div>
                                </Grid>
                            }
                        >
                            {metricData?.benchmarkingData && Object.entries(metricData?.benchmarkingData).map(([key, value], index) => (
                                <div>
                                    <Typography
                                        variant="h2"
                                        gutterBottom
                                        style={{
                                            fontWeight: 600,
                                            fontSize: 18,
                                            color: "black",
                                            paddingLeft: 25
                                        }}
                                    >
                                        {key}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<PrintIcon />}
                                        onClick={() => exportChart(`${key}`, `${key}.pdf`)}
                                        style={{ marginRight: 20, marginLeft: 25, marginBottom: 10 }}
                                    >
                                        Export Chart
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<PrintIcon />}
                                        onClick={() => refs.current[index].current.onBtnExport()}
                                        style={{ marginRight: 20, marginLeft: 25, marginBottom: 10 }}
                                    >
                                        Export Table
                                    </Button>
                                    <Grid container id="shippedRevenue">
                                        <Grid item xs={6}>
                                            {customerConfig?.biAccess ? <PieChart
                                                data={value}
                                                biAccess={customerConfig?.biAccess || false}
                                                id={key}
                                            /> :
                                                <BenchmarkChart
                                                    data={value}
                                                    biAccess={customerConfig?.biAccess || false}
                                                />
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12} >
                                                    <CompetitiveBenchmarking
                                                        ref={refs.current[index]}
                                                        tableData={value}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                        </Widget>
                    </Grid>
                </Grid>
            )}
            {!fullPageLoader && (
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Widget
                            upperTitle
                            noBodyPadding
                            bodyClass={classes.tableOverflow}
                            header={
                                <Grid item xs={12}>
                                    <div className={classes.mainChartHeader}>
                                        <Typography
                                            variant="h2"
                                            gutterBottom
                                            style={{
                                                fontWeight: 600,
                                                fontSize: 20,
                                                color: "black",
                                            }}
                                        >
                                            BCG Matrix
                                        </Typography>
                                        {/* <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            startIcon={<PrintIcon />}
                                            onClick={() => exportBSR('bcgMatrix', 'BCG Matrix.pdf')}
                                        >
                                            Export Chart
                                        </Button> */}
                                    </div>
                                </Grid>
                            }
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <BcgMatrix
                                        ref={bcgMatrixRef}
                                        tableData={metricData?.overallCategoryPerformance}
                                        id="bcgMatrix"
                                    />
                                </Grid>
                            </Grid>
                        </Widget>
                    </Grid>
                </Grid>
            )}
            {!fullPageLoader && (
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Widget
                            upperTitle
                            noBodyPadding
                            bodyClass={classes.tableOverflow}
                            header={
                                <Grid item xs={12}>
                                    <div className={classes.mainChartHeader}>
                                        <Typography
                                            variant="h2"
                                            gutterBottom
                                            style={{
                                                fontWeight: 600,
                                                fontSize: 20,
                                                color: "black",
                                            }}
                                        >
                                            BSR Rank
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            startIcon={<PrintIcon />}
                                            onClick={() => exportBSR('bsrRank', 'BSR Rank.pdf')}
                                            style={{ marginRight: 20 }}
                                        >
                                            Export Chart
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            startIcon={<PrintIcon />}
                                            onClick={() => bsrRankRef.current.onBtnExport()}
                                        >
                                            Export Table
                                        </Button>
                                    </div>
                                </Grid>
                            }
                        >
                            <Grid container id="bsrRank">
                                <Grid item xs={6}>
                                    <Typography
                                        variant="h2"
                                        gutterBottom
                                        style={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                            color: "black",
                                            paddingLeft: 30,
                                            paddingTop: 5
                                        }}
                                    >
                                        Largest Competitor BSR Rank
                                    </Typography>
                                    <BarChart
                                        data={metricData?.bsrRankData?.currentWeekCompetitorBSR}
                                        previousYearData={metricData?.bsrRankData?.previous4WeeksCompetitorBSR}
                                        labels={metricData?.labels}
                                        label="Largest Competitor BSR Rank"
                                        yAxisLabel="BSR"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="h2"
                                        gutterBottom
                                        style={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                            color: "black",
                                            paddingLeft: 30,
                                            paddingTop: 5
                                        }}
                                    >
                                        {metricData.brandName}
                                    </Typography>
                                    <BarChart
                                        data={metricData?.bsrRankData?.currentWeekBSR}
                                        previousYearData={metricData?.bsrRankData?.previous4WeeksBSR}
                                        labels={metricData?.labels}
                                        label="BSR Rank"
                                        yAxisLabel="BSR"
                                    />
                                </Grid>
                                <ChartLegend legendData={categoryPerformance} />
                            </Grid>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <BsrRank
                                                ref={bsrRankRef}
                                                tableData={metricData?.bsrRankData?.data}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Widget>
                    </Grid>
                </Grid>
            )}
            <Backdrop className={classes.backdrop} open={fullPageLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
