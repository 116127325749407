import {
  Button,
  Grid,
  Modal,
  TextField,
  Backdrop,
  Box,
  Fade,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import useStyles from "./styles";
import { useState } from "react";

const AddLTLPalletModal = ({ open, handleClose, setmainTableData }) => {
  const classes = useStyles();
  const [palletDetails, setPalletDetails] = useState({
    length: 40,
    width: 48,
    height: "",
    weight: "",
    isStacked: false,
  });

  console.log({ palletDetails });

  const handlePalletDetailsChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setPalletDetails((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  function addPalletDetails() {
    setmainTableData((prevState) => [
      ...prevState,
      { id: prevState.length + 1, ...palletDetails },
    ]);
    setPalletDetails({
      length: 40,
      width: 48,
      height: "",
      weight: "",
      isStacked: false,
    });
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h1 style={{ textAlign: "center" }}>Add Pallet Information</h1>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            style={{ marginBottom: 10 }}
          >
            <Grid item>
              <TextField
                name="length"
                label="Length (IN)"
                type="number"
                variant="outlined"
                value={40}
                onChange={handlePalletDetailsChange}
                disabled
              />
            </Grid>

            <Grid item>
              <TextField
                name="width"
                label="Width (IN)"
                type="number"
                variant="outlined"
                value={48}
                onChange={handlePalletDetailsChange}
                disabled
              />
            </Grid>

            <Grid item>
              <TextField
                name="height"
                label="Height (IN)"
                type="number"
                variant="outlined"
                value={palletDetails?.height}
                onChange={handlePalletDetailsChange}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            // justifyContent="center"
            style={{ marginBottom: 10 }}
          >
            <Grid item>
              <TextField
                name="weight"
                label="Weight (LB)"
                type="number"
                variant="outlined"
                value={palletDetails?.weight}
                onChange={handlePalletDetailsChange}
              />
            </Grid>
            <Grid item>
              <FormControl variant="outlined" style={{ minWidth: 220 }}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Is Stacked
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={palletDetails?.isStacked}
                  onChange={handlePalletDetailsChange}
                  label="Is Stacked"
                  name="isStacked"
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ width: "150px" }}
              onClick={addPalletDetails}
              disabled={
                !(
                  palletDetails.height &&
                  palletDetails.height > 0 &&
                  palletDetails.height <= 72 &&
                  palletDetails.weight
                )
              }
            >
              add
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginLeft: "10px", width: "150px" }}
              onClick={handleClose}
            >
              close
            </Button>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddLTLPalletModal;
