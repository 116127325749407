import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css';
import _ from "lodash";

export default forwardRef(function Tables(props, ref) {

  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const rowStyle = { background: 'white' };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else
        gridApi.exportDataAsCsv();
    }
  }));

  const FavIconRenderer = (asin) => {
    let url = `https://sd-catalog-images.s3.amazonaws.com/${asin}.jpg`;
    return `<img style="height: 40px; width: 40px" src="${url}">`
  };

  const formatPrice = (params) => {
    if (params.value) {
      return params.value.toFixed()
    } else {
      return '-'
    }
  }

  return (
    <div className="ag-theme-material" style={{ height: 620, width: '100%' }} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        suppressDragLeaveHidesColumns={true}
        frameworkComponents={{
          faviconRenderer: FavIconRenderer,
        }}
      >
        <AgGridColumn key={2} headerName={''} field={'asin'} width={100} cellRenderer={({value}) => FavIconRenderer(value, props.vendorCode)}  pinned="left"/>
        <AgGridColumn key={2} headerName={'ASIN'} field={'asin'} pinned="left" />
        {!_.isEmpty(props.labels) && Object.keys(props.labels).map((key) => {
          return <AgGridColumn colId={key} headerName={key}>
            {
              props.labels[key].map((label) => (
                <AgGridColumn key={2} headerName={label} field={`${label}_conversion`} valueFormatter={formatPrice} />
              ))
            }
          </AgGridColumn>
        })}
      </AgGridReact>
    </div>
  );
})