import React, {useEffect, useRef} from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js"
import Heatmap from "highcharts/modules/heatmap.js";
import Tilemap from "highcharts/modules/tilemap.js";

// context
import { useLayoutState } from "../../../../context/LayoutContext";

//helpers
import { numberWithCommas } from "../../../../healpers/utilityFunctions";

highchartsMore(Highcharts);
Heatmap(Highcharts);
Tilemap(Highcharts);

export default function App(props) {

  // global
  var layoutState = useLayoutState();

  //local
  const chartRef = useRef();

  // To make map responsive
  useEffect(() => {
      if(chartRef.current) {
        setTimeout(() => {
            Highcharts.charts[chartRef.current.chart.index].reflow()
        }, 200);
      }
  }, [layoutState.isSidebarOpened])


  const MapOptions = {
    chart: {
      type: 'tilemap',
      inverted: true,
      height: '60%'
  },

  title: null,

  xAxis: {
      visible: false
  },

  yAxis: {
      visible: false
  },

  colorAxis: {
      dataClasses: [{
          from: 0,
          to: (0.20*props.maxRevenue).toFixed(2),
          color: '#F9EDB3',
          name: '< '+numberWithCommas(0.20*props.maxRevenue, 2)
      }, {
          from: (0.20*props.maxRevenue).toFixed(2),
          to: (0.40*props.maxRevenue).toFixed(2),
          color: '#FFC428',
          name: numberWithCommas(0.20*props.maxRevenue, 2)+' - '+numberWithCommas(0.40*props.maxRevenue, 2)
      }, {
          from: (0.40*props.maxRevenue).toFixed(2),
          to: 0.60*props.maxRevenue.toFixed(2),
          color: '#FF7987',
          name: numberWithCommas(0.40*props.maxRevenue, 2)+' - '+numberWithCommas(0.60*props.maxRevenue, 2)
      }, {
          from: (0.60*props.maxRevenue).toFixed(2),
          color: '#FF2371',
          name: '> '+numberWithCommas(0.60*props.maxRevenue, 2)
      }]
  },

  credits: {
    enabled: false
  },

  tooltip: {
      headerFormat: '',
      pointFormatter: function() {
        return "Shipped Revenue <b>(" + this.name +") </b> : <b> $"+ numberWithCommas(this.value) +"</b>"
      }
  },

  plotOptions: {
      series: {
          dataLabels: {
              enabled: true,
              format: '{point.hc-a2}',
              color: '#000000',
              style: {
                  textOutline: false,
              }
          }
      }
  },

  series: [{
      name: '',
      data: props.mapData
  }]
  };

  if(props.mapData && props.mapData.length)
  return (
    <div style={{
      width:'80%',
      margin: 'auto',
    }}>
        <HighchartsReact highcharts={Highcharts} options={MapOptions} ref={chartRef}/>
    </div>
  )
  else return (
    <h3 style={{ textAlign: "center",margin:'16% 0'}}>
      ...No Data To Show...
    </h3>
  )
};
