import React, { useEffect, useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Container, Grid, Breadcrumbs, Typography, FormControl, Select, MenuItem, TextField } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import { fetchWbrOperationsReport, uploadWbrOperations } from "../../http/api";
import { useVendorState } from "../../context/VendorContext";
import { Button } from "@material-ui/core";
import * as XLSX from "xlsx";
import OperationsTicketsTemplate from "../../healpers/assets/OperationsTicketsTemplate.xlsx";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import CreateActionItemModal from "../actionItems/components/createActionItemModal";
import { ACTION_ITEMS, FILTER_OPTIONS } from "../../constant/wbr";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const getDocDefinition = (agGridApi, agGridColumnApi) => {
  const getColumnsToExport = () => {
    const columnsToExport = [];

    agGridColumnApi.getAllDisplayedColumns().forEach((col) => {
      columnsToExport.push({
        colId: col.getColId(),
        text: col.colDef.headerName,
        style: "tableHeader",
      });
    });

    return columnsToExport;
  };

  const getRowsToExport = (columnsToExport) => {
    const rowsToExport = [];

    agGridApi.forEachNodeAfterFilterAndSort((node) => {
      const rowToExport = columnsToExport.map(({ colId }) => {
        return {
          text:
            agGridApi.getValue(colId, node) !== undefined
              ? agGridApi.getValue(colId, node)
              : "",
          style: "tableCell",
        };
      });
      rowsToExport.push(rowToExport);
    });

    return rowsToExport;
  };

  const columnsToExport = getColumnsToExport();
  const rowsToExport = getRowsToExport(columnsToExport);
  const body = [columnsToExport, ...rowsToExport];

  const docDefinition = {
    pageOrientation: "landscape",
    content: [
      {
        text: "Operations",
        style: {
          fontSize: 24,
          bold: true,
        },
      },
      {
        table: {
          headerRows: 1,
          widths: ["20%", ...Array(9).fill("9%")],
          body,
        },
      },
    ],
    styles: {
      tableHeader: {
        bold: true,
      },
    },
  };

  return docDefinition;
};

export default function WbrOperations() {
  const vendorState = useVendorState();
  const [agGridApi, setAgGridApi] = useState(null);
  const [agGridColumnApi, setAgGridColumnApi] = useState(null);
  const [operationsReport, setOperationsReport] = useState([]);
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [rowData, setRowData] = useState([]);
  const rowHeaderTitle = "Summary";
  const [operationsTicketsTypes, setOperationsTicketsTypes] = useState([]);
  const [actionItemModal, setActionItemModal] = useState(false);
  const [asin, setAsin] = useState("")
  const [category, setCategory] = useState("")
  const [selectedFilter, setSelectedFilter] = useState("")

  const loadOperationsReport = async () => {
    try {
      let params = {
        vendorCode: vendorState?.selected
      }
      if (asin !== "" && selectedFilter === FILTER_OPTIONS.ASIN) {
        params.asins = asin.split(",")
      }
      if (category !== "" && selectedFilter === FILTER_OPTIONS.CATEGORY) {
        params.category = category
      }
      const { status, message } = await fetchWbrOperationsReport(params);
      if (status === "Success") {
        setOperationsReport(message);
      } else {
        setOperationsReport([]);
      }
    } catch (err) {
      console.log(err);
      setOperationsReport([]);
    }
  };

  useEffect(() => {
    vendorState?.selected && loadOperationsReport();
  }, [vendorState?.selected]);

  useEffect(() => {
    setColumnHeaders([rowHeaderTitle, ...Object.keys(operationsReport)]);
    const ticketTypes = operationsReport?.YTD
      ? Object.keys(operationsReport.YTD).filter(
        (item) => ["existing", "new"].includes(item) === false
      )
      : [];

    setOperationsTicketsTypes([...ticketTypes]);
    const rows = [];
    const createRow = (title, key) => {
      const row = {
        [rowHeaderTitle]: title,
      };
      const keys = key.split(".");
      Object.keys(operationsReport).forEach((item) => {
        row[item] = operationsReport[item]?.[keys[0]]?.[keys[1]] || 0;
      });
      return row;
    };

    // Existing old tickets
    rows.push({
      [rowHeaderTitle]: "Pending Tickets",
    });
    rows.push(
      createRow("Pending tickets at the start", "existing.pendingAtStart")
    );
    rows.push(createRow("Tickets resolved", "existing.resolved"));
    rows.push(
      createRow(
        "% of previous year sales contributed by impacted ASINs",
        "existing.resolvedAmountAsins"
      )
    );
    rows.push(createRow("Tickets WIP", "existing.pending"));
    rows.push(createRow("Pending with Amazon", "existing.pendingAmazon"));
    rows.push(createRow("Pending with customer", "existing.pendingCustomer"));
    rows.push(createRow("Pending with SD", "existing.pendingSd"));
    rows.push(
      createRow(
        "% of previous year sales contributed by impacted ASINs",
        "existing.pendingAmountAsins"
      )
    );

    // New tickets
    rows.push({
      [rowHeaderTitle]: "New Tickets",
    });
    rows.push(createRow("New tickets opened", "new.pendingAtStart"));
    rows.push(createRow("Tickets resolved", "new.resolved"));
    rows.push(
      createRow(
        "% of previous year sales contributed by impacted ASINs",
        "new.resolvedAmountAsins"
      )
    );
    rows.push(createRow("Tickets WIP", "new.pending"));
    rows.push(createRow("Pending with Amazon", "new.pendingAmazon"));
    rows.push(createRow("Pending with customer", "new.pendingCustomer"));
    rows.push(createRow("Pending with SD", "new.pendingSd"));
    rows.push(
      createRow(
        "% of previous year sales contributed by impacted ASINs",
        "new.pendingAmountAsins"
      )
    );
    setRowData([...rows]);
  }, [operationsReport]);


  const handleOnChange = (event) => {
    if (selectedFilter === FILTER_OPTIONS.CATEGORY) {
      setCategory(event.target.value)
    } else {
      setAsin(event.target.value)
    }
  }

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (event) => {
      const fileContent = event.target.result;
      const wb = XLSX.read(fileContent, { type: "array" });
      const wsName = wb.SheetNames[0];
      const ws = wb.Sheets[wsName];
      const data = XLSX.utils.sheet_to_json(ws, { raw: false });
      const operationsTickets = data.map((item) => {
        return {
          ticketId: item["Ticket ID"],
          ticketType: item["Type"] || "Misc Issue",
          ticketDate: moment.utc(item["Created time"], "DD/MM/YYYY").toDate(),
          status: item["Status"] || "Open",
          resolutionDate: item["Resolved time"]
            ? moment.utc(item["Resolved time"], "DD/MM/YYYY").toDate()
            : undefined,
          vendorCode: item["Vendor code"] || "Unknown",
          asins: item["ASINs"]
            ? item["ASINs"]
              .split(",")
              .map((asin) => asin.trim())
            : [],
        };
      });
      try {
        const { message } = await uploadWbrOperations(
          operationsTickets
        );
        window.alert(message);
      } catch (e) {
        window.alert(e);
      }
    };
    reader.readAsArrayBuffer(file);
  }

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Operations"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="primary">Operations</Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                width: '100%',
                flexDirection: 'column',
                gap: 20
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width:'100%', gap:20  }}>
              <a
                href={OperationsTicketsTemplate}
                download="OperationsTicketsTemplate.xlsx"
                style={{ textDecoration: "none" }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  component="span"
                  size="small"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Download Template
                </Button>
              </a>
              <>
                <input
                  id="file-upload-input"
                  type="file"
                  accept=".xlsx"
                  onChange={(e) => {
                    handleFileUpload(e)
                  }}
                  style={{ display: "none" }}
                />
                <label htmlFor="file-upload-input">
                  <Button
                    component="span"
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Upload [.xlsx]
                  </Button>
                </label>
              </>
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={!(agGridApi && agGridColumnApi)}
                onClick={() => {
                  const docDefinition = getDocDefinition(
                    agGridApi,
                    agGridColumnApi
                  );
                  pdfMake.createPdf(docDefinition).download();
                }}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                Export to PDF
              </Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width:'100%', gap:20 }}>
                <FormControl style={{ minWidth: 100 }} variant="outlined">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(event) => setSelectedFilter(event.target.value)}
                    value={selectedFilter}
                  >
                    <MenuItem value={FILTER_OPTIONS.CATEGORY}>Category</MenuItem>
                    <MenuItem value={FILTER_OPTIONS.ASIN}>Asin</MenuItem>
                  </Select>
                </FormControl>
                {selectedFilter !== "" && <div>
                  <FormControl variant="outlined">
                    <TextField
                      type="text"
                      value={selectedFilter === FILTER_OPTIONS.CATEGORY ? category : asin}
                      onChange={(event) => {
                        handleOnChange(event)
                      }}
                      label={selectedFilter.toLowerCase()}
                      variant="outlined"
                    />
                  </FormControl>
                </div>}
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    loadOperationsReport();
                  }}
                  disabled={selectedFilter === "" && (asin === "" || category === "")}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    setActionItemModal(true)
                  }}
                >
                  Add Action Item
                </Button>
              </div>
            </div>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12} style={{ backgroundColor: "white" }}>
            <div
              className="ag-theme-material"
              style={{ height: 620, width: "100%" }}
            >
              <AgGridReact
                onGridReady={(param) => {
                  setAgGridApi(param.api);
                  setAgGridColumnApi(param.columnApi);
                }}
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  filter: "agTextColumnFilter",
                  headerComponentParams: {
                    template:
                      '<div class="ag-cell-label-container" role="presentation">' +
                      '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                      '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                      '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                      '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                      '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                      '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                      '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                      '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                      "  </div>" +
                      "</div>",
                  },
                }}
                rowData={rowData}
                getRowStyle={(param) => {
                  if (
                    [
                      "Pending Tickets",
                      "New Tickets",
                      ...operationsTicketsTypes.map(
                        (item) => `Tickets by type "${item}"`
                      ),
                    ].includes(param.data[rowHeaderTitle])
                  ) {
                    return {
                      "pointer-events": "none",
                      "font-weight": "bold",
                      "background-color": "#A4A4A4",
                    };
                  }
                }}
              >
                {columnHeaders.map((item, index) => (
                  <AgGridColumn
                    key={index}
                    headerName={item}
                    field={item}
                    width={index === 0 ? 400 : undefined}
                    pinned={index === 0}
                    cellStyle={(param) => {
                      if (
                        ["Tickets resolved", "Tickets WIP"].includes(
                          param.data[rowHeaderTitle]
                        ) &&
                        index === 0
                      ) {
                        return {
                          "padding-left": "50px",
                          "font-weight": "bold",
                        };
                      }
                      if (
                        [
                          "% of previous year sales contributed by impacted ASINs",
                          "% of previous year sales contributed by impacted ASINs",
                          "Pending with Amazon",
                          "Pending with customer",
                          "Pending with SD",
                        ].includes(param.data[rowHeaderTitle]) &&
                        index === 0
                      ) {
                        return {
                          "padding-left": "70px",
                        };
                      }
                    }}
                  />
                ))}
              </AgGridReact>
            </div>
          </Grid>
        </Grid>
        <CreateActionItemModal
          open={actionItemModal}
          close={() => {
            setActionItemModal(false);
          }}
          slideName={ACTION_ITEMS.OPERATION_TICKETS}
        />
      </Container>
    </>
  );
}
