import {
  Button,
  Grid,
  Modal,
  TextField,
  Backdrop,
  Box,
  Fade,
  CircularProgress,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Typography,
} from "@material-ui/core";
import useStyles from "./styles";
import { useEffect, useState } from "react";

import { BASE_URL } from "../../../../healpers/api";
import api from "../../../../healpers/apiRoutes";
import axios from "axios";
import { useVendorState } from "../../../../context/VendorContext";

const ShipFromAddressModal = ({
  open,
  handleAddressModalClose,
  addresses,
  selectedAddress,
  setSelectedAddress,
  setfullPageLoader,
  setnotification,
  seterr,
  fetchAddresses,
}) => {
  const vendorState = useVendorState();
  const classes = useStyles();

  const handleChange = (event) => {
    console.log({ value: event.target.value });
    setSelectedAddress(event.target.value);
  };

  const setDefaultAddress = async () => {
    try {
      setfullPageLoader(true);
      const response = await axios.post(BASE_URL + api.setDefaultAddress, {
        vendorCode: vendorState?.selected,
        id: selectedAddress,
      });
      setnotification(response.data.msg);
      setTimeout(() => setnotification(null), 3000);
      fetchAddresses();
    } catch (err) {
      console.log(err);
      seterr("Error occured");
      setTimeout(() => seterr(null), 3000);
    } finally {
      setfullPageLoader(false);
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h1 style={{ textAlign: "center" }}>Select Ship From Address</h1>
            {addresses.map((address) => {
              return (
                <Box key={address.id}>
                  <Radio
                    checked={address.id == selectedAddress}
                    onChange={handleChange}
                    value={address.id}
                    color="primary"
                  />
                  <span
                    style={{ fontSize: 18 }}
                  >{`${address.addressLine1} ${address.city}, ${address.stateOrProvinceCode} ${address.postalCode} ${address.countryCode}`}</span>
                  {address.default && (
                    <Typography
                      color="primary"
                      style={{ marginLeft: 40, fontWeight: "bold" }}
                      variant="span"
                      component="span"
                    >
                      DEFAULT
                    </Typography>
                  )}
                </Box>
              );
            })}
            <Box display={"flex"} justifyContent={"flex-end"} marginTop={5}>
              <Button
                variant="contained"
                color="primary"
                disabled={!selectedAddress}
                onClick={setDefaultAddress}
              >
                SET AS DEFAULT
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleAddressModalClose}
                style={{ marginLeft: 10 }}
              >
                CLOSE
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default ShipFromAddressModal;
