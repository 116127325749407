import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import useStyles from "./styles";
import { fetchCategoriesList, fetchSegmentsList, fetchWbrFunnelData } from "../../http/api";
import { useVendorState } from "../../context/VendorContext";
import moment from "moment";

export default function WbrFunnel() {
  const vendorState = useVendorState();
  const classes = useStyles();
  const [funnel, setFunnel] = useState({});
  const [isLoadingFunnel, setIsLoadingFunnel] = useState(false);

  // ASIN, Category, Segment filters
  const [filter, setFilter] = useState("ASIN");
  const [filterValues, setFilterValues] = useState([
    "All",
    "Top 80"
  ]);
  const [filterValue, setFilterValue] = useState("All");
  const [categories, setCategories] = useState([]);
  const [segments, setSegments] = useState([]);

  // Date filters
  const [period, setPeriod] = useState("weekly");
  const [appliedPeriod, setAppliedPeriod] = useState("weekly");
  const weekRanges = [];
  let startDate = moment().startOf("year").startOf("week");
  while (startDate.isBefore(moment().subtract(1, "week"))) {
    const endDate = startDate.clone().endOf("week");
    weekRanges.push(
      `${startDate.format("MM/DD/YYYY")} - ${endDate.format("MM/DD/YYYY")}`
    );
    startDate = endDate.clone().add(1, "day");
  }
  weekRanges.push(
    `${moment().startOf("week").format("MM/DD/YYYY")} - ${moment().format(
      "MM/DD/YYYY"
    )}`
  );
  const [periodRangeValues, setPeriodRangeValues] = useState(weekRanges);
  const [periodRange, setPeriodRange] = useState(weekRanges[weekRanges.length - 2]);

  const [activeTab, setActiveTab] = useState(0);

  const TABS = {
    "weekly": ["Week to Date (WTD)", "Week-on-Week (WOW)", "Year-on-Year (YOY)"],
    "monthly": ["Month to Date (MTD)", "Month-on-Month (MOM)", "Year-on-Year (YOY)"],
    "yearly": ["Year to Date (YTD)", "Year-on-Year (YOY)"],
  };

  const columns = [
    "Period",
    "Impressions",
    "GV Conversion",
    "GV",
    "Sales Conversion",
    "Units",
    "ASP",
    "Revenue",
    "Spend",
    "Sales",
    "TACOS",
  ];

  const transposeData = (data) => {
    const transposed = columns.map((colName) => {
      const obj = { name: colName === "Period" ? "Metric" : colName };
      data.forEach((row, index) => {
        obj[index] = row[colName];
      });
      return obj;
    });
    return transposed;
  };

  const loadFunnel = async (vendorCode, filter, filterValue) => {
    if (!periodRange) return;
    try {
      setIsLoadingFunnel(true);
      const startDate = periodRange.split("-")[0].trim();
      const endDate = periodRange.split("-")[1].trim();
      const { data, status } = await fetchWbrFunnelData(
        vendorCode,
        filter,
        filterValue,
        period,
        startDate,
        endDate,
      );
      if (status === "Success") {
        setFunnel(data);
      } else {
        setFunnel({});
      }
    } catch (err) {
      console.log(err);
      setFunnel({});
    } finally {
      setIsLoadingFunnel(false);
    }
  };

  useEffect(() => {
    if (filter === "ASIN") {
      setFilterValues([
        "All",
        "Top 80",
      ]);
      setFilterValue("All");
    } else if (filter === "Category") {
      setFilterValues(categories);
      setFilterValue(categories[0] || "");
    } else if (filter === "Segment") {
      setFilterValues(segments);
      setFilterValue(segments[0] || "");
    }
  }, [filter]);

  useEffect(() => {
    if (period === "weekly") {
      const weekRanges = [];
      let startDate = moment().startOf("year").startOf("week");

      while (startDate.isBefore(moment().subtract(1, "week"))) {
        const endDate = startDate.clone().endOf("week");
        weekRanges.push(
          `${startDate.format("MM/DD/YYYY")} - ${endDate.format("MM/DD/YYYY")}`
        );
        startDate = endDate.clone().add(1, "day");
      }
      weekRanges.push(
        `${moment().startOf("week").format("MM/DD/YYYY")} - ${moment().format(
          "MM/DD/YYYY"
        )}`
      );
      setPeriodRangeValues(weekRanges);
      setPeriodRange(weekRanges[weekRanges.length - 2]);
    } else if (period === "monthly") {
      const monthRanges = [];
      let startDate = moment().subtract(1, "year").startOf("month");

      for (let i = 0; i < 12; i++) {
        const endDate = startDate.clone().endOf("month");
        monthRanges.push(
          `${startDate.format("MM/DD/YYYY")} - ${endDate.format("MM/DD/YYYY")}`
        );
        startDate = startDate.add(1, "month");
      }
      monthRanges.push(
        `${moment().startOf("month").format("MM/DD/YYYY")} - ${moment().format(
          "MM/DD/YYYY"
        )}`
      );
      setPeriodRangeValues(monthRanges);
      setPeriodRange(monthRanges[monthRanges.length - 1]);
    } else if (period === "yearly") {
      setPeriodRangeValues([
        `${moment()
          .subtract(1, "year")
          .startOf("year")
          .format("MM/DD/YYYY")} - ${moment()
          .subtract(1, "year")
          .endOf("year")
          .format("MM/DD/YYYY")}`,
        `${moment().startOf("year").format("MM/DD/YYYY")} - ${moment().format(
          "MM/DD/YYYY"
        )}`,
      ]);
      setPeriodRange(
        `${moment().startOf("year").format("MM/DD/YYYY")} - ${moment().format(
          "MM/DD/YYYY"
        )}`
      );
    }
  }, [period]);

  useEffect(() => {
    const loadCategories = async (vendorCode) => {
      const categoriesList = await fetchCategoriesList({ vendorCode });
      setCategories(categoriesList?.data || []);
    };

    const loadSegments = async (vendorCode) => {
      const segmentsList = await fetchSegmentsList({ vendorCode });
      setSegments(segmentsList?.data || []);
    };

    if (vendorState?.selected) {
      loadCategories(vendorState?.selected);
      loadSegments(vendorState?.selected);
      if (["Category", "Segment"].includes(filter)) {
        setFilter("ASIN");
        setFilterValue("All");
        loadFunnel(vendorState?.selected, "ASIN", "All");
      } else {
        loadFunnel(vendorState?.selected, filter, filterValue);
      }
    }
    setAppliedPeriod(period);
  }, [vendorState?.selected]);

  useEffect(() => {
    setActiveTab(0);
  }, [appliedPeriod]);

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.header}
      >
        <Grid item xs={4}>
          <Typography
            variant="h4"
            weight="medium"
            style={{ paddingBottom: "20px" }}
          >
            Sales Conversion Funnel
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className={classes.link}>
              WBR
            </Link>
            <Typography className={classes.link} color="primary">
              Sales Conversion Funnel
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={8}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                minWidth: "45%",
                maxWidth: "45%",
              }}
            >
              <FormControl
                style={{ minWidth: 100 }}
                fullWidth
                variant="outlined"
                size="small"
              >
                <RadioGroup
                  value={filter}
                  onChange={(event) => setFilter(event.target.value)}
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <FormControlLabel
                    value="ASIN"
                    control={<Radio color="primary" size="small" />}
                    label="ASIN"
                  />
                  <FormControlLabel
                    value="Category"
                    control={<Radio color="primary" size="small" />}
                    label="Category"
                  />
                  <FormControlLabel
                    value="Segment"
                    control={<Radio color="primary" size="small" />}
                    label="Segment"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl
                style={{ minWidth: 100 }}
                fullWidth
                variant="outlined"
                size="small"
              >
                <InputLabel>{filterValues.length === 0 ? "No items available to show" : `Select ${filter}`}</InputLabel>
                <Select
                  value={filterValue || ""}
                  onChange={(e) => {
                    setFilterValue(e.target.value);
                  }}
                  disabled={filterValues.length === 0}
                  label={`Select ${filter}`}
                >
                  {filterValues.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })
                  }
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                minWidth: "45%",
                maxWidth: "45%",
              }}
            >
              <FormControl
                style={{ minWidth: 100 }}
                fullWidth
                variant="outlined"
                size="small"
              >
                <RadioGroup
                  value={period}
                  onChange={(event) => setPeriod(event.target.value)}
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <FormControlLabel
                    value="weekly"
                    control={<Radio color="primary" size="small" />}
                    label="Weekly"
                  />
                  <FormControlLabel
                    value="monthly"
                    control={<Radio color="primary" size="small" />}
                    label="Monthly"
                  />
                  <FormControlLabel
                    value="yearly"
                    control={<Radio color="primary" size="small" />}
                    label="Yearly"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl
                style={{ minWidth: 100 }}
                fullWidth
                variant="outlined"
                size="small"
              >
                <InputLabel>{`Select ${period.slice(0, -2)}`}</InputLabel>
                <Select
                  value={periodRange || ""}
                  onChange={(e) => {
                    setPeriodRange(e.target.value);
                  }}
                  label={`Select ${period.slice(0, -2)}`}
                >
                  {periodRangeValues.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div style={{
              minWidth: "10%",
              maxWidth: "10%",
            }}>
              <Button
                variant="contained"
                color="primary"
                disabled={!filterValue}
                onClick={() => {
                  loadFunnel(vendorState?.selected, filter, filterValue);
                  setAppliedPeriod(period);
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>

      {isLoadingFunnel ? (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Tabs
            value={activeTab}
            onChange={(e, tabIndex) => setActiveTab(tabIndex)}
            style={{
              background: "white",
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#4B50DC",
              }
            }}
          >
            {TABS[appliedPeriod].map((item, index) => {
              return (
                <Tab
                  key={index}
                  label={item} style={{
                    fontWeight: "bold",
                    textTransform: "none"
                  }}
                />
              )
            })}
          </Tabs>

          {/* WTD */}
          {["weekly"].includes(appliedPeriod) && activeTab === 0 && (
            <>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <div
                          className="ag-theme-material"
                          style={{ height: 550 }}
                          id="#grid-theme-wrapper"
                        >
                          <AgGridReact
                            defaultColDef={{
                              resizable: true,
                              sortable: true,
                              filter: "agTextColumnFilter",
                              headerComponentParams: {
                                template:
                                  '<div class="ag-cell-label-container" role="presentation">' +
                                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                                  "  </div>" +
                                  "</div>",
                              },
                            }}
                            columnDefs={[
                              { field: "name", pinned: true, width: 300 },
                              ...(funnel?.WTD || []).map((row, index) => ({ field: index.toString(), width: 300 }))
                            ]}
                            headerHeight={0}
                            rowData={transposeData(funnel.WTD || [])}
                            getRowStyle={(param) => {
                              if (param.rowIndex === 0) {
                                return {
                                  "font-size": "14px",
                                  "color": "gray",
                                }
                              }
                            }}
                            rowStyle={{
                              background: "white",
                            }}
                            fullWidth
                          >
                          </AgGridReact>
                        </div>
                      </Grid>
                    </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {/* WOW */}
          {["weekly"].includes(appliedPeriod) && activeTab === 1 && (
            <>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <div
                          className="ag-theme-material"
                          style={{ height: 550 }}
                          id="#grid-theme-wrapper"
                        >
                          <AgGridReact
                            defaultColDef={{
                              resizable: true,
                              sortable: true,
                              filter: "agTextColumnFilter",
                              headerComponentParams: {
                                template:
                                  '<div class="ag-cell-label-container" role="presentation">' +
                                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                                  "  </div>" +
                                  "</div>",
                              },
                            }}
                            columnDefs={[
                              { field: "name", pinned: true, width: 300 },
                              ...(funnel?.WOW || []).map((row, index) => ({ field: index.toString(), width: 300 }))
                            ]}
                            headerHeight={0}
                            rowData={transposeData(funnel.WOW || [])}
                            getRowStyle={(param) => {
                              if (param.rowIndex === 0) {
                                return {
                                  "font-size": "14px",
                                  "color": "gray",
                                }
                              }
                            }}
                            rowStyle={{
                              background: "white",
                            }}
                            fullWidth
                          >
                          </AgGridReact>
                        </div>
                      </Grid>
                    </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {/*  MTD */}
          {["monthly"].includes(appliedPeriod) && activeTab === 0 && (
            <>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <div
                          className="ag-theme-material"
                          style={{ height: 550 }}
                          id="#grid-theme-wrapper"
                        >
                          <AgGridReact
                            defaultColDef={{
                              resizable: true,
                              sortable: true,
                              filter: "agTextColumnFilter",
                              headerComponentParams: {
                                template:
                                  '<div class="ag-cell-label-container" role="presentation">' +
                                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                                  "  </div>" +
                                  "</div>",
                              },
                            }}
                            columnDefs={[
                              { field: "name", pinned: true, width: 300 },
                              ...(funnel?.MTD || []).map((row, index) => ({ field: index.toString(), width: 300 }))
                            ]}
                            headerHeight={0}
                            rowData={transposeData(funnel.MTD || [])}
                            getRowStyle={(param) => {
                              if (param.rowIndex === 0) {
                                return {
                                  "font-size": "14px",
                                  "color": "gray",
                                }
                              }
                            }}
                            rowStyle={{
                              background: "white",
                            }}
                            fullWidth
                          >
                          </AgGridReact>
                        </div>
                      </Grid>
                    </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {/* MOM */}
          {["monthly"].includes(appliedPeriod) && activeTab === 1 && (
            <>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <div
                          className="ag-theme-material"
                          style={{ height: 550 }}
                          id="#grid-theme-wrapper"
                        >
                          <AgGridReact
                            defaultColDef={{
                              resizable: true,
                              sortable: true,
                              filter: "agTextColumnFilter",
                              headerComponentParams: {
                                template:
                                  '<div class="ag-cell-label-container" role="presentation">' +
                                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                                  "  </div>" +
                                  "</div>",
                              },
                            }}
                            columnDefs={[
                              { field: "name", pinned: true, width: 300 },
                              ...(funnel?.MOM || []).map((row, index) => ({ field: index.toString(), width: 300 }))
                            ]}
                            headerHeight={0}
                            rowData={transposeData(funnel.MOM || [])}
                            getRowStyle={(param) => {
                              if (param.rowIndex === 0) {
                                return {
                                  "font-size": "14px",
                                  "color": "gray",
                                }
                              }
                            }}
                            rowStyle={{
                              background: "white",
                            }}
                            fullWidth
                          >
                          </AgGridReact>
                        </div>
                      </Grid>
                    </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {/* YTD */}
          {["yearly"].includes(appliedPeriod) && activeTab === 0 && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <div
                        className="ag-theme-material"
                        style={{ height: 550 }}
                        id="#grid-theme-wrapper"
                      >
                        <AgGridReact
                          defaultColDef={{
                            resizable: true,
                            sortable: true,
                            filter: "agTextColumnFilter",
                            headerComponentParams: {
                              template:
                                '<div class="ag-cell-label-container" role="presentation">' +
                                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                                "  </div>" +
                                "</div>",
                            },
                          }}
                          columnDefs={[
                            { field: "name", pinned: true, width: 300 },
                            ...(funnel?.YTD || []).map((row, index) => ({ field: index.toString(), width: 300 }))
                          ]}
                          headerHeight={0}
                          rowData={transposeData(funnel.YTD || [])}
                          getRowStyle={(param) => {
                            if (param.rowIndex === 0) {
                              return {
                                "font-size": "14px",
                                "color": "gray",
                              }
                            }
                          }}
                          rowStyle={{
                            background: "white",
                          }}
                          fullWidth
                        >
                        </AgGridReact>
                      </div>
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
          )}

          {/* YOY */}
          {((["weekly", "monthly"].includes(appliedPeriod) && activeTab === 2) || (["yearly"].includes(appliedPeriod) && activeTab === 1)) && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <div
                      className="ag-theme-material"
                      style={{ height: 550 }}
                      id="#grid-theme-wrapper"
                    >
                      <AgGridReact
                        defaultColDef={{
                          resizable: true,
                          sortable: true,
                          filter: "agTextColumnFilter",
                          headerComponentParams: {
                            template:
                              '<div class="ag-cell-label-container" role="presentation">' +
                              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                              "  </div>" +
                              "</div>",
                          },
                        }}
                        columnDefs={[
                          { field: "name", pinned: true, width: 300 },
                          ...(funnel?.YOY || []).map((row, index) => ({ field: index.toString(), width: 300 }))
                        ]}
                        headerHeight={0}
                        rowData={transposeData(funnel.YOY || [])}
                        getRowStyle={(param) => {
                          if (param.rowIndex === 0) {
                            return {
                              "font-size": "14px",
                              "color": "gray",
                            }
                          }
                        }}
                        rowStyle={{
                          background: "white",
                        }}
                        fullWidth
                      >
                      </AgGridReact>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
}
