import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Button } from "@material-ui/core";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import '../styles'
import { numberWithCommas } from "../../../healpers/utilityFunctions";

export default forwardRef(function Tables(props, ref) {

  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);


  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true, fileName: 'Category Performance.csv' });
      else
        gridApi.exportDataAsCsv({ fileName: 'Category Performance.csv' });
    }
  }));

  const rowStyle = { background: 'white', width: '100%' };

  const StatusRenderer = (props) => {
    let declining = props.data.performance === 'DECLINING'
    let color = 'green'
    if (declining) color = 'red'
    return <div style={{ color }}>{props.data.performance}</div>
  };


  return (
    <div className="ag-theme-material" style={{ height: 620, width: '100%' }} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={50}
        rowStyle={rowStyle}
        fullWidth
        suppressDragLeaveHidesColumns={true}
        suppressRowClickSelection={true}
        frameworkComponents={{
          statusRenderer: StatusRenderer,
        }}
      >
        <AgGridColumn headerName="Category" field="category" width={300}  />
        <AgGridColumn headerName="Previous YTD" field="previousYTDValue" valueFormatter={(params) => formatValue(params)} />
        <AgGridColumn headerName="Current YTD" field="currentYTDValue" valueFormatter={(params) => formatValue(params)} width={300} />
        <AgGridColumn headerName="Performance" field="performance" width={300} cellRenderer={"statusRenderer"} />
      </AgGridReact>
    </div>
  );
})


const formatValue = (params) => {
  if (params.value) { return numberWithCommas(params.value) } else { return '-' }
}



