const AM_RULES_TYPES = {
    ACCOUNT: "ACCOUNT",
    ASINS: "ASINS",
    CATEGORY: "CATEGORY",
    TOP_80: "TOP 80",
}

const CURRENT_RANGE_TYPES = {
    RECENT_WEEK: "RECENT WEEK",
    WTD: "WTD",
    MTD: "MTD",
    YTD: "YTD",
    DAILY: "DAILY",
    OVERALL: "OVERALL",
}

const RULE_TYPES = {
    GREATER_THAN: "GREATER THAN",
    LESS_THAN: "LESS THAN",
    EQUAL: "EQUAL",
}

const COMPARING_RANGE_TYPES = {
    PREVIOUS_YEAR_SAME_RANGE: "PREVIOUS YEAR SAME RANGE",
    PREVIOUS_PERIOD_WEEKS: "PREVIOUS PERIOD WEEKS",
    PREVIOUS_PERIOD_MONTHS: "PREVIOUS PERIOD MONTHS",
    VALUE: "VALUE",
    PREVIOUS_PERIOD_DAYS: "PREVIOUS PERIOD DAYS",
}

const TARGET_TYPES = {
    FORECAST: "FORECAST",
    PERCENTAGE: "PERCENTAGE",
    VALUE: "VALUE",
}

const AM_STATUS = {
    GREEN: "GREEN",
    RED: "RED",
    AMBER: "AMBER",
}

const AM_METRICS = {
    AMS_IMPRESSIONS: "AMS IMPRESSIONS",
    GLANCE_VIEW_CONVERSION: "GLANCE VIEW CONVERSION",
    SALES_CONVERSION: "SALES CONVERSION",
    BUYBOX: "BUYBOX",
    INVENTORY: "INVENTORY",
    COST_PER_IMPRESSION: "COST PER 1000 IMPRESSIONS",
    // REVIEWS: "REVIEWS",
    RATINGS: "RATINGS",
    PRICE_CHANGES: "PRICE CHANGES",
    // COMPETITOR_PRICE_CHANGES: "COMPETITOR PRICE CHANGES",
}
const AM_METRICS_DATA = [
    {
        name: AM_METRICS.AMS_IMPRESSIONS,
        targetType: TARGET_TYPES.FORECAST,
        accountType: ['vendor', 'seller']
    },
    { 
        name: AM_METRICS.SALES_CONVERSION, 
        targetType: TARGET_TYPES.FORECAST, 
        accountType: ['vendor', 'seller'] 
    },
    {   name: AM_METRICS.GLANCE_VIEW_CONVERSION, 
        targetType: TARGET_TYPES.FORECAST, 
        accountType: ['vendor', 'seller'] 
    },
    { 
        name: AM_METRICS.INVENTORY, 
        accountType: ['vendor', 'seller'],
        targetType: TARGET_TYPES.VALUE 
    },
    { 
        name: AM_METRICS.BUYBOX, 
        accountType: ['seller'] 
    },
    { 
        name: AM_METRICS.COST_PER_IMPRESSION, 
        accountType: ['vendor', 'seller'] 
    },
    // { 
    //     name: AM_METRICS.REVIEWS, 
    //     accountType: ['vendor', 'seller'] 
    // },
    { 
        name: AM_METRICS.RATINGS, 
        accountType: ['vendor', 'seller'],
    },
    { 
        name: AM_METRICS.PRICE_CHANGES, 
        accountType: ['vendor', 'seller'] 
    },
    // { 
    //     name: AM_METRICS.COMPETITOR_PRICE_CHANGES, 
    //     accountType: ['vendor', 'seller'] 
    // },
];

const NOTIFICATION_FREQUENCY = {
    // IMMEDIATE: "IMMEDIATE",
    DAILY: "DAILY",
    WEEKLY: "WEEKLY",
    MONTHLY: "MONTHLY",
    NEVER: "NEVER",
};

const AMS_STRATEGY = {
    NO_AMS: "NO_AMS",
    TOP_OF_SEARCH_IMPRESSIONS: "TOP_OF_SEARCH_IMPRESSIONS",
    MIX_OF_ALL: "MIX_OF_ALL",
  }


const AM_CATEGORY_PERFORMANCE = {
    GROWING: 'GROWING',
    DECLINING: 'DECLINING',
}

const AM_MARKET_SHARE = {
    LEADER: 'LEADER',
    "TOP TIER": 'TOP TIER',
    LAGGER: 'LAGGER',
}

module.exports = {
    AM_RULES_TYPES,
    CURRENT_RANGE_TYPES,
    RULE_TYPES,
    COMPARING_RANGE_TYPES,
    TARGET_TYPES,
    AM_STATUS,
    AM_METRICS,
    NOTIFICATION_FREQUENCY,
    AM_METRICS_DATA,
    AMS_STRATEGY,
    AM_CATEGORY_PERFORMANCE,
    AM_MARKET_SHARE
}