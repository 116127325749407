import * as React from "react";
import FormHelperText from "@mui/material/FormHelperText";

export default function TextInputCounter(props) {
  const textInput = props.textInput || "";
  return (
    <>
      <FormHelperText
        component={"div"}
        style={{
          margin: 0,
        }}
      >
        <div
          style={{
            textAlign: "right",
          }}
        >
          {`Characters: ${textInput.length}, Words: ${
            textInput ? textInput.trim().split(/\s+/)?.length || 0 : 0
          }`}
        </div>
      </FormHelperText>
    </>
  );
}
