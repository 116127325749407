import React, { useState, useEffect } from "react";
import {
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  TextField,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  Search as SearchIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import { Avatar } from "@material-ui/core";

// logo
import sdLogo from "../../images/SD-logo-02.jpg";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserState, useUserDispatch, signOut } from "../../context/UserContext";
import {
  useVendorState,
  useVendorDispatch,
  getAllVendors,
  setSelectedVendor,
} from "../../context/VendorContext";
import { Autocomplete } from "@material-ui/lab";

export default function Header(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var userState = useUserState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();
  var vendorState = useVendorState();
  var vendorDispatch = useVendorDispatch();
  let vendorList = [];

  // local
  var [profileMenu, setProfileMenu] = useState(null);
  var [accountType, setAccountType] = useState(1);

  const handleVendorChange = (vendor) => {
    const activeVendorType = vendorState.accounttype;
    const switchVendorType = vendor.accounttype;
    if (activeVendorType !== switchVendorType) {
      if (!props.screenType.includes(switchVendorType)) {
        props.history.push(switchVendorType === "seller" ? "/app/sales/sellerCentral" : "/app/sales/shippedCOGS");
      }
    }
  };

  useEffect(() => {
    if(userState.userData) 
      getAllVendors(vendorDispatch, userState.userData.email, props.history)
  }, [userState.userData]);

  if(vendorState.list && vendorState.list.length) {
    vendorList = vendorState.list.sort((a, b) => a.vendorname.localeCompare(b.vendorname));
  }

  return (
    <Toolbar className={classes.appBar}>
      <IconButton
        color="inherit"
        onClick={() => toggleSidebar(layoutDispatch)}
        className={classNames(
          classes.headerMenuButtonSandwich,
          classes.headerMenuButtonCollapse
        )}
      >
        {layoutState.isSidebarOpened ? (
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        ) : (
          <MenuIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        )}
      </IconButton>
      <img src={sdLogo} alt="google" className={classes.logotype} />
      <FormControl variant="outlined" style={{ display: "none" }} >
        <InputLabel id="demo-simple-select-outlined-label"  >
          Account Type
        </InputLabel>
        <Select
          value={accountType}
          onChange={(e) => {
            setAccountType(e.target.value);
            // if(e.target.value == 3)
            //   setCustomDateToggle(true)
            // else setCustomDateToggle(false)
          }}
          label="Account Type"
          className={classes.accountType}
        >
          <MenuItem value={1}>Admin</MenuItem>
          <MenuItem value={2}>Sales</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" style={{ marginLeft: 25 }}>
        <Autocomplete
          value={
            vendorState?.list?.find(
              (obj) => obj.vendorcode === vendorState?.selected
            ) ?? { vendorname: "" }
          }
          size="small"
          id="combo-box-demo"
          options={vendorList}
          getOptionLabel={(option) => option.vendorname}
          onChange={(e) => {
            const vendor = vendorList?.find(
              (obj) => obj.vendorname === e.target.textContent
            );
            if (vendor) {
              handleVendorChange(vendor);
              setSelectedVendor(vendorDispatch, vendorState, vendor.vendorcode);
            }
          }}
          onInputChange={(event, newInputValue) => {
            const vendor = vendorList?.find(
              (obj) => obj.vendorname === newInputValue
            );
            if (vendor) {
              handleVendorChange(vendor);
              setSelectedVendor(vendorDispatch, vendorState, vendor.vendorcode);
            }
          }}
          style={{ width: 400 }}
          renderInput={(params) => {
            return vendorState.list ? (
              <TextField
                {...params}
                label="Vendor"
                variant="outlined"
                disabled={
                  vendorState.list && vendorState.list.length !== 0
                    ? false
                    : true
                }
              />
            ) : (
              <TextField
                disabled
                variant="outlined"
                value="No Vendors"
                size="small"
              />
            );
          }}
        />
      </FormControl>
      
      <div style={{ display: "none" }}
        className={classNames(classes.search, {
          [classes.searchFocused]: true,
        })}
      >
        <div
          className={classNames(classes.searchIcon, {
            [classes.searchIconOpened]: true,
          })}
        >
          <SearchIcon classes={{ root: classes.headerIcon }} />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
        />
      </div>
      <div className={classes.grow} />
      <IconButton
        color="inherit"
        className={classes.headerProfile}
        aria-controls="profile-menu"
        onClick={(e) => setProfileMenu(e.currentTarget)}
      >
        <div
          className={classNames(
            classes.messageNotificationSide,
            classes.messageNotificationBodySide
          )}
          style={{
            marginRight: 10,
            marginLeft: 10,
            "&:hover, &:focus": {
              backgroundColor: "black",
            },
          }}
        >
          <Typography color="text" colorBrightness="secondary">
            {userState.userData ? userState.userData.firstName : ""} {userState.userData ? userState.userData.lastName : ""}
          </Typography>
          <Typography color="text" colorBrightness="secondary">
            {userState.userData ? userState.userData.orgPosition : ""}
          </Typography>
        </div>
        <div className={classes.messageNotificationSide}>
          <Avatar
            classes={{ root: classes.headerIcon }}
          />
        </div>
        <ExpandMoreIcon style={{ color: "#6E6E6E99" }} />
      </IconButton>
      <Menu
        id="profile-menu"
        open={Boolean(profileMenu)}
        anchorEl={profileMenu}
        onClose={() => setProfileMenu(null)}
        className={classes.headerMenu}
        classes={{ paper: classes.profileMenu }}
        disableAutoFocusItem
      >
        <div className={classes.profileMenuUser}>
          <Typography variant="h4" weight="medium">
            {userState.userData ? userState.userData.firstName : ""} {userState.userData ? userState.userData.lastName : ""}
          </Typography>
          <Typography
            className={classes.profileMenuLink}
            variant="subtitle"
            color="primary"
          >
            {userState.userData ? userState.userData.email : ""}
          </Typography>
        </div>
        <div className={classes.profileMenuUser}>
          <Typography
            className={classes.profileMenuLink}
            color="primary"
            onClick={() => signOut(userDispatch, props.history)}
          >
            Sign Out
          </Typography>
        </div>
      </Menu>
    </Toolbar>
  );
}
