import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  LinearProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  FormHelperText,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  deleteContent,
  fetchContentRealtimeInfo,
  saveContents,
  saveContentChangelogs,
} from "../../../../http/api";
import DeleteContent from "../DeleteContent/DeleteContent";
import moment from "moment";
import _ from "lodash";
import { CloseOutlined } from "@material-ui/icons";
import { useUserState } from "../../../../context/UserContext";
import {
  ATTRIBUTES,
  CHANGELOG_ACTION,
  DEFAULT_LIVE_CONTENT,
  ROLES,
  STATUS,
} from "../../../../constant/content";
import { getChangelog } from "../../../../healpers/content";
import KeywordsTable from "../KeywordsTable/KeywordsTable";
import TextInputCounter from "../TextInputCounter/TextInputCounter";
import KeywordsHighlight from "../KeywordsHighlight/KeywordsHighlight";

const EditContentItemContainer = styled("div")({
  display: "flex",
  gap: "4px",
  padding: "0px 4px",
});

const EditContentItemLabelContainer = styled(Typography)({
  width: "15%",
  textAlign: "right",
});

const getBulletedText = (text = []) => {
  return text.length > 0 ? "\u2022\u00A0" + text.join("\n\u2022\u00A0") : "";
};

const getModifiedValues = (before, after) => {
  const keyNames = {
    title: "Title",
    description: "Description",
    bulletPoints: "Bullet Points",
    keywords: "Keywords",
    hiddenKeywords: "Hidden Keywords",
    tagLines: "Tag Lines",
    recommendations: "Recommendations",
    status: "Status",
  };
  const modifiedValues = {};

  for (const key in before) {
    if (!_.isEqual(before[key], after[key])) {
      modifiedValues[keyNames[key]] = before[key];
    }
  }

  return modifiedValues;
};

const FeedbackBox = ({
  show,
  attribute,
  value,
  onChange,
  placeholder,
  width,
  content,
  setContent,
  reloadContents,
  email,
}) => {
  return show ? (
    <TextField
      value={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
      multiline
      minRows={3}
      variant="outlined"
      InputProps={{
        startAdornment: content?.internalFeedback?.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              marginBottom: "4px",
              maxHeight: "100px",
              width: "100%",
              overflow: "scroll",
            }}
          >
            {content?.internalFeedback
              ?.filter((item) => item.attribute === attribute)
              ?.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      padding: "4px 0px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                        }}
                      >
                        <Typography
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {item?.role === ROLES.REVIEWER
                            ? "Reviewer"
                            : "Creator"}
                        </Typography>
                        {item?.user && <Typography>({item.user})</Typography>}
                      </div>
                      <Typography variant="caption" component="div">
                        {moment(moment(item?.time).toISOString()).format(
                          "DD MMM, YYYY hh:mm A"
                        )}
                      </Typography>
                    </div>
                    <Typography style={{ whiteSpace: "break-spaces" }}>
                      {item?.message}
                    </Typography>
                    <Divider />
                  </div>
                );
              })}
          </div>
        ),
        endAdornment: (
          <>
            <Button
              onClick={async () => {
                const internalFeedbackDetails = {
                  user: email,
                  role: ROLES.SALESDUO,
                  attribute,
                  message: value,
                  time: moment.utc(),
                };
                const internalFeedback = [
                  internalFeedbackDetails,
                  ...content.internalFeedback,
                ];
                await saveContents(content.vendorCode, [
                  {
                    asin: content.asin,
                    vendorCode: content.vendorCode,
                    internalFeedback,
                  },
                ]);
                setContent({
                  ...content,
                  internalFeedback,
                });
                reloadContents();
                onChange("");
              }}
              disabled={!value}
              variant="contained"
              color="primary"
              size="small"
              style={{
                position: "absolute",
                bottom: "24px",
                right: "12px",
              }}
            >
              Save
            </Button>
          </>
        ),
        style: {
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        },
        inputProps: {
          style: {
            border: "1px solid #DADADA",
            borderRadius: "4px",
            padding: "4px",
            paddingRight: "0px",
          },
        },
      }}
      style={{
        width,
      }}
    />
  ) : (
    <></>
  );
};

export default function EditContent(props) {
  const userState = useUserState();
  const [content, setContent] = useState(props.content);
  const [contentUnmodified, setContentUnmodified] = useState(props.content);
  const [liveContent, setLiveContent] = useState(DEFAULT_LIVE_CONTENT);
  const [showLiveContent, setShowLiveContent] = useState(false);
  const [showFeedbackBox, setShowFeedbackBox] = useState(false);
  const [showMore, setShowMore] = useState(null);
  const [message, setMessage] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const [titleFeedback, setTitleFeedback] = useState("");
  const [descriptionFeedback, setDescriptionFeedback] = useState("");
  const [bulletPointsFeedback, setBulletPointsFeedback] = useState("");

  const [feedback, setFeedback] = useState("");
  const [internalFeedback, setInternalFeedback] = useState("");

  const [showDeleteContent, setShowDeleteContent] = useState(false);

  const loadLiveContent = async () => {
    if (liveContent?.asin) return;
    try {
      setShowLoader(true);
      const { status, data } = await fetchContentRealtimeInfo(content.asin, props.country);
      setShowLoader(false);
      if (status === "Success") {
        setLiveContent({ ...data?.[0] });
      } else {
        setLiveContent(DEFAULT_LIVE_CONTENT);
      }
    } catch (err) {
      console.log(err);
      setShowLoader(false);
      setLiveContent(DEFAULT_LIVE_CONTENT);
    }
  };

  const handleClose = () => {
    setContent({});
    setContentUnmodified({});
    setFeedback("");
    setInternalFeedback("");
    props.handleClose();
  };

  useEffect(() => {
    const handleShowMore = async () => {
      if (!showMore) {
        setShowLiveContent(false);
        setShowFeedbackBox(false);
      } else {
        if (showMore === "showLiveContent") {
          setShowFeedbackBox(false);
          setShowLiveContent(true);
          await loadLiveContent();
        } else if (showMore === "showFeedbackBox") {
          setShowLiveContent(false);
          setShowFeedbackBox(true);
        }
      }
    };
    handleShowMore();
  }, [showMore]);

  return (
    <>
      {showDeleteContent && (
        <DeleteContent
          content={content}
          handleConfirm={async () => {
            setShowDeleteContent(false);
            setShowLoader(true);
            const deleteEditContent = await deleteContent(
              content.vendorCode,
              content.asin
            );
            setShowLoader(false);
            setMessage(deleteEditContent.data);
            if (deleteEditContent.status === "Success") {
              setTimeout(() => {
                const changelogs = [];
                changelogs.push({
                  asin: content.asin,
                  vendorCode: content.vendorCode,
                  action: CHANGELOG_ACTION.DELETE,
                  ...getChangelog(contentUnmodified, {}),
                  attributes: [],
                  afterChange: {},
                  user: userState?.userData?.email,
                });
                setMessage("");
                handleClose();
                props.reloadContents(props.content.vendorCode);
                saveContentChangelogs(props.content.vendorCode, changelogs);
              }, 3000);
            } else {
              setTimeout(() => {
                setMessage("");
              }, 3000);
            }
          }}
          handleCancel={() => setShowDeleteContent(false)}
        />
      )}
      <Dialog
        open={true}
        onClose={handleClose}
        maxWidth={showMore ? "xl" : "lg"}
        fullWidth={true}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {"Edit Content"}
            <CloseOutlined
              onClick={handleClose}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          {showLoader && <LinearProgress style={{ margin: "0px 32px" }} />}
          <DialogContentText style={{ margin: "0px", padding: "0px 32px" }}>
            {message}
          </DialogContentText>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "8px",
              gap: "8px",
            }}
          >
            <EditContentItemContainer>
              <EditContentItemLabelContainer>
                ASIN:
              </EditContentItemLabelContainer>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "8px",
                  width: "85%",
                }}
              >
                <Typography>{content?.asin}</Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={showMore}
                  onChange={(event, showMore) => {
                    setShowMore(showMore);
                  }}
                  exclusive={true}
                  size="small"
                >
                  <ToggleButton
                    value="showLiveContent"
                    style={{
                      padding: "0px 7px",
                      textTransform: "none",
                    }}
                  >
                    Show Live Content
                  </ToggleButton>
                  <ToggleButton
                    value="showFeedbackBox"
                    style={{
                      padding: "0px 7px",
                      textTransform: "none",
                    }}
                  >
                    Show Feedback Box
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </EditContentItemContainer>
            <EditContentItemContainer>
              <EditContentItemLabelContainer>
                Title:
              </EditContentItemLabelContainer>
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  width: "85%",
                }}
              >
                <div
                  style={{
                    width: showMore ? "50%" : "100%",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid #E8E8E8",
                      borderRadius: "4px",
                      width: "100%",
                      padding: "12px",
                    }}
                  >
                    <KeywordsHighlight
                      text={content?.title}
                      keywords={content?.keywords}
                      onChange={(value) => {
                        setContent({
                          ...content,
                          title: value,
                        });
                      }}
                    />
                  </div>
                  <TextInputCounter textInput={content?.title} />
                </div>
                {showLiveContent && (
                  <TextField
                    label="Live Content"
                    value={liveContent?.title}
                    multiline
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: "50%",
                    }}
                  />
                )}
                <FeedbackBox
                  show={showFeedbackBox}
                  attribute={ATTRIBUTES.TITLE}
                  value={titleFeedback}
                  placeholder={"Enter title feedback"}
                  onChange={(value) => setTitleFeedback(value)}
                  content={content}
                  setContent={(content) =>
                    setContent({
                      ...content,
                    })
                  }
                  reloadContents={() =>
                    props.reloadContents(props.content.vendorCode)
                  }
                  email={userState?.userData?.email}
                  width={"50%"}
                />
              </div>
            </EditContentItemContainer>
            <EditContentItemContainer>
              <EditContentItemLabelContainer>
                Description:
              </EditContentItemLabelContainer>
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  width: "85%",
                }}
              >
                <div
                  style={{
                    width: showMore ? "50%" : "100%",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid #E8E8E8",
                      borderRadius: "4px",
                      minHeight: "150px",
                      width: "100%",
                      padding: "12px",
                    }}
                  >
                    <KeywordsHighlight
                      text={content?.description}
                      keywords={content?.keywords}
                      onChange={(value) => {
                        setContent({
                          ...content,
                          description: value,
                        });
                      }}
                    />
                  </div>
                  <TextInputCounter textInput={content?.description} />
                </div>
                {showLiveContent && (
                  <TextField
                    label="Live Content"
                    value={liveContent?.description}
                    multiline
                    minRows={4}
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: "50%",
                    }}
                  />
                )}
                <FeedbackBox
                  show={showFeedbackBox}
                  attribute={ATTRIBUTES.DESCRIPTION}
                  value={descriptionFeedback}
                  placeholder={"Enter description feedback"}
                  onChange={(value) => setDescriptionFeedback(value)}
                  content={content}
                  setContent={(content) =>
                    setContent({
                      ...content,
                    })
                  }
                  reloadContents={() =>
                    props.reloadContents(props.content.vendorCode)
                  }
                  email={userState?.userData?.email}
                  width={"50%"}
                />
              </div>
            </EditContentItemContainer>
            <EditContentItemContainer>
              <EditContentItemLabelContainer>
                Bullet Points:
              </EditContentItemLabelContainer>
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  width: "85%",
                }}
              >
                <div
                  style={{
                    width: showMore ? "33%" : "50%",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid #E8E8E8",
                      borderRadius: "4px",
                      minWidth: "300px",
                      width: "100%",
                      padding: "12px",
                    }}
                  >
                    <KeywordsHighlight
                      text={content?.bulletPoints?.join("\n")}
                      keywords={content?.keywords}
                      onChange={(value) => {
                        setContent({
                          ...content,
                          bulletPoints: value?.split("\n") || "",
                        });
                      }}
                    />
                  </div>
                  <FormHelperText
                    component={"div"}
                    style={{
                      margin: 0,
                    }}
                  >
                    <div>
                      {`Bullet Points (all) - Characters: ${
                        content?.bulletPoints?.join("\n")
                          ? content?.bulletPoints?.join("\n").length -
                            content?.bulletPoints?.length +
                            1
                          : 0
                      }, Words: ${
                        content?.bulletPoints?.join("\n")
                          ? content?.bulletPoints
                              ?.join("\n")
                              .trim()
                              .split(/\s+/)?.length || 0
                          : 0
                      }`}
                    </div>
                    {content?.bulletPoints?.map((item, index) => {
                      return (
                        <div>{`Bullet Point ${index + 1} - Characters: ${
                          item.length
                        }, Words: ${
                          item ? item.trim().split(/\s+/)?.length || 0 : 0
                        }`}</div>
                      );
                    })}
                  </FormHelperText>
                </div>
                <TextField
                  variant="outlined"
                  label="Preview"
                  multiline
                  InputProps={{
                    readOnly: true,
                  }}
                  value={getBulletedText(content?.bulletPoints)}
                  style={{
                    width: showMore ? "33%" : "50%",
                  }}
                />
                {showLiveContent && (
                  <TextField
                    variant="outlined"
                    label="Live Content"
                    multiline
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={getBulletedText(liveContent?.bulletPoints)}
                    style={{
                      width: "33%",
                    }}
                  />
                )}
                <FeedbackBox
                  show={showFeedbackBox}
                  attribute={ATTRIBUTES.BULLET_POINTS}
                  value={bulletPointsFeedback}
                  placeholder={"Enter bullet points feedback"}
                  onChange={(value) => setBulletPointsFeedback(value)}
                  content={content}
                  setContent={(content) =>
                    setContent({
                      ...content,
                    })
                  }
                  reloadContents={() =>
                    props.reloadContents(props.content.vendorCode)
                  }
                  email={userState?.userData?.email}
                  width={"33%"}
                />
              </div>
            </EditContentItemContainer>
            <EditContentItemContainer>
              <EditContentItemLabelContainer>
                Keywords:
              </EditContentItemLabelContainer>
              <KeywordsTable
                rows={(content?.keywords || []).map((item) => {
                  return typeof item === "string" ? { keyword: item } : item;
                })}
                width={"85%"}
                updateHandler={(keywords) => {
                  setContent((prevSate) => ({
                    ...prevSate,
                    keywords,
                  }));
                }}
                hiddenKeywords={content?.hiddenKeywords || ""}
                setHiddenKeywords={(hiddenKeywords) => {
                  setContent((prevSate) => ({
                    ...prevSate,
                    hiddenKeywords,
                  }));
                }}
              />
            </EditContentItemContainer>
            <EditContentItemContainer>
              <EditContentItemLabelContainer>
                Tag Lines:
              </EditContentItemLabelContainer>
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  width: "85%",
                }}
              >
                <TextField
                  variant="outlined"
                  multiline
                  value={content?.tagLines?.join("\n")}
                  onChange={(e) =>
                    setContent({
                      ...content,
                      tagLines: e.target.value?.split("\n"),
                    })
                  }
                  style={{
                    width: "50%",
                  }}
                />
                <TextField
                  variant="outlined"
                  label="Preview"
                  multiline
                  InputProps={{
                    readOnly: true,
                  }}
                  value={getBulletedText(content?.tagLines)}
                  style={{
                    width: "50%",
                  }}
                />
              </div>
            </EditContentItemContainer>
            <EditContentItemContainer>
              <EditContentItemLabelContainer>
                Recommendations:
              </EditContentItemLabelContainer>
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  width: "85%",
                }}
              >
                <TextField
                  variant="outlined"
                  multiline
                  value={content?.recommendations?.join("\n")}
                  onChange={(e) =>
                    setContent({
                      ...content,
                      recommendations: e.target.value?.split("\n"),
                    })
                  }
                  style={{
                    width: "50%",
                  }}
                />
                <TextField
                  variant="outlined"
                  label="Preview"
                  multiline
                  InputProps={{
                    readOnly: true,
                  }}
                  value={getBulletedText(content?.recommendations)}
                  style={{
                    width: "50%",
                  }}
                />
              </div>
            </EditContentItemContainer>
            <EditContentItemContainer>
              <EditContentItemLabelContainer>
                Status:
              </EditContentItemLabelContainer>
              <TextField select value={content?.status} variant="outlined">
                {Object.keys(STATUS).map((item) => (
                  <MenuItem
                    key={item}
                    value={item}
                    onClick={() => setContent({ ...content, status: item })}
                  >
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </EditContentItemContainer>
            <EditContentItemContainer>
              <EditContentItemLabelContainer>
                Internally Approved:
              </EditContentItemLabelContainer>
              <Typography
                style={{
                  width: "85%",
                }}
              >
                {`${content?.internallyApproved}`}
              </Typography>
            </EditContentItemContainer>
            <EditContentItemContainer>
              <EditContentItemLabelContainer>
                Internal Feedback:
              </EditContentItemLabelContainer>
              <TextField
                value={internalFeedback}
                placeholder="Enter feedback"
                onChange={(e) => setInternalFeedback(e.target.value)}
                multiline
                minRows={4}
                variant="outlined"
                InputProps={{
                  startAdornment: content?.internalFeedback?.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column-reverse",
                        marginBottom: "4px",
                        maxHeight: "180px",
                        width: "100%",
                        overflow: "scroll",
                      }}
                    >
                      {content?.internalFeedback?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              padding: "4px 0px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "4px",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item?.role === ROLES.REVIEWER
                                    ? "Reviewer"
                                    : "Creator"}
                                </Typography>
                                {item?.user && (
                                  <Typography>({item.user})</Typography>
                                )}
                              </div>
                              <Typography variant="caption" component="div">
                                {moment(
                                  moment(item?.time).toISOString()
                                ).format("DD MMM, YYYY hh:mm A")}
                              </Typography>
                            </div>
                            <Typography style={{ whiteSpace: "break-spaces" }}>
                              {item?.message}
                            </Typography>
                            <Divider />
                          </div>
                        );
                      })}
                    </div>
                  ),
                  style: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  },
                  inputProps: {
                    style: {
                      border: "1px solid #DADADA",
                      borderRadius: "4px",
                      padding: "4px",
                      paddingRight: "0px",
                    },
                  },
                }}
                style={{
                  width: "85%",
                }}
              />
            </EditContentItemContainer>
            <EditContentItemContainer>
              <EditContentItemLabelContainer>
                Feedback:
              </EditContentItemLabelContainer>
              <TextField
                value={feedback}
                placeholder="Enter feedback"
                onChange={(e) => setFeedback(e.target.value)}
                multiline
                minRows={4}
                variant="outlined"
                InputProps={{
                  startAdornment: content?.feedback?.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column-reverse",
                        marginBottom: "4px",
                        maxHeight: "180px",
                        width: "100%",
                        overflow: "scroll",
                      }}
                    >
                      {content?.feedback?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              padding: "4px 0px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {item?.role === ROLES.CUSTOMER
                                  ? "Customer"
                                  : "SalesDuo"}
                              </Typography>
                              <Typography variant="caption" component="div">
                                {moment(
                                  moment(item?.time).toISOString()
                                ).format("DD MMM, YYYY hh:mm A")}
                              </Typography>
                            </div>
                            <Typography style={{ whiteSpace: "break-spaces" }}>
                              {item?.message}
                            </Typography>
                            <Divider />
                          </div>
                        );
                      })}
                    </div>
                  ),
                  style: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  },
                  inputProps: {
                    style: {
                      border: "1px solid #DADADA",
                      borderRadius: "4px",
                      padding: "4px",
                      paddingRight: "0px",
                    },
                  },
                }}
                style={{
                  width: "85%",
                }}
              />
            </EditContentItemContainer>
            <EditContentItemContainer>
              <EditContentItemLabelContainer>
                Last modified on:
              </EditContentItemLabelContainer>
              <Typography
                style={{
                  width: "85%",
                }}
              >
                {content?.updatedAt
                  ? moment(content.updatedAt).format("MM-DD-YYYY")
                  : "-"}
              </Typography>
            </EditContentItemContainer>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() => setShowDeleteContent(true)}
            variant="contained"
            color="secondary"
            size="small"
          >
            Delete
          </Button>
          <Button
            onClick={async () => {
              setShowLoader(true);
              const saveData = { ...content };
              const modifiedValues = getModifiedValues(
                contentUnmodified,
                content
              );

              if (Object.keys(modifiedValues).length > 0) {
                let message = "\nChanged from:\n";
                Object.keys(modifiedValues).forEach((key) => {
                  message =
                    message +
                    "  " +
                    key +
                    ": " +
                    (_.isArray(modifiedValues[key])
                      ? "\n    " +
                        (key === "Keywords"
                          ? modifiedValues[key].map((item) =>
                              typeof item === "string" ? item : item.keyword
                            )
                          : modifiedValues[key]
                        ).join("\n    ")
                      : modifiedValues[key]) +
                    "\n";
                });
                const changeDetails = {
                  message:
                    "(System generated message)\nContent attribute(s) modified." +
                    message,
                  time: moment.utc(),
                };

                saveData.feedback = [
                  {
                    user: userState?.userData?.email,
                    role: ROLES.SALESDUO,
                    ...changeDetails,
                  },
                  ...saveData.feedback,
                ];
                saveData.internalFeedback = [
                  {
                    user: userState?.userData?.email,
                    role: ROLES.CREATOR,
                    ...changeDetails,
                  },
                  ...saveData.internalFeedback,
                ];
              }

              if (feedback) {
                const feedbackDetails = {
                  user: userState?.userData?.email,
                  role: ROLES.SALESDUO,
                  message: feedback,
                  time: moment.utc(),
                };
                saveData.feedback = [feedbackDetails, ...saveData.feedback];
              }
              if (internalFeedback) {
                const internalFeedbackDetails = {
                  user: userState?.userData?.email,
                  role: ROLES.CREATOR,
                  message: internalFeedback,
                  time: moment.utc(),
                };
                saveData.internalFeedback = [
                  internalFeedbackDetails,
                  ...saveData.internalFeedback,
                ];
              }
              const saveEditContent = await saveContents(content.vendorCode, [
                {
                  ...saveData,
                },
              ]);
              setShowLoader(false);
              setMessage(saveEditContent.data);
              if (saveEditContent.status === "Success") {
                setTimeout(() => {
                  const changelogs = [];
                  changelogs.push({
                    asin: content.asin,
                    vendorCode: content.vendorCode,
                    action: CHANGELOG_ACTION.UPDATE,
                    ...getChangelog(contentUnmodified, content),
                    user: userState?.userData?.email,
                  });
                  setMessage("");
                  handleClose();
                  props.reloadContents(props.content.vendorCode);
                  saveContentChangelogs(props.content.vendorCode, changelogs);
                }, 3000);
              } else {
                setTimeout(() => {
                  setMessage("");
                }, 3000);
              }
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
