/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Breadcrumbs,
  Button,
  Link,
  Typography,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import "date-fns";
import axios from "axios";
import XLSX from "xlsx";

//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
//context
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";

import useStyles from "./styles";

export default function ASINsOnboarding(props) {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  var vendorState = useVendorState();
  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [headerNames, setHeaderNames] = useState([]);

  useEffect(() => {
    if (vendorState.selected) {
      fetchData();
    }

    return () => {
      setmainTableData([]);
    };
  }, [vendorState.selected]);

  async function fetchData() {
    try {
      setfullPageLoader(true);
      const response = await axios.get(
        BASE_URL + api.catalogGetAPI,
        tokenConfig({
          vendorCode: vendorState.selected,
        })
      );

      if (response?.data?.data) {
        const data = response.data.data;
        const filteredKeys = [
          "id",
          "created_at",
          "updated_at",
          "vendorCode",
          "supported",
        ];
        const headerNames = Object.keys(data[0])
          .filter((key) => filteredKeys.indexOf(key) < 0)
          .map((key) => {
            return {
              headerName: key
                .replaceAll(/[A-Z]/g, (x) => ` ${x}`)
                .replace(/^\w/g, (x) => x.toUpperCase()),
              field: key,
            };
          });
        setHeaderNames(headerNames);
        setmainTableData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setfullPageLoader(false);
    }
  }

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="ASINs"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                // href="/#/app/dashboard"
                className={classes.link}
              >
                Onboarding
              </Link>
              <Typography className={classes.link}>
                <EqualizerIcon color="primary" className={classes.icon} />
                ASINs
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<PrintIcon />}
              disabled={mainTableData.length === 0}
              onClick={() => {
                const worksheet = XLSX.utils.json_to_sheet([
                  ...mainTableData
                    .map((item) => item.asin)
                    .sort()
                    .map((item) => ({ ASIN: item })),
                ]);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                const exportFile = `ASINs_${vendorState?.selected}.xlsx`;
                XLSX.writeFile(workbook, exportFile);
              }}
              style={{
                height: "fit-content",
              }}
            >
              Export
            </Button>
          }
        />
        <Widget noBodyPadding bodyClass={classes.tableOverflow}>
          <Table
            ref={exportFunctionRef}
            tableData={mainTableData}
            headerNames={headerNames}
            classes={classes}
            fetchData={fetchData}
          />
        </Widget>
        <Backdrop className={classes.backdrop} open={fullPageLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
