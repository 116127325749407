import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";

import { BASE_URL } from "../../../../healpers/api";
import api from "../../../../healpers/apiRoutes";
import axios from "axios";

export default function DraggableDialog({
  open,
  closeDialog,
  deleteAddressId,
  setDeleteAddressId,
  setnotification,
  seterr,
  setfullPageLoader,
}) {
  async function deleteCasePackDetails() {
    try {
      setfullPageLoader(true);
      await axios.post(BASE_URL + api.deleteCasePackDetails, {
        id: deleteAddressId,
      });
      setnotification("ASIN deleted");
      setTimeout(() => setnotification(null), 3000);
    } catch (err) {
      seterr("Error occured");
      setTimeout(() => seterr(null), 3000);
      console.log(err);
    } finally {
      setDeleteAddressId(null);
      closeDialog();
      setfullPageLoader(false);
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={closeDialog} fullWidth={true}>
        <Paper>
          <DialogTitle>Confirm delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This row will be permanently deleted
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              variant="outlined"
              onClick={() => {
                closeDialog();
                setDeleteAddressId(null);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={deleteCasePackDetails}
              color="primary"
            >
              Delete
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
}
