import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";
import { BASE_URL } from "../../../../healpers/api";
import api from "../../../../healpers/apiRoutes";

// import EditAddressModal from "../EditAddressModal/EditAddressModal";
import DeleteCasePackDialog from "../DeleteCasePackDialog/DeleteCasePackDialog";
import EditCasePackModal from "../EditCasePackModal/EditCasePackModal";

export default forwardRef(function Tables(
  {
    tableData,
    setnotification,
    seterr,
    setfullPageLoader,
    fetchCasePackDetails,
    isDeleteDialogOpen,
    setDeleteDialogOpen,
    setDeleteDialogClose,
  },
  ref
) {
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [editCasePack, setEditCasePack] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  console.log({ selectedRowData });
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const columnKeys = [
    "asin",
    "sku",
    "unitsPerCase",
    "length",
    "width",
    "height",
    "weight",
    "expirationDate",
  ];
  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({
          onlySelected: true,
          columnKeys,
        });
      else gridApi.exportDataAsCsv({ columnKeys });
    },
  }));

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#F6F7FF" };
    }
  };

  const rowStyle = { background: "white" };

  const EditButtonRenderer = (props) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={(e) => {
          setEditCasePack(true);
          setSelectedRowData(props.data);
        }}
      >
        EDIT
      </Button>
    );
  };

  const DeleteButtonRenderer = (props) => {
    return (
      <Button
        variant="contained"
        color="secondary"
        size="medium"
        onClick={() => {
          setDeleteDialogOpen();
          setDeleteId(props.data.id);
        }}
      >
        DELETE
      </Button>
    );
  };

  return (
    <div
      className="ag-theme-material"
      style={{ height: 620, width: "100%" }}
      id="#grid-theme-wrapper"
    >
      <AgGridReact
        ref={gridRef}
        rowData={tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        getRowStyle={getRowStyle}
        suppressDragLeaveHidesColumns={true}
        frameworkComponents={{
          editButtonRenderer: EditButtonRenderer,
          deleteButtonRenderer: DeleteButtonRenderer,
        }}
      >
        <AgGridColumn headerName="ASIN" field="asin" width={150} />
        <AgGridColumn headerName="SKU" field="sku" width={200} />
        <AgGridColumn
          headerName="Units Per Case"
          field="unitsPerCase"
          width={150}
        />
        <AgGridColumn headerName="Length (IN)" field="length" width={150} />
        <AgGridColumn headerName="Width (IN)" field="width" width={150} />
        <AgGridColumn headerName="Height (IN)" field="height" width={150} />
        <AgGridColumn headerName="Weight (LB)" field="weight" width={150} />
        <AgGridColumn
          headerName="Expiration Date (Days)"
          field="expirationDate"
          width={150}
        />
        {/* <AgGridColumn
          width={120}
          headerName="Edit"
          cellRenderer="editButtonRenderer"
        /> */}
        <AgGridColumn
          width={120}
          headerName="Delete"
          cellRenderer="deleteButtonRenderer"
        />
      </AgGridReact>
      <EditCasePackModal
        showmodal={editCasePack}
        closeModal={() => {
          setEditCasePack(false);
          fetchCasePackDetails();
        }}
        toggleError={(msg) => {
          seterr(msg);
          setTimeout(() => seterr(null), 3000);
        }}
        toggleNotification={(msg) => {
          setnotification(msg);
          setTimeout(() => setnotification(null), 3000);
        }}
        setfullPageLoader={setfullPageLoader}
        selectedRowData={selectedRowData}
        setSelectedRowData={setSelectedRowData}
      />
      <DeleteCasePackDialog
        open={isDeleteDialogOpen}
        closeDialog={() => {
          setDeleteDialogClose();
          fetchCasePackDetails();
        }}
        deleteAddressId={deleteId}
        setDeleteAddressId={setDeleteId}
        setnotification={setnotification}
        seterr={seterr}
        setfullPageLoader={setfullPageLoader}
        fetchCasePackDetails={fetchCasePackDetails}
      />
    </div>
  );
});
